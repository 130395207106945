import type { FreightForwarderSingleOption } from 'utils/api/FreightForwarderAPI';
import { FreightForwarderPill } from './FreightForwarderPill';

interface FreightForwarderListProps {
	freightForwarders: FreightForwarderSingleOption[] | undefined;
	emptyText: string;
}

export const FreightForwarderList = ({
	freightForwarders,
	emptyText,
}: FreightForwarderListProps) => {
	if (freightForwarders && freightForwarders.length > 0) {
		return (
			<>
				{freightForwarders?.map((freightForwarder) => (
					<>
						<FreightForwarderPill
							key={freightForwarder.freightForwarderId}
							freightForwarder={freightForwarder}
						/>{' '}
					</>
				))}
			</>
		);
	}

	return <>{emptyText}</>;
};
