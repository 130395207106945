import {
	AccountEntity,
	AccountInfo,
	BrowserAuthError,
	InteractionRequiredAuthError,
	PublicClientApplication,
	RedirectRequest,
	SilentRequest,
	SsoSilentRequest,
} from '@azure/msal-browser';
import { CacheAccountType } from '@azure/msal-common';

import { BrowserCacheManager } from '@azure/msal-browser/dist/internals';
import { Eraser } from 'lucide-react';
import { ToastType } from 'ui/components/Toaster/Toast';
import { catchWithError } from './catchHandlers';
import { clearSession } from './session';
import { createToast } from './toast';

const MSAL_LOGIN_HINT_KEY = 'msal.loginHint';

export const clearMsalCache = (msalInstance: PublicClientApplication) => {
	clearSession();
	(msalInstance as any).browserStorage.clear();
};

export const performLoginRedirect = async (
	msalInstance: PublicClientApplication,
	request: RedirectRequest
) => {
	const url = new URL(window.location.href);
	const returnUrl = url.searchParams.get('returnUrl');

	return msalInstance
		.loginRedirect({
			...request,
			redirectStartPage: returnUrl ?? '/',
		})
		.catch(catchWithError);
};

export const performLogoutRedirect = async (
	msalInstance: PublicClientApplication,
	redirectUri = '/logged-out'
) => {
	localStorage.removeItem(MSAL_LOGIN_HINT_KEY);

	clearSession();

	return msalInstance
		.logoutRedirect({
			account: msalInstance.getActiveAccount(),
			postLogoutRedirectUri: redirectUri,
		})
		.catch(catchWithError);
};

export const clearAllMsalData = (msalInstance: PublicClientApplication) => {
	clearMsalCache(msalInstance);

	const sessionProviders = [localStorage, sessionStorage];

	for (const provider of sessionProviders) {
		const msalKeys = Object.keys(provider).filter((key) => {
			return key.startsWith('msal.');
		});

		for (const key of msalKeys) {
			provider.removeItem(key);
		}
	}
};

export const performMsalAuth = async (
	msalInstance: PublicClientApplication,
	scope: string
) => {
	if (
		window.location.pathname === '/logged-out' ||
		'isPlaywright' in localStorage
	)
		return;

	const existingAccounts = msalInstance.getAllAccounts();
	const loginHint = localStorage.getItem(MSAL_LOGIN_HINT_KEY);

	try {
		if (existingAccounts.length === 0) {
			const ssoSilentRequest: SsoSilentRequest = {
				scopes: [scope],
				loginHint: loginHint ?? undefined,
			};
			await msalInstance.ssoSilent(ssoSilentRequest);
		} else if (existingAccounts.length === 1) {
			const aquireTokenRequest: SilentRequest = {
				scopes: [scope],
				account: existingAccounts[0],
			};

			await msalInstance.acquireTokenSilent(aquireTokenRequest);
		}

		const accountsAfterAuth = msalInstance.getAllAccounts();
		if (accountsAfterAuth.length > 0) {
			msalInstance.setActiveAccount(accountsAfterAuth[0]);
		}

		const activeAccount = msalInstance.getActiveAccount();
		const activeAccountEmail = activeAccount?.idTokenClaims?.email as
			| string
			| undefined;

		if (activeAccountEmail) {
			localStorage.setItem(MSAL_LOGIN_HINT_KEY, activeAccountEmail);
		}
	} catch (error) {
		const hasLoginHint = !!localStorage.getItem(MSAL_LOGIN_HINT_KEY);

		await handleMsalError(msalInstance, error, hasLoginHint);
	}
};

export const handleMsalError = async (
	msalInstance: PublicClientApplication,
	error: unknown,
	redirectIfNecessary = true
) => {
	const existingAccounts = msalInstance.getAllAccounts();

	//Acquire token silent failure, and send an interactive request
	if (error instanceof InteractionRequiredAuthError && redirectIfNecessary) {
		const accessTokenRequest: RedirectRequest = {
			scopes: [window.msal.scope],
			account: existingAccounts[0],
		};

		await window.msal.instance.acquireTokenRedirect(accessTokenRequest);
	} else if (error instanceof BrowserAuthError) {
		/* 
			Error 1: 	BrowserAuthError: block_iframe_reload: 
						Request was blocked inside an iframe because MSAL detected an authentication 
						response. For more visit: aka.ms/msaljs/browser-errors

			Error 2: 	BrowserAuthError: monitor_window_timeout:
						Token acquisition in iframe failed due to timeout.
						For more visit: aka.ms/msaljs/browser-errors.
		*/

		console.error(error);

		createToast(
			ToastType.ERROR,
			{
				message: 'An error occured while trying to authenticate.',
				hint: [
					'Try clearing the cache by clicking the eraser and reloading the page.',
					'If the error persists, please contact us.',
				],
				actions: [
					{
						icon: Eraser,
						label: 'Clear cache',
						onClick: () => {
							clearMsalCache(window.msal.instance);
							window.location.reload();
						},
					},
				],
			},
			{
				duration: Infinity,
			}
		);
	} else {
		throw error;
	}
};

export const handlePendingLogout = () => {
	/**
	 * We can detect whether a user has logged out in another window
	 * by checking whether the login hint is still set. If the user
	 * is logged in, but the login hint is not set, then the user has logged out
	 * elsewhere, and we can mirror this by performing a logout in the local tab, too.
	 */

	const isLoggedIn = window.msal.instance.getActiveAccount() !== null;
	const hasLoginHint = localStorage.getItem(MSAL_LOGIN_HINT_KEY) !== null;

	if (isLoggedIn && !hasLoginHint) {
		performLogoutRedirect(window.msal.instance);
	}
};

export const isInIframe = () => window.self !== window.top;

export const mockMsalAccount = (msalInstance: PublicClientApplication) => {
	const DUMMY_ACCOUNT: AccountInfo = {
		homeAccountId: '<homeAccountId>',
		environment: '<environment>',
		tenantId: '<tenantId>',
		username: 'testuser@example.org',
		localAccountId: '<localAccountId>',
		name: 'Test User',
	};

	const account: AccountEntity = new AccountEntity();

	account.authorityType = CacheAccountType.GENERIC_ACCOUNT_TYPE;
	account.homeAccountId = DUMMY_ACCOUNT.homeAccountId;
	account.localAccountId = DUMMY_ACCOUNT.localAccountId;
	account.nativeAccountId = DUMMY_ACCOUNT.nativeAccountId;

	account.realm = DUMMY_ACCOUNT.tenantId;
	account.environment = DUMMY_ACCOUNT.environment;

	account.username = DUMMY_ACCOUNT.username;
	account.name = DUMMY_ACCOUNT.name;

	account.cloudGraphHostName = '<cloudGraphHostName>';
	account.msGraphHost = '<msGraphHost>';

	const browserStorage = (msalInstance as any)
		.browserStorage as BrowserCacheManager;

	browserStorage.setAccount(account);
	msalInstance.setActiveAccount(DUMMY_ACCOUNT);
};
