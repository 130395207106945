import { ChevronRight } from 'lucide-react';
import { Fragment } from 'react';
import { Link, useLocation, useMatches } from 'react-router-dom';
import { RouteHandle } from 'utils/types/common';

export type Breadcrumb = {
	label: string;
	path?: string;
};

type BreadcrumbsProps = {};

const Breadcrumbs = ({}: BreadcrumbsProps) => {
	const location = useLocation();
	const matches = useMatches();

	let crumbs: Required<Breadcrumb>[] = [];

	for (const match of matches) {
		const handle = match?.handle as RouteHandle | undefined;
		const getMatchBreadcrumbs = handle?.breadcrumbs;

		let breadcrumbOrBreadcrumbs: Breadcrumb[] | Breadcrumb | undefined;

		// Either a function or an array of breadcrumbs, or a breadcrumb
		if (typeof getMatchBreadcrumbs === 'function') {
			breadcrumbOrBreadcrumbs = getMatchBreadcrumbs({
				params: match.params,
				data: match.data,
			});
		} else if (getMatchBreadcrumbs !== undefined) {
			breadcrumbOrBreadcrumbs = getMatchBreadcrumbs;
		}

		if (!breadcrumbOrBreadcrumbs) {
			continue;
		}

		// Now make sure that we have an array of breadcrumbs
		const breadcrumbs = Array.isArray(breadcrumbOrBreadcrumbs)
			? breadcrumbOrBreadcrumbs
			: [breadcrumbOrBreadcrumbs];

		// Make sure path is populated
		const breadcrumbsWithPath = breadcrumbs.map((crumb) => ({
			...crumb,
			path: crumb.path ?? match.pathname,
		}));

		crumbs = [...crumbs, ...breadcrumbsWithPath];
	}

	// Filter breadcrumbs that reference the current page
	crumbs = crumbs.filter((crumb) => {
		const normalizedCrumbPath = crumb.path?.replace(/\/$/, '');
		const normalizedLocationPath = location.pathname.replace(/\/$/, '');

		if (normalizedCrumbPath === normalizedLocationPath) {
			return false;
		}

		return true;
	});

	if (crumbs.length === 0) {
		return null;
	}

	return (
		<ol className="breadcrumbs">
			{crumbs.map((crumb, index) => (
				<Fragment key={crumb.path}>
					<li className="breadcrumbs__item" key={crumb.path}>
						<Link to={crumb.path}>{crumb.label}</Link>
					</li>

					{index < crumbs.length - 1 && (
						<li
							className="breadcrumbs__chevron"
							role="presentation"
							aria-hidden
							key={`chevron-${crumb.path}`}
						>
							<ChevronRight size={14} />
						</li>
					)}
				</Fragment>
			))}
		</ol>
	);
};

export default Breadcrumbs;
