const pluralize = (word: string, count: number) => {
	if (Math.abs(count) === 1) {
		return word;
	}

	const vowels = ['a', 'e', 'i', 'o', 'u'];

	if (word.endsWith('y') && !vowels.includes(word[word.length - 2])) {
		return `${word.slice(0, -1)}ies`;
	}

	const esList = ['s', 'ss', 'sh', 'ch', 'x', 'z', 'o'];
	if (esList.some((ending) => word.endsWith(ending))) {
		return `${word}es`;
	}

	const vesList = ['f', 'fe'];
	if (vesList.some((ending) => word.endsWith(ending))) {
		return `${word.slice(0, -1)}ves`;
	}

	return `${word}s`;
};

export default pluralize;
