/**
 *
 * Old component
 */

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { format, isSameMonth } from 'date-fns';
import { msalInstance } from 'iata-cargois-client-portal/src/auth/authConfig';
import { CSSProperties, useMemo } from 'react';
import DateFragment from 'ui/components/DateFragment/DateFragment';
import Flex from 'ui/components/Flex/Flex';
import Table from 'ui/components/Table/Table';
import Toggle from 'ui/components/Toggle/Toggle';
import useFormReset from 'ui/components/ValidatedForm/useFormReset';
import { Dimension } from 'utils/api/WebToolAPI';
import { sortBySortOrderArray } from 'utils/helpers/sorting';
import WorksheetLayoutBuilder, {
	LayoutBuilderMode,
} from './LayoutBuilder/LayoutBuilder';
import { useWorksheetContext } from './WorksheetContext';
import WorksheetSection from './WorksheetSection';

type WorksheetLayoutProps = {};

const WorksheetLayout = ({}: WorksheetLayoutProps) => {
	const {
		state,
		setDeepState,
		isLayoutPanelOpen,
		setIsLayoutPanelOpen,
		derivedState,
	} = useWorksheetContext();

	useFormReset(() => {
		setDeepState('layout.displayReportParameters', false);
	});

	const layoutColumnHelper = createColumnHelper<any>();

	const fieldDimensions = useMemo(() => {
		return [...derivedState.selectedFieldDimensions]
			.filter(
				(dimension) => !state.layout.columnsSortOrder.includes(dimension.id)
			)
			.sort(sortBySortOrderArray(state.layout.fieldSortOrder, 'id'));
	}, [derivedState.selectedFieldDimensions, state.layout.fieldSortOrder]);

	const dataFieldDimensions = useMemo(() => {
		return [...derivedState.selectedDataFieldDimensions].sort(
			sortBySortOrderArray(state.layout.dataFieldSortOrder, 'id')
		);
	}, [
		derivedState.selectedDataFieldDimensions,
		state.layout.dataFieldSortOrder,
	]);

	const columnDimensions = useMemo(() => {
		return [
			...derivedState.selectedFieldDimensions,
			...derivedState.selectedDataFieldDimensions,
		]
			.filter((d) => state.layout.columnsSortOrder.includes(d.id))
			.sort(sortBySortOrderArray(state.layout.columnsSortOrder, 'id'));
	}, [
		derivedState.selectedFieldDimensions,
		derivedState.selectedDataFieldDimensions,
		state.layout.columnsSortOrder,
	]);

	const columnPivotGroup = useMemo(() => {
		// Recursively create groups for each item of columnDimensions
		const createGroup = (
			dimensions: Dimension[],
			identifier = 0
		): ColumnDef<any, any>[] => {
			if (dimensions.length === 0) {
				return dataFieldDimensions.map((dimension) =>
					layoutColumnHelper.display({
						id: `${dimension.id}#${identifier}`,
						header: dimension.name,
					})
				);
			}

			const dimension = dimensions[0];

			return [
				layoutColumnHelper.group({
					id: `${dimension.id}#${identifier + 100}`,
					header: `${dimension.name} 1`,
					columns: createGroup(dimensions.slice(1), identifier),
				}),
			];
		};

		if (state.parameters.dateRange.selectedType === 'compare') {
			const formatRange = (rangeA: Date, rangeB: Date) => {
				if (isSameMonth(rangeA, rangeB)) {
					return format(rangeA, 'MMM yyyy');
				} else {
					return `${format(rangeA, 'MMM yyyy')} - ${format(
						rangeB,
						'MMM yyyy'
					)}`;
				}
			};

			return [
				layoutColumnHelper.group({
					id: 'compare-a',
					header: formatRange(
						state.parameters.dateRange.compare.startDate,
						state.parameters.dateRange.compare.endDate
					),
					columns: createGroup(columnDimensions, 1),
				}),
				layoutColumnHelper.group({
					id: 'compare-b',
					header: formatRange(
						state.parameters.dateRange.compare.compareStartDate,
						state.parameters.dateRange.compare.compareEndDate
					),
					columns: createGroup(columnDimensions, 2),
				}),
				layoutColumnHelper.group({
					id: 'compare-diff',
					header: 'Diff',
					columns: createGroup(columnDimensions, 3),
				}),
				layoutColumnHelper.group({
					id: 'compare-diff-percentage',
					header: 'Diff %',
					columns: createGroup(columnDimensions, 4),
				}),
			];
		}

		return createGroup(columnDimensions);
	}, [
		columnDimensions,
		dataFieldDimensions,
		state.parameters.dateRange.selectedType,
		state.parameters.dateRange.compare.startDate,
		state.parameters.dateRange.compare.endDate,
		state.parameters.dateRange.compare.compareStartDate,
		state.parameters.dateRange.compare.compareEndDate,
	]);

	const fieldDimensionsGroup = useMemo(() => {
		const groupLevel = columnDimensions.length;

		if (fieldDimensions.length === 0) {
			return [];
		}

		const createGroup = (level = 0): ColumnDef<any, any>[] => {
			if (level === groupLevel) {
				return fieldDimensions.map((dimension) =>
					layoutColumnHelper.display({
						id: `${dimension.id}`,
						header: dimension.name,
					})
				);
			}

			return [
				layoutColumnHelper.group({
					id: `field-group-${level}`,
					header: columnDimensions[level].name,
					columns: createGroup(level + 1),
					meta: {
						headerAlignment: 'right' as const,
					},
				}),
			];
		};

		if (state.parameters.dateRange.selectedType === 'compare') {
			return [
				{
					id: 'compare',
					header: 'Comparison',
					columns: createGroup(),
					meta: {
						headerAlignment: 'right' as const,
					},
				},
			];
		}

		return createGroup();
	}, [
		fieldDimensions,
		columnDimensions,
		state.parameters.dateRange.selectedType,
	]);

	const layoutColumns = [...fieldDimensionsGroup, ...columnPivotGroup];

	return (
		<WorksheetSection
			title="Report Layout (Optional)"
			style={{ '--parameters-grid': 'minmax(100%, 1fr)' } as CSSProperties}
			isOpen={isLayoutPanelOpen}
			onOpenChange={setIsLayoutPanelOpen}
		>
			<Flex direction="column" gap={24}>
				<Flex direction="column" gap={8}>
					<Flex justifyContent="space-between" gap={0}>
						<b>
							Table Layout:{' '}
							{derivedState.layoutBuilderMode === LayoutBuilderMode.PIVOT
								? 'Pivot'
								: 'Tabular'}
						</b>
						<Flex alignItems="center" gap={8}>
							<Toggle
								size="small"
								isChecked={state.layout.displayReportParameters}
								onChange={() =>
									setDeepState(
										'layout.displayReportParameters',
										!state.layout.displayReportParameters
									)
								}
							/>{' '}
							Display Report Parameter On Export
						</Flex>
					</Flex>

					<Flex gap={16} direction="column">
						<Table
							className="table--grouped"
							data={[]}
							columns={layoutColumns}
							identifierKey="id"
						/>

						{state.layout.displayReportParameters && (
							<div>
								Report Generation:{' '}
								<DateFragment date={new Date()} includeTime /> by{' '}
								{msalInstance.getAllAccounts()[0].username}
								<br />
								Report Parameters: XXX
							</div>
						)}
					</Flex>
				</Flex>

				<WorksheetLayoutBuilder />
			</Flex>
		</WorksheetSection>
	);
};

export default WorksheetLayout;
