import { PlaneTakeoff } from 'lucide-react';
import { useState } from 'react';
import Button from 'ui/components/Button';
import Flex from 'ui/components/Flex';
import FormField from 'ui/components/FormField';
import Modal from 'ui/components/Modal';
import Separator from 'ui/components/Separator';
import TradelaneList from 'ui/components/TradelaneList';
import TradelanesField from 'ui/components/TradelanesField';
import { useWorksheetContext } from './WorksheetContext';

interface WorksheetTradelanesPickerProps {}

const WorksheetTradelanesPicker = (props: WorksheetTradelanesPickerProps) => {
	const [isPickerModalOpen, setIsPickerModalOpen] = useState(false);

	const { setDeepState, state, worksheet } = useWorksheetContext();

	const customGroups = worksheet.config.webToolGroups.filter(
		(g) => g.groupType === 'trade-lane'
	);

	return (
		<>
			<FormField label="Tradelanes">
				<Flex direction="column" gap={4}>
					<TradelaneList
						tradelanes={state.parameters.location.tradelanes}
						emptyText="No tradelanes"
					/>

					<Separator orientation="horizontal" />

					<Button
						type="button"
						size="small"
						variant="secondary"
						icon={PlaneTakeoff}
						onClick={() => setIsPickerModalOpen(true)}
					>
						Manage tradelanes
					</Button>
				</Flex>
			</FormField>

			<Modal
				isOpen={isPickerModalOpen}
				onClose={() => setIsPickerModalOpen(false)}
				title="Manage Tradelanes"
				size="lg"
			>
				<Modal.Body>
					<TradelanesField
						label="Selected Tradelanes"
						emptyText="No tradelanes selected"
						value={state.parameters.location.tradelanes ?? []}
						onChange={(value) =>
							setDeepState('parameters.location.tradelanes', value)
						}
						locationTypes={worksheet.config.parameters.tradeLaneLocationTypes}
						name="tradelanes"
						customTradelaneGroups={customGroups}
					/>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default WorksheetTradelanesPicker;
