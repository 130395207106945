import { createColumnHelper } from '@tanstack/react-table';
import { AlertCircle } from 'lucide-react';
import { Link } from 'react-router-dom';
import { AirlineList } from 'ui/components/AirlinePicker';
import DateFragment from 'ui/components/DateFragment';
import Flex from 'ui/components/Flex';
import { subscriptionsApplicableForAirlines } from 'ui/components/SubscriptionAirlinePicker';
import Tooltip from 'ui/components/Tooltip';
import TooltipContent from 'ui/components/Tooltip/TooltipContent';
import TooltipTrigger from 'ui/components/Tooltip/TooltipTrigger';
import {
	SubscriptionAirlinesGroup,
	WebToolAirlineGroupItem,
} from 'utils/api/WebToolGroupAPI';

const columnHelper = createColumnHelper<WebToolAirlineGroupItem>();

export const createdAtColumn = columnHelper.accessor('createdAt', {
	header: 'Created Date',
	cell: (info) => <DateFragment includeTime={true} date={info.getValue()} />,
	enableSorting: false,
});

export const nameColumn = columnHelper.accessor('name', {
	header: 'Name',
	cell: (info) => (
		<Link to={`/webtool/groups/airline/${info.row.original.id}`}>
			{info.getValue()}
		</Link>
	),
});

export const nameWithApplicabilityCheckColumn = (
	subscriptionGroups: SubscriptionAirlinesGroup[]
) => {
	return columnHelper.accessor('name', {
		header: 'Name',
		cell: (item) => {
			const applicableSubscriptions = subscriptionsApplicableForAirlines(
				subscriptionGroups,
				item.row.original.airlines
			);

			return (
				<Flex alignItems="center" gap={6}>
					<Link to={`/webtool/groups/airline/${item.row.original.id}`}>
						{item.getValue()}
					</Link>
					{applicableSubscriptions.length === 0 ? (
						<Tooltip>
							<TooltipContent>
								This group cannot be used in the Web Tool because the selected
								airlines do not reside in one subscription.
							</TooltipContent>
							<TooltipTrigger>
								<AlertCircle className="icon--error" size={20} />
							</TooltipTrigger>
						</Tooltip>
					) : null}
				</Flex>
			);
		},
	});
};

export const descriptionColumn = columnHelper.accessor('description', {
	header: 'Description',
});

export const airlinesColumn = columnHelper.display({
	id: 'airlines',
	header: 'Airlines',
	cell: (item) => (
		<AirlineList airlines={item.row.original.airlines} emptyText="-" />
	),
});

export const conflictsColumn = columnHelper.display({
	id: 'conflicts',
	header: 'Airline Overlaps',
	cell: (item) =>
		item.row.original.conflicts.map((conf, i) => (
			<>
				{i !== 0 ? ', ' : ''}
				<span> {conf.name} </span>
			</>
		)),
});
