import FormField, { FormFieldProps } from 'ui/components/FormField/FormField';
import Select, { SelectProps } from 'ui/components/Select/Select';
import useValidation from 'ui/components/ValidatedForm/useValidation';

/******************************************
  WARNING: Temporary component to get started
  needs improvement to bring the native select options with it
	rather than accepting one by one
******************************************/

interface DropdownFieldProps<T> extends FormFieldProps, SelectProps<T> {
	name: string;
}

export function DropdownField<T>(
	props: Omit<DropdownFieldProps<T>, 'children'>
) {
	const { label } = props;

	const { errorTree, revalidate } = useValidation(props.name);

	return (
		<FormField
			label={label}
			errors={errorTree?._errors}
			isRequired={props.isRequired}
		>
			<Select
				{...props}
				onOptionSelected={(o) => {
					props.onOptionSelected?.(o);
					revalidate();
				}}
			/>
		</FormField>
	);
}
