import { SetStateAction, useCallback, useRef, useState } from 'react';

const isSetStateActionFunction = <T>(
	setStateAction: SetStateAction<T>
): setStateAction is (prevState: T) => T =>
	typeof setStateAction === 'function';

type ReadOnlyRefObject<T> = {
	readonly current: T;
};

const useStateRef = <T>(initialState: T | (() => T)) => {
	const [state, setStateRaw] = useState(initialState);
	const ref = useRef(state);

	const setState: typeof setStateRaw = useCallback((setStateAction) => {
		const newValue = isSetStateActionFunction(setStateAction)
			? setStateAction(ref.current)
			: setStateAction;

		ref.current = newValue;
		setStateRaw(ref.current);
	}, []);

	return [state, setState, ref as ReadOnlyRefObject<T>] as const;
};

export default useStateRef;
