import { FloatingTree } from '@floating-ui/react';
import { PropsWithChildren } from 'react';
import MenuItem from './MenuItem';
import MenuList from './MenuList';
import MenuTrigger from './MenuTrigger';

type MenuProps = PropsWithChildren<{
	isOpen?: boolean;
	onOpenChange?: (open: boolean) => void;
	alignment?: 'start' | 'end';
	closeOnClick?: boolean;
}>;

const Menu = (props: MenuProps) => {
	return (
		<FloatingTree>
			<MenuList label="" {...props} />
		</FloatingTree>
	);
};

Menu.List = MenuList;
Menu.Item = MenuItem;
Menu.Trigger = MenuTrigger;

export default Menu;
