import { ToastType } from 'ui/components/Toaster/Toast';
import pluralize from './pluralize';
import { createToast, updateToast } from './toast';

export type CodeValidationResult = {
	matchedCodes: string[];
	unmatchedCodes: string[];
	mismatchedCodes?: string[];
	unparsedCodes: string[];
};

export type ValidationResult<T> = {
	validationResult: CodeValidationResult;
	options: T[];
};

export const createValidationToast = async <T>(
	resultPromise: Promise<ValidationResult<T>>,
	objectNoun: string
) => {
	const toastId = createToast(
		ToastType.LOADING,
		`Validating ${objectNoun} codes...`,
		{ duration: Infinity }
	);

	const pl = (n: number) => pluralize(objectNoun, n);

	const result = await resultPromise;
	const { validationResult } = result;

	const { matchedCodes, mismatchedCodes, unmatchedCodes, unparsedCodes } =
		validationResult;

	const invalidCodes = [
		...(mismatchedCodes ?? []),
		...unmatchedCodes,
		...unparsedCodes,
	];

	if (matchedCodes.length > 0 && invalidCodes.length === 0) {
		updateToast(
			toastId,
			ToastType.SUCCESS,
			{
				message: `Validation successful`,
				hint: `${matchedCodes.length} ${pl(matchedCodes.length)} inserted`,
			},
			{ duration: Infinity }
		);
	} else if (matchedCodes.length > 0 && invalidCodes.length > 0) {
		updateToast(
			toastId,
			ToastType.WARNING,
			{
				message: `Validation partially successful`,
				hint: [
					`${matchedCodes.length} ${pl(matchedCodes.length)} inserted`,
					mismatchedCodes &&
						mismatchedCodes.length > 0 &&
						`${mismatchedCodes.length} mismatched: ${mismatchedCodes.join(
							', '
						)}`,
					unmatchedCodes.length > 0 &&
						`${unmatchedCodes.length} unmatched: ${unmatchedCodes.join(', ')}`,
					unparsedCodes.length > 0 &&
						`${unparsedCodes.length} unparsed: ${unparsedCodes.join(', ')}`,
				].filter((item): item is string => item !== false),
			},
			{ duration: Infinity }
		);
	} else if (matchedCodes.length === 0) {
		updateToast(
			toastId,
			ToastType.ERROR,
			{
				message: `Validation failed`,
				hint: `All codes (${invalidCodes.length}) are invalid`,
			},
			{ duration: Infinity }
		);
	}
};

export const parseTextToCodes = (text: string) => {
	const isCommaSeparatedList = text.includes(',');

	if (isCommaSeparatedList) {
		return text.split(',').map((code) => code.trim());
	}

	return null;
};
