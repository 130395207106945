import { ChevronRight, FileSpreadsheet } from 'lucide-react';
import Button from 'ui/components/Button';
import Card from 'ui/components/Card';
import DateFragment from 'ui/components/DateFragment';
import formatBytes from 'utils/helpers/formatBytes';
import { AdhocReport } from '../../api/ReportHubAPI';

type AdhocReportsPanelProps = {
	reports: AdhocReport[];
};

const AdhocReportsPanel = ({ reports }: AdhocReportsPanelProps) => {
	if (reports.length === 0) {
		return null;
	}

	return (
		<Card className="adhoc-reports">
			<div className="adhoc-reports__header">
				<h2 className="adhoc-reports__title">My Adhoc Reports</h2>
				<Button
					variant="secondary"
					icon={ChevronRight}
					iconPosition="right"
					to="/report-hub/adhoc-reports"
				>
					Report Hub - Adhoc
				</Button>
			</div>
			<ul className="adhoc-reports__list">
				{reports.map((report) => (
					<li key={report.id} className="adhoc-reports__item">
						<div className="adhoc-reports__icon">
							<FileSpreadsheet />
						</div>
						<div className="adhoc-reports__details">
							<a
								href={`/report-hub/adhoc-reports/download/${report.id}`}
								target="_blank"
							>
								<span className="adhoc-reports__date">
									<DateFragment date={report.reportDate} />
								</span>
								<b className="adhoc-reports__name">
									{report.title} ({formatBytes(report.fileSizeInBytes)})
								</b>
							</a>
						</div>
					</li>
				))}
			</ul>
		</Card>
	);
};

export default AdhocReportsPanel;
