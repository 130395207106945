import classNames from 'classnames';
import { LucideIcon } from 'lucide-react';
import { useEffect, useRef } from 'react';
import Button from '../Button/Button';

export type TableMessageAction = {
	text: string;
	onClick: () => void;
};

type TableMessageProps = {
	text: string;
	hint?: string;
	icon: LucideIcon;
	actions?: TableMessageAction[];
	isInline?: boolean;
	className?: string;
	tableRef: React.RefObject<HTMLTableElement>;
};

const TableMessage = ({
	text,
	hint,
	icon: IconComponent,
	actions = [],
	isInline = false,
	className,
	tableRef,
}: TableMessageProps) => {
	const stickyRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!stickyRef.current || !tableRef.current) return;

		const updateLeftOffset = () => {
			if (!stickyRef.current || !tableRef.current) return;

			const stickyWidth = stickyRef.current.clientWidth;
			const stickyLeftOffset = (tableRef.current.clientWidth - stickyWidth) / 2;

			stickyRef.current.style.left = `${stickyLeftOffset}px`;
		};

		const resizeObserver = new ResizeObserver(updateLeftOffset);
		resizeObserver.observe(tableRef.current);
		resizeObserver.observe(stickyRef.current);

		updateLeftOffset();

		return () => {
			if (!stickyRef.current || !tableRef.current) return;
			resizeObserver.unobserve(stickyRef.current);
			resizeObserver.unobserve(tableRef.current);
			resizeObserver.disconnect();
		};
	}, []);

	return (
		<tr>
			<td
				className={classNames(
					'table__message',
					isInline && 'table__message--inline',
					className
				)}
				colSpan={1000}
			>
				<div className="table__message-sticky" ref={stickyRef}>
					<IconComponent size={24} />
					<div className="table__message-content">
						<p className="table__message-text">{text}</p>
						{hint && <p className="table__message-hint">{hint}</p>}
					</div>
					{actions.length > 0 && (
						<div className="table__message-actions">
							{actions.map((action) => (
								<Button
									key={action.text}
									type="button"
									variant="secondary"
									size="small"
									onClick={action.onClick}
								>
									{action.text}
								</Button>
							))}
						</div>
					)}
				</div>
			</td>
		</tr>
	);
};

export default TableMessage;
