import { RefreshCw } from 'lucide-react';
import { useEffect, useRef } from 'react';
import { ToastType } from 'ui/components/Toaster/Toast';
import { createToast } from '../helpers/toast';

const MAIN_SCRIPT_REGEX = /index-([a-z0-9]+)\.js/;
const CHECK_FREQUENCY = 1000 * 60 * 5;

const getLocalMainScriptHash = () => {
	const allScripts = document.getElementsByTagName('script');

	for (const script of allScripts) {
		const src = script.getAttribute('src');
		if (!src) continue;

		const result = MAIN_SCRIPT_REGEX.exec(src);
		if (result) return result[1];
	}

	return null;
};

const getRemoteMainScriptHash = async () => {
	const remoteUrl = window.location.origin;

	const response = await fetch(remoteUrl);
	const html = await response.text();

	const result = MAIN_SCRIPT_REGEX.exec(html);
	return result ? result[1] : null;
};

export const checkForUpdates = async (): Promise<boolean> => {
	const localHash = getLocalMainScriptHash();
	if (!localHash) return false;

	const remoteHash = await getRemoteMainScriptHash();
	if (!remoteHash) return false;

	return localHash !== remoteHash;
};

const useUpdateNotifications = () => {
	// Need to use a ref because of the useEffect dependency array
	const hasUpdate = useRef(false);

	useEffect(() => {
		const intervalId = window.setInterval(async () => {
			if (hasUpdate.current) return;

			const isUpdateAvailable = await checkForUpdates();
			hasUpdate.current = isUpdateAvailable;

			if (isUpdateAvailable) {
				createToast(
					ToastType.WARNING,
					{
						message: 'A new version of the application is available',
						hint: 'Please refresh the page to update.',
						actions: [
							{
								label: 'Refresh',
								onClick: () => {
									window.location.reload();
								},
								icon: RefreshCw,
							},
						],
						canDismiss: false,
					},
					{
						duration: Infinity,
						position: 'bottom-right',
					}
				);
			}
		}, CHECK_FREQUENCY);

		return () => {
			window.clearInterval(intervalId);
		};
	}, []);
};

export default useUpdateNotifications;
