import classNames from 'classnames';
import { ChevronLeft } from 'lucide-react';
import React, { PropsWithChildren, useState } from 'react';
import SidebarActions from './SidebarActions';
import SidebarWrapper from './SidebarWrapper';

export type SidebarProps = PropsWithChildren<{
	title: string;
	className?: string;
}>;

const Sidebar = ({ title, children, className }: SidebarProps) => {
	const localStorageExpanded = localStorage.getItem('sidebarExpanded');
	const [isExpanded, setExpanded] = useState(
		localStorageExpanded === 'true' || localStorageExpanded === null
	);

	const toggleExpanded = () => {
		const newExpanded = !isExpanded;
		setExpanded(newExpanded);
		localStorage.setItem('sidebarExpanded', newExpanded.toString());
	};

	return (
		<aside
			className={classNames(
				'sidebar',
				isExpanded && 'sidebar--expanded',
				className
			)}
		>
			<div className="sidebar__header">
				<h3 className="sidebar__title">{title}</h3>
				<button
					className="sidebar__handle"
					type="button"
					onClick={toggleExpanded}
				>
					<ChevronLeft size={22} />
				</button>
			</div>
			<div className="sidebar__content">{children}</div>
		</aside>
	);
};

Sidebar.Actions = SidebarActions;
Sidebar.Wrapper = SidebarWrapper;

export default Sidebar;
