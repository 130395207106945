import { useId } from 'react';
import FilePicker from '../FilePicker';
import FormField, { FormFieldProps } from '../FormField';
import useValidation from '../ValidatedForm/useValidation';

type FileFieldProps = {
	name: string;
	className?: string;
	allowedExtensions?: string[];
	isMultiple?: boolean;
	defaultValue?: File[];
} & FormFieldProps;

const FileField = ({
	name,
	className,
	label,
	allowedExtensions,
	isMultiple,
	defaultValue,
	...restProps
}: FileFieldProps) => {
	const id = useId();

	const { errorTree } = useValidation(name);

	return (
		<FormField label={label} id={id} {...restProps} errors={errorTree?._errors}>
			<FilePicker
				id={id}
				name={name}
				allowedExtensions={allowedExtensions}
				multiple={isMultiple}
				defaultValue={defaultValue}
			/>
		</FormField>
	);
};

export default FileField;
