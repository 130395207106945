import { SubscriptionLocations } from '../../api/ProfileAPI';

export type SubscriptionLocationsListProps = {
	locations: SubscriptionLocations;
	emptyText: string;
};

export function SubscriptionLocationsList({
	locations,
	emptyText,
}: SubscriptionLocationsListProps) {
	const stations = locations.stations ?? [];
	const cities = locations.cities ?? [];
	const provinces = locations.provinces ?? [];
	const countries = locations.countries ?? [];
	const regions = locations.regions ?? [];
	const allEmpty =
		stations.length === 0 &&
		cities.length === 0 &&
		provinces.length === 0 &&
		countries.length === 0 &&
		regions.length === 0;

	return (
		<span>
			{stations.map((stationNk, index, array) => (
				<span
					title={`Airport: ${stationNk.name} (${stationNk.code}) `}
					key={stationNk.code}
				>
					{stationNk.code}
					{index < array.length - 1 ||
					cities.length + provinces.length + countries.length + regions.length >
						0
						? ', '
						: ' '}
				</span>
			))}

			{cities.map((cityNk, index, array) => (
				<span title={`City: ${cityNk.name} (${cityNk.code})`} key={cityNk.code}>
					{cityNk.code}
					{index < array.length - 1 ||
					provinces.length + countries.length + regions.length > 0
						? ', '
						: ' '}
				</span>
			))}
			{provinces.map((provinceNk, index, array) => (
				<span
					title={`Province: ${provinceNk.name} (${provinceNk.code})`}
					key={provinceNk.code}
				>
					{provinceNk.code}
					{index < array.length - 1 || countries.length + regions.length > 0
						? ', '
						: ' '}
				</span>
			))}
			{countries.map((countryNk, index, array) => (
				<span title={`Country: ${countryNk.name}`} key={countryNk.code}>
					{countryNk.code}
					{index < array.length - 1 || regions.length > 0 ? ', ' : ' '}
				</span>
			))}
			{regions.map((regionNk, index, array) => (
				<span title={`Region: ${regionNk.name}`} key={regionNk.code}>
					{regionNk.code}
					{index < array.length - 1 ? ', ' : ' '}
				</span>
			))}
			{allEmpty && <>{emptyText}</>}
		</span>
	);
}
