import { unstable_useBlocker as useBlocker } from 'react-router-dom';
import usePersistantState from 'utils/hooks/usePersistantState';
import Button from 'ui/components/Button/Button';
import Modal from 'ui/components/Modal/Modal';
import { useWorksheetContext } from './WorksheetContext';

type WorksheetNavigationConfirmationProps = {};

const WorksheetNavigationConfirmation =
	({}: WorksheetNavigationConfirmationProps) => {
		const { worksheetHasChanges, schedule } = useWorksheetContext();
		const [confirmUnsavedChanges] = usePersistantState(
			'webtool.confirmUnsavedChanges',
			true
		);

		const blocker = useBlocker(
			confirmUnsavedChanges && !!schedule && worksheetHasChanges
		);

		if (!blocker || blocker.state === 'unblocked') return null;

		return (
			<Modal
				title="Unsaved Changes"
				isOpen
				onClose={() => blocker.reset?.()}
				size="sm"
			>
				<Modal.Body>
					<p>
						You have unsaved changes. Are you sure you want to leave this page?
					</p>
				</Modal.Body>
				<Modal.Actions alignment="between">
					<Button
						onClick={blocker.proceed}
						variant="secondary"
						intent="danger"
						isLoading={blocker.state === 'proceeding'}
					>
						Leave
					</Button>

					<Button
						onClick={blocker.reset}
						variant="secondary"
						isDisabled={blocker.state === 'proceeding'}
					>
						Cancel
					</Button>
				</Modal.Actions>
			</Modal>
		);
	};

export default WorksheetNavigationConfirmation;
