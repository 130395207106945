import { File, FileArchive, FileSpreadsheet, FileText } from 'lucide-react';
import React, { useMemo } from 'react';

type AttachmentIconProps = {
	fileExtension?: string;
	className?: string;
};

const AttachmentIcon = ({ fileExtension, className }: AttachmentIconProps) => {
	const iconProps = {
		size: 16,
	};

	const FileIcon = useMemo(() => {
		switch (fileExtension?.toLowerCase()) {
			case 'doc':
			case 'docx':
			case 'pdf':
			case 'txt':
			case 'rtf':
			case 'odt':
				return FileText;
			case 'xls':
			case 'xlsx':
			case 'csv':
			case 'tsv':
			case 'ods':
				return FileSpreadsheet;
			case 'zip':
			case 'rar':
			case '7z':
			case 'tar':
			case 'gz':
				return FileArchive;
			default:
				return File;
		}
	}, [fileExtension]);

	return <FileIcon {...iconProps} className={className} />;
};

export default AttachmentIcon;
