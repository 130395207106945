import { createContext } from 'react';

type TabsContextValue = {
	actionsPortalId: string;
};

const TabsContext = createContext<TabsContextValue>({
	actionsPortalId: '',
});

export default TabsContext;
