import { Plus } from 'lucide-react';
import { LoaderFunctionArgs, redirect, useLoaderData } from 'react-router-dom';
import Button from 'ui/components/Button';
import PageHeader from 'ui/components/PageHeader';
import Pagination from 'ui/components/Pagination';
import RevalidationButton from 'ui/components/RevalidationButton';
import Table from 'ui/components/Table';
import WebToolGroupAPI from 'utils/api/WebToolGroupAPI';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import UserAPI from '../../api/UserAPI';
import * as webToolQueryColumns from '../../utils/columns/webToolStationColumns';

const loader = async ({ request }: LoaderFunctionArgs) => {
	const webToolAvailable = await UserAPI.hasWebtoolAccess();
	if (!webToolAvailable) {
		return redirect('/');
	}

	const url = new URL(request.url);
	const { groups } = await WebToolGroupAPI.getStationGroups(url.searchParams);

	return {
		groups: groups,
	};
};

const WebToolGroupArea = () => {
	const data = useLoaderData() as LoaderData<typeof loader>;
	const columns = [
		webToolQueryColumns.nameColumn,
		webToolQueryColumns.descriptionColumn,
		webToolQueryColumns.locationsColumn,
		webToolQueryColumns.conflictsColumn,
		webToolQueryColumns.createdAtColumn,
	];
	return (
		<div className="content">
			<div>
				<PageHeader title="Airport Groups">
					<RevalidationButton>Refresh</RevalidationButton>
					<Button
						variant="primary"
						icon={Plus}
						to="/webtool/groups/station/create"
					>
						New group
					</Button>
				</PageHeader>
			</div>
			<Table identifierKey="id" data={data.groups.items} columns={columns} />
			{data.groups.totalCount > 0 && (
				<Pagination
					baseUrl={new URL(window.location.href)}
					page={data.groups.page}
					pageParameterName="page"
					pageSize={data.groups.pageSize}
					itemCount={data.groups.totalCount}
				/>
			)}
		</div>
	);
};

export const WEBTOOL_STATION_GROUP_LIST_ROUTE: DecentralizedRouteProps = {
	loader,
	element: <WebToolGroupArea />,
};
