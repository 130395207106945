import { LoaderFunctionArgs, Outlet, redirect } from 'react-router-dom';
import Tabs from 'ui/components/Tabs';
import { DecentralizedRouteProps } from 'utils/types/common';

import requireAuthentication from 'utils/helpers/requireAuthentication';
import urlPathName from '../../utils/urlPathName';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	const path = urlPathName(request);
	await requireAuthentication(request);

	if (path === '/reference-data') return redirect('/reference-data/airlines');
	return null;
};

const ReferenceDataPage = () => {
	return (
		<>
			<Tabs
				className="page-tabs"
				tabs={[
					{
						name: 'Airlines',
						to: '/reference-data/airlines',
					},
					{
						name: 'Airports',
						to: '/reference-data/airports',
					},
					{
						name: 'Regions',
						to: '/reference-data/regions',
					},
					{
						name: 'Freight Forwarders',
						to: '/reference-data/freight-forwarders',
					},
				]}
				renderOutlet={false}
			/>
			<Outlet />
		</>
	);
};

export const REFERENCE_DATA_ROUTE: DecentralizedRouteProps = {
	loader: loader,
	element: <ReferenceDataPage />,
};
