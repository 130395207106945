import { FormEvent, useId, useState } from 'react';
import Button from 'ui/components/Button';
import Modal, { ModalProps } from 'ui/components/Modal/Modal';
import { catchWithError } from 'utils/helpers/catchHandlers';
import { useWebToolContext } from '../../routes/web-tool/WebToolContext';
import CreateWorksheetForm from './CreateWorksheetForm';

type CreateWorksheetModalProps = {} & Omit<ModalProps, 'title'>;

const CreateWorksheetModal = ({ ...modalProps }: CreateWorksheetModalProps) => {
	const formId = useId();
	const { createWorksheet, subscriptions } = useWebToolContext();
	const [isLoading, setLoading] = useState(false);

	const handleCreateWorksheet = async (e: FormEvent) => {
		e.preventDefault();
		setLoading(true);

		const form = e.target as HTMLFormElement;
		const formData = new FormData(form);

		const name = formData.get('name') as string;
		const subscriptionId = formData.get('subscriptionId') as string;

		await createWorksheet(name, subscriptionId);
		setLoading(false);
		modalProps.onClose();
	};

	return (
		<Modal title="Create report" size="md" {...modalProps}>
			<Modal.Body>
				<CreateWorksheetForm
					subscriptions={subscriptions}
					id={formId}
					onSubmit={(e) => handleCreateWorksheet(e).catch(catchWithError)}
				/>
			</Modal.Body>
			<Modal.Actions>
				<Button
					variant="primary"
					type="submit"
					form={formId}
					isLoading={isLoading}
				>
					Create
				</Button>
			</Modal.Actions>
		</Modal>
	);
};

export default CreateWorksheetModal;
