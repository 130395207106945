import { createColumnHelper } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import DateFragment from 'ui/components/DateFragment';
import { WebToolStationGroupItem } from 'utils/api/WebToolGroupAPI';
import { LocationList } from 'ui/components/LocationPicker/LocationList';

const columnHelper = createColumnHelper<WebToolStationGroupItem>();

export const createdAtColumn = columnHelper.accessor('createdAt', {
	header: 'Created Date',
	cell: (info) => <DateFragment includeTime={true} date={info.getValue()} />,
	enableSorting: false,
});

export const nameColumn = columnHelper.accessor('name', {
	header: 'Name',
	cell: (info) => (
		<Link to={`/webtool/groups/station/${info.row.original.id}`}>
			{info.getValue()}
		</Link>
	),
});

export const descriptionColumn = columnHelper.accessor('description', {
	header: 'Description',
});

export const locationsColumn = columnHelper.display({
	id: 'locations',
	header: 'Airports / Cities',
	cell: (item) => (
		<LocationList
			locations={item.row.original.locations}
			emptyText="-"
			variant="full"
		/>
	),
});

export const conflictsColumn = columnHelper.display({
	id: 'conflicts',
	header: 'Location Overlaps',
	cell: (item) =>
		item.row.original.conflicts.map((conf, i) => (
			<>
				{i !== 0 ? ', ' : ''}
				<span> {conf.name} </span>
			</>
		)),
});
