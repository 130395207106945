import { ComponentType, useState } from 'react';
import Dropdown from '../Dropdown';
import { DropdownProps } from '../Dropdown/Dropdown';

export type MultiSelectPillProps<T> = {
	value: T;
	onRemove: () => void;
};

export type MultiSelectProps<T> = {
	initialValues?: T[];
	selectedOptions?: T[];
	onSelectedOptionsChange?: (value: T[]) => void;
	onOptionsSelected?: (value: T[]) => void;
	pillComponent: ComponentType<{ value: T; onRemove: () => void }>;
	onCopy?: (value: T[]) => void;
} & Omit<
	DropdownProps<T>,
	| 'isOpen'
	| 'onOpenChange'
	| 'previewSource'
	| 'selectedOptions'
	| 'components'
	| 'onOptionsSelected'
	| 'isMulti'
> &
	Partial<
		Pick<
			DropdownProps<T>,
			| 'isOpen'
			| 'onOpenChange'
			| 'handlePasteSelection'
			| 'handleSearchSubmitSelection'
		>
	>;

const MultiSelect = <T,>({
	initialValues = [],
	contentSource,
	onOptionsSelected,
	identifierKey,
	pillComponent: PillComponent,
	isOpen: propsIsOpen,
	onOpenChange: propsOnOpenChange,
	selectedOptions: propsSelectedOptions,
	onSelectedOptionsChange: propsOnSelectedOptionsChange,
	...restProps
}: MultiSelectProps<T>) => {
	const [stateSelectedOptions, setStateSelectedOptions] =
		useState<T[]>(initialValues);
	const [stateIsOpen, stateSetOpen] = useState(false);

	const isOpen = propsIsOpen ?? stateIsOpen;
	const onOpenChange = propsOnOpenChange ?? stateSetOpen;

	const selectedOptions = propsSelectedOptions ?? stateSelectedOptions;
	const onSelectedOptionsChange =
		propsOnSelectedOptionsChange ?? setStateSelectedOptions;

	const removeOption = (option: T) => {
		onSelectedOptionsChange(
			selectedOptions.filter((prevOption) => prevOption !== option)
		);
	};

	return (
		<Dropdown<T>
			isOpen={isOpen}
			onOpenChange={onOpenChange}
			selectedOptions={selectedOptions}
			contentSource={contentSource}
			identifierKey={identifierKey}
			previewSource={(selectedOptions: T[]) => {
				return (
					<div className="pills">
						{selectedOptions.map((selectedOption) => (
							<PillComponent
								key={`${selectedOption[identifierKey]}-pill`}
								value={selectedOption}
								onRemove={() => removeOption(selectedOption)}
							/>
						))}
					</div>
				);
			}}
			onOptionsSelected={(options) => {
				if (options.length > 0) {
					onSelectedOptionsChange([...selectedOptions, ...options]);
				} else {
					onSelectedOptionsChange([]);
				}

				if (onOptionsSelected) {
					onOptionsSelected(options);
				}

				onOpenChange(false);
			}}
			isMulti={true}
			{...restProps}
		/>
	);
};

export default MultiSelect;
