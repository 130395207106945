import {
	LayoutRouteProps,
	LoaderFunctionArgs,
	Outlet,
	redirect,
	useLoaderData,
	useParams,
} from 'react-router-dom';
import Flex from 'ui/components/Flex';
import Grid from 'ui/components/Grid';
import PageHeader from 'ui/components/PageHeader';
import Pagination from 'ui/components/Pagination';
import Table from 'ui/components/Table';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { LoaderData } from 'utils/types/loaderData';
import AirImpactReportsAPI from '../../api/AirImpactReportsAPI';
import * as publicationColumns from '../../utils/columns/publicationColumns';

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);

	const url = new URL(request.url);
	const getAirImpactReportsResponse =
		await AirImpactReportsAPI.getImpactReportsList(url.searchParams);

	if (
		!params.publicationId &&
		getAirImpactReportsResponse.reports.items.length > 0
	) {
		return redirect(
			'/publications/' + getAirImpactReportsResponse.reports.items[0].id
		);
	}

	return {
		impactReports: getAirImpactReportsResponse.reports,
	};
};

export function ListPublicationsPage() {
	const data = useLoaderData() as LoaderData<typeof loader>;

	const columns = [
		publicationColumns.editionColumn,
		publicationColumns.titleColumn,
		publicationColumns.publicationDateColumn,
	];

	const params = useParams();

	return (
		<>
			<div className="content">
				<PageHeader title="Publications" />

				<Grid
					columns={
						params.publicationId ? 'minmax(0, 2fr) minmax(0, 1fr)' : undefined
					}
				>
					<Flex direction="column" gap={16}>
						<Table
							columns={columns}
							data={data.impactReports.items}
							identifierKey="id"
							emptyText="No reports found."
						/>
						{data.impactReports.totalCount > 0 && (
							<Pagination
								baseUrl={new URL(window.location.href)}
								page={data.impactReports.page}
								pageParameterName="page"
								pageSize={data.impactReports.pageSize}
								itemCount={data.impactReports.totalCount}
							/>
						)}
					</Flex>
					<Outlet />
				</Grid>
			</div>
		</>
	);
}

export const LIST_PUBLICATIONS_ROUTE: LayoutRouteProps = {
	loader: loader,
	element: <ListPublicationsPage />,
	handle: {
		crumb: () => 'Air Cargo Impact Reports',
	},
};
