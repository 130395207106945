import { Save } from 'lucide-react';
import { useState } from 'react';
import Button from 'ui/components/Button/Button';
import Flex from 'ui/components/Flex';
import Modal, { ModalProps } from 'ui/components/Modal/Modal';
import TextField from 'ui/components/TextField/TextField';
import Toggle from 'ui/components/Toggle';
import { WorksheetBase } from 'utils/api/WebToolAPI';
import { catchWithError } from 'utils/helpers/catchHandlers';
import { useWebToolContext } from './WebToolContext';

type DuplicateWorksheetModalProps = {
	worksheet: WorksheetBase;
} & Omit<ModalProps, 'title'>;

const DuplicateWorksheetModal = ({
	worksheet,
	...modalProps
}: DuplicateWorksheetModalProps) => {
	const [name, setName] = useState(`${worksheet.name} (copy)`);
	const [retainSchedule, setRetainSchedule] = useState(true);
	const [isLoading, setIsLoading] = useState(false);

	const { duplicateWorksheet } = useWebToolContext();

	const handleDuplicate = async () => {
		setIsLoading(true);

		try {
			await duplicateWorksheet(worksheet, name, retainSchedule);
			modalProps.onClose();
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Modal title="Duplicate report" {...modalProps}>
			<Modal.Body>
				<input type="hidden" name="_action" value="duplicate" />
				<Flex gap={12} direction="column">
					<TextField
						label="New Report Name"
						name="name"
						value={name}
						onChange={(e) => setName(e.target.value)}
						autoFocus
					/>
					{worksheet.schedule && (
						<Flex gap={12} alignItems="center">
							<Toggle
								isChecked={retainSchedule}
								onChange={(e) => setRetainSchedule(e.target.checked)}
							/>
							<span>Copy existing schedule</span>
						</Flex>
					)}
				</Flex>
			</Modal.Body>
			<Modal.Actions>
				<Button
					type="submit"
					icon={Save}
					onClick={() => handleDuplicate().catch(catchWithError)}
					isLoading={isLoading}
					disabled={name.length === 0}
				>
					Duplicate
				</Button>
			</Modal.Actions>
		</Modal>
	);
};

export default DuplicateWorksheetModal;
