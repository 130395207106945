import classNames from 'classnames';
import { CornerDownLeft, Search } from 'lucide-react';
import { ChangeEvent, ForwardedRef, forwardRef } from 'react';

type DropdownSearchProps = {
	placeholder?: string;
	value: string;
	onChange: (e: ChangeEvent<HTMLInputElement>) => void;

	canSubmit?: boolean;
	onSubmit?: (value: string) => void;
};

const DropdownSearch = (
	{
		value,
		onChange,
		onSubmit,
		placeholder = 'Type to search...',
	}: DropdownSearchProps,
	ref: ForwardedRef<HTMLInputElement>
) => {
	return (
		<div className="dropdown__search search">
			<Search className="search__icon" size={18} />
			<input
				className="search__input"
				type="text"
				ref={ref}
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				onKeyDown={(e) => {
					e.stopPropagation();

					if (e.key === 'Enter' && onSubmit) {
						e.preventDefault();
						onSubmit(value);
					}
				}}
			/>
			{onSubmit && (
				<CornerDownLeft
					className={classNames(
						'search__enter',
						value.length > 0 && 'search__enter--enabled'
					)}
					size={18}
				/>
			)}
		</div>
	);
};

export default forwardRef(DropdownSearch);
