import classNames from 'classnames';
import { X } from 'lucide-react';
import { KeyboardEvent, MouseEvent } from 'react';

type PillProps = {
	code?: string;
	name?: string;
	title?: string;
	intent?: 'success' | 'warning' | 'error' | 'info';
	onRemove?: () => void;
};

const Pill = ({ intent = 'info', code, name, title, onRemove }: PillProps) => {
	const handleRemoveClick = (e: MouseEvent | KeyboardEvent) => {
		e.stopPropagation();
		onRemove?.();
	};

	return (
		<div className={classNames('pill', `pill--${intent}`)} title={title}>
			{code && <span className="pill__code">{code}</span>}
			{name && (
				<span className="pill__name" title={name}>
					{name}
				</span>
			)}
			{onRemove && (
				<div
					className="pill__remove"
					onClick={handleRemoveClick}
					role="button"
					tabIndex={0}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							handleRemoveClick(e);
						}
					}}
				>
					<X size={14} />
				</div>
			)}
		</div>
	);
};

export default Pill;
