import z from 'zod';
import {
	zArrayOfStringRequired,
	zEnsureArrayPreprocessor,
} from 'utils/zod/zodValidation';

const citiesSchema = z.preprocess(
	zEnsureArrayPreprocessor,
	zArrayOfStringRequired('Cities is required')
);

export const webToolCityGroupSchema = z.object({
	id: z.string(),
	name: z
		.string({})
		.min(1, { message: 'Name is required' })
		.max(100, { message: 'Name must not be more than 100 characters' }),
	description: z
		.string({})
		.min(1, { message: 'Description is required' })
		.max(250, { message: 'Description must not be more than 250 characters' }),
	cities: citiesSchema,
});

export const createWebToolCityGroupSchema = webToolCityGroupSchema.pick({
	name: true,
	description: true,
	cities: true,
});

export const updateWebToolCityGroupSchema = webToolCityGroupSchema.pick({
	name: true,
	description: true,
	cities: true,
});

export type CreateWebToolCityGroupModel = z.infer<
	typeof createWebToolCityGroupSchema
>;
export type UpdateWebToolCityGroupModel = z.infer<
	typeof updateWebToolCityGroupSchema
>;
