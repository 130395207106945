import {
	addDays,
	eachDayOfInterval,
	endOfMonth,
	endOfWeek,
	endOfYear,
	format,
	startOfMonth,
	startOfWeek,
	startOfYear,
} from 'date-fns';
import { useCallback, useMemo } from 'react';
import Alert from 'ui/components/Alert';
import DateField from 'ui/components/DateField/DateField';
import Flex from 'ui/components/Flex/Flex';
import FormField from 'ui/components/FormField/FormField';
import MonthDayPicker, {
	MonthAndDay,
} from 'ui/components/MonthDayPicker/MonthDayPicker';
import Select from 'ui/components/Select/Select';
import { WorksheetSchedule } from 'utils/api/WebToolAPI';
import {
	abstractLocalToUtc,
	abstractUtcToLocal,
	getDayOffsetForLocalHour,
	getUTCOffsetInHours,
	localHourToUTC,
	modDaysOfMonth,
	modDaysOfWeek,
	modHours,
} from 'utils/helpers/timezone';
import { LabeledValue } from 'utils/types/common';

type WorksheetScheduleFormProps = {
	schedule: WorksheetSchedule;
	onScheduleChange: (schedule: WorksheetSchedule) => void;
	id?: string;
};

const WorksheetScheduleForm = ({
	schedule,
	onScheduleChange,
	id,
}: WorksheetScheduleFormProps) => {
	const nextTenYears = useMemo(() => {
		const currentDate = new Date();
		const currentYear = currentDate.getFullYear();
		const nextTenYears = Array.from({ length: 10 }, (_, i) => {
			return { value: currentYear + i, label: `${currentYear + i}` };
		});

		return nextTenYears;
	}, []);

	const hoursOfDay = useMemo(() => {
		/* Since some timezones have 30 or even 45 minute offsets, we need to account for that
		 * when generating the hours of the day. We do this by showing the local hours with their
		 * corresponding minute offset so that the UTC time is always on the hour.
		 * e.g.
		 * Kathmandu (UTC+5:45) -> Label shows 00:45, value is 19:00 (UTC)
		 * Other Timezone (UTC+5:15) -> Label shows 00:15, value is 19:00 (UTC)
		 * Some Timezone (UTC-3:15) -> Label shows 00:45, value is 04:00 (UTC)
		 * Some Timezone (UTC-3:45) -> Label shows 00:15, value is 04:00 (UTC)
		 * */

		const utcMinuteOffset = (getUTCOffsetInHours() % 1) * 60;

		// We use format instead of string generation so we can localize easily
		return Array.from({ length: 24 }, (_, i) => {
			const date = new Date();
			const minuteOffset =
				utcMinuteOffset < 0 ? Math.abs(utcMinuteOffset) : 60 - utcMinuteOffset;

			date.setHours(i, utcMinuteOffset !== 0 ? minuteOffset : 0, 0, 0);

			return {
				value: (date.getHours() * 60 + date.getMinutes()) / 60,
				label: format(date, 'HH:mm'),
			};
		});
	}, []);

	const daysOfMonth = useMemo(() => {
		// January always has 31 days, so we use it as reference here
		const januaryDate = startOfYear(new Date());

		const daysOfMonth = eachDayOfInterval({
			start: startOfMonth(januaryDate),
			end: endOfMonth(januaryDate),
		});

		return daysOfMonth.map((day, dayIndex) => {
			return { value: dayIndex + 1, label: format(day, 'do') };
		});
	}, []);

	const daysOfWeek = useMemo(() => {
		const currentDate = new Date();
		const weekOptions = { weekStartsOn: 1 as const };
		const daysOfWeek = eachDayOfInterval({
			start: startOfWeek(currentDate, weekOptions),
			end: endOfWeek(currentDate, weekOptions),
		});

		return daysOfWeek.map((day, dayIndex) => {
			return { value: dayIndex + 1, label: format(day, 'EEEE') };
		});
	}, []);

	type FrequencyOption = {
		label: string;
		value: WorksheetSchedule['frequency'];
	};
	type FormatOption = { label: string; value: 'excel' | 'csv' | 'tsv' };

	const frequencyOptions = useMemo<FrequencyOption[]>(
		() => [
			{ label: 'On new data', value: 'on-new-data' },
			{ label: 'Weekly', value: 'weekly' },
			{ label: 'Monthly', value: 'monthly' },
			{ label: 'Yearly', value: 'yearly' },
		],
		[]
	);

	const formatOptions = useMemo<FormatOption[]>(
		() => [
			{ label: 'Excel', value: 'excel' },
			{ label: 'CSV', value: 'csv' },
			{ label: 'TSV', value: 'tsv' },
		],
		[]
	);

	const DEFAULT_TIME = 11;
	const handleFormatChange = (option: FormatOption | null) => {
		if (!option) return;

		onScheduleChange({
			...schedule,
			outputFormat: option.value,
		});
	};
	const handleFrequencyChange = (option: FrequencyOption | null) => {
		if (!option) return;

		switch (option.value) {
			case 'on-new-data':
				onScheduleChange({
					...schedule,
					frequency: 'on-new-data',
				});
				break;
			case 'weekly':
				onScheduleChange({
					...schedule,
					frequency: 'weekly',
					dayOfWeek: 1,
					time: DEFAULT_TIME,
				});
				break;
			case 'monthly':
				onScheduleChange({
					...schedule,
					frequency: 'monthly',
					dayOfMonth: 1,
					time: DEFAULT_TIME,
				});
				break;
			case 'yearly':
				onScheduleChange({
					...schedule,
					frequency: 'yearly',
					monthAndDay: {
						month: new Date().getMonth() + 1,
						day: 1,
					},
					activeTo: schedule.activeTo
						? endOfYear(schedule.activeTo)
						: undefined,
					activeFrom: startOfYear(schedule.activeFrom),
					time: DEFAULT_TIME,
				});
				break;

			default:
				break;
		}
	};

	// Time

	const handleTimeSelected = (option: LabeledValue<number> | null) => {
		if (!('time' in schedule)) return;
		const value = option?.value ?? DEFAULT_TIME - 1;

		// Convert hour to UTC
		const utcHour = localHourToUTC(value);
		const dayOffset = getDayOffsetForLocalHour(value);

		if (schedule.frequency === 'weekly') {
			onScheduleChange({
				...schedule,
				time: utcHour,
				dayOfWeek: modDaysOfWeek(
					selectedDayOfWeekOption.value + dayOffset
				) as Day,
			});
		} else if (schedule.frequency === 'monthly') {
			onScheduleChange({
				...schedule,
				time: utcHour,
				dayOfMonth: modDaysOfMonth(selectedDayOfMonthOption.value + dayOffset),
			});
		} else if (schedule.frequency === 'yearly') {
			const { date: utcDay, month: utcMonth } = abstractLocalToUtc({
				date: selectedMonthAndDayOption.day,
				month: selectedMonthAndDayOption.month,
				hours: value,
			});

			onScheduleChange({
				...schedule,
				time: utcHour,
				monthAndDay: {
					day: utcDay,
					month: utcMonth,
				},
			});
		}
	};

	const selectedTimeOption = useMemo<LabeledValue<number>>(() => {
		const utcHour = 'time' in schedule ? schedule.time : DEFAULT_TIME;

		const utcOffset = getUTCOffsetInHours();
		const value = modHours(utcHour - utcOffset);

		return (
			hoursOfDay.find((hour) => hour.value === value) ??
			hoursOfDay[DEFAULT_TIME - 1]
		);
	}, [schedule, hoursOfDay]);

	const dayOffset = useMemo(() => {
		if (!('time' in schedule)) return 0;

		return getDayOffsetForLocalHour(selectedTimeOption.value);
	}, [schedule]);

	// Weekly

	const selectedDayOfWeekOption = useMemo<LabeledValue<number>>(() => {
		if (!('dayOfWeek' in schedule)) return daysOfWeek[0];

		const dayOfWeek = modDaysOfWeek(schedule.dayOfWeek - dayOffset);
		return daysOfWeek[dayOfWeek - 1];
	}, [schedule, daysOfWeek, dayOffset]);

	const handleDayOfWeekSelected = useCallback(
		(option: LabeledValue<number> | null) => {
			if (!('dayOfWeek' in schedule)) return;
			const value = option?.value ?? 1;
			const utcDay = modDaysOfWeek(value + dayOffset);

			onScheduleChange({
				...schedule,
				dayOfWeek: utcDay as Day,
			});
		},
		[schedule, dayOffset]
	);

	// Monthly

	const selectedDayOfMonthOption = useMemo<LabeledValue<number>>(() => {
		if (!('dayOfMonth' in schedule)) return daysOfMonth[0];

		const dayOfMonth = modDaysOfMonth(schedule.dayOfMonth - dayOffset);

		return daysOfMonth[dayOfMonth - 1];
	}, [schedule, daysOfMonth, dayOffset]);

	const handleDayOfMonthSelected = useCallback(
		(option: LabeledValue<number> | null) => {
			if (!('dayOfMonth' in schedule) || !option) return;

			const dayOfMonth = modDaysOfMonth(option.value + dayOffset);

			onScheduleChange({
				...schedule,
				dayOfMonth: dayOfMonth,
			});
		},
		[schedule, dayOffset]
	);

	// Yearly schedule

	const selectedMonthAndDayOption = useMemo<MonthAndDay>(() => {
		if (!('monthAndDay' in schedule)) return { month: 1, day: 1 };

		const utcMonthAndDay = schedule.monthAndDay;

		const { date: localDay, month: localMonth } = abstractUtcToLocal({
			date: utcMonthAndDay.day,
			month: utcMonthAndDay.month,
			hours: schedule.time,
			year: new Date().getFullYear(),
		});

		return {
			day: localDay,
			month: localMonth,
		};
	}, [schedule]);

	const handleMonthAndDaySelected = useCallback(
		(localMonthAndDay: MonthAndDay) => {
			if (!('monthAndDay' in schedule)) return;

			const utcMonthAndDay = abstractLocalToUtc({
				month: localMonthAndDay.month,
				date: localMonthAndDay.day,
				hours: selectedTimeOption.value,
				year: new Date().getFullYear(),
			});

			onScheduleChange({
				...schedule,
				monthAndDay: {
					month: utcMonthAndDay.month,
					day: utcMonthAndDay.date,
				},
			});
		},
		[schedule]
	);

	const hasActiveRange =
		schedule.frequency === 'on-new-data' ||
		schedule.frequency === 'weekly' ||
		schedule.frequency === 'monthly';

	const isActiveRangeValid = useMemo(() => {
		if (!hasActiveRange) return true;

		const activeFrom = schedule.activeFrom;
		const activeTo = schedule.activeTo;

		if (!activeFrom) return false;

		if (activeTo) {
			return activeFrom < activeTo;
		}

		return true;
	}, [schedule, hasActiveRange]);

	return (
		<Flex direction="column" gap={12}>
			{schedule.suspended && (
				<div>
					<Alert intent="warning" title="Schedule suspended">
						{schedule.suspendedMessage}
					</Alert>
				</div>
			)}

			<Flex gap={16}>
				<FormField
					label="Frequency"
					description="Choose how often this report should run."
					style={{ flex: 2 }}
				>
					<Select
						options={frequencyOptions}
						selectedOption={frequencyOptions.find(
							(itm) => itm.value === schedule.frequency
						)}
						onOptionSelected={handleFrequencyChange}
						isClearable={false}
						identifierKey="value"
						contentSource="label"
					/>
				</FormField>

				<FormField label="Format" style={{ flex: 1 }}>
					<Select
						options={formatOptions}
						selectedOption={formatOptions.find(
							(itm) => itm.value === schedule.outputFormat
						)}
						onOptionSelected={handleFormatChange}
						isClearable={false}
						identifierKey="value"
						contentSource="label"
					/>
				</FormField>
			</Flex>

			{schedule?.frequency === 'weekly' && (
				<>
					<Flex gap={16}>
						<FormField label="Day Of Week" style={{ width: '100%' }}>
							<Select
								options={daysOfWeek}
								initialValue={daysOfWeek[0]}
								selectedOption={selectedDayOfWeekOption}
								isClearable={false}
								identifierKey="value"
								contentSource="label"
								onOptionSelected={handleDayOfWeekSelected}
							/>
						</FormField>

						<FormField label="Time" style={{ width: '100%' }}>
							<Select
								options={hoursOfDay}
								initialValue={hoursOfDay[DEFAULT_TIME - 1]}
								selectedOption={selectedTimeOption}
								isClearable={false}
								identifierKey="label"
								contentSource="label"
								onOptionSelected={handleTimeSelected}
							/>
						</FormField>
					</Flex>
				</>
			)}

			{schedule.frequency === 'monthly' && (
				<>
					<Flex gap={16}>
						<FormField
							label="Day Of Month"
							description="If there are fewer days in a month, the month's last day will be used."
							style={{ width: '100%' }}
						>
							<Select
								options={daysOfMonth}
								initialValue={{ value: 1, label: '1st' }}
								selectedOption={selectedDayOfMonthOption}
								isClearable={false}
								identifierKey="value"
								contentSource="label"
								onOptionSelected={handleDayOfMonthSelected}
							/>
						</FormField>

						<FormField label="Time" style={{ width: '100%' }}>
							<Select
								options={hoursOfDay}
								initialValue={hoursOfDay[DEFAULT_TIME - 1]}
								selectedOption={selectedTimeOption}
								isClearable={false}
								identifierKey="label"
								contentSource="label"
								onOptionSelected={handleTimeSelected}
							/>
						</FormField>
					</Flex>
				</>
			)}

			{schedule.frequency === 'yearly' && (
				<>
					<Flex gap={16}>
						<FormField label="Date" style={{ width: '66.666%' }}>
							<MonthDayPicker
								value={selectedMonthAndDayOption}
								onChange={handleMonthAndDaySelected}
							/>
						</FormField>

						<FormField label="Time" style={{ width: '33.333%' }}>
							<Select
								options={hoursOfDay}
								initialValue={hoursOfDay[DEFAULT_TIME - 1]}
								selectedOption={selectedTimeOption}
								isClearable={false}
								identifierKey="label"
								contentSource="label"
								onOptionSelected={handleTimeSelected}
							/>
						</FormField>
					</Flex>

					<Flex gap={16}>
						<FormField label="First Year" style={{ width: '100%' }}>
							<Select
								options={nextTenYears}
								selectedOption={nextTenYears.find(
									(option) => option.value === schedule.activeFrom.getFullYear()
								)}
								isClearable={false}
								initialValue={nextTenYears[0]}
								identifierKey="value"
								contentSource="label"
								onOptionSelected={(option) => {
									if (!option) {
										return;
									}

									onScheduleChange({
										...schedule,
										activeFrom: startOfYear(new Date(option.value, 1)),
									});
								}}
							/>
						</FormField>

						<FormField label="Last Year" style={{ width: '100%' }}>
							<Select
								placeholder="Optional"
								options={nextTenYears}
								selectedOption={nextTenYears.find(
									(option) => option.value === schedule.activeTo?.getFullYear()
								)}
								isClearable
								identifierKey="value"
								contentSource="label"
								name="lastYear"
								onOptionSelected={(option) => {
									if (!option) {
										onScheduleChange({
											...schedule,
											activeTo: undefined,
										});
										return;
									}

									onScheduleChange({
										...schedule,
										activeTo: endOfYear(new Date(option.value, 1)),
									});
								}}
							/>
						</FormField>
					</Flex>
				</>
			)}

			{hasActiveRange && (
				<Flex gap={16}>
					<DateField
						label="Active from"
						description="The schedule will only run on or after this date."
						value={schedule.activeFrom}
						timezone="local"
						minDate={new Date()}
						onChange={(date) => {
							if (!date) {
								return;
							}

							onScheduleChange({
								...schedule,
								activeFrom: date,
							});
						}}
					/>

					<DateField
						label="Active until"
						description="The schedule will only run on or before this date."
						placeholder="Optional"
						value={schedule.activeTo}
						minDate={addDays(new Date(), 1)}
						timezone="local"
						isClearable
						onChange={(date) => {
							onScheduleChange({
								...schedule,
								activeTo: date ?? undefined,
							});
						}}
						errors={
							!isActiveRangeValid
								? 'Active until date cannot be before active from date.'
								: undefined
						}
					/>
				</Flex>
			)}
		</Flex>
	);
};

export default WorksheetScheduleForm;
