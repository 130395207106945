import { useState } from 'react';

const usePersistantState = <T>(key: string, initialValue: T) => {
	const [storedValue, setStoredValue] = useState<T>(() => {
		try {
			const item = window.localStorage.getItem(key);
			return item ? JSON.parse(item) : initialValue;
		} catch (error) {
			console.error(`Error parsing localStorage value for key '${key}'`, error);
			return initialValue;
		}
	});

	const setValue = (value: T | ((val: T) => T)) => {
		try {
			const processedValue =
				value instanceof Function ? value(storedValue) : value;

			setStoredValue(processedValue);
			window.localStorage.setItem(key, JSON.stringify(processedValue));
		} catch (error) {
			console.error(`Error setting localStorage value for key '${key}'`, error);
		}
	};

	return [storedValue, setValue] as const;
};

export default usePersistantState;
