import { useId } from 'react';
import DatePicker, {
	DatePickerProps,
} from 'ui/components/DatePicker/DatePicker';
import FormField, { FormFieldProps } from 'ui/components/FormField';
import useValidation from 'ui/components/ValidatedForm/useValidation';

type DateFieldProps = {} & FormFieldProps & DatePickerProps;

const DateField = ({
	name,
	label,
	isRequired,
	description,
	errors,
	...restProps
}: DateFieldProps) => {
	const { errorTree, revalidate } = useValidation(name);
	const id = useId();

	return (
		<FormField
			id={id}
			label={label}
			errors={errorTree?._errors ?? errors}
			isRequired={isRequired}
			description={description}
		>
			<DatePicker name={name} onChange={revalidate} id={id} {...restProps} />
		</FormField>
	);
};

export default DateField;
