export const formDataAsObject = (formData: FormData) => {
	const entries = [...formData.entries()];
	const result: Record<string, unknown> = {};

	for (const [entryKey, entryValue] of entries) {
		// First split the path into segments
		const paths = entryKey.split('.');

		let current: Record<string | number, unknown> = result;

		// Format is now [key, 0, anotherKey]
		while (paths.length) {
			const currentKey = paths.shift() as string;

			if (currentKey in current) {
				current = current[currentKey] as Record<string | number, unknown>;
			} else {
				if (paths.length) {
					// Not the last path, so we're dealing with an array or object
					const nextKey = paths[0];
					current[currentKey] = !isNaN(parseInt(nextKey)) ? [] : {};
					current = current[currentKey] as Record<string | number, unknown>;
				} else {
					// Last path, we just set the value
					const allValues = formData.getAll(entryKey);
					current[currentKey] = allValues.length > 1 ? allValues : entryValue;
				}
			}
		}
	}

	return result;
};
