import { PackageOpen, Plus } from 'lucide-react';
import { LoaderFunctionArgs, redirect, useLoaderData } from 'react-router-dom';
import Button from 'ui/components/Button';
import PageHeader from 'ui/components/PageHeader';
import Pagination from 'ui/components/Pagination';
import RevalidationButton from 'ui/components/RevalidationButton';
import WeightBreaksCard from 'ui/components/WeightBreaksCard';
import WebToolGroupAPI from 'utils/api/WebToolGroupAPI';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import UserAPI from '../../api/UserAPI';

const loader = async ({ request }: LoaderFunctionArgs) => {
	const webToolAvailable = await UserAPI.hasWebtoolAccess();
	if (!webToolAvailable) {
		return redirect('/');
	}

	const url = new URL(request.url);
	const { groups } = await WebToolGroupAPI.getBreaksGroups(url.searchParams);

	return {
		groups: groups,
	};
};

const WebToolGroupArea = () => {
	const data = useLoaderData() as LoaderData<typeof loader>;
	return (
		<div className="content">
			<div>
				<PageHeader title="Weight Break Groups">
					<RevalidationButton>Refresh</RevalidationButton>
					<Button
						variant="primary"
						icon={Plus}
						to="/webtool/groups/breaks/create"
					>
						New group
					</Button>
				</PageHeader>
			</div>

			{data.groups.items.length > 0 && (
				<div className="weightbreaks-groups">
					{data.groups.items.map((group) => {
						return <WeightBreaksCard {...group} key={group.name} />;
					})}
				</div>
			)}

			{data.groups.items.length === 0 && (
				<div className="weightbreaks-groups weightbreaks-groups--empty">
					<PackageOpen size={24} />
					<p>No entries found</p>
				</div>
			)}

			{data.groups.totalCount > 0 && (
				<Pagination
					baseUrl={new URL(window.location.href)}
					page={data.groups.page}
					pageParameterName="page"
					pageSize={data.groups.pageSize}
					itemCount={data.groups.totalCount}
				/>
			)}
		</div>
	);
};

export const WEBTOOL_BREAKS_GROUP_LIST_ROUTE: DecentralizedRouteProps = {
	loader,
	element: <WebToolGroupArea />,
};
