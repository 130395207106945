const SESSION_ID_KEY = 'session-id';

export const getSessionId = () => {
	const existingSessionId = localStorage.getItem(SESSION_ID_KEY);

	if (existingSessionId) {
		return existingSessionId;
	}

	const sessionId = crypto.randomUUID();
	localStorage.setItem(SESSION_ID_KEY, sessionId);
	return sessionId;
};

export const clearSession = () => {
	localStorage.removeItem(SESSION_ID_KEY);
};
