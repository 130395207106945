import { PropsWithChildren, ReactNode } from 'react';
import Breadcrumbs from '../Breadcrumbs';

export type PageHeaderProps = PropsWithChildren<{
	title: string | ReactNode;
	label?: string;
}>;

const PageHeader = ({ title, label, children }: PageHeaderProps) => {
	return (
		<div className="page-header">
			<div className="page-header__main">
				{label ? (
					<span className="page-header__label">{label}</span>
				) : (
					<Breadcrumbs />
				)}
				<h1 className="page-header__title" data-testid="pageHeader">
					{title}
				</h1>
			</div>
			<div className="page-header__actions">{children}</div>
		</div>
	);
};

export default PageHeader;
