import AsyncMultiSelect from 'ui/components/AsyncMultiSelect';
import AsyncSelect from 'ui/components/AsyncSelect';
import FormField from 'ui/components/FormField';
import Pill from 'ui/components/Pill';
import useValidation from 'ui/components/ValidatedForm/useValidation';
import CASSAreasAPI, { CASSAreaOption } from 'utils/api/CASSAreasAPI';
import { catchSilently } from 'utils/helpers/catchHandlers';
import {
	createValidationToast,
	parseTextToCodes,
} from 'utils/helpers/codeValidation';

type SingleCASSAreaPickerProps = {
	isMulti?: false;
	initialValue?: CASSAreaOption;
	onSelectedCASSAreaChange?: (value: CASSAreaOption | null) => void;
	selectedCASSArea?: CASSAreaOption | null;
};

type MultiCASSAreaPickerProps = {
	isMulti: true;
	initialValues?: CASSAreaOption[];
	onSelectedCASSAreasChange?: (values: CASSAreaOption[]) => void;
	selectedCASSAreas?: CASSAreaOption[];
};

type CASSAreaPickerProps = {
	name: string;
	label?: string;
	isRequired?: boolean;
	isDisabled?: boolean;
	placeholder?: string;
} & (SingleCASSAreaPickerProps | MultiCASSAreaPickerProps);

const CASSAreaPicker = (props: CASSAreaPickerProps) => {
	const { name, label, isRequired, isDisabled } = props;

	const { errorTree } = useValidation(name);

	const searchCASSAreas = async (searchTerm: string) => {
		const data = await CASSAreasAPI.getCASSAreas(searchTerm);
		return data.options;
	};

	const handleInsert = async (text: string) => {
		const codes = parseTextToCodes(text);

		if (codes) {
			const validatePromise = CASSAreasAPI.validateCASSAreaCodes(codes);

			createValidationToast(validatePromise, 'cass area').catch(catchSilently);

			const validationResult = await validatePromise;
			return validationResult.options;
		}

		return false;
	};

	return (
		<FormField
			label={label}
			errors={errorTree?._errors}
			isRequired={isRequired}
		>
			{props.isMulti ? (
				<AsyncMultiSelect<CASSAreaOption>
					defaultOptions={[]}
					isDisabled={isDisabled}
					onSearch={searchCASSAreas}
					identifierKey="code"
					name={name}
					placeholder={props.placeholder ?? 'Search CASS Operations...'}
					handlePasteSelection={handleInsert}
					handleSearchSubmitSelection={handleInsert}
					selectedOptions={props.selectedCASSAreas}
					onSelectedOptionsChange={props.onSelectedCASSAreasChange}
					contentSource={(cassArea) => (
						<>
							<Pill code={cassArea.code} /> {cassArea.name}
						</>
					)}
					initialValues={props.initialValues}
					pillComponent={({ value, onRemove }) => (
						<Pill
							name={value.name}
							code={value.code}
							title={value.name}
							onRemove={onRemove}
						/>
					)}
				/>
			) : (
				<AsyncSelect<CASSAreaOption>
					defaultOptions={[]}
					isDisabled={isDisabled}
					onSearch={searchCASSAreas}
					identifierKey="code"
					name={name}
					placeholder={props.placeholder ?? 'Search CASS Operation...'}
					handlePasteSelection={handleInsert}
					handleSearchSubmitSelection={handleInsert}
					contentSource={(cassArea) => (
						<>
							<Pill code={cassArea.code} /> {cassArea.name}
						</>
					)}
					isClearable={true}
					onOptionSelected={props.onSelectedCASSAreaChange}
					selectedOption={props.selectedCASSArea}
					initialValue={props.initialValue}
					previewSource={(cassArea) => (
						<Pill
							name={cassArea.name}
							code={cassArea.code}
							title={cassArea.name}
						/>
					)}
				/>
			)}
		</FormField>
	);
};

export default CASSAreaPicker;
