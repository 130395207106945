import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { useId, useState } from 'react';
import FormField from 'ui/components/FormField';
import useValidation from 'ui/components/ValidatedForm/useValidation';
import {
	YearAndMonth,
	dateToYearMonth,
	yearMonthToDate,
} from 'utils/types/helpers';
import { flattenErrors } from 'utils/zod/zodErrors';
import MonthPicker from '../MonthPicker';

type YearMonthPickerProps = {
	name: string;
	label?: string;
	value?: YearAndMonth | null;
	initialValue?: YearAndMonth | null;
	isRequired?: boolean;
	onChange?: (yearMonth: YearAndMonth | null) => void;
	disabled?: boolean;
	placeholder?: string;

	minDate?: Date;
	maxDate?: Date;
};

const YearMonthPicker = ({
	name,
	label,
	value: controlledValue,
	initialValue,
	isRequired,
	onChange: controlledOnChange,
	disabled,
	placeholder,
	minDate,
	maxDate,
}: YearMonthPickerProps) => {
	const { errorTree, revalidate } = useValidation(name);
	const [uncontrolledValue, setUncontrolledValue] = useState(initialValue);

	const value =
		controlledValue !== undefined ? controlledValue : uncontrolledValue;
	const onChange = controlledOnChange ?? setUncontrolledValue;

	const id = useId();

	const initialDate = initialValue ? yearMonthToDate(initialValue) : null;
	const yearMonthValue = yearMonthToDate(value);

	return (
		<FormField
			label={label}
			errors={disabled ? [] : flattenErrors(errorTree)}
			isRequired={isRequired}
			id={id}
		>
			<MonthPicker
				value={yearMonthValue ? zonedTimeToUtc(yearMonthValue, 'UTC') : null}
				initialValue={initialDate ?? undefined}
				onChange={(date) => {
					const yearMonth = dateToYearMonth(
						date ? utcToZonedTime(date, 'UTC') : null
					);
					onChange(yearMonth);
					revalidate();
				}}
				isDisabled={disabled}
				placeholder={placeholder}
				id={id}
				minDate={minDate}
				maxDate={maxDate}
			/>
			{value && name && (
				<>
					<input type="hidden" name={`${name}.month`} value={value.month} />
					<input type="hidden" name={`${name}.year`} value={value.year} />
				</>
			)}
		</FormField>
	);
};

export default YearMonthPicker;
