import React from 'react';
import Skeleton from '../Skeleton';

type DropdownLoadingProps = {};

const DropdownLoading = ({}: DropdownLoadingProps) => {
	return (
		<li className="dropdown__option dropdown__option--loading">
			<Skeleton />
		</li>
	);
};

export default DropdownLoading;
