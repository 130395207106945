import { useEffect, useState } from 'react';
import Button from 'ui/components/Button';
import Modal, { ModalProps } from 'ui/components/Modal/Modal';
import WeightBreaks from 'ui/components/WeightBreaks';

type WorksheetWeightBreaksModalProps = {
	weightBreaks: number[];
	onWeightBreaksChange: (weightBreaks: number[]) => void;
} & Omit<ModalProps, 'title'>;

const WorksheetWeightBreaksModal = ({
	weightBreaks,
	onWeightBreaksChange,
	...modalProps
}: WorksheetWeightBreaksModalProps) => {
	useEffect(() => {
		setSelectedValue(weightBreaks ?? []);
	}, [modalProps.isOpen]);

	const [selectedValue, setSelectedValue] = useState<number[]>(weightBreaks);

	const handleSave = () => {
		onWeightBreaksChange(selectedValue);
		modalProps.onClose();
	};

	return (
		<Modal title="Edit Custom Weight Breaks" size="sm" {...modalProps}>
			<Modal.Body>
				<WeightBreaks
					value={selectedValue}
					onChange={(newValue) => {
						setSelectedValue(newValue);
					}}
				/>
			</Modal.Body>
			<Modal.Actions alignment="between">
				<Button variant="secondary" onClick={modalProps.onClose}>
					Cancel
				</Button>
				<Button variant="primary" onClick={handleSave}>
					Save
				</Button>
			</Modal.Actions>
		</Modal>
	);
};

export default WorksheetWeightBreaksModal;
