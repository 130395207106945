import { Placement } from '@floating-ui/react';
import { PropsWithChildren, useMemo } from 'react';
import TooltipContent from './TooltipContent';
import TooltipContext, { useTooltip } from './TooltipContext';
import TooltipTrigger from './TooltipTrigger';

type TooltipProps = PropsWithChildren<{
	initialOpen?: boolean;
	placement?: Placement;
	open?: boolean;
	onOpenChange?: (open: boolean) => void;
	delay?: number;
	disabled?: boolean;
	detachTriggerWhileDisabled?: boolean;
}>;

const Tooltip = ({ children, ...options }: TooltipProps) => {
	const tooltip = useTooltip(options);

	return useMemo(
		() => (
			<TooltipContext.Provider value={tooltip}>
				{children}
			</TooltipContext.Provider>
		),
		[children, tooltip]
	);
};

Tooltip.Content = TooltipContent;
Tooltip.Trigger = TooltipTrigger;

export default Tooltip;
