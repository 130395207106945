import Pill from 'ui/components/Pill';
import { AirlineOption } from 'utils/api/AirlinesAPI';

export type AirlinePillProps = {
	airline: AirlineOption;
	variant?: 'full' | 'small';
};

export const AirlinePill = ({ airline, variant }: AirlinePillProps) => {
	if (airline.itemType === 'custom-group') {
		return <Pill name={airline.name} />;
	}

	return (
		<>
			{variant === 'small' ? (
				<Pill code={airline.iataCode} title={airline.name} />
			) : (
				<Pill
					name={airline.airlinePrefix}
					code={airline.iataCode}
					title={airline.name}
				/>
			)}
		</>
	);
};
