import classNames from 'classnames';
import { ChevronsLeft, ChevronsRight } from 'lucide-react';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';

type MonthPickerHeaderProps = ReactDatePickerCustomHeaderProps;

const MonthPickerHeader = ({
	customHeaderCount,

	nextYearButtonDisabled,
	prevYearButtonDisabled,
	increaseYear,
	decreaseYear,

	changeMonth,
	changeYear,

	monthDate,
	date,
}: MonthPickerHeaderProps) => {
	const currentDate = new Date();
	const showCurrentYearJumpLink =
		currentDate.getFullYear() !== monthDate.getFullYear();

	const showJumpLinks = customHeaderCount === 0 && showCurrentYearJumpLink;

	const jumpToToday = () => {
		changeMonth(currentDate.getMonth());
		changeYear(currentDate.getFullYear());
	};

	const isStartAndEndInSameYear =
		nextYearButtonDisabled && prevYearButtonDisabled;

	return (
		<div className="datepicker__header">
			<div
				className={classNames(
					'datepicker__actions',
					isStartAndEndInSameYear && 'datepicker__actions--hidden'
				)}
			>
				<button
					type="button"
					className="datepicker__action datepicker__action--previous-year"
					onClick={decreaseYear}
					disabled={prevYearButtonDisabled}
				>
					<ChevronsLeft size={16} />
				</button>
			</div>

			<div
				className={classNames(
					'datepicker__info',
					showJumpLinks && 'datepicker__info--has-jump-links'
				)}
			>
				<div className="datepicker__current">
					<span className="datepicker__current-year">
						{monthDate.getFullYear()}
					</span>
				</div>

				{showJumpLinks && (
					<div className="datepicker__jump-links">
						<button
							type="button"
							className="datepicker__jump-link"
							onClick={jumpToToday}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									jumpToToday();
								}
							}}
						>
							Jump to this year
						</button>
					</div>
				)}
			</div>

			<div
				className={classNames(
					'datepicker__actions',
					isStartAndEndInSameYear && 'datepicker__actions--hidden'
				)}
			>
				<button
					type="button"
					className="datepicker__action datepicker__action--next-year"
					onClick={increaseYear}
					disabled={nextYearButtonDisabled}
				>
					<ChevronsRight size={16} />
				</button>
			</div>
		</div>
	);
};

export default MonthPickerHeader;
