import { ReactNode } from 'react';
import BulletIndicator from '../BulletIndicator';

export type WebToolStatusIndicatorProps = {
	intent: string;
	label?: string | ReactNode;
	toolTip?: string;
};

const WebToolStatusIndicator = ({
	intent,
	label,
	toolTip,
}: WebToolStatusIndicatorProps) => {
	let intentBullet: 'info' | 'success' | 'error' | 'warning' = 'info';

	switch (intent) {
		case 'in-progress':
			intentBullet = 'info';
			break;
		case 'success':
			intentBullet = 'success';
			break;
		case 'failed':
			intentBullet = 'error';
			break;
		case 'cancelled':
			intentBullet = 'warning';
			break;
	}
	return (
		<BulletIndicator label={label} toolTip={toolTip} intent={intentBullet} />
	);
};

export default WebToolStatusIndicator;
