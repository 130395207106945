import { createColumnHelper } from '@tanstack/react-table';
import AttachmentLink from 'ui/components/AttachmentLink';
import DateFragment from 'ui/components/DateFragment';
import { WebToolReport } from '../../api/ReportHubAPI';
import ReportOutputPopover from '../../components/ReportOutputPopover/ReportOutputPopover';
import WebToolStatusIndicator from 'ui/components/WebToolStatusIndicator/WebToolStatusIndicator';

const columnHelper = createColumnHelper<WebToolReport>();

export const titleColumn = columnHelper.accessor('title', {
	header: 'Report',
	enableSorting: true,
});

export const statusColumn = columnHelper.accessor('status', {
	header: 'Status',
	cell: ({ row }) => {
		return (
			<WebToolStatusIndicator
				intent={row.original.status.value}
				label={row.original.status.label}
			/>
		);
	},
});

export const executedAtColumn = columnHelper.accessor('executedAt', {
	header: 'Executed At',
	cell: (info) => <DateFragment includeTime={true} date={info.getValue()} />,
	enableSorting: true,
});

export const detailsColumn = columnHelper.display({
	header: 'Details',
	cell: (info) => (
		<ReportOutputPopover
			parameters={info.row.original.parameters}
			columns={info.row.original.columns}
		/>
	),
});

export const attachmentWebToolColumn = columnHelper.accessor('filename', {
	header: 'Attachment',
	cell: (info) => {
		if (info.row.original.filename === '') {
			return null;
		}
		return (
			<AttachmentLink
				url={`/report-hub/webtool-reports/${info.row.original.id}/download`}
				target="_blank"
				name={info.row.original.filename}
				size={info.row.original.fileSizeInBytes}
			/>
		);
	},
});
