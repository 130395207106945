import { useState } from 'react';
import Button from 'ui/components/Button';
import Modal, { ModalProps } from 'ui/components/Modal/Modal';
import TextField from 'ui/components/TextField';
import { useWorksheetContext } from '../WorksheetContext';
import AWBSerialNumberPill from './AWBSerialNumberPill';

export function sanitizeAwbInput(rawValue: string) {
	return rawValue.replace(/[^\d,;]/g, '');
}

export const sanitizeAwbSerialNumbers = (serialNumbers: string) => {
	if (!serialNumbers || serialNumbers.trim() === '') {
		return [];
	}

	return serialNumbers
		.trim()
		.replaceAll(';', ',')
		.split(',')
		.map((s) => s.trim())
		.filter((s) => s !== '');
};

type AWBSerialNumbersModalProps = Omit<ModalProps, 'title'>;

const AWBSerialNumbersModal = ({
	...modalProps
}: AWBSerialNumbersModalProps) => {
	const { state, setDeepState } = useWorksheetContext();

	const [newSerialNumbersRaw, setNewSerialNumbersRaw] = useState('');

	const serialNumberRaw = state.parameters.awb.serialNumber;

	const serialNumbers: Array<string> =
		sanitizeAwbSerialNumbers(serialNumberRaw);

	const handleRemove = (index: number) => {
		const newSerialNumbers = [...serialNumbers];
		newSerialNumbers.splice(index, 1);

		setDeepState('parameters.awb.serialNumber', newSerialNumbers.join(','));
	};

	const handleAdd = () => {
		const newSerialNumbers = sanitizeAwbSerialNumbers(newSerialNumbersRaw);
		const combinedSerialNumbers = [...serialNumbers, ...newSerialNumbers];

		setNewSerialNumbersRaw('');
		setDeepState(
			'parameters.awb.serialNumber',
			combinedSerialNumbers.join(',')
		);
	};

	return (
		<Modal title="AWB Serial Numbers" size="md" {...modalProps}>
			<Modal.Body className="awb__modal">
				{serialNumbers.length === 0 ? (
					<p className="awb__description">
						There are currently no airway bill serial numbers selected.
					</p>
				) : (
					<>
						<p className="awb__description">
							The following airway bill serial numbers are currently selected:
						</p>

						<ul className="awb__serial-numbers">
							{serialNumbers.map((serialNumber, index) => (
								<li key={index}>
									<AWBSerialNumberPill
										serialNumber={serialNumber}
										onRemove={() => handleRemove(index)}
									/>
								</li>
							))}
						</ul>
					</>
				)}

				<div className="awb__add">
					<TextField
						placeholder="Enter comma-separated serial numbers..."
						value={newSerialNumbersRaw}
						onChange={(e) =>
							setNewSerialNumbersRaw(sanitizeAwbInput(e.target.value))
						}
						name=""
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								handleAdd();
							}
						}}
					/>

					<Button variant="secondary" size="small" onClick={handleAdd}>
						Add Serial Number(s)
					</Button>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default AWBSerialNumbersModal;
