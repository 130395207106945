type PaginationStatusProps = {
	itemCount: number;
	pageSize: number;
	page: number;
};

const PaginationStatus = ({
	itemCount,
	pageSize,
	page,
}: PaginationStatusProps) => {
	const startItem = (page - 1) * pageSize + 1;
	const endItem = Math.min(page * pageSize, itemCount);

	if (itemCount === 0) {
		return null;
	}

	return (
		<div className="pagination__status">
			Showing {startItem} - {endItem} of {itemCount} items
		</div>
	);
};

export default PaginationStatus;
