import { useCallback, useMemo } from 'react';
import AsyncMultiSelect from 'ui/components/AsyncMultiSelect';
import AsyncSelect from 'ui/components/AsyncSelect';
import FormField from 'ui/components/FormField';
import Pill from 'ui/components/Pill';
import useValidation from 'ui/components/ValidatedForm/useValidation';
import FreightForwarderAPI, {
	FreightForwarderCustomGroupOption,
	FreightForwarderOption,
	FreightForwarderSingleOption,
	FreightForwarderType,
} from 'utils/api/FreightForwarderAPI';
import { WorksheetConfigWebToolGroup } from 'utils/api/WebToolAPI';
import { catchSilently } from 'utils/helpers/catchHandlers';
import {
	createValidationToast,
	parseTextToCodes,
} from 'utils/helpers/codeValidation';
import { createToast } from 'utils/helpers/toast';
import { OptionGroup } from '../Dropdown/types';
import { MultiSelectPillProps } from '../MultiSelect/MultiSelect';
import { ToastType } from '../Toaster/Toast';

const FreightForwarderMultiSelectPill = ({
	value,
	onRemove,
}: MultiSelectPillProps<FreightForwarderOption>) => {
	if (value.itemType === 'custom-group' || value.itemType === 'group') {
		return <Pill name={value.name} onRemove={onRemove} />;
	} else {
		return <Pill name={value.name} code={value.code} onRemove={onRemove} />;
	}
};

const customGroupToFreightForwarderOption = (
	group: WorksheetConfigWebToolGroup
): FreightForwarderCustomGroupOption => ({
	name: group.name,
	itemType: 'custom-group',
	freightForwarderId: `custom-group-${group.id}`,
	code: group.id,
});

type SingleFreightForwarderPickerProps = {
	isMulti?: false;
	initialValue?: FreightForwarderOption;
	initialValues?: undefined;
	onSelectedFreightForwarderChange?: (
		value: FreightForwarderOption | null
	) => void;
	onSelectedFreightForwardersChange?: undefined;
	selectedFreightForwarder?: FreightForwarderOption | null;
	selectedFreightForwarders?: undefined;
};

type MultiFreightForwarderPickerProps = {
	isMulti: true;
	initialValue?: undefined;
	initialValues?: FreightForwarderOption[];
	onSelectedFreightForwarderChange?: undefined;
	onSelectedFreightForwardersChange?: (
		values: FreightForwarderOption[]
	) => void;
	selectedFreightForwarder?: undefined;
	selectedFreightForwarders?: FreightForwarderOption[];
};

type FreightForwarderPickerProps = {
	name: string;
	label?: string;
	isRequired?: boolean;
	freightForwarderTypes?: FreightForwarderType[];
	subscriptionId?: string;
	isDisabled?: boolean;
	freightForwarderCustomGroups?: WorksheetConfigWebToolGroup[];
} & (SingleFreightForwarderPickerProps | MultiFreightForwarderPickerProps);

const FreightForwarderPicker = (props: FreightForwarderPickerProps) => {
	const {
		name,
		label,
		isRequired,
		isDisabled,
		freightForwarderTypes = ['head-office'],
		freightForwarderCustomGroups = [],
	} = props;

	const { errorTree } = useValidation(name);
	const isGrouped = freightForwarderTypes.length > 1;

	const getFreightForwarders = async (searchTerm: string) => {
		const data = await FreightForwarderAPI.getFreightForwarders(
			searchTerm,
			freightForwarderTypes,
			props.subscriptionId
		);
		return data.options || data.groups;
	};

	const handleInsert = async (text: string) => {
		const codes = parseTextToCodes(text);

		if (codes) {
			const validatePromise = FreightForwarderAPI.validateFreightForwarderCodes(
				codes,
				freightForwarderTypes
			);

			createValidationToast(validatePromise, 'freight forwarder').catch(
				catchSilently
			);

			const validationResult = await validatePromise;
			return validationResult.options;
		}

		return false;
	};

	const handleCopy = (
		options: FreightForwarderOption[] | FreightForwarderOption | null
	) => {
		let value = '';
		let hasCustomGroups = false;

		if (Array.isArray(options)) {
			if (options.some((option) => option.itemType === 'custom-group')) {
				hasCustomGroups = true;
			}

			value = options
				.filter((option): option is FreightForwarderSingleOption => {
					return option.itemType !== 'custom-group';
				})
				.map((option) => option.code)
				.join(', ');
		} else if (options) {
			if (options.itemType === 'custom-group') {
				hasCustomGroups = true;
			} else if ('code' in options) {
				value = options.code;
			}
		}

		if (value) {
			navigator.clipboard.writeText(value);
		}

		if (hasCustomGroups) {
			createToast(ToastType.WARNING, {
				message: 'Custom groups omitted',
				hint: 'The selected custom groups have been omitted. They must be manually selected from the dropdown.',
			});
		}
	};

	const defaultOptions = useMemo(():
		| FreightForwarderOption[]
		| OptionGroup<FreightForwarderOption>[]
		| undefined => {
		if (freightForwarderCustomGroups.length === 0) {
			return undefined;
		}

		if (isGrouped) {
			return [
				{
					label: 'Custom Groupings',
					options: freightForwarderCustomGroups.map(
						customGroupToFreightForwarderOption
					),
				},
			];
		} else {
			return freightForwarderCustomGroups.map(
				customGroupToFreightForwarderOption
			);
		}
	}, [isGrouped, freightForwarderCustomGroups]);

	const freightForwarderContentSource = useCallback(
		(option: FreightForwarderOption) => {
			if (option.itemType === 'custom-group' || option.itemType === 'group') {
				return <Pill name={option.name} />;
			} else {
				return <Pill code={option.code} name={option.name} />;
			}
		},
		[]
	);

	return (
		<FormField
			label={label}
			errors={errorTree?._errors}
			isRequired={isRequired}
		>
			{props.isMulti ? (
				<AsyncMultiSelect<FreightForwarderOption>
					defaultOptions={defaultOptions}
					isGrouped={isGrouped}
					isDisabled={isDisabled}
					onSearch={getFreightForwarders}
					handlePasteSelection={handleInsert}
					onCopy={handleCopy}
					handleSearchSubmitSelection={handleInsert}
					initialValues={props.initialValues}
					selectedOptions={props.selectedFreightForwarders}
					onSelectedOptionsChange={props.onSelectedFreightForwardersChange}
					identifierKey={isGrouped ? 'freightForwarderId' : 'code'}
					name={name}
					placeholder="Select freight forwarders..."
					contentSource={freightForwarderContentSource}
					pillComponent={FreightForwarderMultiSelectPill}
				/>
			) : (
				<AsyncSelect<FreightForwarderOption>
					defaultOptions={defaultOptions}
					isGrouped={isGrouped}
					isDisabled={isDisabled}
					onSearch={getFreightForwarders}
					handlePasteSelection={handleInsert}
					handleSearchSubmitSelection={handleInsert}
					onCopy={handleCopy}
					initialValue={props.initialValue}
					selectedOption={props.selectedFreightForwarder}
					onOptionSelected={props.onSelectedFreightForwarderChange}
					identifierKey={isGrouped ? 'freightForwarderId' : 'code'}
					name={name}
					placeholder="Select freight forwarder..."
					contentSource={freightForwarderContentSource}
				/>
			)}
		</FormField>
	);
};

export default FreightForwarderPicker;
