import { ENVIRONMENT_SHARED } from 'environment';
import API from 'utils/api/API';
import { TradelaneOriginDestinationOption } from 'utils/api/WebToolGroupAPI';
import { ValidationResult } from '../helpers/codeValidation';
import { LocationType } from './LocationAPI';

export type ValidateTradelanesResponse =
	ValidationResult<TradelaneOriginDestinationOption>;

class TradelaneAPI extends API {
	protected static readonly apiEndpoint =
		ENVIRONMENT_SHARED.subscriptionsApiEndpoint;

	static async validateTradelanes(
		tradelaneCodes: string[],
		type: LocationType[] = [
			LocationType.STATION,
			LocationType.CITY,
			LocationType.COUNTRY,
		]
	) {
		const params = new URLSearchParams({
			codes: tradelaneCodes.join(','),
		});

		const allTypes = Array.isArray(type) ? type : [type];
		for (const type of allTypes) {
			params.append('type', type);
		}

		return TradelaneAPI.get<ValidateTradelanesResponse>(
			`/trade-lane/validate?${params.toString()}`
		);
	}
}

export default TradelaneAPI;
