import { ReactNode, useState } from 'react';
import { DataSource } from 'utils/types/dataSource';
import Dropdown from '../Dropdown';
import { DropdownProps } from '../Dropdown/Dropdown';

export type SelectProps<T> = {
	initialValue?: T | null;
	selectedOption?: T | null;
	previewSource?: DataSource<T, ReactNode>;
	onPasteSelection?: (value: string) => void;
	onOptionSelected?: (option: T | null) => void;
	onCopy?: (value: T | null) => void;
} & Omit<
	DropdownProps<T>,
	| 'isOpen'
	| 'onOpenChange'
	| 'previewSource'
	| 'selectedOptions'
	| 'components'
	| 'isMulti'
	| 'onOptionsSelected'
> &
	Partial<
		Pick<
			DropdownProps<T>,
			| 'isOpen'
			| 'onOpenChange'
			| 'handlePasteSelection'
			| 'handleSearchSubmitSelection'
		>
	>;

const Select = <T,>({
	initialValue = null,
	onOptionSelected,
	isOpen: propsIsOpen,
	onOpenChange: propsOnOpenChange,
	contentSource,
	previewSource = contentSource,
	selectedOption: propsSelectedOption,
	...restProps
}: SelectProps<T>) => {
	const [stateSelectedOption, setStateSelectedOption] = useState<T | null>(
		initialValue
	);
	const [stateIsOpen, stateSetOpen] = useState(false);

	const isOpen = propsIsOpen ?? stateIsOpen;
	const onOpenChange = propsOnOpenChange ?? stateSetOpen;

	const selectedOption =
		propsSelectedOption !== undefined
			? propsSelectedOption
			: stateSelectedOption;

	return (
		<Dropdown<T>
			isOpen={isOpen}
			onOpenChange={onOpenChange}
			selectedOptions={selectedOption}
			contentSource={contentSource}
			onOptionsSelected={(option) => {
				setStateSelectedOption(option);
				if (onOptionSelected) {
					onOptionSelected(option);
				}
				onOpenChange(false);
			}}
			previewSource={previewSource}
			isMulti={false}
			{...restProps}
		/>
	);
};

export default Select;
