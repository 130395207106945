import { createColumnHelper } from '@tanstack/react-table';
import AttachmentLink from 'ui/components/AttachmentLink';
import DateFragment from 'ui/components/DateFragment';
import { LocationNaturalKeyList } from 'ui/components/LocationPicker/LocationNaturalKeyList';
import { StaticReport } from '../../api/ReportHubAPI';

const columnHelper = createColumnHelper<StaticReport>();

export const productNameColumn = columnHelper.accessor('productName', {
	header: 'Report Type',
});

export const reportMonthColumn = columnHelper.accessor('reportDate', {
	header: 'Month of Report',
	cell: ({ getValue }) =>
		`${getValue().month.toString().padStart(2, '0')}/${getValue().year}`,
	enableSorting: true,
});

export const createdAtColumn = columnHelper.accessor('createdAt', {
	header: 'Created Date',
	cell: (info) => <DateFragment includeTime={true} date={info.getValue()} />,
	enableSorting: true,
});

export const originsColumn = columnHelper.display({
	id: 'origins',
	header: 'Origin',
	cell: (info) => {
		const data = info.row.original;
		if (data.variant)
			return (
				<>
					<span title={`Country: ${data.variant.key}`}>{data.variant.key}</span>
				</>
			);
		else if (data.origin)
			return (
				<>
					<LocationNaturalKeyList locations={data.origin} />
					{data.origin2 && <LocationNaturalKeyList locations={data.origin2} />}
					{data.destination2 && !data.origin2 && <div>World</div>}
					{data.origin3 && <LocationNaturalKeyList locations={data.origin3} />}
					{data.destination3 && !data.origin3 && <div>World</div>}
				</>
			);
		else return '-';
	},
});

export const destinationsColumn = columnHelper.display({
	id: 'destinations',
	header: 'Destination',
	cell: (info) => {
		const data = info.row.original;
		if (!data.destination) return '-';
		return (
			<>
				<LocationNaturalKeyList locations={data.destination} />
				{data.destination2 && (
					<LocationNaturalKeyList locations={data.destination2} />
				)}
				{data.origin2 && !data.destination2 && <div>World</div>}
				{data.destination3 && (
					<LocationNaturalKeyList locations={data.destination3} />
				)}
				{data.origin3 && !data.destination3 && <div>World</div>}
			</>
		);
	},
});

export const attachmentColumn = columnHelper.accessor('filename', {
	header: 'Attachment',
	cell: (info) => {
		return (
			<AttachmentLink
				url={`/report-hub/reports/download/${info.row.original.id}`}
				target="_blank"
				name={info.row.original.filename}
				size={info.row.original.fileSizeInBytes}
			/>
		);
	},
});
