export type ZodFormattedErrors = Partial<{
	[key: string]: ZodFormattedErrors;
}> & {
	/* eslint-disable-next-line @typescript-eslint/naming-convention */
	_errors: string[];
};

export const mergeErrors = (...errors: (string[] | undefined)[]): string[] => {
	let mergedErrors: string[] = [];

	for (const currentErrors of errors) {
		if (currentErrors) {
			mergedErrors = [...mergedErrors, ...currentErrors];
		}
	}

	return mergedErrors;
};

export const flattenErrors = (
	errors: ZodFormattedErrors | undefined
): string[] => {
	if (errors === undefined) {
		return [];
	}

	const errorsArray = Object.entries(errors).map(([key, value]) => {
		if (key === '_errors') {
			return value as string[];
		}

		return flattenErrors(value as ZodFormattedErrors);
	});

	return mergeErrors(...errorsArray);
};
