import { RefreshCcw, Search } from 'lucide-react';
import {
	LayoutRouteProps,
	LoaderFunctionArgs,
	useLoaderData,
	useLocation,
} from 'react-router-dom';
import AirlinePicker from 'ui/components/AirlinePicker';
import Button from 'ui/components/Button';
import CountryPicker from 'ui/components/CountryPicker';
import ExportButton from 'ui/components/ExportButton';
import Flex from 'ui/components/Flex';
import FormField from 'ui/components/FormField';
import PageHeader from 'ui/components/PageHeader';
import Pagination from 'ui/components/Pagination';
import Select from 'ui/components/Select';
import Sidebar from 'ui/components/Sidebar';
import Table from 'ui/components/Table';
import TextField from 'ui/components/TextField';
import ValidatedForm from 'ui/components/ValidatedForm';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { LoaderData } from 'utils/types/loaderData';
import ReferenceDataAPI from '../../api/ReferenceDataAPI';
import * as airlineReferenceDataColumns from '../../utils/columns/airlineReferenceDataColumns';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);

	const requestUrl = new URL(request.url);
	const getAirlinesResponse = await ReferenceDataAPI.getAirlines(
		requestUrl.searchParams
	);

	return {
		airlines: getAirlinesResponse.airlines,
	};
};

export function ListAirlinesPage() {
	const data = useLoaderData() as LoaderData<typeof loader>;
	const location = useLocation();

	const columns = [
		airlineReferenceDataColumns.prefixColumn,
		airlineReferenceDataColumns.designatorColumn,
		airlineReferenceDataColumns.nameColumn,
		airlineReferenceDataColumns.countryCodeColumn,
		airlineReferenceDataColumns.countryNameColumn,
		airlineReferenceDataColumns.isActiveColumn,
	];

	const exportData = (format: string) => {
		const searchParams = new URLSearchParams(location.search);
		const newWindow = window.open(
			`/reference-data/airline/export?exportType=${format}&${searchParams}`,
			'_blank'
		);

		newWindow?.blur();
		window.focus();
	};

	return (
		<Sidebar.Wrapper>
			<div className="content">
				<PageHeader title="Airline Reference Data">
					<ExportButton onExport={exportData} fallbackFormatId="xlsx" />
				</PageHeader>

				<Flex direction="column" gap={16}>
					<Table
						columns={columns}
						data={data.airlines.items}
						identifierKey="id"
						emptyText="No airlines found."
					/>

					<Pagination
						baseUrl={new URL(window.location.href)}
						page={data.airlines.page}
						pageParameterName="page"
						pageSize={data.airlines.pageSize}
						itemCount={data.airlines.totalCount}
					/>
				</Flex>
			</div>

			<Sidebar title="Airline filters">
				<ValidatedForm resetOnNavigation>
					<TextField name="keyword" label="Keyword" />

					<AirlinePicker name="airlineCodes" label="Airline" isMulti />
					<CountryPicker name="countryCodes" label="Country" isMulti />

					<FormField label="Active Status">
						<Select
							name="status"
							placeholder="Select status..."
							options={[
								{ label: 'Active', value: 'active' },
								{ label: 'Inactive', value: 'inactive' },
							]}
							identifierKey="value"
							contentSource={(o) => o.label}
						/>
					</FormField>

					<Sidebar.Actions>
						<Button variant="secondary" icon={RefreshCcw} type="reset">
							Clear search
						</Button>
						<Button variant="primary" icon={Search} type="submit">
							Search
						</Button>
					</Sidebar.Actions>
				</ValidatedForm>
			</Sidebar>
		</Sidebar.Wrapper>
	);
}

export const LIST_AIRLINES_ROUTE: LayoutRouteProps = {
	loader: loader,
	element: <ListAirlinesPage />,
	handle: {
		crumb: () => 'Airlines',
	},
};
