import classNames from 'classnames';
import { ComponentProps, ForwardedRef, forwardRef } from 'react';

type BadgeProps = {
	size?: 'sm' | 'md';
} & ComponentProps<'span'>;

const Badge = (
	{ children, className, size = 'md', ...restProps }: BadgeProps,
	ref: ForwardedRef<HTMLSpanElement>
) => {
	return (
		<span
			className={classNames('badge', `badge--${size}`, className)}
			ref={ref}
			{...restProps}
		>
			{children}
		</span>
	);
};

export default forwardRef(Badge);
