import { RefreshCcw, Search } from 'lucide-react';
import {
	LayoutRouteProps,
	LoaderFunctionArgs,
	useLoaderData,
	useLocation,
} from 'react-router-dom';
import Alert from 'ui/components/Alert';
import Button from 'ui/components/Button';
import ExportButton from 'ui/components/ExportButton';
import Flex from 'ui/components/Flex';
import FreightForwarderPicker from 'ui/components/FreightForwarderPicker/FreightForwarderPicker';
import LocationPicker from 'ui/components/LocationPicker/LocationPicker';
import PageHeader from 'ui/components/PageHeader';
import Pagination from 'ui/components/Pagination';
import Sidebar from 'ui/components/Sidebar';
import Table from 'ui/components/Table';
import TextField from 'ui/components/TextField';
import ValidatedForm from 'ui/components/ValidatedForm';
import { LocationType } from 'utils/api/LocationAPI';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { LoaderData } from 'utils/types/loaderData';
import ReferenceDataAPI from '../../api/ReferenceDataAPI';
import * as freightForwarderReferenceDataColumns from '../../utils/columns/freightForwarderReferenceDataColumns';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);

	const requestUrl = new URL(request.url);
	const getFreightForwardersResponse =
		await ReferenceDataAPI.getFreightForwarders(requestUrl.searchParams);

	return {
		freightForwarders: getFreightForwardersResponse.freightForwarders,
	};
};

export function ListFreightForwardersPage() {
	const data = useLoaderData() as LoaderData<typeof loader>;
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);

	const requiredFilters = [
		'keyword',
		'locationCodes',
		'freightForwarderCodes',
		'city',
		'postalCode',
	];

	const shouldDisplayResults = requiredFilters.some((filter) => {
		const filterValue = searchParams.get(filter);
		return filterValue && filterValue.length > 0;
	});

	const columns = [
		freightForwarderReferenceDataColumns.branchOfficeCodeColumn,
		freightForwarderReferenceDataColumns.headOfficeCodeColumn,
		freightForwarderReferenceDataColumns.headOfficeNameColumn,
		freightForwarderReferenceDataColumns.branchOfficeNameColumn,
		freightForwarderReferenceDataColumns.countryCodeColumn,
		freightForwarderReferenceDataColumns.cityNameColumn,
		freightForwarderReferenceDataColumns.postalCodeColumn,
		freightForwarderReferenceDataColumns.freightForwarderGroupNameColumn,
	];

	const exportData = (format: string) => {
		const newWindow = window.open(
			`/reference-data/freight-forwarder/export?exportType=${format}&${searchParams}`,
			'_blank'
		);

		newWindow?.blur();
		window.focus();
	};

	return (
		<Sidebar.Wrapper>
			<div className="content">
				<PageHeader title="Freight Forwarder Reference Data">
					{shouldDisplayResults && (
						<ExportButton onExport={exportData} fallbackFormatId="xlsx" />
					)}
				</PageHeader>

				<Flex direction="column" gap={16}>
					{shouldDisplayResults ? (
						<>
							<Table
								columns={columns}
								data={data.freightForwarders.items}
								identifierKey="id"
								emptyText="No freight forwarders found."
							/>

							<Pagination
								baseUrl={new URL(window.location.href)}
								page={data.freightForwarders.page}
								pageParameterName="page"
								pageSize={data.freightForwarders.pageSize}
								itemCount={data.freightForwarders.totalCount}
							/>
						</>
					) : (
						<Alert
							title="Perform a search to view freight forwarders"
							intent="info"
						>
							You can search for freight forwarders by keyword, location, and
							more.
						</Alert>
					)}
				</Flex>
			</div>

			<Sidebar title="Freight forwarder filters">
				<ValidatedForm resetOnNavigation>
					<TextField name="keyword" label="Keyword" />

					<LocationPicker
						name="locationCodes"
						label="Countries"
						isMulti
						locationTypes={[LocationType.COUNTRY]}
					/>

					<FreightForwarderPicker
						name="freightForwarderCodes"
						freightForwarderTypes={['head-office', 'group']}
						label="Freight forwarders"
						isMulti
					/>

					<TextField name="city" label="City" />
					<TextField name="postalCode" label="Postal Code" />

					<Sidebar.Actions>
						<Button variant="secondary" icon={RefreshCcw} type="reset">
							Clear search
						</Button>
						<Button variant="primary" icon={Search} type="submit">
							Search
						</Button>
					</Sidebar.Actions>
				</ValidatedForm>
			</Sidebar>
		</Sidebar.Wrapper>
	);
}

export const LIST_FREIGHT_FORWARDERS_ROUTE: LayoutRouteProps = {
	loader: loader,
	element: <ListFreightForwardersPage />,
	handle: {
		crumb: () => 'Freight Forwarders',
	},
};
