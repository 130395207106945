import classNames from 'classnames';
import { ReactNode, useMemo } from 'react';

export type KeyValue = {
	key: string;
	value: ReactNode;
};

type KeyValuePairsProps = {
	entries: Array<KeyValue>;
	layout?: 'horizontal' | 'vertical' | 'list';
	hideEmpty?: boolean;
	className?: string;
};

const KeyValuePairs = ({
	entries,
	layout = 'horizontal',
	className,
	hideEmpty,
}: KeyValuePairsProps) => {
	const filteredEntries = useMemo(
		() => entries.filter((entry) => (!hideEmpty ? true : entry.value)),
		[entries, hideEmpty]
	);

	return (
		<ul
			className={classNames(
				'keyvaluepairs',
				`keyvaluepairs--${layout}`,
				className
			)}
		>
			{filteredEntries.map((entry) => (
				<li className="keyvaluepairs__entry" key={entry.key}>
					<dl>
						<dt className="keyvaluepairs__key">{entry.key}</dt>
						<dd className="keyvaluepairs__value">{entry.value || '–'}</dd>
					</dl>
				</li>
			))}
		</ul>
	);
};

export default KeyValuePairs;
