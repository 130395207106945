import classNames from 'classnames';
import { ChevronDown } from 'lucide-react';
import { OptionGroup } from './types';

type DropdownGroupHeadingProps<T> = {
	group: OptionGroup<T>;
	isExpanded: boolean;
	onExpandToggle: () => void;
};

const DropdownGroupHeading = <T,>({
	group,
	isExpanded,
	onExpandToggle,
}: DropdownGroupHeadingProps<T>) => {
	const isEmpty = group.options.length === 0;

	return (
		<li
			role="presentation"
			className={classNames(
				'dropdown__group-heading',
				isEmpty && 'dropdown__group-heading--empty',
				isExpanded && 'dropdown__group-heading--expanded'
			)}
			onClick={onExpandToggle}
		>
			{group.label} ({group.options.length})
			<ChevronDown size={14} />
		</li>
	);
};

export default DropdownGroupHeading;
