import { ENVIRONMENT_SHARED } from 'environment';
import API from 'utils/api/API';
import { LabeledValue, Paginated } from 'utils/types/common';
import { CustomerOption } from './CustomerAPI';
import { UserOption } from './UserAPI';

export enum UsageTrackingType {
	VIEW = 'view',
	DOWNLOAD = 'download',
	EXTERNAL_URL = 'external',
}

export type EventTypeOption = {
	id: UsageTrackingType;
	name: string;
};

export type UsageTrackingDataRow = {
	id: string;
	timestamp: Date;
	eventType: {
		name: string;
		value: UsageTrackingType;
	};
	eventDetail: string;
	parameters: string;
	user: string;
	customer: string;
	url: string;
};

export type GetUsageTrackingDataResponse = {
	trackingData: Paginated<UsageTrackingDataRow>;
	eventType: string[];
	customersSelected: CustomerOption[];
	usersSelected: UserOption[];
	dateRangeDefault: LabeledValue;
	dateRangeOptions: LabeledValue[];
	eventTypeOptions: EventTypeOption[];
};

class UsageTrackingApi extends API {
	protected static readonly apiEndpoint =
		ENVIRONMENT_SHARED.subscriptionsApiEndpoint;

	static getUsageTrackingData(request: URLSearchParams) {
		return UsageTrackingApi.get<GetUsageTrackingDataResponse>(
			`/usage-tracking?${request}`
		);
	}

	static beginUsageTrackingDataExport(
		format: string,
		request: URLSearchParams
	) {
		return UsageTrackingApi.post<GetUsageTrackingDataResponse>(
			`/usage-tracking/export?exportType=${format}&${request}`
		);
	}
}

export default UsageTrackingApi;
