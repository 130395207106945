import { createColumnHelper } from '@tanstack/react-table';
import BulletIndicator from 'ui/components/BulletIndicator';
import { ReferenceAirline } from '../../api/ReferenceDataAPI';

const columnHelper = createColumnHelper<ReferenceAirline>();

export const prefixColumn = columnHelper.accessor('airlinePrefix', {
	header: 'Prefix',
	enableSorting: true,
	enableMultiSort: false,
});

export const designatorColumn = columnHelper.accessor('airlineDesignator', {
	header: 'Designator',
	enableSorting: true,
	enableMultiSort: false,
});

export const nameColumn = columnHelper.accessor('airlineName', {
	header: 'Name',
	enableSorting: true,
	enableMultiSort: false,
});

export const countryCodeColumn = columnHelper.accessor('countryCode', {
	header: 'Country Code',
});

export const countryNameColumn = columnHelper.accessor('countryName', {
	header: 'Country Name',
});

export const isActiveColumn = columnHelper.accessor('active', {
	header: 'Status',
	cell: (info) => {
		return (
			<BulletIndicator
				intent={info.getValue() ? 'success' : 'error'}
				label={info.getValue() ? 'Active' : 'Inactive'}
			/>
		);
	},
});
