import { ColumnDef, SortDirection, SortingState } from '@tanstack/react-table';
import { useLocation, useNavigate } from 'react-router-dom';

export type TableSortingStrategy = {
	onSort: (
		columnName: string,
		sortOrder: SortDirection | null,
		multiSort: boolean
	) => void;

	getSortingState: (columns: ColumnDef<any, any>[]) => SortingState;
};

export const useSearchParamSortStrategy = (): TableSortingStrategy => {
	const navigate = useNavigate();
	const location = useLocation();

	const handleSearchParamSort = (
		columnName: string,
		sortOrder: SortDirection | null,
		multiSort = false
	) => {
		const searchParams = new URLSearchParams(location.search);
		const navigateToSearchParams = () =>
			navigate(`${location.pathname}?${searchParams.toString()}`);

		// If this isn't a multi-sort event, clear the existing sort params
		if (!multiSort) {
			searchParams.delete('sortOrders');
			searchParams.delete('sortColumns');

			// If multi-sort is disabled and the sort order is empty, we can exit here
			if (sortOrder === null) {
				navigateToSearchParams();
				return;
			}
		}

		// If there are already sort params for this column, override them
		const sortColumns = searchParams.getAll('sortColumns');
		const sortOrders = searchParams.getAll('sortOrders');

		// Find out which column is targeted
		const sortColumnIndex = sortColumns.indexOf(columnName);
		if (sortColumnIndex >= 0) {
			// The targeted column exists

			if (sortOrder === null) {
				// If the sort order isn't given, remove it
				sortOrders.splice(sortColumnIndex, 1);
				sortColumns.splice(sortColumnIndex, 1);
			} else {
				// Update the sort order
				sortOrders[sortColumnIndex] = sortOrder;
			}

			// Since URLSearchParams doesn't have a setAll method,
			// delete all values and append again
			searchParams.delete('sortOrders');
			searchParams.delete('sortColumns');

			for (let i = 0; i < sortOrders.length; i++) {
				searchParams.append('sortColumns', sortColumns[i]);
				searchParams.append('sortOrders', sortOrders[i]);
			}
		} else if (sortOrder !== null) {
			// The targeted column does not exist, and the sortOrder is defined, set it
			searchParams.append('sortOrders', sortOrder);
			searchParams.append('sortColumns', columnName);
		}

		navigateToSearchParams();
	};

	const getSortingState = (columns: ColumnDef<any, any>[]) => {
		const urlParams = new URLSearchParams(location.search);
		const sortingState: SortingState = [];

		const sortColumns = urlParams.getAll('sortColumns');
		const sortOrders = urlParams.getAll('sortOrders');

		if (sortColumns.length !== sortOrders.length) {
			return [];
		}

		columns.forEach((c) => {
			if (!c.enableSorting || !('accessorKey' in c)) return;

			const paramIndex = sortColumns.indexOf(c.accessorKey.toString());
			if (paramIndex === -1) return;

			const sortOrder = sortOrders[paramIndex];
			if (sortOrder !== 'asc' && sortOrder !== 'desc') return;

			sortingState.push({
				id: c.accessorKey.toString(),
				desc: sortOrders[paramIndex] === 'desc',
			});
		});

		return sortingState;
	};

	return {
		onSort: handleSearchParamSort,
		getSortingState,
	};
};
