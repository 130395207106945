import { LucideIcon } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';

type CountdownBannerProps = {
	icon: LucideIcon;
	title: string;
	description: string | ((remainingSeconds: number) => string);
	seconds: number;
	onCountdownEnd: () => void;

	canSkip?: boolean;
	skipText?: string;
};

const CountdownBanner = ({
	icon: Icon,
	title,
	description,
	seconds,
	onCountdownEnd,
	canSkip = false,
	skipText = 'Skip',
}: CountdownBannerProps) => {
	const [countdown, setCountdown] = useState(seconds);
	const [intervalId, setIntervalId] = useState<number | null>(null);

	useEffect(() => {
		const interval = window.setInterval(() => {
			setCountdown((countdown) => Math.max(countdown - 1, 0));
		}, 1000);

		setIntervalId(interval);
		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		if (countdown === 0 && intervalId) {
			clearInterval(intervalId);
			setIntervalId(null);
			onCountdownEnd();
		}
	}, [countdown]);

	const descriptionText = useMemo(() => {
		return typeof description === 'string'
			? description
			: description(countdown);
	}, [description, countdown]);

	return (
		<div className="countdown-banner">
			<Icon className="countdown-banner__icon" size={26} />
			<h1 className="countdown-banner__title">{title}</h1>
			<p className="countdown-banner__description">{descriptionText}</p>
			{canSkip && (
				<button className="countdown-banner__skip" onClick={onCountdownEnd}>
					{skipText}
				</button>
			)}
		</div>
	);
};

export default CountdownBanner;
