import classNames from 'classnames';
import { ComponentProps, ForwardedRef, forwardRef } from 'react';

type FlexProps = {
	direction?: 'row' | 'column';
	alignItems?: 'start' | 'center' | 'end' | 'stretch' | 'baseline';
	justifyContent?:
		| 'start'
		| 'center'
		| 'end'
		| 'space-between'
		| 'space-around'
		| 'space-evenly';
	gap?: number;
	wrap?: 'wrap' | 'nowrap' | 'wrap-reverse';
	flex?: number;
} & ComponentProps<'div'>;

const Flex = (
	{
		children,
		className,
		direction = 'row',
		alignItems,
		justifyContent,
		gap = 0,
		wrap,
		style,
		flex,
		...restProps
	}: FlexProps,
	ref: ForwardedRef<HTMLDivElement>
) => {
	return (
		<div
			ref={ref}
			className={classNames(
				'flex',
				{
					'flex--row': direction === 'row',
					'flex--column': direction === 'column',

					'flex--align-start': alignItems === 'start',
					'flex--align-center': alignItems === 'center',
					'flex--align-end': alignItems === 'end',
					'flex--align-stretch': alignItems === 'stretch',
					'flex--align-baseline': alignItems === 'baseline',

					'flex--justify-start': justifyContent === 'start',
					'flex--justify-center': justifyContent === 'center',
					'flex--justify-end': justifyContent === 'end',
					'flex--justify-space-between': justifyContent === 'space-between',
					'flex--justify-space-around': justifyContent === 'space-around',
					'flex--justify-space-evenly': justifyContent === 'space-evenly',

					'flex--wrap': wrap === 'wrap',
					'flex--nowrap': wrap === 'nowrap',
					'flex--wrap-reverse': wrap === 'wrap-reverse',
				},
				className
			)}
			style={{
				gap: gap,
				flex,
				...style,
			}}
			{...restProps}
		>
			{children}
		</div>
	);
};

export default forwardRef(Flex);
