import { LoaderFunctionArgs, RouteProps } from 'react-router-dom';
import { msalInstance } from '../../auth/authConfig';

export const loader = async ({}: LoaderFunctionArgs) => {
	await msalInstance.logoutRedirect();

	return null;
};

export default function LogoutPage() {
	return null;
}

export const LOGOUT_ROUTE: RouteProps = {
	element: <LogoutPage />,
	loader,
};
