import Pill from 'ui/components/Pill';
import { Country } from 'utils/api/common';

interface CountryPillProps {
	country: Country;
	variant?: 'full' | 'small';
}

export const CountryPill = ({ country, variant }: CountryPillProps) => {
	return variant === 'small' ? (
		<Pill title={country.name} code={country.code} />
	) : (
		<Pill name={country.name} code={country.code} />
	);
};
