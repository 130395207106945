/* eslint-disable @typescript-eslint/naming-convention */
// Zod's parameters are snake_case, but we only allow camelCase
// Disable the rule for this file

import z from 'zod';
import { zNumberPreprocessor } from 'utils/zod/zodValidation';

function weightBreakSchema(requiredMessage?: string | undefined) {
	const schema = z.array(z.preprocess(zNumberPreprocessor, z.number()), {
		required_error: requiredMessage,
	});

	return requiredMessage ? schema.nonempty(requiredMessage) : schema.optional();
}

export const weightBreaksSchema = {
	required: (requiredMessage: string) => weightBreakSchema(requiredMessage),
	optional: () => weightBreakSchema(),
};
