import classNames from 'classnames';
import { Link, LinkProps } from 'react-router-dom';

type PaginationButtonProps = {
	isActive?: boolean;
	isDisabled?: boolean;
} & LinkProps;

const PaginationButton = ({
	isActive = false,
	isDisabled,
	children,
	...restProps
}: PaginationButtonProps) => {
	return (
		<li
			className={classNames(
				'pagination__item',
				isActive && 'pagination__item--active',
				isDisabled && 'pagination__item--disabled'
			)}
		>
			<Link className="pagination__button" {...restProps}>
				{children}
			</Link>
		</li>
	);
};

export default PaginationButton;
