import { ComponentProps } from 'react';

type LinkButtonProps = ComponentProps<'button'>;

const LinkButton = ({ children, ...props }: LinkButtonProps) => {
	return (
		<button {...props} className="link-button">
			{children}
		</button>
	);
};

export default LinkButton;
