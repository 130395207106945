import classNames from 'classnames';
import { X } from 'lucide-react';

type AWBSerialNumberPillProps = {
	serialNumber: string;
	onRemove: () => void;
};

const AWBSerialNumberPill = ({
	serialNumber,
	onRemove,
}: AWBSerialNumberPillProps) => {
	const isInvalid = serialNumber.length !== 8;

	return (
		<div
			className={classNames('awb-pill', isInvalid && 'awb-pill--invalid')}
			title={isInvalid ? 'Invalid format' : undefined}
		>
			<div className="awb-pill__serial-number">{serialNumber}</div>

			<button className="awb-pill__remove" onClick={onRemove} tabIndex={0}>
				<X size={14} />
			</button>
		</div>
	);
};

export default AWBSerialNumberPill;
