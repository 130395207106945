import useRootLoader from 'utils/hooks/useRootLoader';
import { LoaderData } from 'utils/types/loaderData';
import { rootLoader } from '../../main';

export function useAreAirlinesRestricted() {
	const rootLoaderData = useRootLoader() as LoaderData<typeof rootLoader>;
	const areAirlinesRestricted =
		rootLoaderData?.userProfile.webToolAirlineCount !== null;

	return areAirlinesRestricted;
}
