import Alert from 'ui/components/Alert';
import { Link } from 'react-router-dom';

const WebToolGroupLocationWarning = ({
	locations = 'locations',
}: {
	locations?: string;
}) => {
	return (
		<Alert title="" intent="info">
			<p>
				Any {locations} can be grouped. However, when the group is applied the
				webtool result returned is restricted by the geographic coverage in your{' '}
				<Link to="/">subscription</Link>.
			</p>
		</Alert>
	);
};

export default WebToolGroupLocationWarning;
