import { startOfDay } from 'date-fns';
import { useId, useState } from 'react';
import ErrorList from 'ui/components/ErrorList/ErrorList';
import Flex from 'ui/components/Flex/Flex';
import Modal, { ModalProps } from 'ui/components/Modal/Modal';
import WebToolAPI, { WorksheetSchedule } from 'utils/api/WebToolAPI';
import APIError from 'utils/errors/APIError';
import WorksheetScheduleForm from './WorksheetScheduleForm';
import WorksheetScheduleToolbar from './WorksheetScheduleToolbar';

export enum WorksheetScheduleModalMode {
	CREATE = 'create',
	EDIT = 'edit',
}

export const DEFAULT_WORKSHEET_SCHEDULE: WorksheetSchedule = {
	frequency: 'on-new-data',
	activeFrom: startOfDay(new Date()),
	activeTo: undefined,
	suspended: false,
	suspendedMessage: '',
	outputFormat: 'excel',
};

type WorksheetScheduleModalProps = {
	mode?: WorksheetScheduleModalMode;
	worksheetId: string;
	initialSchedule?: WorksheetSchedule;
	onAfterSave?: (schedule: WorksheetSchedule) => void;
	onAfterDelete?: () => void;
} & Pick<ModalProps, 'isOpen' | 'onClose' | 'onBack'>;

const WorksheetScheduleModal = ({
	mode = WorksheetScheduleModalMode.CREATE,
	worksheetId,
	initialSchedule = DEFAULT_WORKSHEET_SCHEDULE,
	onAfterSave,
	onAfterDelete,
	onClose,
	...modalProps
}: WorksheetScheduleModalProps) => {
	const formId = useId();

	const [schedule, setSchedule] = useState(initialSchedule);
	const [error, setError] = useState<APIError | undefined>();

	const handleSave = async () => {
		const result = await WebToolAPI.updateWorksheetSchedule(
			worksheetId,
			schedule
		);

		if (result instanceof APIError) {
			setError(result);
			return;
		}

		schedule.suspended = false;

		onAfterSave?.(schedule);
	};

	const handleDelete = async () => {
		const result = await WebToolAPI.deleteWorksheetSchedule(worksheetId);

		if (result instanceof APIError) {
			setError(result);
			return;
		}

		setSchedule(initialSchedule);
		onAfterDelete?.();
	};

	const handleClose = () => {
		setSchedule(initialSchedule);
		onClose();
	};

	return (
		<Modal
			title={
				mode === WorksheetScheduleModalMode.CREATE
					? 'Schedule report'
					: 'Edit report schedule'
			}
			size="md"
			onClose={handleClose}
			{...modalProps}
		>
			<Modal.Body>
				<Flex gap={16} direction="column">
					<ErrorList
						title="An error occured while updating the schedule"
						text={null}
						error={error}
					/>
					<WorksheetScheduleForm
						schedule={schedule}
						onScheduleChange={setSchedule}
					/>
				</Flex>
			</Modal.Body>
			<Modal.Actions alignment="between">
				<WorksheetScheduleToolbar
					mode={mode}
					formId={formId}
					onSave={handleSave}
					onDelete={handleDelete}
				/>
			</Modal.Actions>
		</Modal>
	);
};

export default WorksheetScheduleModal;
