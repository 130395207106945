import { IdentifierKeys } from 'utils/types/helpers';
import useFieldCounter from '../ValidatedForm/useFieldCounter';
import { DropdownSelectedOptions } from './Dropdown';

type DropdownInputsProps<T> = {
	name?: string;
	valueKey: IdentifierKeys<T>;
} & DropdownSelectedOptions<T>;

const DropdownInputs = <T,>({
	name,
	selectedOptions,
	isMulti,
	valueKey,
}: DropdownInputsProps<T>) => {
	if (!name) return null;

	const counter = useFieldCounter(name);

	if (selectedOptions === null || (isMulti && selectedOptions.length === 0)) {
		return null;
	}

	if (isMulti) {
		return (
			<>
				{selectedOptions.map((option, index) => (
					<input
						key={option[valueKey] as string}
						type="hidden"
						name={counter(index)}
						value={option[valueKey] as string}
					/>
				))}
			</>
		);
	} else {
		// TypeScript is doing some shenanigans here
		if (!selectedOptions) return null;

		return (
			<input
				type="hidden"
				name={name}
				value={selectedOptions[valueKey] as string}
			/>
		);
	}
};

export default DropdownInputs;
