import format from 'date-fns/format';
import React from 'react';
import { YearAndMonth } from 'utils/types/helpers';

type YearAndMonthFragmentProps = {
	value?: YearAndMonth;
	emptyText?: string;
};

const YearAndMonthFragment = ({
	value,
	emptyText = '–',
}: YearAndMonthFragmentProps) => {
	const dateFormat = 'MMM yyyy';
	const date = value ? new Date(value.year, value.month - 1) : undefined;
	const isEmpty = date === null || date === undefined;

	return <>{isEmpty ? emptyText : format(date, dateFormat)}</>;
};

export default YearAndMonthFragment;
