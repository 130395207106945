import { ENVIRONMENT_SHARED } from 'environment';
import { SubscriptionLocations } from 'ui/components/LocationPicker/LocationNaturalKeyList';
import API from 'utils/api/API';
import { WorksheetSchedule } from 'utils/api/WebToolAPI';
import { Country, ReportCountryVariant } from 'utils/api/common';
import { Option, Paginated } from 'utils/types/common';
import { YearAndMonth } from 'utils/types/helpers';
import { ProductOption } from '../../../admin/src/api/SubscriptionAPI';

export type GetBipResponse = {
	keyword?: string;
	productTypes: string[];
	fromReportDate?: YearAndMonth;
	toReportDate?: YearAndMonth;
	fromCreationDate?: Date;
	toCreationDate?: Date;
	earliestReportYear?: number;
	latestReportYear?: number;
	productOptions: ProductOption[];
	originCountriesSelected: Country[];
	destinationCountriesSelected: Country[];
	reports: Paginated<BipReport>;
};

export type GetStaticResponse = {
	keyword?: string;
	productTypes: string[];
	fromReportDate?: YearAndMonth;
	toReportDate?: YearAndMonth;
	fromCreationDate?: Date;
	toCreationDate?: Date;
	earliestReportYear?: number;
	latestReportYear?: number;
	productOptions: ProductOption[];
	originCountriesSelected: Country[];
	destinationCountriesSelected: Country[];
	latestPublishedMonth: YearAndMonth;
	latestPublishedCount: number;
	reports: Paginated<StaticReport>;
};

type ReportBase = {
	id: string;
	filename: string;
	fileSizeInBytes: number;
};

export type BipReport = ReportBase & {
	subscriptionName: string;
	subscriptionNumber: string;
	productName: string;
	createdAt: Date;
	reportDate: YearAndMonth;
	origin?: SubscriptionLocations;
	destination?: SubscriptionLocations;
	iiNetAccountId?: string;
	finishedAt?: Date;
};

export type StaticReport = ReportBase & {
	subscriptionName: string;
	subscriptionNumber: string;
	productName: string;
	createdAt: Date;
	reportDate: YearAndMonth;
	variant?: ReportCountryVariant;
	origin?: SubscriptionLocations;
	destination?: SubscriptionLocations;
	origin2?: SubscriptionLocations; // Only appropriate for market share
	destination2?: SubscriptionLocations; // Only appropriate for market share
	origin3?: SubscriptionLocations; // Only appropriate for market share
	destination3?: SubscriptionLocations; // Only appropriate for market share
};

export type GetAdhocResponse = {
	keyword?: string;
	fromReportDate?: Date;
	toReportDate?: Date;
	reports: Paginated<AdhocReport>;
};

export type AdhocReport = {
	id: string;
	title: string;
	filename: string;
	fileSizeInBytes: number;
	reportDate: Date;
};

export type GetWebToolResponse = {
	reports: Paginated<WebToolReport>;
};

type WebToolReportParameter = {
	name: string;
	value: string[];
};

type WebToolReportStatus = {
	label: string;
	value: string;
};

export type WebToolReport = ReportBase & {
	worksheetId: string;
	status: WebToolReportStatus;
	executedAt: Date;
	title: string;
	columns: string[];
	parameters: WebToolReportParameter[];
};

export type LastMonthReport = ReportBase & {
	subscriptionName: string;
	subscriptionNumber: string;
	productCode: string;
	productName: string;
	createdAt: Date;
	reportDate: YearAndMonth;
	iiNetAccountId?: string;
	finishedAt?: Date;
	finalStatus?: string;
};

export type GetLatestWebToolWorksheetsItem = {
	id: string;
	name: string;
	lastUpdatedAt: Date;
};

export type GetLastMonthsReportsResponse = {
	staticReports: LastMonthReport[];
	bipReports: LastMonthReport[];
	adhocReports: AdhocReport[];
};

type GetLatestWebToolWorksheetsResponse = {
	worksheets: GetLatestWebToolWorksheetsItem[];
	scheduled: WebToolReportScheduleSummary[];
};

export type WebToolReportRunResult = {
	id: string;
	executedAt: Date;
	status: WebToolReportStatus;
	filename: string;
	fileSizeInBytes: number;
	parameters: { name: string; value: string[] }[];
	columns: string[];
};

export type ScheduledWebToolReport = {
	id: string;
	title: string;
	columns?: Option<string[]>;
	parameters?: Option<WebToolReportParameter[]>;
	nextExecution?: Option<Date>;
	suspended: boolean;
	suspendedMessage?: Option<string>;
	schedule: WorksheetSchedule;
	subscriptionName: string;
	lastExecution: Option<{
		id: string;
		filename: string;
		fileSizeInBytes: number;
		status: WebToolReportStatus;
		executedAt: Date;
	}>;
	//history: WebToolReportRunResult[];
};

export type WebToolReportScheduleSummary = {
	id: string;
	name: string;
	lastExecution?: Date;
	nextExecution?: Date;
	isExpired: boolean;
	isSuspended: boolean;
};

export type GetScheduledWebToolReportsResponse = {
	reports: Paginated<ScheduledWebToolReport>;
};

export type GetScheduledWebToolHistoryResponse = {
	history: WebToolReportRunResult[];
};

class ReportHubAPI extends API {
	protected static readonly apiEndpoint =
		ENVIRONMENT_SHARED.subscriptionsApiEndpoint;

	static getBipList(params: URLSearchParams) {
		return ReportHubAPI.get<GetBipResponse>(`/report-hub/bip?${params}`);
	}

	static getStaticList(params: URLSearchParams) {
		return ReportHubAPI.get<GetStaticResponse>(`/report-hub/static?${params}`);
	}

	static getAdhocList(params: URLSearchParams) {
		return ReportHubAPI.get<GetAdhocResponse>(`/report-hub/adhoc?${params}`);
	}

	static async getWebToolList(
		params: URLSearchParams
	): Promise<Paginated<WebToolReport>> {
		const paginatedItems = await ReportHubAPI.get<GetWebToolResponse>(
			`/report-hub/web-tool?${params}`
		);

		return {
			...paginatedItems.reports,
			items: paginatedItems.reports.items.map((item) => ({
				...item,
				schedule: {
					frequency: 'on-new-data',
					activeFrom: new Date(),
				},
			})),
		};
	}

	static getLastMonthsReports() {
		return ReportHubAPI.get<GetLastMonthsReportsResponse>(
			`/report-hub/last-month-reports`
		);
	}

	static downloadReport(id: string) {
		return ReportHubAPI.get<{ filename: string; url: string }>(
			`/report-hub/reports/${id}/download`
		);
	}

	static downloadReports(ids: string[]) {
		const params = new URLSearchParams();
		for (const id of ids) {
			params.append('ids', id);
		}

		return ReportHubAPI.get<{
			filename: string;
			url: string;
			originalFileName: string | undefined;
		}>(`/report-hub/reports/download?${params}`);
	}

	static downloadAllReports(params: URLSearchParams) {
		return ReportHubAPI.get<{
			filename: string;
			url: string;
			originalFileName: string | undefined;
		}>(`/report-hub/reports/download/all?${params}`);
	}

	static downloadAdhocReports(ids: string[]) {
		const params = new URLSearchParams();
		for (const id of ids) {
			params.append('ids', id);
		}

		return ReportHubAPI.get<{ filename: string; url: string }>(
			`/report-hub/adhoc-reports/download?${params}`
		);
	}

	static downloadAllAdhocReports(params: URLSearchParams) {
		return ReportHubAPI.get<{ filename: string; url: string }>(
			`/report-hub/adhoc-reports/download/all?${params}`
		);
	}

	static downloadWebToolReport(id: string) {
		return ReportHubAPI.get<{
			filename: string;
			url: string;
			originalFileName: string;
		}>(`/web-tool/worksheet-reports/${id}/download`);
	}

	static getLatestWebToolWorksheetsAndSchedules() {
		return ReportHubAPI.get<GetLatestWebToolWorksheetsResponse>(
			`/web-tool/worksheets/latest`
		);
	}

	static getScheduledWebToolReports(
		params: URLSearchParams
	): Promise<GetScheduledWebToolReportsResponse> {
		return ReportHubAPI.get<GetScheduledWebToolReportsResponse>(
			`/report-hub/web-tool/scheduled?${params}`
		);
	}

	static getScheduledWebToolHistory(
		id: string
	): Promise<GetScheduledWebToolHistoryResponse> {
		return ReportHubAPI.get<GetScheduledWebToolHistoryResponse>(
			`/report-hub/web-tool/${id}/history`
		);
	}
}

export default ReportHubAPI;
