import classNames from 'classnames';
import { PropsWithChildren, ReactNode } from 'react';
import CardHeader from './CardHeader';

type CardProps = PropsWithChildren<{
	className?: string;
	label?: string;
	secondaryLabel?: ReactNode;
	isUnpadded?: boolean;
	isTransparent?: boolean;
}>;

const Card = ({
	children,
	className,
	isUnpadded,
	isTransparent,
	label,
	secondaryLabel,
}: CardProps) => {
	return (
		<div
			className={classNames(
				'card',
				isUnpadded && 'card--unpadded',
				isTransparent && 'card--transparent',
				className
			)}
		>
			{label && (
				<div className="card__labels">
					<span className="card__label">{label}</span>
					{secondaryLabel}
				</div>
			)}
			<div className="card__content">{children}</div>
		</div>
	);
};

Card.Header = CardHeader;

export default Card;
