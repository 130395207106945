import { ENVIRONMENT_SHARED } from 'environment';
import API from 'utils/api/API';
import { Paginated } from 'utils/types/common';

export type ReferenceAirline = {
	id: string;
	airlinePrefix: string;
	airlineDesignator: string;
	airlineName: string;
	countryCode: string;
	countryName: string;
	active: boolean;
};

export type GetAirlinesResponse = {
	airlines: Paginated<ReferenceAirline>;
};

export type ExportAirlinesResponse = {
	filename: string;
	url: string;
};

export type ReferenceAirport = {
	id: string;
	airportCode: string;
	airportName: string;
	cityCode: string;
	cityName: string;
	provinceCode: string;
	provinceName: string;
	countryCode: string;
	countryName: string;
	globalRegion: string;
	tactRegion: string;
	latitude: number;
	longitude: number;
	active: boolean;
};

export type GetAirportsResponse = {
	airports: Paginated<ReferenceAirport>;
};

export type ExportAirportsResponse = {
	filename: string;
	url: string;
};

export type ReferenceFreightForwarder = {
	id: string;
	branchOfficeCode: string;
	branchOfficeName: string;
	headOfficeCode: string;
	headOfficeName: string;
	countryCode: string;
	cityName: string;
	postalCode: string;
	iataFreightForwarderGroupName: string;
};

export type GetFreightForwardersResponse = {
	freightForwarders: Paginated<ReferenceFreightForwarder>;
};

export type ExportFreightForwardersResponse = {
	filename: string;
	url: string;
};

export type ReferenceRegion = {
	key: string;
	countryName: string;
	countryCode: string;
	provinceName: string;
	provinceCode: string;
	cargoIsRegion: string;
	tactRegion: string;
	active: boolean;
};

export type GetRegionsResponse = {
	regions: Paginated<ReferenceRegion>;
};

export type ExportRegionsResponse = {
	filename: string;
	url: string;
};

class ReferenceDataAPI extends API {
	protected static readonly apiEndpoint =
		ENVIRONMENT_SHARED.subscriptionsApiEndpoint;

	static getAirlines(params?: URLSearchParams) {
		return ReferenceDataAPI.get<GetAirlinesResponse>(
			`/reference-data/airline?${params}`
		);
	}

	static exportAirlines(params?: URLSearchParams) {
		return ReferenceDataAPI.get<ExportAirlinesResponse>(
			`/reference-data/airline/download?${params}`
		);
	}

	static getAirports(params?: URLSearchParams) {
		return ReferenceDataAPI.get<GetAirportsResponse>(
			`/reference-data/airport?${params}`
		);
	}

	static exportAirports(params?: URLSearchParams) {
		return ReferenceDataAPI.get<ExportAirportsResponse>(
			`/reference-data/airport/download?${params}`
		);
	}

	static getFreightForwarders(params?: URLSearchParams) {
		return ReferenceDataAPI.get<GetFreightForwardersResponse>(
			`/reference-data/freight-forwarder?${params}`
		);
	}

	static exportFreightForwarders(params?: URLSearchParams) {
		return ReferenceDataAPI.get<ExportFreightForwardersResponse>(
			`/reference-data/freight-forwarder/download?${params}`
		);
	}

	static getRegions(params?: URLSearchParams) {
		return ReferenceDataAPI.get<GetRegionsResponse>(
			`/reference-data/region?${params}`
		);
	}

	static exportRegions(params?: URLSearchParams) {
		return ReferenceDataAPI.get<ExportRegionsResponse>(
			`/reference-data/region/download?${params}`
		);
	}
}

export default ReferenceDataAPI;
