import { ChevronDown, Loader2, X } from 'lucide-react';
import CopyButton from '../CopyButton/CopyButton';

type DropdownIndicatorsProps = {
	clearEnabled: boolean;
	onClearClick: () => void;
	isLoading?: boolean;
	openEnabled: boolean;

	copyEnabled?: boolean;
	onCopyClick?: () => void;
};

const DropdownIndicators = ({
	clearEnabled,
	onClearClick,
	isLoading,
	openEnabled,
	copyEnabled,
	onCopyClick,
}: DropdownIndicatorsProps) => {
	return (
		<div className="dropdown__indicators">
			{isLoading ? (
				<Loader2
					className="dropdown__indicator dropdown__indicator--loader"
					size={18}
				/>
			) : (
				<>
					{clearEnabled && (
						<>
							<X
								size={18}
								className="dropdown__indicator dropdown__indicator--clear"
								style={{ pointerEvents: 'auto' }}
								onClick={(e) => {
									onClearClick();
									e.preventDefault();
									e.stopPropagation();
								}}
							/>
							{(openEnabled || copyEnabled) && (
								<div className="dropdown__indicator-separator" />
							)}
						</>
					)}
					{copyEnabled && (
						<>
							<CopyButton
								className="dropdown__indicator"
								onClick={onCopyClick ?? (() => {})}
							/>
							{openEnabled && <div className="dropdown__indicator-separator" />}
						</>
					)}
					{openEnabled && (
						<ChevronDown
							className="dropdown__indicator dropdown__indicator--open"
							size={18}
						/>
					)}
				</>
			)}
		</div>
	);
};

export default DropdownIndicators;
