import { ReactNode, useMemo } from 'react';
import AsyncMultiSelect from 'ui/components/AsyncMultiSelect';
import AsyncSelect from 'ui/components/AsyncSelect';
import FormField from 'ui/components/FormField';
import Pill from 'ui/components/Pill';
import useValidation from 'ui/components/ValidatedForm/useValidation';
import LocationAPI, {
	LocationType,
	describeLocationType,
} from 'utils/api/LocationAPI';
import { WorksheetConfigWebToolGroup } from 'utils/api/WebToolAPI';
import { Location, LocationCustomGroupOption } from 'utils/api/common';
import { catchSilently } from 'utils/helpers/catchHandlers';
import {
	createValidationToast,
	parseTextToCodes,
} from 'utils/helpers/codeValidation';
import { createToast } from 'utils/helpers/toast';
import { OptionGroup } from '../Dropdown/types';
import { MultiSelectPillProps } from '../MultiSelect/MultiSelect';
import { ToastType } from '../Toaster/Toast';
import LocationPickerContent from './LocationPickerContent';
import LocationPickerPreview from './LocationPickerPreview';

const customGroupToLocationOption = (
	group: WorksheetConfigWebToolGroup
): LocationCustomGroupOption => ({
	naturalKey: group.id,
	name: group.name,
	itemType: 'custom-group',
	locationId: `custom-group-${group.id}`,
	code: '-',
});

const LocationMultiSelectPill = ({
	value,
	onRemove,
}: MultiSelectPillProps<Location>) => {
	if (value.itemType === 'custom-group') {
		return <Pill name={value.name} onRemove={onRemove} />;
	} else {
		return <Pill name={value.name} code={value.code} onRemove={onRemove} />;
	}
};

type SingleLocationPickerProps = {
	isMulti?: false;
	initialValue?: Location;
	initialValues?: undefined;
	onSelectedLocationChange?: (location: Location | null) => void;
	onSelectedLocationsChange?: undefined;
	selectedLocation?: Location | null;
	selectedLocations?: undefined;
};

type MultiLocationPickerProps = {
	isMulti: true;
	initialValue?: undefined;
	initialValues?: Location[];
	onSelectedLocationChange?: undefined;
	onSelectedLocationsChange?: (locations: Location[]) => void;
	selectedLocation?: undefined;
	selectedLocations?: Location[];
};

type LocationPickerProps = {
	name?: string;
	label?: string;
	isRequired?: boolean;
	locationTypes?: LocationType[];
	secondaryLabel?: ReactNode;
	locationCustomGroups?: WorksheetConfigWebToolGroup[];
} & (SingleLocationPickerProps | MultiLocationPickerProps);

const LocationPicker = (props: LocationPickerProps) => {
	const {
		name,
		label,
		isRequired,
		locationTypes = [LocationType.COUNTRY, LocationType.REGION],
		secondaryLabel,
		locationCustomGroups = [],
	} = props;

	const { errorTree, revalidate } = useValidation(name);

	const isGrouped = locationTypes.length > 1;

	const getLocations = async (searchTerm: string) => {
		const data = await LocationAPI.getLocations(searchTerm, locationTypes);
		return data.options ?? data.groups;
	};

	const handleInsert = async (text: string) => {
		const codes = parseTextToCodes(text);

		if (codes) {
			const validatePromise = LocationAPI.validateCodesForLocationType(
				codes,
				locationTypes
			);

			createValidationToast(validatePromise, 'location').catch(catchSilently);

			const validationResult = await validatePromise;
			return validationResult.options;
		}

		return false;
	};

	const handleCopy = (options: Location[] | Location | null) => {
		let value = '';
		let hasCustomGroups = false;

		if (Array.isArray(options)) {
			if (options.some((option) => option.itemType === 'custom-group')) {
				hasCustomGroups = true;
			}

			value = options
				.filter(
					(option): option is Exclude<Location, LocationCustomGroupOption> =>
						'code' in option && option.itemType !== 'custom-group'
				)
				.map((option) => option.code)
				.join(', ');
		} else if (options) {
			if (options.itemType === 'custom-group') {
				hasCustomGroups = true;
			} else if ('code' in options) {
				value = options.code;
			}
		}

		if (value) {
			navigator.clipboard.writeText(value);
		}

		if (hasCustomGroups) {
			createToast(ToastType.WARNING, {
				message: 'Custom groups omitted',
				hint: 'The selected custom groups have been omitted. They must be manually selected from the dropdown.',
			});
		}
	};

	const placeholder = `Select a ${locationTypes
		.map(describeLocationType)
		.join(', ')}...`;

	const defaultOptions = useMemo(():
		| Location[]
		| OptionGroup<Location>[]
		| undefined => {
		if (locationCustomGroups.length === 0) {
			return undefined;
		}

		if (isGrouped) {
			return [
				{
					label: 'Custom Groupings',
					options: locationCustomGroups.map(customGroupToLocationOption),
				},
			];
		} else {
			return locationCustomGroups.map(customGroupToLocationOption);
		}
	}, [isGrouped, locationCustomGroups]);

	return (
		<FormField
			label={label}
			errors={errorTree?._errors}
			isRequired={isRequired}
			secondaryLabel={secondaryLabel}
		>
			{props.isMulti ? (
				<AsyncMultiSelect<Location>
					defaultOptions={defaultOptions}
					isGrouped={isGrouped}
					onSearch={getLocations}
					identifierKey="locationId"
					initialValues={props.initialValues ?? []}
					onOptionsSelected={revalidate}
					handlePasteSelection={handleInsert}
					handleSearchSubmitSelection={handleInsert}
					selectedOptions={props.selectedLocations}
					onCopy={handleCopy}
					onSelectedOptionsChange={props.onSelectedLocationsChange}
					contentSource={(location) => (
						<LocationPickerContent location={location} />
					)}
					pillComponent={LocationMultiSelectPill}
					placeholder={placeholder}
					name={name}
				/>
			) : (
				<AsyncSelect<Location>
					defaultOptions={defaultOptions}
					isGrouped={isGrouped}
					onSearch={getLocations}
					identifierKey="locationId"
					initialValue={props.initialValue}
					handlePasteSelection={handleInsert}
					onCopy={handleCopy}
					handleSearchSubmitSelection={handleInsert}
					selectedOption={props.selectedLocation}
					onOptionSelected={props.onSelectedLocationChange}
					contentSource={(location) => (
						<LocationPickerContent location={location} />
					)}
					previewSource={(location) => (
						<LocationPickerPreview location={location} />
					)}
					placeholder={placeholder}
					name={name}
				/>
			)}
		</FormField>
	);
};

export default LocationPicker;
