import classNames from 'classnames';
import { PropsWithChildren } from 'react';

type ModalActionsProps = PropsWithChildren<{
	className?: string;
	alignment?: 'left' | 'right' | 'between';
}>;

const ModalActions = ({
	children,
	className,
	alignment = 'right',
}: ModalActionsProps) => {
	return (
		<div
			className={classNames(
				'modal__actions',
				`modal__actions--align-${alignment}`,
				className
			)}
		>
			{children}
		</div>
	);
};

export default ModalActions;
