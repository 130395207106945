import { createColumnHelper } from '@tanstack/react-table';
import DateFragment from 'ui/components/DateFragment';
import LinkButton from 'ui/components/LinkButton';
import { LocationNaturalKeyList } from 'ui/components/LocationPicker/LocationNaturalKeyList';
import { BipReport } from '../../api/ReportHubAPI';

const columnHelper = createColumnHelper<BipReport>();

export const productNameColumn = columnHelper.accessor('productName', {
	header: 'Report Type',
});

export const reportMonthColumn = columnHelper.accessor('reportDate', {
	header: 'Month of Report',
	cell: ({ getValue }) =>
		`${getValue().month.toString().padStart(2, '0')}/${getValue().year}`,
	enableSorting: true,
});

export const createdAtColumn = columnHelper.accessor('createdAt', {
	header: 'Created Date',
	cell: (info) => <DateFragment includeTime={true} date={info.getValue()} />,
	enableSorting: true,
});

export const originsColumn = columnHelper.display({
	id: 'origins',
	header: 'Origin',
	cell: (info) => {
		const data = info.row.original;
		if (data.origin)
			return (
				<>
					<LocationNaturalKeyList locations={data.origin} />
				</>
			);
		else return '-';
	},
});

export const destinationsColumn = columnHelper.display({
	id: 'destinations',
	header: 'Destination',
	cell: (info) => {
		const data = info.row.original;
		if (!data.destination) return '-';
		return (
			<>
				<LocationNaturalKeyList locations={data.destination} />
			</>
		);
	},
});

export const attachmentColumn = (
	onReportSelected: (selectedReport: BipReport) => void
) =>
	columnHelper.accessor('filename', {
		header: 'Attachment',
		cell: (info) => {
			if (info.row.original.filename && info.row.original.iiNetAccountId)
				return (
					<LinkButton
						onClick={() => onReportSelected(info.row.original)}
					>{`iiNET Account ID: ${info.row.original.iiNetAccountId}`}</LinkButton>
				);
			else return '–';
		},
	});
