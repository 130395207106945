import * as objectPath from 'object-path-immutable';
import { useContext } from 'react';
import { ZodFormattedErrors } from 'utils/zod/zodErrors';
import ValidatedFormContext from './ValidatedFormContext';

const useValidation = (path: string | undefined) => {
	const context = useContext(ValidatedFormContext);

	const revalidate = () => {
		if (!context) return;

		// Defer call to revalidate until after the current render cycle
		requestAnimationFrame(() => {
			context.revalidate();
		});
	};

	if (!path || !context || !context.errorTree || !context.hasSubmitted) {
		return { errorTree: undefined, revalidate };
	}

	return {
		errorTree: objectPath.get(context.errorTree, path) as
			| ZodFormattedErrors
			| undefined,
		revalidate,
	};
};

export default useValidation;
