import { MoreHorizontal } from 'lucide-react';
import { useState } from 'react';
import Button from 'ui/components/Button';
import Flex from 'ui/components/Flex';
import FormField from 'ui/components/FormField';
import TextField from 'ui/components/TextField';
import AWBSerialNumbersModal, {
	sanitizeAwbInput,
} from './AWBSerialNumbersModal';

type AWBSerialNumbersFieldProps = {
	disabled: boolean;
	onChange: (value: string) => void;
	value: string;
};

const AWBSerialNumbersField = ({
	disabled,
	value,
	onChange,
}: AWBSerialNumbersFieldProps) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	return (
		<FormField label="AWB Serial Numbers" name="awbserial">
			<Flex gap={12}>
				<TextField
					value={value}
					onChange={(e) => onChange(sanitizeAwbInput(e.target.value))}
					name="awbserial"
					placeholder="########"
					disabled={disabled}
				/>

				<Button
					variant="secondary"
					icon={MoreHorizontal}
					type="button"
					disabled={disabled}
					onClick={() => setIsModalOpen(true)}
					data-testid="awb-serial-numbers-modal-button"
				/>

				<AWBSerialNumbersModal
					isOpen={isModalOpen && !disabled}
					onClose={() => setIsModalOpen(false)}
				/>
			</Flex>
		</FormField>
	);
};

export default AWBSerialNumbersField;
