import {
	TradelaneCustomGroupOption,
	TradelaneOption,
	TradelaneOriginDestinationOption,
} from 'utils/api/WebToolGroupAPI';
import DirectionalPill from '../DirectionalPill/DirectionalPill';

const TradelaneOriginDestinationPill = ({
	tradelane,
	onRemove,
}: {
	tradelane: TradelaneOriginDestinationOption;
	onRemove?: () => void;
}) => {
	return (
		<DirectionalPill
			left={tradelane.origin.code}
			right={tradelane.destination.code}
			leftTitle={`${tradelane.origin.name} (${tradelane.origin.naturalKey})`}
			rightTitle={`${tradelane.destination.name} (${tradelane.destination.naturalKey})`}
			onRemove={onRemove}
		/>
	);
};

const TradelaneCustomGroupPill = ({
	tradelane,
	onRemove,
}: {
	tradelane: TradelaneCustomGroupOption;
	onRemove?: () => void;
}) => {
	return <DirectionalPill left={tradelane.name} onRemove={onRemove} />;
};

type TradelaneListProps = {
	tradelanes?: TradelaneOption[];
	emptyText: string;
	onTradelaneRemoved?: (index: number) => void;
};

const TradelaneList = ({
	tradelanes,
	emptyText,
	onTradelaneRemoved,
}: TradelaneListProps) => {
	if (tradelanes && tradelanes.length > 0) {
		return (
			<div className="pills">
				{tradelanes?.map((tradelane, i) => {
					if (tradelane.itemType === 'custom-group') {
						return (
							<TradelaneCustomGroupPill
								key={tradelane.id}
								tradelane={tradelane}
								onRemove={
									onTradelaneRemoved ? () => onTradelaneRemoved?.(i) : undefined
								}
							/>
						);
					} else {
						return (
							<TradelaneOriginDestinationPill
								key={tradelane.origin.code + tradelane.destination.code}
								tradelane={tradelane}
								onRemove={
									onTradelaneRemoved ? () => onTradelaneRemoved?.(i) : undefined
								}
							/>
						);
					}
				})}
			</div>
		);
	}

	return <>{emptyText}</>;
};

export default TradelaneList;
