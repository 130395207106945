import classNames from 'classnames';
import { ComponentProps } from 'react';

type GridProps = {
	columns?: 1 | 2 | 3 | string;
	isBox?: boolean;
	gap?: string;
} & ComponentProps<'div'>;

const Grid = ({
	children,
	columns = 1,
	isBox = false,
	className,
	gap,
	...restProps
}: GridProps) => {
	return (
		<div
			className={classNames(
				`grid`,
				typeof columns === 'number' && `grid--${columns}-columns`,
				isBox && 'grid--box',
				className
			)}
			{...restProps}
			style={{
				gridTemplateColumns: typeof columns === 'string' ? columns : undefined,
				gap,
				...restProps.style,
			}}
		>
			{children}
		</div>
	);
};

export default Grid;
