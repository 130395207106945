import { LayoutRouteProps, LoaderFunctionArgs } from 'react-router-dom';
import DownloadPage from 'ui/pages/DownloadPage/DownloadPage';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import AirImpactReportsAPI from '../../api/AirImpactReportsAPI';

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	const publicationId = params.publicationId as string;
	const file = await AirImpactReportsAPI.getImpactReportDownload(publicationId);

	downloadFile(file.url, file.originalFileName);

	return null;
};

function downloadFile(exportFileUrl: string, fileName: string): void {
	const link = document.createElement('a');
	link.setAttribute('href', exportFileUrl);
	link.setAttribute('download', fileName);
	link.click();
}

export const PUBLICATIONS_DOWNLOAD_ROUTE: LayoutRouteProps = {
	loader: loader,
	element: <DownloadPage />,
};
