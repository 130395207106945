import FormField, { FormFieldProps } from '../components/FormField';
import useValidation from '../components/ValidatedForm/useValidation';
import { flattenErrors } from 'utils/zod/zodErrors';
import WeightBreaks, { WeightBreak } from '../components/WeightBreaks';

interface WeightBreaksFieldProps<T> extends FormFieldProps {
	name: string;
	initialValue?: number[];
	weightBreaks?: WeightBreak[];
}

export function WeightBreaksField<T>(
	props: Omit<WeightBreaksFieldProps<T>, 'children'>
) {
	const { label } = props;

	const { errorTree } = useValidation(props.name);

	return (
		<FormField
			label={label}
			errors={flattenErrors(errorTree)}
			isRequired={props.isRequired}
		>
			<WeightBreaks
				name={props.name}
				weightBreaks={props.weightBreaks}
				initialValue={props.initialValue}
			/>
		</FormField>
	);
}
