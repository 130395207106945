import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { SortDirection } from '@tanstack/react-table';
import className from 'classnames';
import { LayoutBuilderSortable } from './LayoutBuilder';
import LayoutBuilderItemPresentation from './LayoutBuilderItemPresentation';

type LayoutBuilderItemProps = {
	id: string;
	item: LayoutBuilderSortable;

	sortDirection?: SortDirection;
	onSortDirectionChange?: (sortDirection: SortDirection) => void;

	isDisabled?: boolean;
	onRemove?: () => void;

	isSorted?: boolean;
	onAddToSort?: () => void;
	onRemoveFromSort?: () => void;
};

const LayoutBuilderItem = ({
	id,
	item,
	...passThroughProps
}: LayoutBuilderItemProps) => {
	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
		isDragging,
	} = useSortable({
		id,
	});

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	return (
		<LayoutBuilderItemPresentation
			{...attributes}
			{...listeners}
			ref={setNodeRef}
			style={style}
			name={item.name}
			className={className(isDragging && 'layout-item--dragging')}
			{...passThroughProps}
		/>
	);
};

export default LayoutBuilderItem;
