import { useState } from 'react';
import TextField, { TextFieldProps } from '../TextField/TextField';

type NumberFieldProps = {
	value?: number | null;
	onChange?: (value: number | null) => void;
	min?: number;
	max?: number;
	defaultValue?: number;
} & Omit<
	TextFieldProps,
	| 'onChange'
	| 'type'
	| 'inputMode'
	| 'pattern'
	| 'value'
	| 'min'
	| 'max'
	| 'defaultValue'
>;

const NumberField = ({
	onChange: controlledOnChange,
	value: controlledValue,
	min = -1000,
	max = Infinity,
	defaultValue,
	...textFieldProps
}: NumberFieldProps) => {
	const [uncontrolledValue, setUncontrolledValue] = useState<number | null>(
		defaultValue ?? 0
	);

	const value =
		controlledValue === undefined ? uncontrolledValue : controlledValue;
	const onChange = controlledOnChange ?? setUncontrolledValue;

	const allowNegative = min < 0;
	const allowedCharacters = allowNegative ? /^-?\d*$/ : /^\d*$/;

	const handleRawChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;

		const isAllowed = allowedCharacters.test(value);
		if (!isAllowed) return;

		const parsedNumber = parseInt(value);
		handleChange(parsedNumber);
	};

	// Capture onChange so that we can prevent non-numeric characters from being entered
	const handleChange = (value: number) => {
		if (isNaN(value)) {
			onChange(null);
			return;
		}

		if (value < min) return;
		if (value > max) return;

		onChange(value);
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (value === null) return;

		if (e.key === 'ArrowUp') {
			e.preventDefault();
			handleChange(value + 1);
		} else if (e.key === 'ArrowDown') {
			e.preventDefault();
			handleChange(value - 1);
		}
	};

	return (
		<TextField
			type="text"
			inputMode="numeric"
			pattern="[0-9]*"
			value={value === null ? '' : value}
			onChange={handleRawChange}
			onKeyDown={handleKeyDown}
			{...textFieldProps}
		/>
	);
};

export default NumberField;
