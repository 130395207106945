import classNames from 'classnames';
import { useEffect, useState } from 'react';
import DateFragment from 'ui/components/DateFragment';
import { WebAnnouncementFragment } from '../../api/ProfileAPI';

type WebAnnouncementsPanelProps = {
	announcements: WebAnnouncementFragment[];
};

const WebAnnouncementsPanel = ({
	announcements,
}: WebAnnouncementsPanelProps) => {
	const [selectedAnnouncement, setSelectedAnnouncement] =
		useState<WebAnnouncementFragment>(announcements[0]);

	useEffect(() => {
		if (selectedAnnouncement) {
			markAsRead(selectedAnnouncement);
		}
	}, [selectedAnnouncement]);

	const isRead = (announcement: WebAnnouncementFragment) => {
		const readAnnouncements = localStorage.getItem('readAnnouncements');
		const parsedReadAnnouncements = JSON.parse(readAnnouncements ?? '[]');

		return parsedReadAnnouncements.includes(announcement.id);
	};

	const markAsRead = (announcement: WebAnnouncementFragment) => {
		const readAnnouncements = localStorage.getItem('readAnnouncements');
		const parsedReadAnnouncements = JSON.parse(readAnnouncements ?? '[]');

		if (!parsedReadAnnouncements.includes(announcement.id)) {
			parsedReadAnnouncements.push(announcement.id);
		}

		localStorage.setItem(
			'readAnnouncements',
			JSON.stringify(parsedReadAnnouncements)
		);
	};

	if (!announcements.length) {
		return null;
	}

	return (
		<div className="web-announcements">
			<h2 className="web-announcements__title">Recent updates</h2>
			<hr />
			<div className="web-announcements__wrapper">
				<ul className="web-announcements__list">
					{announcements.map((announcement) => (
						<li
							className={classNames(
								'web-announcements__item',
								isRead(announcement) && 'web-announcements__item--read',
								announcement.id === selectedAnnouncement.id &&
									'web-announcements__item--active'
							)}
							key={announcement.id}
						>
							<button onClick={() => setSelectedAnnouncement(announcement)}>
								<DateFragment date={announcement.releaseDate} />
							</button>
						</li>
					))}
				</ul>
				<div className="web-announcements__details web-announcement">
					<span className="web-announcement__date">
						Released on <DateFragment date={selectedAnnouncement.releaseDate} />
					</span>
					<h3 className="web-announcement__title">
						{selectedAnnouncement.title}
					</h3>
					<div
						className="web-announcement__content html-content"
						dangerouslySetInnerHTML={{ __html: selectedAnnouncement.body }}
					/>
				</div>
			</div>
		</div>
	);
};

export default WebAnnouncementsPanel;
