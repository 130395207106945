import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

type VisuallyHiddenProps = PropsWithChildren<{
	isHidden?: boolean;
	removeFromFlow?: boolean;
}>;

const VisuallyHidden = ({
	isHidden = true,
	removeFromFlow = false,
	children,
}: VisuallyHiddenProps) => {
	return React.cloneElement(children as React.ReactElement, {
		className: classNames(
			isHidden && 'visually-hidden',
			removeFromFlow && 'visually-hidden--removed-from-flow'
		),
	});
};

export default VisuallyHidden;
