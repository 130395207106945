import { MultiDropdownField } from 'iata-cargois-admin-fe/src/forms/MultiDropdownField';
import { useMemo, useState } from 'react';
import { AirlineOption } from 'utils/api/AirlinesAPI';
import { SubscriptionAirlinesGroup } from 'utils/api/WebToolGroupAPI';
import AirlinePicker from '../AirlinePicker';
import {
	AirlineContentSource,
	AirlinePickerPill,
} from '../AirlinePicker/AirlinePicker';
import Alert from '../Alert';
import { subscriptionsApplicableForAirlines } from './subscriptionsApplicableForAirlines';

type SubscriptionAirlinePickerProps = {
	name: string;
	label?: string;
	areAirlinesRestricted: boolean;
	isRequired?: boolean;
	airlinesBySubscription: SubscriptionAirlinesGroup[];
	initialValues?: AirlineOption[];
};

const SubscriptionAirlinePicker = (props: SubscriptionAirlinePickerProps) => {
	const {
		name,
		label,
		isRequired,
		initialValues,
		areAirlinesRestricted,
		airlinesBySubscription,
	} = props;
	const [airlines, setAirlines] = useState(initialValues ?? []);
	const [groupValidity, applicableSubscriptions] = useMemo(() => {
		if (
			!areAirlinesRestricted ||
			airlines.length === 0 ||
			airlinesBySubscription.length < 2
		)
			return ['not-applicable' as const];

		const applicableSubscriptions = subscriptionsApplicableForAirlines(
			airlinesBySubscription,
			airlines
		);

		return applicableSubscriptions.length > 0
			? ['valid' as const, applicableSubscriptions]
			: ['invalid' as const];
	}, [airlines, airlinesBySubscription]);

	return (
		<>
			{areAirlinesRestricted ? (
				<>
					<MultiDropdownField<AirlineOption>
						name={name}
						label={label}
						isGrouped={true}
						isRequired={isRequired}
						identifierKey={'code'}
						contentSource={AirlineContentSource}
						pillComponent={AirlinePickerPill}
						options={applicableSubscriptions ?? airlinesBySubscription}
						selectedOptions={airlines}
						onSelectedOptionsChange={setAirlines}
					></MultiDropdownField>

					{groupValidity === 'valid' ? (
						<Alert
							title="This group can be used in the following subscription(s)"
							intent="info"
						>
							<ul>
								{applicableSubscriptions.map((subscription) => (
									<li key={subscription.label}>{subscription.label}</li>
								))}
							</ul>
						</Alert>
					) : null}
					{groupValidity === 'invalid' ? (
						<Alert
							title="Airlines Span Multiple Subscriptions"
							intent="warning"
						>
							This group cannot be used in the Web Tool because the selected
							airlines do not reside in one subscription.
						</Alert>
					) : null}
				</>
			) : (
				<AirlinePicker
					name={name}
					label={label}
					isRequired={isRequired}
					isMulti={true}
					initialValues={initialValues ?? []}
				></AirlinePicker>
			)}
		</>
	);
};

export default SubscriptionAirlinePicker;
