/// <reference path="../../../types/global.d.ts" />

import { formatInTimeZone } from 'date-fns-tz';
import { ISO_FORMAT } from 'ui/components/DatePicker/DatePicker';
import APIError from 'utils/errors/APIError';
import { clearMsalCache, handleMsalError } from '../helpers/msal';
import { getSessionId } from '../helpers/session';
import { isTestRunner } from '../helpers/test';

type RequestOptions = Omit<Omit<RequestInit, 'body'>, 'method'>;
type RequestData = { [key: string]: unknown };

// Regular expression to match an ISO8601 timestamp in the format YYYY-MM-DDTHH:mm:ss.sssZ
const iso8601Regex =
	// eslint-disable-next-line max-len
	/^(-?(?:[1-9]\d*)?\d{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12]\d)T(2[0-3]|[01]\d):([0-5]\d):([0-5]\d)(\.\d+)?(Z|[+-](?:2[0-3]|[01]\d):[0-5]\d)?$/;

abstract class API {
	protected static readonly apiEndpoint: string;

	private static jsonReplacer(key: string, value: unknown) {
		// value is already the stringified version, but we need
		// the date object. JSON.stringify binds the current object
		// to the this keyword of the replacer, so we can access
		// the raw current value by using this[key]
		const currentValue = (this as any)[key] as unknown;

		if (typeof value === 'undefined') {
			return null;
		}

		if (currentValue instanceof Date) {
			return formatInTimeZone(currentValue, 'UTC', ISO_FORMAT);
		}

		return value;
	}

	protected static async sendJSONRequest<T = null>(
		apiUrl: string,
		method = 'GET',
		data?: RequestData,
		options?: RequestOptions
	): Promise<T> {
		const serializedData = data
			? JSON.stringify(data, this.jsonReplacer)
			: undefined;

		const customHeaders: { [key: string]: string } = {};

		if (method !== 'GET' && data) {
			customHeaders['Content-Type'] = 'application/json';
		}

		const response = await this.sendRequest(apiUrl, method, serializedData, {
			...options,
			headers: {
				...customHeaders,
				...options?.headers,
			},
		});

		let json = null;
		if (response.headers.get('Content-Type')?.includes('application/json')) {
			const text = await response.text();

			json = JSON.parse(text, (key, value) => {
				if (typeof value === 'string' && iso8601Regex.test(value)) {
					return new Date(value);
				}

				return value;
			});
		}

		return json as T;
	}

	protected static async sendRequest(
		apiUrl: string,
		method = 'GET',
		data?: BodyInit | null | undefined,
		options?: RequestOptions
	): Promise<Response> {
		if (!this.apiEndpoint) {
			throw new Error('API endpoint not set.');
		}

		const apiEndpoint = this.apiEndpoint;
		if (apiEndpoint) apiUrl = apiEndpoint + apiUrl;

		// Make sure that the URL format is correct for GET requests
		if (method === 'GET') {
			const url = new URL(apiUrl);
			const searchParams = new URLSearchParams(url.search);

			// Change array.0=value to array=value
			for (const [key, value] of searchParams.entries()) {
				const endsWithNumber = key.match(/\.(\d+)$/);

				if (endsWithNumber) {
					const newKey = key.replace(/\.(\d+)$/, '');
					url.searchParams.delete(key);
					url.searchParams.append(newKey, value);
				}
			}

			apiUrl = url.toString();
		}

		const requestInit: RequestInit = {
			...options,
			method,
			body: data,
		};

		const customHeaders: { [key: string]: string } = {};

		const authToken = isTestRunner ? 'E2EToken' : await this.getAuthToken();

		if (authToken) {
			customHeaders['Authorization'] = `Bearer ${authToken}`;
		}

		customHeaders['x-session-id'] = getSessionId();

		requestInit.headers = { ...requestInit.headers, ...customHeaders };
		const response = await fetch(apiUrl, requestInit);

		if (!response.ok) {
			// Handle external login
			if (
				response.status === 401 &&
				response.headers?.get('x-logged-in-elsewhere') === 'logout' &&
				window.router.state.location.pathname !== '/login'
			) {
				clearMsalCache(window.msal.instance);
				sessionStorage.setItem('loggedInElsewhere', 'true');
				window.router.navigate('/login');
			}

			throw new APIError(
				'An error occured during an API request.',
				response.status,
				await response.json().catch(() => undefined),
				response.headers
			);
		}

		return response;
	}

	protected static async getAuthToken() {
		try {
			// Acquire token silent success
			const response = await window.msal.instance.acquireTokenSilent({
				scopes: [window.msal.scope],
			});

			return response.accessToken;
		} catch (error) {
			await handleMsalError(window.msal.instance, error);

			return null;
		}
	}

	protected static get<T = undefined>(apiUrl: string): Promise<T> {
		return this.sendJSONRequest<T>(apiUrl, 'GET');
	}

	protected static post<T = undefined>(
		apiUrl: string,
		data?: RequestData
	): Promise<T | Error | APIError> {
		return this.sendJSONRequest<T>(apiUrl, 'POST', data).catch(
			this.catchHandler
		);
	}

	protected static put<T = undefined>(
		apiUrl: string,
		data?: RequestData
	): Promise<T | Error | APIError> {
		return this.sendJSONRequest<T>(apiUrl, 'PUT', data).catch(
			this.catchHandler
		);
	}

	protected static patch<T = undefined>(
		apiUrl: string,
		data?: RequestData
	): Promise<T | Error | APIError> {
		return this.sendJSONRequest<T>(apiUrl, 'PATCH', data).catch(
			this.catchHandler
		);
	}

	protected static delete<T = undefined>(
		apiUrl: string,
		data?: RequestData
	): Promise<T | Error | APIError> {
		return this.sendJSONRequest<T>(apiUrl, 'DELETE', data).catch(
			this.catchHandler
		);
	}

	protected static catchHandler(error: Error | APIError) {
		return error;
	}
}

export default API;
