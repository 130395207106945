import { Download } from 'lucide-react';
import Button from 'ui/components/Button';
import Card from 'ui/components/Card';
import Flex from 'ui/components/Flex';
import { AirImpactReportModel } from 'utils/schemas/airImpactReportSchema';

type ImpactReportPanelProps = {
	impactReport?: AirImpactReportModel;
	showPreviousLink?: boolean;
	showDownloadLink?: boolean;
};

const ImpactReportPanel = ({
	impactReport,
	showDownloadLink = true,
	showPreviousLink = false,
}: ImpactReportPanelProps) => {
	if (!impactReport) return null;

	return (
		<Card className="impact-report">
			<div className="impact-report__banner">
				<img
					src={impactReport.banner.fileName}
					alt="Impact Report Banner"
					className="impact-report__image"
				/>
			</div>
			<div className="impact-report__label">{impactReport.edition}</div>
			<div className="impact-report__wrapper">
				<h2 className="impact-report__title">{impactReport.title}</h2>
				<div
					className="impact-report__summary"
					dangerouslySetInnerHTML={{ __html: impactReport.summary }}
				/>
				{(showDownloadLink || showPreviousLink) && (
					<Flex justifyContent="space-between" gap={16}>
						{showDownloadLink && (
							<Button
								icon={Download}
								className="impact-report__button"
								href={`/reference-data/publications/${impactReport.id}/download`}
								target="_blank"
							>
								Download Report
							</Button>
						)}
						{showPreviousLink && (
							<Button variant="secondary" to="/publications">
								Previous Reports
							</Button>
						)}
					</Flex>
				)}
			</div>
		</Card>
	);
};

export default ImpactReportPanel;
