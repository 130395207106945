import { formatInTimeZone } from 'date-fns-tz';
import format from 'date-fns/format';
import React from 'react';

type DateFragmentProps = {
	date?: Date | null;
	includeTime?: boolean;
	includeDay?: boolean;
	emptyText?: string;
	timezone?: 'utc' | 'local';
};

const DateFragment = ({
	date,
	includeTime = false,
	includeDay = true,
	emptyText = '–',
	timezone = 'local',
}: DateFragmentProps) => {
	const dateFormat = includeDay ? 'dd MMM yyyy' : 'MMM yyyy';
	const dateTimeFormat = `${dateFormat} HH:mm`;
	const relevantFormat = includeTime ? dateTimeFormat : dateFormat;

	if (!date) {
		return <>{emptyText}</>;
	}

	const formattedDate =
		timezone === 'local'
			? format(date, relevantFormat)
			: formatInTimeZone(date, 'UTC', relevantFormat);

	return <>{formattedDate}</>;
};

export default React.memo(DateFragment);
