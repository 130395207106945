import classNames from 'classnames';
import { Check, ChevronRight, LucideIcon } from 'lucide-react';
import { ButtonHTMLAttributes, ForwardedRef, forwardRef } from 'react';
import Tooltip from '../Tooltip';

type MenuItemProps = {
	label: string;
	icon?: LucideIcon;
	showArrow?: boolean;
	isChecked?: boolean;
	tooltip?: string;
	tooltipIcon?: LucideIcon;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const MenuItem = (
	{
		label,
		icon: MenuIcon,
		showArrow = false,
		isChecked,
		className,
		tooltip,
		tooltipIcon: TooltipIcon,
		...props
	}: MenuItemProps,
	ref: ForwardedRef<HTMLButtonElement>
) => {
	const hasCheckmark = isChecked !== undefined;
	const hasTooltip = tooltip && TooltipIcon && !showArrow;

	return (
		<button
			className={classNames('menu__item menuitem', className)}
			role="menuitem"
			{...props}
			type="button"
			ref={ref}
		>
			{hasCheckmark && (
				<Check
					className={classNames(
						'menuitem__checkmark',
						isChecked && 'menuitem__checkmark--visible'
					)}
					size={16}
				/>
			)}
			{MenuIcon && !hasCheckmark && (
				<MenuIcon className="menuitem__icon" size={16} />
			)}
			{label}
			{hasTooltip ? (
				<Tooltip>
					<Tooltip.Trigger>
						<TooltipIcon className="menuitem__tooltip-icon" size={13} />
					</Tooltip.Trigger>
					<Tooltip.Content>{tooltip}</Tooltip.Content>
				</Tooltip>
			) : (
				<ChevronRight
					className={classNames(
						'menuitem__chevron',
						showArrow && 'menuitem__chevron--visible'
					)}
					size={14}
				/>
			)}
		</button>
	);
};

export default forwardRef(MenuItem);
