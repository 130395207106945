import { LoaderFunctionArgs, redirect, useParams } from 'react-router-dom';
import WebToolAPI from 'utils/api/WebToolAPI';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { DecentralizedRouteProps } from 'utils/types/common';
import UserAPI from '../../api/UserAPI';
import WebTool from './WebTool';
import { WebToolProvider } from './WebToolContext';

const loader = async ({ params, request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);

	const webToolAvailable = await UserAPI.hasWebtoolAccess();
	if (!webToolAvailable) {
		return redirect('/');
	}

	const { worksheets } = await WebToolAPI.getWorksheets();

	const lastActiveWorksheetId = localStorage.getItem(
		'webtool.activeWorksheetId'
	);

	const openWorksheetIdsJSON = localStorage.getItem('webtool.openWorksheetIds');

	const openWorksheetIds: string[] = openWorksheetIdsJSON
		? JSON.parse(openWorksheetIdsJSON)
		: [];

	const filteredOpenWorksheetIds = openWorksheetIds.filter((id) =>
		worksheets.some((worksheet) => worksheet.id === id)
	);

	if (filteredOpenWorksheetIds.length !== openWorksheetIds.length) {
		localStorage.setItem(
			'webtool.openWorksheetIds',
			JSON.stringify(filteredOpenWorksheetIds)
		);
	}

	if (!params.id && worksheets.length > 0) {
		const previousWorksheetExists =
			lastActiveWorksheetId &&
			worksheets.some((worksheet) => worksheet.id === lastActiveWorksheetId) &&
			filteredOpenWorksheetIds.includes(lastActiveWorksheetId);

		let targetWorksheetId: string | null = null;
		if (previousWorksheetExists) {
			targetWorksheetId = lastActiveWorksheetId;
		} else if (filteredOpenWorksheetIds.length > 0) {
			targetWorksheetId = filteredOpenWorksheetIds[0];
		}

		if (targetWorksheetId) {
			const requestUrl = new URL(request.url);
			requestUrl.pathname = `/webtool/${targetWorksheetId}`;

			return redirect(requestUrl.toString());
		}

		return null;
	}

	return null;
};

const WebToolList = () => {
	const params = useParams();

	return (
		<WebToolProvider activeWorksheetId={params.id}>
			<WebTool />
		</WebToolProvider>
	);
};

export const WEBTOOL_AREA_ROUTE: DecentralizedRouteProps = {
	loader,
	element: <WebToolList />,
};
