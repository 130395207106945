import classNames from 'classnames';
import { ArrowLeft } from 'lucide-react';
import {
	MouseEvent,
	useCallback,
	useId,
	useMemo,
	useRef,
	useState,
} from 'react';
import Button from 'ui/components/Button/Button';
import Flex from 'ui/components/Flex';
import Modal, { ModalProps } from 'ui/components/Modal/Modal';
import Select from 'ui/components/Select/Select';
import TextField from 'ui/components/TextField';
import WebToolAPI, {
	WorksheetBase,
	WorksheetSchedule,
} from 'utils/api/WebToolAPI';
import { createToastFromError } from 'utils/helpers/toast';
import useScrollbarVisible from 'utils/hooks/useScrollbarVisible';
import { useWebToolContext } from '../../routes/web-tool/WebToolContext';
import WorksheetScheduleForm from '../WorksheetScheduleModal/WorksheetScheduleForm';
import {
	DEFAULT_WORKSHEET_SCHEDULE,
	WorksheetScheduleModalMode,
} from '../WorksheetScheduleModal/WorksheetScheduleModal';
import WorksheetScheduleToolbar from '../WorksheetScheduleModal/WorksheetScheduleToolbar';
import WorksheetBrowserList from './WorksheetBrowserList';

type WorksheetBrowserModalProps = {
	worksheets: WorksheetBase[];
} & Omit<ModalProps, 'title'>;

const WorksheetBrowserModal = ({
	worksheets,
	onClose,
	...modalProps
}: WorksheetBrowserModalProps) => {
	const [search, setSearch] = useState('');
	const [schedulingWorksheet, setSchedulingWorksheet] =
		useState<WorksheetBase | null>(null);

	const { loadWorksheets } = useWebToolContext();

	const [schedule, setSchedule] = useState<WorksheetSchedule>(
		DEFAULT_WORKSHEET_SCHEDULE
	);

	const [scheduledReportsFilter, setScheduledReportsFilter] = useState({
		label: 'All',
		value: 'all',
	});

	const isWorksheetIncludedInFilter = useCallback(
		(worksheet: WorksheetBase) => {
			let isIncludedInFilter = true;

			switch (scheduledReportsFilter.value) {
				case 'scheduled':
					isIncludedInFilter = !!worksheet.schedule;
					break;
				case 'shared':
					isIncludedInFilter = !!worksheet.shareDetails;
					break;
				case 'support':
					isIncludedInFilter = !!worksheet.supportWorksheet;
					break;
			}

			return (
				isIncludedInFilter &&
				worksheet.name.toLowerCase().includes(search.toLowerCase())
			);
		},
		[search, scheduledReportsFilter]
	);

	const ownWorksheets = useMemo(
		() =>
			worksheets
				.filter((worksheet) => !worksheet.supportWorksheet)
				.filter(isWorksheetIncludedInFilter),
		[worksheets, search, scheduledReportsFilter, isWorksheetIncludedInFilter]
	);

	const supportWorksheetsGroupedByCustomer = useMemo(() => {
		const supportWorksheets = worksheets.filter(
			(worksheet) => worksheet.supportWorksheet
		);

		const groupedWorksheets: {
			[customerName: string]: WorksheetBase[];
		} = {};

		supportWorksheets.forEach((worksheet) => {
			if (!isWorksheetIncludedInFilter(worksheet)) return;

			const customerName = worksheet.customer.name;
			if (!groupedWorksheets[customerName]) {
				groupedWorksheets[customerName] = [];
			}

			groupedWorksheets[customerName].push(worksheet);
		});

		return groupedWorksheets;
	}, [worksheets, isWorksheetIncludedInFilter]);

	const handleWorksheetClick = (worksheet: WorksheetBase, e: MouseEvent) => {
		// TODO: Figure out what key to use here to prevent closing the modal
		onClose?.();
	};

	const handleScheduleSave = async () => {
		if (!schedulingWorksheet) return;

		const result = await WebToolAPI.updateWorksheetSchedule(
			schedulingWorksheet.id,
			schedule
		);

		if (result instanceof Error) {
			createToastFromError(result);
			return;
		}

		await loadWorksheets();
		setSchedulingWorksheet(null);
	};

	const handleScheduleDelete = async () => {
		if (!schedulingWorksheet) return;

		const result = await WebToolAPI.deleteWorksheetSchedule(
			schedulingWorksheet.id
		);

		if (result instanceof Error) {
			createToastFromError(result);
			return;
		}

		await loadWorksheets();
		setSchedulingWorksheet(null);
	};

	const handleModalClose = () => {
		setSchedulingWorksheet(null);
		onClose();
	};

	// Only show own worksheets if there are some, or if there are no worksheets at all (Not found message)
	const shouldDisplayOwnWorksheets =
		ownWorksheets.length > 0 ||
		(ownWorksheets.length === 0 &&
			Object.values(supportWorksheetsGroupedByCustomer).length === 0);

	const schedulingFormId = useId();

	const wrapperRef = useRef<HTMLDivElement>(null);
	const isWrapperScrollbarVisible = useScrollbarVisible(wrapperRef);

	return (
		<Modal
			title={
				schedulingWorksheet
					? `Schedule '${schedulingWorksheet.name}'`
					: 'Browse reports'
			}
			size="lg"
			onClose={handleModalClose}
			{...modalProps}
		>
			<Modal.Body>
				<Flex direction="column" gap={16} className="worksheet-browser">
					{!schedulingWorksheet ? (
						<div
							className={classNames(
								'worksheet-browser__wrapper',
								isWrapperScrollbarVisible &&
									'worksheet-browser__wrapper--scrollable'
							)}
							ref={wrapperRef}
						>
							<Flex gap={12}>
								<TextField
									name="search"
									placeholder="Search by name..."
									className="worksheet-browser__search"
									value={search}
									onChange={(e) => setSearch(e.target.value)}
								/>
								<div style={{ width: '35%' }}>
									<Select
										options={[
											{ label: 'All', value: 'all' },
											{ label: 'Scheduled', value: 'scheduled' },
											{ label: 'Shared With Me', value: 'shared' },
											{ label: 'Support', value: 'support' },
										]}
										selectedOption={scheduledReportsFilter}
										onOptionSelected={(option) => {
											if (!option) return;
											setScheduledReportsFilter(option);
										}}
										identifierKey="value"
										contentSource="label"
										isClearable={false}
									/>
								</div>
							</Flex>
							{shouldDisplayOwnWorksheets && (
								<div className="worksheet-browser__section">
									{Object.values(supportWorksheetsGroupedByCustomer).length >
										0 && (
										<b className="worksheet-browser__section-title">
											My reports
										</b>
									)}
									<WorksheetBrowserList
										worksheets={ownWorksheets}
										onWorksheetClick={handleWorksheetClick}
										onWorksheetScheduleClick={(worksheet) => {
											setSchedulingWorksheet(worksheet);
											setSchedule(
												worksheet.schedule ?? DEFAULT_WORKSHEET_SCHEDULE
											);
										}}
									/>
								</div>
							)}
							{Object.entries(supportWorksheetsGroupedByCustomer).map(
								([customerName, worksheets]) => (
									<div
										className="worksheet-browser__section"
										key={customerName}
									>
										<b className="worksheet-browser__section-title">
											{customerName}
										</b>
										<WorksheetBrowserList
											worksheets={worksheets}
											onWorksheetClick={handleWorksheetClick}
											onWorksheetScheduleClick={(worksheet) => {
												setSchedulingWorksheet(worksheet);
												setSchedule(
													worksheet.schedule ?? DEFAULT_WORKSHEET_SCHEDULE
												);
											}}
										/>
									</div>
								)
							)}
						</div>
					) : (
						<WorksheetScheduleForm
							schedule={schedule}
							onScheduleChange={setSchedule}
							id={schedulingFormId}
						/>
					)}
				</Flex>
			</Modal.Body>

			{schedulingWorksheet && (
				<>
					<Modal.Actions alignment="between">
						<Button
							variant="secondary"
							onClick={() => {
								setSchedulingWorksheet(null);
								setSchedule(DEFAULT_WORKSHEET_SCHEDULE);
							}}
							icon={ArrowLeft}
						>
							Back
						</Button>

						<Flex gap={12}>
							<WorksheetScheduleToolbar
								mode={
									schedulingWorksheet.schedule
										? WorksheetScheduleModalMode.EDIT
										: WorksheetScheduleModalMode.CREATE
								}
								formId={schedulingFormId}
								onSave={handleScheduleSave}
								onDelete={handleScheduleDelete}
							/>
						</Flex>
					</Modal.Actions>
				</>
			)}
		</Modal>
	);
};

export default WorksheetBrowserModal;
