import { LogIn } from 'lucide-react';
import { LoaderFunctionArgs, redirect, RouteProps } from 'react-router-dom';
import Button from 'ui/components/Button';
import Toaster from 'ui/components/Toaster';
import { ToastType } from 'ui/components/Toaster/Toast';
import { performLoginRedirect } from 'utils/helpers/msal';
import { createToast } from 'utils/helpers/toast';
import { LOGIN_REQUEST, msalInstance } from '../../auth/authConfig';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	if (msalInstance.getAllAccounts().length > 0) {
		return redirect('/');
	}

	const hasLoggedInElsewhere = sessionStorage.getItem('loggedInElsewhere');
	if (hasLoggedInElsewhere) {
		sessionStorage.removeItem('loggedInElsewhere');
		createToast(
			ToastType.ERROR,
			'You have been logged out because your login details have been used elsewhere.',
			{ duration: Infinity }
		);
	}

	return null;
};

export default function LoginPage() {
	return (
		<div className="landing">
			<div className="landing__content">
				<div className="landing__box">
					<h1 className="landing__title">IATA CargoIS</h1>
					<div className="landing__details">
						<div className="landing__text">
							<p>
								Analyse Air Cargo from your unique perspective and stay ahead of
								competition with IATA CargoIS.
							</p>

							<p>
								For over 20 years, IATA's CargoIS is the air cargo industry's
								premier market intelligence tool, used by the full range of air
								cargo stakeholders: airlines, general sales agents, freight
								forwarders, ground handlers, airports and many others!
							</p>

							<p>
								To know more about how CargoIS can help your business please
								visit:{' '}
								<a href="https://www.iata.org/en/services/statistics/intelligence/cargois/">
									IATA CargoIS
								</a>
							</p>
						</div>
					</div>

					<div className="landing__actions">
						<Button
							icon={LogIn}
							variant="primary"
							onClick={() => performLoginRedirect(msalInstance, LOGIN_REQUEST)}
						>
							Login
						</Button>
					</div>
				</div>
			</div>
			<Toaster />
		</div>
	);
}

export const LOGIN_ROUTE: RouteProps = {
	loader: loader,
	element: <LoginPage />,
};
