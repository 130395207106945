import {
	zArrayOfStringRequired,
	zEnsureArrayPreprocessor,
} from 'utils/zod/zodValidation';
import z from 'zod';

const tradelanesSchema = z.preprocess(
	zEnsureArrayPreprocessor,
	zArrayOfStringRequired('Trade Lanes is required')
);

export const webToolTradeGroupSchema = z.object({
	id: z.string(),
	name: z
		.string({})
		.min(1, { message: 'Name is required' })
		.max(100, { message: 'Name must not be more than 100 characters' }),
	description: z
		.string({})
		.min(1, { message: 'Description is required' })
		.max(250, { message: 'Description must not be more than 250 characters' }),
	tradelanes: tradelanesSchema,
});

export const createWebToolTradeGroupSchema = webToolTradeGroupSchema.pick({
	name: true,
	description: true,
	tradelanes: true,
});

export const updateWebToolTradeGroupSchema = webToolTradeGroupSchema.pick({
	name: true,
	description: true,
	tradelanes: true,
});

export type CreateWebToolTradeGroupModel = z.infer<
	typeof createWebToolTradeGroupSchema
>;
export type UpdateWebToolTradeGroupModel = z.infer<
	typeof updateWebToolTradeGroupSchema
>;
