import { Unlink } from 'lucide-react';
import { KeyboardEvent, MouseEvent } from 'react';
import WeightBreakItem from './WeightBreakItem';
import { WeightBreak } from './index';

type WeightBreakGroupProps = {
	items: WeightBreak[];
	onClear?: () => void;
};

const WeightBreakGroup = ({ items, onClear }: WeightBreakGroupProps) => {
	const handleClick = (e: MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
		onClear?.();
	};

	const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
		if (e.key === 'Enter' || e.key === ' ') {
			e.stopPropagation();
			onClear?.();
		}
	};

	return (
		<div
			className="weightbreaks__group"
			role="button"
			tabIndex={0}
			onClick={handleClick}
			onKeyDown={handleKeyDown}
		>
			{items.map((item) => (
				<WeightBreakItem from={item.from} to={item.to} key={item.index} />
			))}
			<div className="weightbreaks__remove-group">
				<Unlink size={18} />
			</div>
		</div>
	);
};

export default WeightBreakGroup;
