import {
	Placement,
	arrow,
	autoUpdate,
	flip,
	offset,
	shift,
	useDismiss,
	useFloating,
	useFocus,
	useHover,
	useInteractions,
	useRole,
} from '@floating-ui/react';
import { createContext, useContext, useMemo, useRef, useState } from 'react';

interface TooltipOptions {
	initialOpen?: boolean;
	placement?: Placement;
	open?: boolean;
	onOpenChange?: (open: boolean) => void;
	delay?: number;
	disabled?: boolean;
	detachTriggerWhileDisabled?: boolean;
}

export function useTooltip({
	initialOpen = false,
	placement = 'top',
	open: controlledOpen,
	onOpenChange: setControlledOpen,
	delay,
	disabled,
	detachTriggerWhileDisabled,
}: TooltipOptions = {}) {
	const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);

	const open = controlledOpen ?? uncontrolledOpen;
	const setOpen = setControlledOpen ?? setUncontrolledOpen;

	const arrowRef = useRef(null);

	const data = useFloating({
		placement,
		open,
		onOpenChange: setOpen,
		whileElementsMounted: autoUpdate,
		middleware: [
			offset(5),
			flip(),
			shift({ padding: 15 }),
			arrow({ element: arrowRef }),
		],
	});

	const context = data.context;

	const hover = useHover(context, {
		move: false,
		enabled: controlledOpen === undefined,
		restMs: delay,
	});

	const focus = useFocus(context, {
		enabled: controlledOpen === undefined,
	});

	const dismiss = useDismiss(context);
	const role = useRole(context, { role: 'tooltip' });

	const interactions = useInteractions([hover, focus, dismiss, role]);

	return useMemo(
		() => ({
			open,
			setOpen,
			arrowRef,
			...interactions,
			...data,
			disabled,
			detachTriggerWhileDisabled,
		}),
		[
			open,
			setOpen,
			arrowRef,
			interactions,
			data,
			disabled,
			detachTriggerWhileDisabled,
		]
	);
}

type TooltipContextValue = ReturnType<typeof useTooltip> | null;
const TooltipContext = createContext<TooltipContextValue>(null);

export const useTooltipContext = () => {
	const context = useContext(TooltipContext);

	if (context === null) {
		throw new Error('Tooltip components must be wrapped in <Tooltip />');
	}

	return context;
};

export default TooltipContext;
