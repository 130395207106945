import {
	ForwardedRef,
	HTMLProps,
	cloneElement,
	forwardRef,
	isValidElement,
} from 'react';

const MenuTrigger = (
	{ children, ...props }: HTMLProps<HTMLElement>,
	propRef: ForwardedRef<HTMLElement>
) => {
	if (!isValidElement(children)) {
		throw new Error('MenuTrigger must be passed a valid React element');
	}

	return cloneElement(children, {
		ref: propRef,
		...props,
	});
};

export default forwardRef(MenuTrigger);
