import { ENVIRONMENT_SHARED } from 'environment';
import API from 'utils/api/API';
import { ValidationResult } from '../helpers/codeValidation';
import { OptionGroup } from 'ui/components/Dropdown/types';

export type Gsa = {
	name: string;
	code: string;
};

export type AirlineMatch = {
	code: string;
	prefix: string | null;
};

export type AirlineSingleOption = {
	name: string;
	code: string;
	airlinePrefix: string;
	iataCode: string;
	itemType?: 'airline';
};

export type AirlineCustomGroupOption = {
	name: string;
	code: string;
	itemType: 'custom-group';
};

export type AirlineOption = AirlineSingleOption | AirlineCustomGroupOption;

export type GetAirlinesOptionsResponse = {
	keyword: string;
	options: AirlineOption[];
	groups: null;
};

export type GetAirlinesByGroupResponse = {
	keyword: string;
	options: null;
	groups: OptionGroup<AirlineOption>[];
};

export type GetAirlinesResponse =
	| GetAirlinesOptionsResponse
	| GetAirlinesByGroupResponse;

export type AirlineType = 'airline' | 'custom-group';

export type ValidateAirlineCodesResponse = ValidationResult<AirlineOption>;

class AirlinesAPI extends API {
	protected static readonly apiEndpoint =
		ENVIRONMENT_SHARED.subscriptionsApiEndpoint;

	static async getAirlines(keyword: string, types: AirlineType[]) {
		const params = new URLSearchParams();
		params.append('Keyword', keyword);

		for (const type of types) {
			params.append('type', type.toString());
		}
		return AirlinesAPI.get<GetAirlinesResponse>(
			`/airlines/lookup?${params.toString()}`
		);
	}
	static async validateAirlineCodes(codes: string[]) {
		const params = new URLSearchParams({
			codes: codes.join(','),
		});

		return AirlinesAPI.get<ValidateAirlineCodesResponse>(
			`/airlines/validate?${params.toString()}`
		);
	}
}

export default AirlinesAPI;
