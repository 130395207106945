import { ComponentProps, useState } from 'react';
import { downloadFile } from 'utils/helpers/file';
import formatBytes from 'utils/helpers/formatBytes';
import { createToast } from 'utils/helpers/toast';
import { Option } from 'utils/types/common';
import { ToastType } from '../Toaster/Toast';
import AttachmentLinkContent from './AttachmentLinkContent';

type AttachmentLinkProps = {
	url: string | (() => Promise<string | undefined>);
	downloadInPlace?: boolean;
	name: string;
	size?: Option<number>;
	showFileName?: boolean;
} & Omit<ComponentProps<'a'>, 'download' | 'href'>;

const AttachmentLink = ({
	name,
	size,
	url,
	showFileName,
	...restProps
}: AttachmentLinkProps) => {
	const [isLoading, setIsLoading] = useState(false);

	const extension = name.split('.').pop();
	const formattedSize = size ? formatBytes(size, 0) : undefined;

	if (typeof url === 'function') {
		const handleLazyDownload = async () => {
			setIsLoading(true);
			try {
				const resolvedUrl = await url();
				if (resolvedUrl === undefined) {
					return;
				}
				downloadFile(resolvedUrl, name);
			} catch (error) {
				if (error instanceof Error) {
					createToast(
						ToastType.ERROR,
						'Error downloading file: ' + error.message
					);
				}
			} finally {
				setIsLoading(false);
			}
		};

		return (
			<span
				role="button"
				className="attachment-link"
				onClick={handleLazyDownload}
				{...restProps}
			>
				<AttachmentLinkContent
					label={showFileName ? name : undefined}
					extension={extension}
					formattedSize={formattedSize}
					isLoading={isLoading}
				/>
			</span>
		);
	} else {
		const defaultDownloadInPlace = url.startsWith('http');

		return (
			<a
				className="attachment-link"
				href={url}
				download={restProps.downloadInPlace ? name : defaultDownloadInPlace}
				title={name}
				{...restProps}
			>
				<AttachmentLinkContent
					label={showFileName ? name : undefined}
					extension={extension}
					formattedSize={formattedSize}
				/>
			</a>
		);
	}
};

export default AttachmentLink;
