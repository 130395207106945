import classNames from 'classnames';
import { Loader2, LucideIcon } from 'lucide-react';
import { ComponentPropsWithoutRef, ForwardedRef, forwardRef } from 'react';
import { Link } from 'react-router-dom';

export type ButtonProps = {
	variant?: 'primary' | 'secondary';
	size?: 'tiny' | 'small' | 'medium';
	icon?: LucideIcon;
	iconPosition?: 'left' | 'right';
	intent?: 'default' | 'danger';
	iconProps?: ComponentPropsWithoutRef<LucideIcon>;
	isDisabled?: boolean;
	isLoading?: boolean;
	loadingText?: string;
} & (
	| ComponentPropsWithoutRef<'button'>
	| ComponentPropsWithoutRef<typeof Link>
	| ComponentPropsWithoutRef<'a'>
);

const Button = (
	{
		variant = 'primary',
		icon,
		iconPosition = 'left',
		iconProps,
		size = 'medium',
		isDisabled: propsIsDisabled,
		isLoading = false,
		loadingText,
		children,
		intent = 'default',
		className,
		...restProps
	}: ButtonProps,
	ref: ForwardedRef<HTMLElement>
) => {
	const IconComponent = isLoading ? Loader2 : icon;

	const isLink = 'to' in restProps;
	const isNativeLink = 'href' in restProps;

	const hasIcon = icon !== undefined || isLoading;
	const hasLoadingText = isLoading && loadingText !== undefined;
	const isDisabled =
		propsIsDisabled ||
		('disabled' in restProps && restProps.disabled) ||
		isLoading;

	const mergedClassName = classNames(
		'button',
		`button--${variant}`,
		`button--${size}`,
		intent && `button--${intent}`,
		isDisabled && 'button--disabled',
		hasIcon && `button--icon button--icon-${iconPosition}`,
		className
	);

	const iconSizeMap = {
		tiny: 12,
		small: 14,
		medium: 16,
	};

	const childComponents = (
		<>
			{IconComponent && (
				<IconComponent
					className={classNames(
						'button__icon',
						isLoading && 'button__icon--spinning'
					)}
					size={iconSizeMap[size]}
					{...iconProps}
				/>
			)}
			{(hasLoadingText || children) && (
				<span className="button__content">
					{hasLoadingText ? loadingText : children}
				</span>
			)}
		</>
	);

	if (isLink) {
		return (
			<Link
				{...restProps}
				aria-disabled={isDisabled}
				className={mergedClassName}
				ref={ref as ForwardedRef<HTMLAnchorElement>}
			>
				{childComponents}
			</Link>
		);
	} else if (isNativeLink) {
		return (
			<a
				{...restProps}
				aria-disabled={isDisabled}
				className={mergedClassName}
				ref={ref as ForwardedRef<HTMLAnchorElement>}
			>
				{childComponents}
			</a>
		);
	} else {
		return (
			<button
				{...(restProps as ComponentPropsWithoutRef<'button'>)}
				aria-disabled={isDisabled}
				disabled={isDisabled}
				className={mergedClassName}
				ref={ref as ForwardedRef<HTMLButtonElement>}
			>
				{childComponents}
			</button>
		);
	}
};

export default forwardRef(Button);
