import { useMergeRefs } from '@floating-ui/react';
import {
	ForwardedRef,
	HTMLProps,
	cloneElement,
	forwardRef,
	isValidElement,
} from 'react';
import { useTooltipContext } from './TooltipContext';

const TooltipTrigger = (
	{ children, ...props }: HTMLProps<HTMLElement>,
	propRef: ForwardedRef<HTMLElement>
) => {
	const context = useTooltipContext();
	const childrenRef = (children as any).ref;
	const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

	if (!isValidElement(children) || typeof children === 'string') {
		throw new Error(
			'TooltipTrigger must be passed a valid React element or string'
		);
	}

	if (context.disabled && context.detachTriggerWhileDisabled) {
		return children;
	}

	return cloneElement(
		children,
		context.getReferenceProps({
			ref,
			...props,
			...children.props,
			'data-state': context.open ? 'open' : 'closed',
		})
	);
};

export default forwardRef(TooltipTrigger);
