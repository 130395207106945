import { ENVIRONMENT_SHARED } from 'environment';
import API from 'utils/api/API';
import { AirImpactReportModel } from 'utils/schemas/airImpactReportSchema';
import { Paginated } from 'utils/types/common';

type GetAirImpactReportsResponse = {
	reports: Paginated<AirImpactReportModel>;
};

export type AirImpactReportDownloadModel = {
	url: string;
	originalFileName: string;
};

class AirImpactReportsAPI extends API {
	protected static readonly apiEndpoint =
		ENVIRONMENT_SHARED.subscriptionsApiEndpoint;

	static getImpactReportsList(params: URLSearchParams) {
		return AirImpactReportsAPI.get<GetAirImpactReportsResponse>(
			`/air-impact-reports/published?${params}`
		);
	}

	static getImpactReport(id: string) {
		return AirImpactReportsAPI.get<AirImpactReportModel>(
			`/air-impact-reports/published/${id}`
		);
	}

	static getImpactReportDownload(id: string) {
		return AirImpactReportsAPI.get<AirImpactReportDownloadModel>(
			`/air-impact-reports/published/${id}/download`
		);
	}
}

export default AirImpactReportsAPI;
