import Pill from 'ui/components/Pill';
import { Location } from 'utils/api/common';

type LocationPickerPreviewProps = {
	location: Location;
};

const LocationPickerPreview = ({ location }: LocationPickerPreviewProps) => {
	/**
	 * Styles:
	 * Station → <code>
	 * City → <code>
	 * State → <Code>
	 * Country → <Code>
	 * Region → <CargoIS Region Name or TACT Area Name>
	 */

	switch (location.itemType) {
		case 'station':
		case 'country':
		case 'city':
		case 'province':
			return <Pill code={location.code} />;
		case 'region':
		case 'custom-group':
			return <Pill name={location.name} />;
	}
};

export default LocationPickerPreview;
