type PageLoaderProps = {
	label: string;
};

const PageLoader = ({ label }: PageLoaderProps) => {
	return (
		<div className="page-loader">
			<div className="page-loader__spinner"></div>
			<div className="page-loader__status">{label}</div>
		</div>
	);
};

export default PageLoader;
