import classNames from 'classnames';
import { Loader2, X } from 'lucide-react';
import { ChangeEvent } from 'react';

export type ToggleProps = {
	isChecked?: boolean;
	defaultChecked?: boolean;
	isDisabled?: boolean;
	size?: 'small' | 'medium';
	loading?: boolean;
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

const Toggle = ({
	isChecked,
	defaultChecked,
	size = 'medium',
	isDisabled,
	loading,
	onChange,
}: ToggleProps) => {
	// Checkbox based approach
	return (
		<label
			className={classNames(
				'toggle',
				`toggle--${size}`,
				loading && 'toggle--loading',
				isDisabled && 'toggle--disabled'
			)}
			data-testid="toggle"
		>
			<input
				type="checkbox"
				checked={isChecked}
				onChange={isDisabled ? undefined : onChange}
				disabled={isDisabled}
				className="toggle__input"
				defaultChecked={defaultChecked}
			/>
			<div className="toggle__track" />
			<div className="toggle__thumb">
				<Loader2 size={12} className="toggle__loader" />
				<X size={12} className="toggle__disabled" />
			</div>
		</label>
	);
};

export default Toggle;
