import { redirect } from 'react-router-dom';
import { isTestRunner } from './test';

const requireAuthentication = async (request: Request) => {
	if (!isTestRunner && window.msal.instance.getAllAccounts().length === 0) {
		const requestUrl = new URL(request.url);
		const returnUrl = requestUrl.pathname + requestUrl.search;

		const redirectUri =
			returnUrl.length > 1
				? `/login?returnUrl=${encodeURIComponent(returnUrl)}`
				: '/login';

		throw redirect(redirectUri);
	}

	return null;
};

export default requireAuthentication;
