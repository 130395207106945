import { SortDirection } from '@tanstack/react-table';
import classNames from 'classnames';
import { ArrowDown, ArrowUp, ArrowUpDown, GripVertical, X } from 'lucide-react';
import { ComponentProps, ForwardedRef, forwardRef } from 'react';
import Tooltip from 'ui/components/Tooltip/Tooltip';

type LayoutBuilderItemPresentationProps = {
	name: string;

	sortDirection?: SortDirection;
	onSortDirectionChange?: (sortDirection: SortDirection) => void;

	isDisabled?: boolean;

	onRemove?: () => void;
	onAddToSort?: () => void;
	onRemoveFromSort?: () => void;
	isSorted?: boolean;

	hasShadow?: boolean;
} & ComponentProps<'li'>;

const LayoutBuilderItemPresentation = (
	{
		name,
		className,
		sortDirection,
		onSortDirectionChange,
		isDisabled = false,
		onRemove,
		onAddToSort,
		onRemoveFromSort,
		isSorted = false,
		hasShadow = false,
		...restProps
	}: LayoutBuilderItemPresentationProps,
	ref: ForwardedRef<HTMLLIElement>
) => {
	const hasSortDirection = sortDirection && onSortDirectionChange;

	const handleSortDirectionChange = () => {
		if (!hasSortDirection) return;

		if (sortDirection === 'asc') {
			onSortDirectionChange('desc');
		} else {
			onSortDirectionChange('asc');
		}
	};

	return (
		<li
			className={classNames(
				'layout-builder__item layout-item',
				isDisabled && 'layout-item--disabled',
				hasShadow && 'layout-item--shadow',
				className
			)}
			{...restProps}
			ref={ref}
		>
			<div className="layout-item__handle">
				<GripVertical size={16} />
			</div>

			<div className="layout-item__content">
				<div className="layout-item__name">{name}</div>
			</div>

			<div className="layout-item__actions">
				{hasSortDirection && (
					<Tooltip>
						<Tooltip.Trigger>
							<button
								className="layout-item__action layout-item__action--sort"
								onClick={handleSortDirectionChange}
								type="button"
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										e.stopPropagation();
										handleSortDirectionChange();
									}
								}}
							>
								{sortDirection === 'asc' ? (
									<ArrowUp size={14} />
								) : (
									<ArrowDown size={14} />
								)}
							</button>
						</Tooltip.Trigger>
						<Tooltip.Content>
							Sorting {sortDirection === 'asc' ? 'ascending' : 'descending'}
						</Tooltip.Content>
					</Tooltip>
				)}

				{onAddToSort && onRemoveFromSort && (
					<Tooltip>
						<Tooltip.Trigger>
							<button
								className={classNames(
									'layout-item__action layout-item__action--sort',
									isSorted && 'layout-item__action--sorted'
								)}
								onClick={isSorted ? onRemoveFromSort : onAddToSort}
								type="button"
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										e.stopPropagation();
										(isSorted ? onRemoveFromSort : onAddToSort)();
									}
								}}
							>
								<ArrowUpDown size={14} />
							</button>
						</Tooltip.Trigger>
						<Tooltip.Content>
							{isSorted ? 'Remove from sort' : 'Add to sort'}
						</Tooltip.Content>
					</Tooltip>
				)}

				{onRemove && (
					<button
						className="layout-item__action layout-item__action--remove"
						onClick={onRemove}
						type="button"
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								e.stopPropagation();
								onRemove();
							}
						}}
					>
						<X size={14} />
					</button>
				)}
			</div>
		</li>
	);
};

export default forwardRef(LayoutBuilderItemPresentation);
