import { ArrowUpDown, Cog } from 'lucide-react';
import { useMemo, useState } from 'react';
import AirlinePicker, {
	AirlinePickerPill,
	AirlinePickerPreviewSource,
	customGroupToAirlineOption,
} from 'ui/components/AirlinePicker/AirlinePicker';
import Button from 'ui/components/Button/Button';
import CASSAreaPicker from 'ui/components/CASSAreaPicker/CASSAreaPicker';
import { OptionGroup } from 'ui/components/Dropdown/types';
import Flex from 'ui/components/Flex/Flex';
import FormField from 'ui/components/FormField/FormField';
import FreightForwarderPicker from 'ui/components/FreightForwarderPicker/FreightForwarderPicker';
import LocationPicker from 'ui/components/LocationPicker/LocationPicker';
import MultiSelect from 'ui/components/MultiSelect/MultiSelect';
import Pill from 'ui/components/Pill/Pill';
import Select from 'ui/components/Select/Select';
import TextField from 'ui/components/TextField/TextField';
import Toggle from 'ui/components/Toggle/Toggle';
import { WeightBreak } from 'ui/components/WeightBreaks';
import {
	DEFAULT_WEIGHT_BREAKS_CASS,
	DEFAULT_WEIGHT_BREAKS_CDD,
} from 'ui/components/WeightBreaks/WeightBreaks';
import { AirlineOption } from 'utils/api/AirlinesAPI';
import {
	WorksheetConfigWebToolGroup,
	WorksheetParameter,
} from 'utils/api/WebToolAPI';
import { WebToolBreaksGroupItem } from 'utils/api/WebToolGroupAPI';
import { Location } from 'utils/api/common';
import { LabeledValue } from 'utils/types/common';
import AWBSerialNumbersField from './AWBSerialNumber/AWBSerialNumbersField';
import { hasValuesForParameter, useWorksheetContext } from './WorksheetContext';
import WorksheetDateRangeField from './WorksheetDateRangeField';
import WorksheetSection from './WorksheetSection';
import WorksheetTradelanesPicker from './WorksheetTradelanesPicker';
import WorksheetWeightBreaksModal from './WorksheetWeightBreaksModal';

type WorksheetParametersProps = {};

const SwitchButton = ({ onClick }: { onClick: () => void }) => (
	<span
		style={{
			paddingRight: '4px',
			display: 'flex',
			alignItems: 'center',
			gap: '4px',
			fontSize: '14px',
			color: 'var(--color-gray-700)',
			cursor: 'pointer',
		}}
		onClick={onClick}
	>
		<span>Switch</span>
		<ArrowUpDown size={16} style={{}} />
	</span>
);

const WeightBreakPill = ({
	value,
	onRemove,
}: {
	value: WeightBreak;
	onRemove: () => void;
}) => (
	<Pill
		name={
			value.to === Infinity
				? `${value.from.toFixed(2)} +`
				: `${value.from.toFixed(2)} - ${value.to}`
		}
		onRemove={onRemove}
	/>
);

type WeightBreakOption = WeightBreak & { id: number };

const WorksheetParameters = ({}: WorksheetParametersProps) => {
	const {
		worksheet,
		state,
		setDeepState,
		weightBreakGroups,
		isParametersPanelOpen,
		setIsParametersPanelOpen,
		derivedState,
	} = useWorksheetContext();

	const { parameters } = state;
	const { disabledParameters } = derivedState;

	const [isWeightBreaksModalOpen, setIsWeightBreaksModalOpen] = useState(false);

	const swapLocations = () => {
		const temp = parameters.location.originLocations;
		setOriginLocations(parameters.location.destinationLocations);
		setDestinationLocations(temp);
	};

	const setOriginLocations = (locations: Location[]) => {
		setDeepState('parameters.location.originLocations', locations);
	};

	const setDestinationLocations = (locations: Location[]) => {
		setDeepState('parameters.location.destinationLocations', locations);
	};

	const atLeastOneAdvancedParameterAvailable = Object.values(
		worksheet.config.parameters.enabledParameters
	).includes('advanced');
	const isAdvanced = state.parameters.advancedMode;
	const showParameter = (parameter: WorksheetParameter) => {
		const parameterType =
			worksheet.config.parameters.enabledParameters[parameter];

		return parameterType && (isAdvanced || parameterType === 'simple');
	};

	const weightBreaksGroupsWithCustom = useMemo<WebToolBreaksGroupItem[]>(() => {
		return [
			...weightBreakGroups,
			{
				id: 'custom',
				name: 'Custom',
				description: 'Custom weight breaks',
				createdAt: new Date(),
				weightBreaks: state.parameters.breakdown.weightBreakGroupEmbedded ?? [],
			},
		];
	}, [weightBreakGroups, state.parameters.breakdown.weightBreakGroupEmbedded]);

	const weightBreakGroup = useMemo<WebToolBreaksGroupItem | null>(() => {
		if (!showParameter('weight-break-set')) return null;

		const weightBreakGroupId = state.parameters.breakdown.weightBreakGroupId;
		const weightBreakGroup = weightBreaksGroupsWithCustom.find(
			(wbg) => wbg.id === weightBreakGroupId
		);

		if (weightBreakGroupId?.length && !weightBreakGroup) {
			// If there is an ID, but no group was found, then pick custom
			return weightBreaksGroupsWithCustom[
				weightBreaksGroupsWithCustom.length - 1
			];
		}

		return weightBreakGroup || null;
	}, [weightBreakGroups, state.parameters.breakdown, isAdvanced]);

	const weightBreaksOptions: WeightBreakOption[] = useMemo(() => {
		const weightBreaks =
			weightBreakGroup?.id === 'custom'
				? state.parameters.breakdown.weightBreakGroupEmbedded
				: weightBreakGroup?.weightBreaks;

		if (weightBreaks) {
			return weightBreaks.reduce<WeightBreakOption[]>((acc, group, wbIndex) => {
				const groupIndex = group - 1;
				const currentWeightBreak = DEFAULT_WEIGHT_BREAKS_CASS[wbIndex];

				if (!acc[groupIndex]) {
					if (groupIndex > 0) {
						acc[groupIndex - 1].to = DEFAULT_WEIGHT_BREAKS_CASS[wbIndex - 1].to;
					}
					acc[groupIndex] = {
						...currentWeightBreak,
						id: groupIndex + 1,
					};
				}

				acc[groupIndex].to = currentWeightBreak.to;

				return acc;
			}, []);
		} else {
			const weightBreaks =
				hasValuesForParameter(state.parameters, 'sph-high-level') ||
				hasValuesForParameter(state.parameters, 'commodity-mid-level') ||
				hasValuesForParameter(state.parameters, 'service-mid-level') ||
				derivedState.selectedFieldDimensions.some(
					(field) => field.requiresServiceWeightBreaks
				) ||
				derivedState.selectedDataFieldDimensions.some(
					(dataField) => dataField.requiresServiceWeightBreaks
				)
					? DEFAULT_WEIGHT_BREAKS_CDD
					: DEFAULT_WEIGHT_BREAKS_CASS;
			return weightBreaks.map((wb, index) => ({
				...wb,
				id: index + 1,
			}));
		}
	}, [weightBreakGroup, state.parameters, derivedState]);

	const selectedWeightBreaks = useMemo(() => {
		if (state.parameters.breakdown.weightBreaks.length === 0) return [];

		return weightBreaksOptions.filter((option) =>
			state.parameters.breakdown.weightBreaks.includes(option.id)
		);
	}, [weightBreaksOptions, state.parameters.breakdown.weightBreaks]);

	const isCustomWeightBreakSelected = useMemo(() => {
		const weightBreakGroupId = state.parameters.breakdown.weightBreakGroupId;

		if (weightBreakGroupId === 'custom') return true;
		if (!weightBreakGroupId) return false;

		const weightBreakGroup = weightBreaksGroupsWithCustom.find(
			(wbg) => wbg.id === weightBreakGroupId
		);

		// If the group was deleted in the meantime, then it's custom
		if (!weightBreakGroup) return true;

		return false;
	}, [parameters.breakdown.weightBreakGroupId]);

	const canFilterOriginAndDestination =
		showParameter('origin') && showParameter('destination');
	const canFilterAnySpecialHandling =
		showParameter('sph-high-level') ||
		showParameter('commodity-mid-level') ||
		showParameter('service-mid-level');

	const locationCustomGroups = worksheet.config.webToolGroups.filter(
		(group) =>
			group.groupType === 'geography' ||
			group.groupType === 'station' ||
			group.groupType === 'city'
	);

	const airlineCustomGroups = worksheet.config.webToolGroups.filter(
		(group) => group.groupType === 'airline'
	);

	const freightForwarderCustomGroups = worksheet.config.webToolGroups.filter(
		(group) => group.groupType === 'freight-forwarder'
	);

	const [airlineOptionsGrouped, airlineOptions] =
		mergeAirlineAndCustomGroupOptions(
			worksheet.config.parameters.airlineOptions,
			worksheet.config.webToolGroups
		);

	return (
		<WorksheetSection
			title="Report Parameters"
			advancedModeEnabled={
				atLeastOneAdvancedParameterAvailable ? isAdvanced : undefined
			}
			onAdvancedModeEnabledChange={() => {
				setDeepState('parameters.advancedMode', !isAdvanced);

				// When WeightBreakGroupId is set always clear out the weight break options to avoid miss-matching
				if (parameters.breakdown.weightBreakGroupId) {
					setDeepState('parameters.breakdown.weightBreaks', []);
				}
			}}
			isOpen={isParametersPanelOpen}
			onOpenChange={setIsParametersPanelOpen}
		>
			<Flex direction="column" gap={16}>
				<b>Location</b>
				{showParameter('origin') && (
					<LocationPicker
						name="origin"
						label="Origins"
						locationTypes={worksheet.config.parameters.originLocationTypes}
						selectedLocations={parameters.location.originLocations}
						onSelectedLocationsChange={setOriginLocations}
						isMulti
						locationCustomGroups={locationCustomGroups}
					/>
				)}
				{showParameter('destination') && (
					<LocationPicker
						name="destinations"
						label="Destinations"
						isMulti
						locationTypes={worksheet.config.parameters.destinationLocationTypes}
						selectedLocations={parameters.location.destinationLocations}
						onSelectedLocationsChange={setDestinationLocations}
						secondaryLabel={
							canFilterOriginAndDestination &&
							(parameters.location.originLocations.length ||
								parameters.location.destinationLocations.length) ? (
								<SwitchButton onClick={swapLocations} />
							) : null
						}
						locationCustomGroups={locationCustomGroups}
					/>
				)}
				{canFilterOriginAndDestination && (
					<Flex direction="row" gap={12} alignItems="center">
						<Toggle
							isChecked={state.parameters.location.includeReverseLocations}
							onChange={(e) =>
								setDeepState(
									'parameters.location.includeReverseLocations',
									e.target.checked
								)
							}
						/>
						<span>Include reverse traffic direction</span>
					</Flex>
				)}
				{showParameter('released-countries-only') && (
					<Flex direction="row" gap={12}>
						<Toggle
							isChecked={state.parameters.location.releasedCountriesOnly}
							onChange={(e) =>
								setDeepState(
									'parameters.location.releasedCountriesOnly',
									e.target.checked
								)
							}
							isDisabled={disabledParameters['released-countries-only']}
						/>
						<span>Display released countries only</span>
					</Flex>
				)}
				{showParameter('cass-area') && (
					<CASSAreaPicker
						selectedCASSAreas={state.parameters.location.cassAreas}
						onSelectedCASSAreasChange={(cassAreas) => {
							setDeepState('parameters.location.cassAreas', cassAreas);
						}}
						name="cassop"
						label="CASS Operations"
						isMulti
						isDisabled={disabledParameters['cass-area']}
					/>
				)}
				{showParameter('tradelane') && <WorksheetTradelanesPicker />}
			</Flex>
			<Flex direction="column" gap={32}>
				<WorksheetDateRangeField />
				{showParameter('air-waybill') && (
					<Flex direction="column" gap={16}>
						<b>AWB</b>

						<AWBSerialNumbersField
							disabled={disabledParameters['air-waybill'] ?? false}
							value={state.parameters.awb.serialNumber}
							onChange={(value) => {
								setDeepState('parameters.awb.serialNumber', value);
							}}
						/>

						<TextField
							value={state.parameters.awb.airlinePrefix}
							onChange={(e) => {
								setDeepState('parameters.awb.airlinePrefix', e.target.value);
							}}
							name="awbprefix"
							label="AWB Airline Prefix"
							placeholder="###"
							maxLength={3}
							disabled={disabledParameters['air-waybill']}
						/>
					</Flex>
				)}
			</Flex>
			{(showParameter('airline') ||
				showParameter('freight-forwarder') ||
				showParameter('weight-break-set') ||
				showParameter('weight-break')) && (
				<Flex direction="column" gap={16} title="Breakdown">
					<b>Breakdown</b>

					{showParameter('airline') &&
						(airlineOptions ? (
							<FormField label="Airlines">
								<MultiSelect<AirlineOption>
									name="airline"
									isDisabled={disabledParameters['airline']}
									options={airlineOptions}
									selectedOptions={parameters.breakdown.airlines}
									onSelectedOptionsChange={(airlines) =>
										setDeepState('parameters.breakdown.airlines', airlines)
									}
									isGrouped={airlineOptionsGrouped}
									identifierKey="name"
									contentSource={AirlinePickerPreviewSource}
									pillComponent={AirlinePickerPill}
								/>
							</FormField>
						) : (
							<AirlinePicker
								name="airline"
								label="Airlines"
								isMulti
								isDisabled={disabledParameters['airline']}
								selectedAirlines={parameters.breakdown.airlines}
								onSelectedAirlinesChange={(airlines) =>
									setDeepState('parameters.breakdown.airlines', airlines)
								}
								airlineTypes={
									airlineCustomGroups.length > 0
										? ['airline', 'custom-group']
										: ['airline']
								}
								airlineCustomGroups={airlineCustomGroups}
							/>
						))}
					{showParameter('freight-forwarder') && (
						<FreightForwarderPicker
							name="freight-forwarder"
							label="Freight Forwarders"
							subscriptionId={worksheet.subscriptionId}
							isMulti
							isDisabled={disabledParameters['freight-forwarder']}
							freightForwarderTypes={
								worksheet.config.parameters.freightForwarderTypes
							}
							selectedFreightForwarders={parameters.breakdown.freightForwarders}
							onSelectedFreightForwardersChange={(freightForwarders) =>
								setDeepState(
									'parameters.breakdown.freightForwarders',
									freightForwarders
								)
							}
							freightForwarderCustomGroups={freightForwarderCustomGroups}
						/>
					)}
					{showParameter('weight-break-set') && (
						<FormField label="Weight Breaks Set">
							<Flex gap={12}>
								<Select
									options={weightBreaksGroupsWithCustom}
									name="weight-breaks-custom"
									placeholder={'IATA Defaults'}
									isDisabled={disabledParameters['weight-break-set']}
									selectedOption={weightBreakGroup}
									onOptionSelected={(option) => {
										setDeepState(
											'parameters.breakdown.weightBreakGroupId',
											option?.id
										);
										setDeepState(
											'parameters.breakdown.weightBreakGroupEmbedded',
											option?.weightBreaks
										);
										setDeepState('parameters.breakdown.weightBreaks', []);
										if (option?.id === 'custom') {
											setIsWeightBreaksModalOpen(true);
										}
									}}
									identifierKey="id"
									contentSource={(i) => {
										if (i.id === 'custom') {
											return <i>Custom</i>;
										} else {
											return i.name;
										}
									}}
								/>

								{isCustomWeightBreakSelected && (
									<Button
										variant="secondary"
										icon={Cog}
										type="button"
										onClick={() => setIsWeightBreaksModalOpen(true)}
									/>
								)}

								<WorksheetWeightBreaksModal
									isOpen={isWeightBreaksModalOpen}
									onClose={() => setIsWeightBreaksModalOpen(false)}
									weightBreaks={
										state.parameters.breakdown.weightBreakGroupEmbedded ?? []
									}
									onWeightBreaksChange={(newWeightBreaks) => {
										setDeepState(
											'parameters.breakdown.weightBreakGroupEmbedded',
											newWeightBreaks
										);
										setDeepState('parameters.breakdown.weightBreaks', []);
									}}
								/>
							</Flex>
						</FormField>
					)}
					{showParameter('weight-break') && (
						<FormField label="Weight Breaks">
							<MultiSelect
								options={weightBreaksOptions}
								name="weight-breaks"
								identifierKey="index"
								isDisabled={disabledParameters['weight-break']}
								selectedOptions={selectedWeightBreaks}
								onSelectedOptionsChange={(options) => {
									setDeepState(
										'parameters.breakdown.weightBreaks',
										options.map((option) => option.id)
									);
								}}
								contentSource={(value) =>
									value.to === Infinity
										? `${value.from.toFixed(2)} +`
										: `${value.from.toFixed(2)} - ${value.to}`
								}
								pillComponent={WeightBreakPill}
							/>
						</FormField>
					)}
					{showParameter('special-handling-code') && (
						<TextField
							value={state.parameters.breakdown.specialHandlingCodes}
							onChange={(e) => {
								// Replace all non-alphanumeric characters except ; and ,
								const cleanSphCodes = e.target.value
									.replace(/[^a-zA-Z0-9;,\s]/, '')
									.toUpperCase();
								if (
									cleanSphCodes !==
									state.parameters.breakdown.specialHandlingCodes
								) {
									setDeepState(
										'parameters.breakdown.specialHandlingCodes',
										cleanSphCodes
									);
								}
							}}
							name="special-handling-code"
							label="Special Handling Code"
							placeholder="Multiple codes can be separated by comma, semi-colon or space"
							disabled={disabledParameters['special-handling-code']}
						/>
					)}
				</Flex>
			)}
			{canFilterAnySpecialHandling && (
				<Flex direction="column" gap={16}>
					<b>Commodity/Service</b>

					{showParameter('sph-high-level') && (
						<FormField label="High-Level">
							<MultiSelect
								name="high-level"
								placeholder="Select High-Level"
								isDisabled={disabledParameters['sph-high-level']}
								options={
									worksheet.config.parameters.specialHandlingHighLevelOptions
								}
								selectedOptions={parameters.specialHandling.highLevels}
								onSelectedOptionsChange={(highLevels) =>
									setDeepState(
										'parameters.specialHandling.highLevels',
										highLevels
									)
								}
								identifierKey="value"
								contentSource={LookupSelected}
								pillComponent={LookupSearchPill}
							/>
						</FormField>
					)}

					{showParameter('commodity-mid-level') && (
						<FormField label="Commodity Mid-Level">
							<MultiSelect
								name="commodity-mid-level"
								placeholder="Select Commodity Mid-Level"
								isDisabled={disabledParameters['commodity-mid-level']}
								options={worksheet.config.parameters.commodityMidLevelOptions}
								selectedOptions={parameters.specialHandling.commodityMidLevels}
								onSelectedOptionsChange={(commodityMidLevels) =>
									setDeepState(
										'parameters.specialHandling.commodityMidLevels',
										commodityMidLevels
									)
								}
								identifierKey="value"
								contentSource={LookupSelected}
								pillComponent={LookupSearchPill}
							/>
						</FormField>
					)}
					{showParameter('service-mid-level') && (
						<FormField label="Service Mid-Level">
							<MultiSelect
								name="service-mid-level"
								placeholder="Select Service Mid-Level"
								isDisabled={disabledParameters['service-mid-level']}
								options={worksheet.config.parameters.serviceMidLevelOptions}
								selectedOptions={parameters.specialHandling.serviceMidLevels}
								onSelectedOptionsChange={(serviceMidLevels) =>
									setDeepState(
										'parameters.specialHandling.serviceMidLevels',
										serviceMidLevels
									)
								}
								identifierKey="value"
								contentSource={LookupSelected}
								pillComponent={LookupSearchPill}
							/>
						</FormField>
					)}
				</Flex>
			)}
		</WorksheetSection>
	);
};

function mergeAirlineAndCustomGroupOptions(
	airlineOptions: AirlineOption[] | null,
	webToolGroups: WorksheetConfigWebToolGroup[]
):
	| [false, null]
	| [false, AirlineOption[]]
	| [true, OptionGroup<AirlineOption>[]] {
	if (!airlineOptions) return [false, null];

	const airlineWebToolGroups = webToolGroups.filter(
		(group) => group.groupType === 'airline'
	);
	if (airlineWebToolGroups.length === 0) return [false, airlineOptions];

	return [
		true,
		[
			{
				label: 'Airlines',
				options: airlineOptions,
			},
			{
				label: 'Custom Groupings',
				options: airlineWebToolGroups.map(customGroupToAirlineOption),
			},
		],
	];
}

function LookupSelected(value: LabeledValue) {
	return <>{value.label}</>;
}

function LookupSearchPill({
	value,
	onRemove,
}: Readonly<{
	value: LabeledValue;
	onRemove: () => void;
}>) {
	return <Pill name={value.label} onRemove={onRemove} />;
}

export default WorksheetParameters;
