import { X } from 'lucide-react';
import {
	ActionFunctionArgs,
	redirect,
	useActionData,
	useNavigation,
} from 'react-router-dom';
import Button from 'ui/components/Button';
import ErrorList from 'ui/components/ErrorList';
import Grid from 'ui/components/Grid';
import PageHeader from 'ui/components/PageHeader';
import TextField from 'ui/components/TextField';
import { ToastType } from 'ui/components/Toaster/Toast';
import ValidatedForm from 'ui/components/ValidatedForm';
import { WeightBreaksField } from 'ui/forms/WeightBreaksField';
import WebToolGroupAPI from 'utils/api/WebToolGroupAPI';
import { formDataAsObject } from 'utils/formData/formData';
import { createToast } from 'utils/helpers/toast';
import { DecentralizedRouteProps } from 'utils/types/common';
import { ActionData } from 'utils/types/loaderData';
import UserAPI from '../../api/UserAPI';
import { createWebToolBreaksGroupSchema } from '../../utils/schemas/webToolBreaksGroupSchema';

const loader = async () => {
	const webToolAvailable = await UserAPI.hasWebtoolAccess();
	if (!webToolAvailable) {
		return redirect('/');
	}

	return null;
};

export const action = async ({ request }: ActionFunctionArgs) => {
	if (request.method === 'POST') {
		const formData = await request.formData();
		const createModel = createWebToolBreaksGroupSchema.parse(
			formDataAsObject(formData)
		);
		const data = await WebToolGroupAPI.createBreaksGroup(createModel);
		if ('id' in data) {
			createToast(ToastType.SUCCESS, 'Group created successfully');

			return redirect(`/webtool/groups/breaks`);
		}

		return data;
	}
	return null;
};

const WebToolGroupCreate = () => {
	const actionData = useActionData() as ActionData<typeof action>;
	const navigation = useNavigation();

	return (
		<div className="content">
			<div>
				<ValidatedForm
					action="/webtool/groups/breaks/create"
					method="post"
					validator={createWebToolBreaksGroupSchema}
				>
					<Grid>
						<PageHeader title="Create Weight Break Group">
							<Button
								variant="secondary"
								to={`/webtool/groups/breaks`}
								icon={X}
							>
								Cancel
							</Button>
							<Button
								isLoading={navigation.state !== 'idle' && !!navigation.formData}
								loadingText="Creating Group"
								type="submit"
							>
								Create Group
							</Button>
						</PageHeader>
						<Grid>
							<ErrorList error={actionData} />
							<Grid columns={2} isBox>
								<Grid>
									<TextField
										name="name"
										label="Name"
										placeholder="Enter the group name"
										isRequired
										autoFocus
									/>
									<TextField
										name="description"
										label="Description"
										placeholder="Enter the group description"
										isRequired
										autoFocus
									/>
								</Grid>
								<Grid>
									<WeightBreaksField
										name="weightBreaks"
										label="Weight Breaks"
										isRequired
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</ValidatedForm>
			</div>
		</div>
	);
};

export const WEBTOOL_BREAKS_GROUP_CREATE_ROUTE: DecentralizedRouteProps = {
	loader,
	action,
	element: <WebToolGroupCreate />,
};
