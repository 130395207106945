import classNames from 'classnames';
import { Check } from 'lucide-react';
import { ForwardedRef } from 'react';

type DropdownOptionProps<T> = {
	value: T;
	onSelect: (value: T) => void;

	isSelected: boolean;
	isActive: boolean;
	isTyping: boolean;
	isDisabled?: boolean;
	objRef?: ForwardedRef<HTMLLIElement>;

	getProps: (
		userProps?: React.HTMLProps<HTMLElement> | undefined
	) => Record<string, unknown>;

	children?: React.ReactNode;
};

const DropdownOption = <T,>({
	onSelect,
	value,
	isSelected,
	isActive: propsIsActive,
	isTyping,
	isDisabled = false,
	getProps,
	children,
	objRef,
}: DropdownOptionProps<T>) => {
	const isActive = propsIsActive && !isDisabled;

	const handleSelect = () => {
		if (!isDisabled) {
			onSelect(value);
		}
	};

	const handleKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			handleSelect();
		}

		if (event.key === ' ' && !isTyping) {
			event.preventDefault();
		}
	};

	const handleKeyUp = (event: React.KeyboardEvent) => {
		if (event.key === ' ' && !isTyping) {
			handleSelect();
		}
	};

	return (
		<li
			className={classNames(
				'dropdown__option',
				isSelected && 'dropdown__option--selected',
				isDisabled && 'dropdown__option--disabled'
			)}
			role="option"
			ref={isDisabled ? undefined : objRef}
			tabIndex={isActive ? 1 : 0}
			aria-selected={isActive}
			data-selected={isSelected}
			{...getProps({
				onClick: handleSelect,
				onKeyDown: handleKeyDown,
				onKeyUp: handleKeyUp,
			})}
		>
			<div className="dropdown__value">{children}</div>
			{isSelected && <Check className="dropdown__icon" size={18} />}
		</li>
	);
};

export default DropdownOption;
