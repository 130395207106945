import {
	FloatingPortal,
	useMergeRefs,
	useTransitionStyles,
} from '@floating-ui/react';
import classNames from 'classnames';
import { ForwardedRef, HTMLProps, forwardRef, useMemo } from 'react';
import { useTooltipContext } from './TooltipContext';

type TooltipContentProps = {
	size?: 'small' | 'medium' | 'large';
	isInteractive?: boolean;
} & Omit<HTMLProps<HTMLElement>, 'size'>;

const TooltipContent = (
	{ size, isInteractive, ...htmlProps }: TooltipContentProps,
	propRef: ForwardedRef<HTMLDivElement>
) => {
	const context = useTooltipContext();
	const ref = useMergeRefs([context.refs.setFloating, propRef]);

	const { isMounted, styles: transitionStyles } = useTransitionStyles(
		context.context,
		{
			initial: {
				opacity: 0,
				transform: 'scale(0.9)',
			},
			duration: 150,
		}
	);

	const maxWidth = useMemo(() => {
		switch (size) {
			case 'small':
				return 240;
			case 'medium':
				return 320;
			case 'large':
				return 480;
			default:
				return undefined;
		}
	}, [size]);

	if (!context.open || context.disabled) return null;

	return (
		<FloatingPortal>
			{isMounted && !context.disabled && (
				<div
					ref={ref}
					style={{
						position: context.strategy,
						top: context.y ?? 0,
						left: context.x ?? 0,
						maxWidth,
						...transitionStyles,
						...htmlProps.style,
					}}
					{...context.getFloatingProps(htmlProps)}
					className={classNames(
						'tooltip',
						`tooltip--${context.placement}`,
						isInteractive && 'tooltip--interactive'
					)}
				>
					<div className="tooltip__content">{htmlProps.children}</div>
					<div
						ref={context.arrowRef}
						style={{
							left: context.middlewareData.arrow?.x,
							top: context.middlewareData.arrow?.y,
						}}
						className="tooltip__arrow"
					/>
				</div>
			)}
		</FloatingPortal>
	);
};

export default forwardRef(TooltipContent);
