import { ENVIRONMENT_SHARED } from 'environment';
import API from 'utils/api/API';
import { AirImpactReportModel } from 'utils/schemas/airImpactReportSchema';
import { WebAnnouncementModel } from 'utils/schemas/webAnnouncementSchema';
import { Paginated } from 'utils/types/common';
import { YearAndMonth } from 'utils/types/helpers';

export type WebAnnouncementFragment = Pick<
	WebAnnouncementModel,
	'id' | 'title' | 'body' | 'releaseDate'
>;

export type StationCodeName = {
	code: string;
	name: string;
};
export type CityCodeName = {
	code: string;
	name: string;
};
export type ProvinceCodeName = {
	code: string;
	name: string;
};
export type CountryCodeName = {
	code: string;
	name: string;
};
export type RegionCodeName = {
	code: string;
	name: string;
};
export type SubscriptionLocations = {
	stations?: StationCodeName[];
	cities?: CityCodeName[];
	provinces?: ProvinceCodeName[];
	countries?: CountryCodeName[];
	regions?: RegionCodeName[];
};

export type ProfileSubscription = {
	subscriptionNumber: string;
	title: string;
	supportSubscription: boolean;
	startPeriod: YearAndMonth;
	endPeriod: YearAndMonth;
	dataGroups: ProfileSubscriptionDataGroup[];
	origin: SubscriptionLocations; // Redundant when all UI code updated to use DataGroups
	destination: SubscriptionLocations; // Redundant when all UI code updated to use DataGroups
	carriers: {
		// Redundant when all UI code updated to use DataGroups
		prefix: string;
		code: string;
		name: string;
	}[];
	freightForwarderHeadOffices: { name: string; code: string }[]; // Redundant when all UI code updated to use DataGroups
	freightForwarderGroups: { name: string }[]; // Redundant when all UI code updated to use DataGroups
};

export type ProfileSubscriptionDataGroup = {
	startPeriod: YearAndMonth;
	endPeriod: YearAndMonth;
	origin: SubscriptionLocations;
	destination: SubscriptionLocations;
	carriers: {
		prefix: string;
		code: string;
		name: string;
	}[];
	freightForwarderHeadOffices: { name: string; code: string }[];
	freightForwarderGroups: { name: string; code?: string }[];
	freightForwarderBranches: { name: string; code: string }[];
};

export type GetHomepageResponse = {
	customerName: string;
	customerNumber: string;
	customerType: string;
	activeFrom: Date;
	activeTo: Date;
	subscriptions: ProfileSubscription[];
	announcements: Paginated<WebAnnouncementFragment>;
	report: AirImpactReportModel | null;
};

class ProfileAPI extends API {
	protected static readonly apiEndpoint =
		ENVIRONMENT_SHARED.subscriptionsApiEndpoint;

	static getHomepageProfile() {
		return ProfileAPI.get<GetHomepageResponse>(`/users/homepage`);
	}
}

export default ProfileAPI;
