import { ArrowRight } from 'lucide-react';
import LocationAPI, { LocationType } from 'utils/api/LocationAPI';
import { Location } from 'utils/api/common';
import AsyncSelect from '../AsyncSelect/AsyncSelect';
import LocationPickerContent from '../LocationPicker/LocationPickerContent';

type TradelanesDropdownEditorProps = {
	from: Location | null;
	onFromChange: (value: Location | null) => void;

	to: Location | null;
	onToChange: (value: Location | null) => void;

	locationTypes: LocationType[];
	layout: 'linear' | 'stacked';
};

const ContentSource = (location: Location) => {
	return <LocationPickerContent location={location} />;
};

const TradelanesDropdownEditor = ({
	from,
	onFromChange,
	to,
	onToChange,
	locationTypes,
	layout,
}: TradelanesDropdownEditorProps) => {
	const getLocations = async (search: string) => {
		const response = await LocationAPI.getLocations(search, locationTypes);

		return response.options ?? response.groups;
	};

	return (
		<div className={`tradelanes__dropdown tradelanes__dropdown--${layout}`}>
			<AsyncSelect<Location>
				defaultOptions={[]}
				isGrouped={true}
				onSearch={getLocations}
				identifierKey="locationId"
				contentSource={ContentSource}
				previewSource={ContentSource}
				isClearable={true}
				placeholder="From"
				onOptionSelected={onFromChange}
				selectedOption={from}
			/>

			<ArrowRight className="tradelanes__dropdown-icon" size={18} />

			<AsyncSelect<Location>
				defaultOptions={[]}
				onSearch={getLocations}
				isGrouped={true}
				identifierKey="locationId"
				contentSource={ContentSource}
				previewSource={ContentSource}
				isClearable={true}
				placeholder="To"
				onOptionSelected={onToChange}
				selectedOption={to}
			/>
		</div>
	);
};

export default TradelanesDropdownEditor;
