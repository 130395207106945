type TradelanesManualEditorProps = {
	value: string;
	onChange: (value: string) => void;
	onSubmit: () => void;
	isLoading?: boolean;
};

const TradelanesManualEditor = ({
	value,
	onChange,
	onSubmit,
	isLoading,
}: TradelanesManualEditorProps) => {
	const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (e.key === 'Enter' && (e.metaKey || e.ctrlKey)) {
			e.preventDefault();
			onSubmit();
		}
	};

	return (
		<div className="tradelanes__manual">
			<textarea
				className="control__input"
				placeholder="e.g. NYC-LHR, FRA-LON"
				value={value}
				onChange={(e) => onChange(e.target.value)}
				onKeyDown={handleKeyDown}
				disabled={isLoading}
			/>
		</div>
	);
};

export default TradelanesManualEditor;
