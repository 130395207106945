import { createColumnHelper } from '@tanstack/react-table';
import BulletIndicator from 'ui/components/BulletIndicator';
import { ReferenceAirport } from '../../api/ReferenceDataAPI';

const columnHelper = createColumnHelper<ReferenceAirport>();

export const codeColumn = columnHelper.accessor('airportCode', {
	header: 'Code',
	enableSorting: true,
	enableMultiSort: false,
});

export const nameColumn = columnHelper.accessor('airportName', {
	header: 'Name',
	enableSorting: true,
	enableMultiSort: false,
});

export const cityCodeColumn = columnHelper.accessor('cityCode', {
	header: 'City Code',
});

export const cityNameColumn = columnHelper.accessor('cityName', {
	header: 'City Name',
});

export const provinceNameColumn = columnHelper.accessor('provinceName', {
	header: 'Province/State Name',
});

export const provinceCodeColumn = columnHelper.accessor('provinceCode', {
	header: 'Province/State Code',
});

export const latitudeColumn = columnHelper.accessor('latitude', {
	header: 'Latitude',
});

export const longitudeColumn = columnHelper.accessor('longitude', {
	header: 'Longitude',
});

export const countryCodeColumn = columnHelper.accessor('countryCode', {
	header: 'Country Code',
});

export const countryNameColumn = columnHelper.accessor('countryName', {
	header: 'Country Name',
});

export const globalRegionColumn = columnHelper.accessor('globalRegion', {
	header: 'Global Region',
});

export const isActiveColumn = columnHelper.accessor('active', {
	header: 'Status',
	cell: (info) => {
		return (
			<BulletIndicator
				intent={info.getValue() ? 'success' : 'error'}
				label={info.getValue() ? 'Active' : 'Inactive'}
			/>
		);
	},
});
