import { RefObject, useEffect, useMemo, useState } from 'react';

export default (ref: RefObject<HTMLElement>) => {
	const [isScrollbarVisible, setScrollbarVisible] = useState(
		ref.current ? ref.current.scrollHeight > ref.current.clientHeight : true
	);

	const resizeObserver = useMemo(() => {
		return new ResizeObserver(() => {
			if (!ref.current) return;

			const scrollbarVisible =
				ref.current.scrollHeight > ref.current.clientHeight;
			if (isScrollbarVisible === scrollbarVisible) return;

			setScrollbarVisible(scrollbarVisible);
		});
	}, []);

	useEffect(() => {
		if (ref.current) {
			resizeObserver.observe(ref.current);
		}

		return () => {
			resizeObserver.disconnect();
		};
	}, []);

	return isScrollbarVisible;
};
