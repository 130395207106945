import { RefreshCcw, Search } from 'lucide-react';
import { useState } from 'react';
import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import Button from 'ui/components/Button';
import DateField from 'ui/components/DateField/DateField';
import YearMonthPicker from 'ui/components/DatePicker/YearMonthPicker';
import FormField from 'ui/components/FormField';
import LocationPicker from 'ui/components/LocationPicker/LocationPicker';
import MultiSelectField from 'ui/components/MultiSelectField';
import PageHeader from 'ui/components/PageHeader';
import Pagination from 'ui/components/Pagination';
import Sidebar from 'ui/components/Sidebar';
import Table from 'ui/components/Table';
import ValidatedForm from 'ui/components/ValidatedForm';
import { LocationType } from 'utils/api/LocationAPI';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import ReportHubAPI, { BipReport } from '../../api/ReportHubAPI';
import BipReportDetailsModal from '../../components/BipReportDetailsModal';
import * as reportColumns from '../../utils/columns/reportHubBipListColumns';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	return ReportHubAPI.getBipList(new URL(request.url).searchParams);
};

export function ReportHubBipListPage() {
	const data = useLoaderData() as LoaderData<typeof loader>;
	const [selectedReport, setSelectedReport] = useState<BipReport | null>(null);

	const columns = [
		reportColumns.productNameColumn,
		reportColumns.reportMonthColumn,
		reportColumns.createdAtColumn,
		reportColumns.attachmentColumn(setSelectedReport),
		reportColumns.originsColumn,
		reportColumns.destinationsColumn,
	];

	return (
		<Sidebar.Wrapper>
			<div className="content">
				<PageHeader title="BIP Exports" />

				<Table identifierKey="id" columns={columns} data={data.reports.items} />

				{data.reports.totalCount > 0 && (
					<Pagination
						baseUrl={new URL(window.location.href)}
						page={data.reports.page}
						pageParameterName="page"
						pageSize={data.reports.pageSize}
						itemCount={data.reports.totalCount}
					/>
				)}
			</div>
			<Sidebar title="Report Filter">
				<ValidatedForm method="get" resetOnNavigation>
					<MultiSelectField
						label="Report Type"
						name="productTypes"
						options={data.productOptions}
						contentSource={(option) => option.name}
						identifierKey="productType"
						placeholder="Please select..."
						pillName={(item) => item.name}
					/>

					<LocationPicker
						label="Origin"
						name="origins"
						initialValues={data.originCountriesSelected}
						isMulti={true}
						locationTypes={[LocationType.COUNTRY]}
					/>

					<LocationPicker
						label="Destination"
						name="destinations"
						initialValues={data.destinationCountriesSelected}
						isMulti={true}
						locationTypes={[LocationType.COUNTRY]}
					/>

					<FormField label="From Report Date">
						<YearMonthPicker name="fromReportDate" />
					</FormField>

					<FormField label="To Report Date">
						<YearMonthPicker name="toReportDate" />
					</FormField>

					<DateField
						label="From Creation Date"
						name="fromCreationDate"
						timezone="utc"
					/>
					<DateField
						label="To Creation Date"
						name="toCreationDate"
						timezone="utc"
					/>

					<Sidebar.Actions>
						<Button variant="secondary" icon={RefreshCcw} type="reset">
							Clear search
						</Button>
						<Button variant="primary" icon={Search} type="submit">
							Search
						</Button>
					</Sidebar.Actions>
				</ValidatedForm>
			</Sidebar>
			<BipReportDetailsModal
				report={selectedReport}
				isOpen={!!selectedReport}
				onClose={() => setSelectedReport(null)}
			/>
		</Sidebar.Wrapper>
	);
}

export const REPORT_HUB_BIP_LIST_ROUTE: DecentralizedRouteProps = {
	loader: loader,
	element: <ReportHubBipListPage />,
};
