import { ToastType } from 'ui/components/Toaster/Toast';
import { createToast } from './toast';

export const catchSilently = (error: Error) => {
	console.error(error);
};

export const catchWithError = (error: Error) => {
	createToast(ToastType.ERROR, error.message);
	console.error(error);
};

export const catchWithMessage = (message: string) => (error: Error) => {
	createToast(ToastType.ERROR, message);
	console.error(error);
};
