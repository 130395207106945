import React, { useMemo } from 'react';

type NumberFragmentProps = {
	value?: number;
	emptyText?: string;
	suffix?: string;
	decimalPlaces?: number;
	minDigits?: number;
};

const NumberFragment = ({
	value,
	emptyText = '–',
	suffix,
	decimalPlaces,
	minDigits,
}: NumberFragmentProps) => {
	const isEmpty = value === null || value === undefined || isNaN(value);

	const formattedValue = useMemo(() => {
		if (isEmpty) return emptyText;

		let formattedNumber = value.toLocaleString(undefined, {
			minimumFractionDigits: decimalPlaces,
			maximumFractionDigits: decimalPlaces,
			minimumIntegerDigits: minDigits,
		});

		if (suffix) {
			formattedNumber += suffix;
		}

		return formattedNumber;
	}, [value, decimalPlaces, emptyText, suffix]);

	return <>{formattedValue}</>;
};

export default React.memo(NumberFragment);
