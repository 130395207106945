import { ArrowLeft, ArrowLeftRight, ArrowRight, X } from 'lucide-react';
import { ReactNode, useMemo } from 'react';

type DirectionalPillProps = {
	direction?: 'ltr' | 'rtl' | 'both';
	left: string | ReactNode;
	leftTitle?: string;
	right?: string | ReactNode;
	rightTitle?: string;
	title?: string;
	onRemove?: () => void;
};

const DirectionalPill = ({
	left,
	leftTitle,
	right,
	rightTitle,
	direction = 'ltr',
	title,
	onRemove,
}: DirectionalPillProps) => {
	const Icon = useMemo(() => {
		if (direction === 'ltr') return ArrowRight;
		if (direction === 'rtl') return ArrowLeft;
		return ArrowLeftRight;
	}, [direction]);

	return (
		<div className="directional-pill" title={title}>
			<div className="directional-pill__content">
				<div className="directional-pill__left" title={leftTitle}>
					{left}
				</div>
				{right && (
					<>
						<div className="directional-pill__icon">
							<Icon size={14} />
						</div>
						<div className="directional-pill__right" title={rightTitle}>
							{right}
						</div>
					</>
				)}
			</div>
			{onRemove && (
				<div
					className="directional-pill__remove"
					onClick={onRemove}
					role="button"
					tabIndex={0}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							onRemove();
						}
					}}
				>
					<X size={14} />
				</div>
			)}
		</div>
	);
};

export default DirectionalPill;
