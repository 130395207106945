import { RouteProps, redirect } from 'react-router-dom';

const loader = async () => {
	if (window.msal.redirectResult === undefined) {
		return redirect('/');
	} else {
		// MSAL will be doing the redirecting
		return null;
	}
};

export default function LoggedInPage() {
	return null;
}

export const LOGGED_IN_ROUTE: RouteProps = {
	element: <LoggedInPage />,
	loader,
};
