import { AlertCircle } from 'lucide-react';
import { useMemo } from 'react';
import { useAsyncError } from 'react-router-dom';
import APIError from 'utils/errors/APIError';

type WebToolWorksheetErrorProps = {
	error?: APIError;
};

const WebToolWorksheetError = ({
	error: propsError,
}: WebToolWorksheetErrorProps) => {
	const asyncError = useAsyncError();
	const error = propsError ?? asyncError;

	const is404 = error instanceof APIError && error.status === 404;

	const errorTitle = useMemo(() => {
		if (is404) {
			return 'Worksheet not found';
		}

		return 'An error occured';
	}, [error]);

	const errorMessage = useMemo(() => {
		if (is404) {
			return 'The worksheet you are looking for does not exist.';
		}

		return 'Please try reloading the worksheet or try again later. If the problem persists, please contact us.';
	}, [error]);

	return (
		<div className="webtool-worksheet-error">
			<AlertCircle className="webtool-worksheet-error__icon" size={24} />
			<h1 className="webtool-worksheet-error__title">{errorTitle}</h1>
			<p className="webtool-worksheet-error__message">{errorMessage}</p>
		</div>
	);
};

export default WebToolWorksheetError;
