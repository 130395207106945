import { createColumnHelper } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import DateFragment from 'ui/components/DateFragment';
import TradelaneList from 'ui/components/TradelaneList';
import { WebToolTradelaneGroupItem } from 'utils/api/WebToolGroupAPI';

const columnHelper = createColumnHelper<WebToolTradelaneGroupItem>();

export const createdAtColumn = columnHelper.accessor('createdAt', {
	header: 'Created Date',
	cell: (info) => <DateFragment includeTime={true} date={info.getValue()} />,
	enableSorting: false,
});

export const nameColumn = columnHelper.accessor('name', {
	header: 'Name',
	cell: (info) => (
		<Link to={`/webtool/groups/trade/${info.row.original.id}`}>
			{info.getValue()}
		</Link>
	),
});

export const descriptionColumn = columnHelper.accessor('description', {
	header: 'Description',
});

export const tradelanesColumn = columnHelper.display({
	id: 'stations',
	header: 'Trade Lanes',
	cell: (item) => (
		<TradelaneList tradelanes={item.row.original.tradeLanes} emptyText="-" />
	),
});

export const conflictsColumn = columnHelper.display({
	id: 'conflicts',
	header: 'Trade Lane Overlaps',
	cell: (item) =>
		item.row.original.conflicts.map((conf, i) => (
			<>
				{i !== 0 ? ', ' : ''}
				<span> {conf.name} </span>
			</>
		)),
});
