import { addMinutes, set } from 'date-fns';
import { mod } from './math';

export const LEAP_YEAR = 2024;

type AbstractDate = {
	year?: number;
	month?: number;
	date?: number;
	hours?: number;
	minutes?: number;
};

export const abstractLocalToUtc = <T extends AbstractDate>(
	abstractDate: T
): T => {
	const {
		month = 1,
		date = 1,
		hours = 0,
		minutes = 0,
		year = 2024, // Default to leap year
	} = abstractDate;

	const localDate = set(new Date(), {
		year,
		month: month - 1,
		date,
		hours,
		minutes,
		seconds: 0,
		milliseconds: 0,
	});

	const utcObject: AbstractDate = {
		hours: localDate.getUTCHours(),
		minutes: localDate.getUTCMinutes(),
		month: localDate.getUTCMonth() + 1,
		date: localDate.getUTCDate(),
		year: localDate.getUTCFullYear(),
	};

	// Only return the properties that were initially supplied
	return Object.keys(abstractDate).reduce((acc, key) => {
		const typedKey = key as keyof AbstractDate;
		acc[typedKey] = utcObject[typedKey];
		return acc;
	}, {} as T);
};

export const abstractUtcToLocal = <T extends AbstractDate>(
	abstractDate: T
): T => {
	const {
		month = 1,
		date = 1,
		hours = 0,
		minutes = 0,
		year = 2024, // Default to leap year
	} = abstractDate;

	const utcInLocalDate = set(new Date(), {
		year,
		month: month - 1,
		date,
		hours,
		minutes,
		seconds: 0,
		milliseconds: 0,
	});

	const offset = utcInLocalDate.getTimezoneOffset();
	const localDate = addMinutes(utcInLocalDate, -offset);

	const localObject: AbstractDate = {
		hours: localDate.getHours(),
		minutes: localDate.getMinutes(),
		month: localDate.getMonth() + 1,
		date: localDate.getDate(),
		year: localDate.getFullYear(),
	};

	// Only return the properties that were initially supplied
	return Object.keys(abstractDate).reduce((acc, key) => {
		const typedKey = key as keyof AbstractDate;
		acc[typedKey] = localObject[typedKey];
		return acc;
	}, {} as T);
};

// Generic helpers

export const getUTCOffsetInHours = () => {
	return new Date().getTimezoneOffset() / 60;
};

export const getDayOffsetForLocalHour = (time: number) => {
	const utcOffset = getUTCOffsetInHours();
	const utcHour = localHourToUTC(time);

	return -1 * Math.floor((utcHour - utcOffset) / 24);
};

// Modulo helpers

export const modHours = (hours: number) => mod(hours, 24);
export const modMinutes = (minutes: number) => mod(minutes, 60);
export const modDaysOfWeek = (days: number) => mod(days - 1, 7) + 1;
export const modDaysOfMonth = (days: number) => mod(days - 1, 31) + 1;
export const modMonths = (months: number) => mod(months - 1, 12) + 1;

// Hour conversion helpers

export const localHourToUTC = (hours: number) => {
	const utcOffset = getUTCOffsetInHours();
	const utcHour = modHours(hours + utcOffset);

	return utcHour;
};

export const utcHourToLocal = (hours: number) => {
	const utcOffset = getUTCOffsetInHours();
	const localHour = modHours(hours - utcOffset);

	return localHour;
};
