import {
	FloatingFocusManager,
	FloatingNode,
	FloatingPortal,
	autoUpdate,
	flip,
	offset,
	safePolygon,
	shift,
	useClick,
	useDismiss,
	useFloating,
	useFloatingNodeId,
	useFloatingParentNodeId,
	useFloatingTree,
	useHover,
	useInteractions,
	useListNavigation,
	useMergeRefs,
	useRole,
	useTypeahead,
} from '@floating-ui/react';

import { LucideIcon } from 'lucide-react';

import React, {
	ForwardedRef,
	PropsWithChildren,
	ReactElement,
	cloneElement,
	forwardRef,
	isValidElement,
	memo,
	useEffect,
	useRef,
	useState,
} from 'react';

import MenuItem from './MenuItem';
import MenuTrigger from './MenuTrigger';

enum TreeEvents {
	CLICK = 'click',
	MENU_OPEN = 'menuopen',
}

export type MenuListProps = PropsWithChildren<{
	label: string;
	icon?: LucideIcon;
	isOpen?: boolean;
	onOpenChange?: (open: boolean) => void;
	alignment?: 'start' | 'end';
	closeOnClick?: boolean;
}>;

const MenuList = (
	{
		children,
		label,
		icon: MenuIcon,
		isOpen: controlledOpen,
		onOpenChange: controlledSetOpen,
		closeOnClick = true,
		...props
	}: MenuListProps,
	forwardedRef: ForwardedRef<HTMLButtonElement>
) => {
	const parentId = useFloatingParentNodeId();
	const isNested = parentId != null;

	const [uncontrolledIsOpen, uncontrolledSetOpen] = useState(false);
	const [activeIndex, setActiveIndex] = useState<number | null>(null);
	const [allowHover, setAllowHover] = useState(false);

	const isOpen = controlledOpen ?? uncontrolledIsOpen;
	const setOpen = controlledSetOpen ?? uncontrolledSetOpen;

	const listItemsRef = useRef<Array<HTMLButtonElement | null>>([]);
	const listContentRef = useRef(
		React.Children.map(children, (child) =>
			isValidElement(child) ? child.props.label : null
		) as Array<string | null>
	);

	const tree = useFloatingTree();
	const nodeId = useFloatingNodeId();

	const triggerChild = React.Children.toArray(children).find(
		(child) => isValidElement(child) && child.type === MenuTrigger
	) as React.ReactElement | undefined;

	if (triggerChild == null && !isNested) {
		throw new Error('MenuList must contain a MenuTrigger component');
	} else if (triggerChild != null && isNested) {
		throw new Error('Nested MenuLists cannot contain a MenuTrigger component');
	}

	const remainingChildren = React.Children.toArray(children).filter(
		(child) => isValidElement(child) && child.type !== MenuTrigger
	);

	const { refs, context, floatingStyles } = useFloating<HTMLButtonElement>({
		nodeId,
		open: isOpen,
		onOpenChange: setOpen,
		placement: isNested
			? 'right-start'
			: `bottom-${props.alignment ?? 'start'}`,
		middleware: [
			offset({
				mainAxis: isNested ? 0 : 4,
				alignmentAxis: isNested ? -4 : 0,
			}),
			flip(),
			shift(),
		],
		whileElementsMounted: autoUpdate,
	});

	const hover = useHover(context, {
		enabled: isNested && allowHover,
		delay: { open: 50 },
		handleClose: safePolygon({
			blockPointerEvents: true,
		}),
	});

	const click = useClick(context, {
		event: 'mousedown',
		toggle: !isNested || !allowHover,
		ignoreMouse: isNested,
	});

	const role = useRole(context, { role: 'menu' });
	const dismiss = useDismiss(context);

	const listNavigation = useListNavigation(context, {
		listRef: listItemsRef,
		activeIndex,
		nested: isNested,
		onNavigate: setActiveIndex,
	});

	const typeahead = useTypeahead(context, {
		enabled: isOpen,
		listRef: listContentRef,
		onMatch: isOpen ? setActiveIndex : undefined,
		activeIndex,
	});

	const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
		[hover, click, role, dismiss, listNavigation, typeahead]
	);

	useEffect(() => {
		if (!tree) return;

		// If an item is clicked, close all menus.
		function handleTreeClick() {
			if (closeOnClick) {
				setOpen(false);
			}
		}

		// If a different menu on the same level as this one is opened, close this menu.
		function handleSubMenuOpen(event: { nodeId: string; parentId: string }) {
			if (event.nodeId !== nodeId && event.parentId === parentId) {
				setOpen(false);
			}
		}

		tree.events.on(TreeEvents.CLICK, handleTreeClick);
		tree.events.on(TreeEvents.MENU_OPEN, handleSubMenuOpen);

		return () => {
			tree.events.off(TreeEvents.CLICK, handleTreeClick);
			tree.events.off(TreeEvents.MENU_OPEN, handleSubMenuOpen);
		};
	}, [tree, nodeId, parentId, closeOnClick]);

	useEffect(() => {
		if (isOpen && tree) {
			tree.events.emit(TreeEvents.MENU_OPEN, { parentId, nodeId });
		}
	}, [tree, isOpen, nodeId, parentId]);

	useEffect(() => {
		// We track whether hover logic is enabled in state so that
		// we can act accordingly, e.g. to prevent focus race when
		// the cursor hovers over a menu item while keyboard navigating.

		// Enable hover on cursor move
		function onPointerMove({ pointerType }: PointerEvent) {
			if (pointerType !== 'touch') {
				setAllowHover(true);
			}
		}

		// Disable hover on keydown
		function onKeyDown() {
			setAllowHover(false);
		}

		// Capture these events globally
		window.addEventListener('pointermove', onPointerMove, {
			once: true,
			capture: true,
		});

		window.addEventListener('keydown', onKeyDown, true);

		return () => {
			window.removeEventListener('pointermove', onPointerMove, {
				capture: true,
			});

			window.removeEventListener('keydown', onKeyDown, true);
		};
	}, [allowHover]);

	const referenceRef = useMergeRefs([refs.setReference, forwardedRef]);
	const referenceProps = getReferenceProps({
		...props,
		onClick(event) {
			event.stopPropagation();
			event.preventDefault();
		},
		role: isNested ? 'menuitem' : 'button',
	});

	return (
		<FloatingNode id={nodeId}>
			{isNested ? (
				<MenuItem
					ref={referenceRef}
					{...referenceProps}
					icon={MenuIcon}
					label={label}
					showArrow
				/>
			) : (
				cloneElement(triggerChild as ReactElement, {
					...referenceProps,
					ref: referenceRef,
					'data-open': isOpen ? '' : undefined,
				})
			)}
			<FloatingPortal>
				{isOpen && (
					<FloatingFocusManager
						context={context}
						modal={false}
						// Only the root menu should have initial focus
						initialFocus={isNested ? -1 : 0}
						// Only the root menu should return focus to the trigger.
						returnFocus={!isNested}
					>
						<div
							ref={refs.setFloating}
							className="menu__list"
							style={floatingStyles}
							{...getFloatingProps()}
						>
							{remainingChildren.map(
								(child, index) =>
									isValidElement(child) &&
									cloneElement(
										child,
										getItemProps({
											tabIndex: activeIndex === index ? 0 : -1,
											className: child.props.className,
											ref(node: HTMLButtonElement) {
												listItemsRef.current[index] = node;
											},
											onClick(event) {
												child.props.onClick?.(event);
												tree?.events.emit(TreeEvents.CLICK);
											},
											onMouseEnter() {
												if (allowHover && isOpen) {
													setActiveIndex(index);
												}
											},
										})
									)
							)}
						</div>
					</FloatingFocusManager>
				)}
			</FloatingPortal>
		</FloatingNode>
	);
};

export default memo(forwardRef(MenuList));
