import { ENVIRONMENT_SHARED } from 'environment';
import { ExternalLink } from 'lucide-react';
import { Toaster } from 'react-hot-toast';
import Button from '../../components/Button';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import TransitionSpinner from '../../components/TransitionSpinner';

const NotAuthenticatedPage = () => {
	return (
		<>
			<div className="page-wrapper">
				<Header navigationItems={[]} showNavigation={false} />
				<main>
					<div className="landing">
						<div className="landing__content">
							<div className="landing__box">
								<h1 className="landing__title">Not authorized</h1>
								<div className="landing__details">
									<div className="landing__text">
										<p>
											You are not authorized. Please refer to FAQ page on the
											IATA Customer Portal Link.
										</p>
									</div>
								</div>
								<div className="landing__signin">
									<Button
										icon={ExternalLink}
										href={ENVIRONMENT_SHARED.contactUsUrl}
										variant="primary"
									>
										Go to IATA Customer Portal
									</Button>
								</div>
							</div>
						</div>
					</div>
				</main>
				<Footer hideAuthenticatedLinks />
			</div>
			<Toaster />
			<TransitionSpinner />
		</>
	);
};

export default NotAuthenticatedPage;
