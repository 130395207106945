import {
	LoaderFunctionArgs,
	useLoaderData,
	useLocation,
} from 'react-router-dom';
import Flex from 'ui/components/Flex/Flex';
import PageHeader from 'ui/components/PageHeader';
import Pagination from 'ui/components/Pagination';
import Table from 'ui/components/Table';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import ReportHubAPI from '../../api/ReportHubAPI';
import * as reportColumns from '../../utils/columns/reportHubWebToolListColumns';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);

	const webToolReports = await ReportHubAPI.getWebToolList(
		new URL(request.url).searchParams
	);

	return {
		webToolReports,
	};
};

export function ReportHubWebToolListPage() {
	const { webToolReports } = useLoaderData() as LoaderData<typeof loader>;
	const location = useLocation();

	// Creating a key for the table so the checkbox selection is persisted across pages but not when new search filters are applied
	const searchParams = new URLSearchParams(location.search);
	searchParams.delete('page');
	const tableKey = searchParams.toString();

	const columns = [
		reportColumns.titleColumn,
		reportColumns.statusColumn,
		reportColumns.executedAtColumn,
		reportColumns.detailsColumn,
		reportColumns.attachmentWebToolColumn,
	];

	return (
		<div className="content">
			<Flex direction="column" gap={48}>
				<Flex direction="column" gap={24}>
					<PageHeader title="Web Tool Reports" />

					<Table
						identifierKey="id"
						key={tableKey}
						columns={columns}
						data={webToolReports.items}
					/>

					{webToolReports.totalCount > 0 && (
						<Pagination
							baseUrl={new URL(window.location.href)}
							page={webToolReports.page}
							pageParameterName="page"
							pageSize={webToolReports.pageSize}
							itemCount={webToolReports.totalCount}
						/>
					)}
				</Flex>
			</Flex>
		</div>
	);
}

export const REPORT_HUB_WEB_TOOL_LIST_ROUTE: DecentralizedRouteProps = {
	loader: loader,
	element: <ReportHubWebToolListPage />,
};
