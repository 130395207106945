import {
	format,
	getDaysInMonth,
	set,
	startOfMonth,
	startOfYear,
} from 'date-fns';
import { useMemo, useState } from 'react';
import Flex from 'ui/components/Flex';
import Select from 'ui/components/Select';
import { LEAP_YEAR } from 'utils/helpers/timezone';
import { LabeledValue } from 'utils/types/common';

export type MonthAndDay = {
	month: number;
	day: number;
};

type MonthDayPickerProps = {
	value?: MonthAndDay;
	onChange?: (value: MonthAndDay) => void;
	initialValue?: MonthAndDay;
	name?: string;

	monthFormat?: string;
	dayFormat?: string;
};

const MonthDayPicker = ({
	value: propsValue,
	onChange,
	initialValue,
	name,

	monthFormat = 'MMMM',
	dayFormat = 'do',
}: MonthDayPickerProps) => {
	const [stateValue, setStateValue] = useState<MonthAndDay | undefined>(
		initialValue
	);

	const value = propsValue ?? stateValue;

	const leapYear = useMemo(() => {
		return startOfYear(new Date(LEAP_YEAR, 0));
	}, []);

	const monthOptions = useMemo(() => {
		const options: Array<LabeledValue<number>> = [];

		for (let i = 0; i < 12; i++) {
			const monthDate = set(startOfMonth(new Date()), { month: i });

			options.push({
				value: i + 1,
				label: format(monthDate, monthFormat),
			});
		}

		return options;
	}, []);

	const dayOptions = useMemo(() => {
		const options: Array<LabeledValue<number>> = [];

		for (let i = 0; i < 31; i++) {
			const dayDate = set(startOfYear(new Date()), { month: 0, date: i + 1 });

			options.push({
				value: i + 1,
				label: format(dayDate, dayFormat),
			});
		}

		return options;
	}, []);

	const getMaxDaysInMonth = (month: number) => {
		const monthDate = set(leapYear, { month: month - 1, date: 1 });

		return getDaysInMonth(monthDate);
	};

	const handleDayChange = (day: LabeledValue<number> | null) => {
		if (!day) return;

		onChange?.({
			month: value?.month ?? 1,
			day: day.value,
		});

		setStateValue({
			month: value?.month ?? 1,
			day: day.value,
		});
	};

	const handleMonthChange = (month: LabeledValue<number> | null) => {
		if (!month) return;

		const dayValue =
			value?.day && value.day <= getMaxDaysInMonth(month.value) ? value.day : 1;

		onChange?.({
			month: month.value,
			day: dayValue,
		});

		setStateValue({
			month: month.value,
			day: dayValue,
		});
	};

	return (
		<Flex gap={16}>
			<Select
				options={monthOptions}
				contentSource="label"
				identifierKey="value"
				isClearable={false}
				initialValue={
					initialValue
						? monthOptions.find((option) => option.value === initialValue.month)
						: undefined
				}
				selectedOption={
					value
						? monthOptions.find((option) => option.value === value.month)
						: undefined
				}
				onOptionSelected={handleMonthChange}
			/>

			<Select
				options={dayOptions}
				contentSource="label"
				identifierKey="value"
				isClearable={false}
				initialValue={
					initialValue
						? dayOptions.find((option) => option.value === initialValue.day)
						: undefined
				}
				selectedOption={
					value
						? dayOptions.find((option) => option.value === value.day)
						: undefined
				}
				optionDisabledSource={(option) => {
					if (!value) return false;

					const monthDate = set(leapYear, { month: value.month - 1, date: 1 });
					const daysInMonth = getDaysInMonth(monthDate);

					return option.value > daysInMonth;
				}}
				onOptionSelected={handleDayChange}
			/>
		</Flex>
	);
};

export default MonthDayPicker;
