import { ToastHint as ToastHintType } from 'utils/helpers/toast';

type ToastHintProps = {
	hint: ToastHintType;
};

const ToastHint = ({ hint }: ToastHintProps) => {
	const isArray = Array.isArray(hint);
	const isEmpty = isArray ? hint.length === 0 : hint === '';

	if (isEmpty) {
		return null;
	}

	// If hint is an array, render a HTML list
	if (isArray) {
		return (
			<ul className="toast__hint">
				{hint.map((item, index) => (
					<li key={index}>{item}</li>
				))}
			</ul>
		);
	} else {
		return <div className="toast__hint">{hint}</div>;
	}
};

export default ToastHint;
