import classNames from 'classnames';
import {
	ChevronLeft,
	ChevronRight,
	ChevronsLeft,
	ChevronsRight,
} from 'lucide-react';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';

type DatePickerHeaderProps = ReactDatePickerCustomHeaderProps;

const DatePickerHeader = ({
	customHeaderCount,

	nextYearButtonDisabled,
	prevYearButtonDisabled,
	increaseYear,
	decreaseYear,

	nextMonthButtonDisabled,
	prevMonthButtonDisabled,
	increaseMonth,
	decreaseMonth,

	changeMonth,
	changeYear,

	monthDate,
	date,
}: DatePickerHeaderProps) => {
	const currentDate = new Date();
	const showTodayJumpLink =
		currentDate.getMonth() !== monthDate.getMonth() ||
		currentDate.getFullYear() !== monthDate.getFullYear();

	const showJumpLinks = customHeaderCount === 0 && showTodayJumpLink;

	const jumpToToday = () => {
		changeMonth(currentDate.getMonth());
		changeYear(currentDate.getFullYear());
	};

	return (
		<div className="datepicker__header">
			<div
				className={classNames(
					'datepicker__actions'
					// customHeaderCount === 1 && 'datepicker__actions--hidden'
				)}
			>
				<button
					type="button"
					className="datepicker__action datepicker__action--previous-year"
					onClick={decreaseYear}
					disabled={prevYearButtonDisabled}
				>
					<ChevronsLeft size={16} />
				</button>
				<button
					type="button"
					className="datepicker__action datepicker__action--previous-month"
					onClick={decreaseMonth}
					disabled={prevMonthButtonDisabled}
				>
					<ChevronLeft size={16} />
				</button>
			</div>

			<div
				className={classNames(
					'datepicker__info',
					showJumpLinks && 'datepicker__info--has-jump-links'
				)}
			>
				<div className="datepicker__current">
					<span className="datepicker__current-month">
						{monthDate.toLocaleString('en-US', {
							month: 'long',
						})}
					</span>
					&nbsp;
					<span className="datepicker__current-year">
						{monthDate.getFullYear()}
					</span>
				</div>

				{showJumpLinks && (
					<div className="datepicker__jump-links">
						<button
							type="button"
							className="datepicker__jump-link"
							onClick={jumpToToday}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									jumpToToday();
								}
							}}
						>
							Jump to today
						</button>
					</div>
				)}
			</div>

			<div
				className={classNames(
					'datepicker__actions'
					// customHeaderCount === 0 && 'datepicker__actions--hidden'
				)}
			>
				<button
					type="button"
					className="datepicker__action datepicker__action--next-month"
					onClick={increaseMonth}
					disabled={nextMonthButtonDisabled}
				>
					<ChevronRight size={16} />
				</button>
				<button
					type="button"
					className="datepicker__action datepicker__action--next-year"
					onClick={increaseYear}
					disabled={nextYearButtonDisabled}
				>
					<ChevronsRight size={16} />
				</button>
			</div>
		</div>
	);
};

export default DatePickerHeader;
