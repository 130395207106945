import { LucideIcon } from 'lucide-react';
import { useMemo } from 'react';
import NavigationItem from './NavigationItem';

type SharedNavigationProperties<Role> = {
	allowedRoles?: Role[];
	isVisible?: boolean;
};

export type NavigationChildItem<Role> = {
	name: string;
	description?: string;
	path: string;
	icon: LucideIcon;
	onClick?: () => void;
	isExternal?: boolean;
} & SharedNavigationProperties<Role>;

export type NavigationChildSection<Role> = {
	name?: string;
	items: NavigationChildItem<Role>[];
} & SharedNavigationProperties<Role>;

export type NavigationRootItem<Role> = {
	name: string;
	path?: string;
	sections?: NavigationChildSection<Role>[];
	onClick?: () => void;
} & SharedNavigationProperties<Role>;

type NavigationProps<Roles extends string> = {
	items: NavigationRootItem<Roles>[];
	activeRole: Roles;
};

const isSharedNavigationItemVisible = <Role extends string>(
	item: SharedNavigationProperties<Role>,
	activeRole: Role
) => {
	if (item.isVisible === false) {
		return false;
	}

	if (item.allowedRoles && !item.allowedRoles.includes(activeRole)) {
		return false;
	}

	return true;
};

const Navigation = <Role extends string>({
	items,
	activeRole,
}: NavigationProps<Role>) => {
	// Create new array of items that are allowed for the active role
	// This needs to include nested items and sections
	const filteredItems = useMemo(() => {
		return items
			.map((item) => {
				if (!isSharedNavigationItemVisible(item, activeRole)) return null;

				if (item.sections) {
					const filteredSections = item.sections
						.map((section) => {
							if (!isSharedNavigationItemVisible(section, activeRole))
								return null;

							const filteredItems = section.items.filter((item) =>
								isSharedNavigationItemVisible(item, activeRole)
							);

							return {
								...section,
								items: filteredItems,
							};
						})
						.filter(
							(section): section is NavigationChildSection<Role> =>
								!!section && section.items.length > 0
						);

					return {
						...item,
						sections: filteredSections,
					};
				}

				return item;
			})
			.filter((item): item is NavigationRootItem<Role> => !!item);
	}, [items, activeRole]);

	return (
		<nav className="navigation">
			<ul role="list" className="navigation__items">
				{filteredItems.map((item, itemIndex) => (
					<NavigationItem key={itemIndex} item={item} />
				))}
			</ul>
		</nav>
	);
};

export default Navigation;
