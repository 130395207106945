import { PropsWithChildren } from 'react';

type DropdownGroupProps = PropsWithChildren<{}>;

const DropdownGroup = ({ children }: DropdownGroupProps) => {
	return (
		<li className="dropdown__group" role="group">
			<ul>{children}</ul>
		</li>
	);
};

export default DropdownGroup;
