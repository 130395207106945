import classNames from 'classnames';
import {
	AlertCircle,
	CheckCircle,
	Loader2,
	LucideIcon,
	X,
	XCircle,
} from 'lucide-react';
import { Toast as HotToast, toast } from 'react-hot-toast';
import { ToastHint as ToastHintType, ToastMessage } from 'utils/helpers/toast';
import ToastHint from './ToastHint';

export enum ToastType {
	BLANK = 'blank',
	SUCCESS = 'success',
	WARNING = 'warning',
	ERROR = 'error',
	LOADING = 'loading',
}

export type ToastAction = {
	label?: string;
	icon?: LucideIcon;
	onClick: () => void;
	shouldDismiss?: boolean;
};

type ToastProps = {
	hint?: ToastHintType;
	actions?: ToastAction[];
	message: ToastMessage;
	type: ToastType;
	rawToast: HotToast;
	canDismiss?: boolean;
};

const Toast = ({
	message,
	hint,
	type,
	actions,
	rawToast,
	canDismiss = true,
}: ToastProps) => {
	const iconMap: { [key: string]: LucideIcon } = {
		[ToastType.SUCCESS]: CheckCircle,
		[ToastType.ERROR]: XCircle,
		[ToastType.LOADING]: Loader2,
		[ToastType.WARNING]: AlertCircle,
	};

	const ToastIcon = type in iconMap ? iconMap[type] : null;

	return (
		<div
			className={classNames(
				'toast',
				`toast--${type}`,
				rawToast.visible && `toast--visible`
			)}
			{...rawToast.ariaProps}
		>
			{ToastIcon && (
				<ToastIcon className={classNames('toast__icon')} size={20} />
			)}
			<div className="toast__content">
				<div className="toast__message">{message}</div>
				{hint && <ToastHint hint={hint} />}
			</div>
			<div className="toast__actions">
				{actions && actions.length > 0 && (
					<>
						{actions.map((action) => (
							<button
								className="toast__action"
								role="button"
								key={action.label ?? action.icon?.name}
								onClick={(e) => {
									e.preventDefault();
									action.onClick();
									if (action.shouldDismiss) {
										toast.dismiss(rawToast.id);
									}
								}}
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										action.onClick();
										if (action.shouldDismiss) {
											toast.dismiss(rawToast.id);
										}
									}
								}}
								title={action.label}
							>
								{action.icon && <action.icon size={16} />}
								{!action.icon && action.label}
							</button>
						))}
					</>
				)}
				{canDismiss && (
					<button
						className="toast__action toast__action--dismiss"
						onClick={() => toast.dismiss(rawToast.id)}
					>
						<X size={16} />
					</button>
				)}
			</div>
		</div>
	);
};

export default Toast;
