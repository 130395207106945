import { DownloadCloud } from 'lucide-react';
import CountdownBanner from '../../components/CountdownBanner';
import Flex from '../../components/Flex';

const DownloadPage = () => {
	return (
		<>
			<div className="page-wrapper">
				<Flex
					style={{ marginTop: 'auto', marginBottom: 'auto' }}
					justifyContent="center"
				>
					<CountdownBanner
						title="Download starting"
						seconds={0}
						description={
							'Your download will start immediately. You can close this page.'
						}
						icon={DownloadCloud}
						onCountdownEnd={() => {}}
					/>
				</Flex>
			</div>
		</>
	);
};

export default DownloadPage;
