import { CalendarClock, ChevronRight } from 'lucide-react';
import Button from 'ui/components/Button';
import Card from 'ui/components/Card';
import DateFragment from 'ui/components/DateFragment/DateFragment';
import { WebToolReportScheduleSummary } from '../../api/ReportHubAPI';

type WebToolScheduledReportsPanelProps = {
	reports: WebToolReportScheduleSummary[];
};

const WebToolScheduledReportsPanel = ({
	reports,
}: WebToolScheduledReportsPanelProps) => {
	if (reports.length === 0) {
		return null;
	}

	function iconClassName(report: WebToolReportScheduleSummary) {
		return report.isExpired || report.isSuspended
			? 'webtool-scheduled-reports-expired__icon'
			: 'webtool-scheduled-reports__icon';
	}

	return (
		<Card className="webtool-scheduled-reports">
			<div className="webtool-scheduled-reports__header">
				<h2 className="webtool-scheduled-reports__title">
					My Scheduled Reports
				</h2>
				<Button
					variant="secondary"
					icon={ChevronRight}
					iconPosition="right"
					to="/report-hub/scheduled-reports"
				>
					Scheduled Reports
				</Button>
			</div>
			<ul className="webtool-scheduled-reports__list">
				{reports.map((report) => (
					<li key={report.id} className="webtool-scheduled-reports__item">
						<div className={iconClassName(report)}>
							<CalendarClock />
						</div>
						<div className="webtool-scheduled-reports__details">
							<a href={`/webtool/${report.id}`}>
								<b className="webtool-scheduled-reports__name">{report.name}</b>
								<span className="webtool-scheduled-reports__date">
									{report.lastExecution && (
										<>
											Last Run:{' '}
											<DateFragment
												date={report.lastExecution}
												includeTime={true}
											/>
											<br />
										</>
									)}
									{report.isExpired || report.isSuspended ? null : (
										<>
											{report.lastExecution ? 'Next' : 'First'} Run:{' '}
											<DateFragment
												date={report.nextExecution}
												includeTime={true}
												emptyText="On new data"
											/>
										</>
									)}
								</span>
							</a>
						</div>
					</li>
				))}
			</ul>
		</Card>
	);
};

export default WebToolScheduledReportsPanel;
