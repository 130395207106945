import classNames from 'classnames';
import React, { useCallback } from 'react';
import Tooltip from 'ui/components/Tooltip';

type PillSelectionIdentifier = string | number;

export type PillSelectionOption<T extends PillSelectionIdentifier> = {
	id: T;
	label: string;
	hint?: string;
};

type PillSelectionProps<T extends PillSelectionIdentifier> = {
	options: PillSelectionOption<T>[];
	defaultSelected?: T[];

	selectedOptions?: T[];
	disabledOptions?: T[];
	onOptionSelected?: (option: T) => void;
	className?: string;

	isDisabled?: boolean;
};

const PillSelection = <T extends PillSelectionIdentifier>({
	options,
	selectedOptions: controlledSelectedOptions,
	disabledOptions = [],
	onOptionSelected: controlledOnOptionSelected,
	defaultSelected,
	className,
	isDisabled,
}: PillSelectionProps<T>) => {
	const [uncontrolledSelectedOptions, uncontrolledSetSelectedOptions] =
		React.useState<T[]>(defaultSelected ?? []);

	const uncontrolledOnOptionSelected = useCallback(
		(option: T) => {
			uncontrolledSetSelectedOptions((selectedOptions) => {
				if (selectedOptions.includes(option)) {
					return selectedOptions.filter(
						(selectedOption) => selectedOption !== option
					);
				} else {
					return [...selectedOptions, option];
				}
			});
		},
		[uncontrolledSetSelectedOptions]
	);

	const selectedOptions =
		controlledSelectedOptions ?? uncontrolledSelectedOptions;
	const onOptionSelected =
		controlledOnOptionSelected ?? uncontrolledOnOptionSelected;

	return (
		<div
			className={classNames(
				'pill-selection',
				isDisabled && 'pill-selection--disabled',
				className
			)}
		>
			{options.map((item) => (
				<Tooltip delay={500} disabled={!item.hint} key={item.id}>
					<Tooltip.Trigger>
						<button
							key={item.id}
							type="button"
							className={classNames(
								'pill-selection__option',
								selectedOptions.includes(item.id) &&
									'pill-selection__option--selected',
								disabledOptions.includes(item.id) &&
									'pill-selection__option--disabled'
							)}
							onClick={(e) => {
								e.stopPropagation();
								if (disabledOptions.includes(item.id)) return;
								onOptionSelected(item.id);
							}}
						>
							{item.label}
						</button>
					</Tooltip.Trigger>
					<Tooltip.Content>{item.hint}</Tooltip.Content>
				</Tooltip>
			))}
		</div>
	);
};

export default PillSelection;
