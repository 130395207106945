import { createColumnHelper } from '@tanstack/react-table';
import BulletIndicator from 'ui/components/BulletIndicator';
import { ReferenceRegion } from '../../api/ReferenceDataAPI';

const columnHelper = createColumnHelper<ReferenceRegion>();

export const countryNameColumn = columnHelper.accessor('countryName', {
	header: 'Country Name',
	enableSorting: true,
	enableMultiSort: false,
});

export const countryCodeColumn = columnHelper.accessor('countryCode', {
	header: 'Country Code',
	enableSorting: true,
	enableMultiSort: false,
});

export const provinceNameColumn = columnHelper.accessor('provinceName', {
	header: 'Province Name',
});

export const provinceCodeColumn = columnHelper.accessor('provinceCode', {
	header: 'Province Code',
});

export const cargoISRegionColumn = columnHelper.accessor('cargoIsRegion', {
	header: 'CargoIS Region',
	enableSorting: true,
	enableMultiSort: false,
});

export const tactRegionColumn = columnHelper.accessor('tactRegion', {
	header: 'TACT Region',
	enableSorting: true,
	enableMultiSort: false,
});

export const isActiveColumn = columnHelper.accessor('active', {
	header: 'Status',
	cell: (info) => {
		return (
			<BulletIndicator
				intent={info.getValue() ? 'success' : 'error'}
				label={info.getValue() ? 'Active' : 'Inactive'}
			/>
		);
	},
});
