import { StringKeys } from '../types/helpers';

type Sortable = {
	sortOrder: number;
};

export const sortBySortOrder = <T extends Sortable>(a: T, b: T) => {
	return a.sortOrder - b.sortOrder;
};

export const sortBySortOrderArray =
	<T>(sortOrder: string[], identifierKey: StringKeys<T>) =>
	(a: T, b: T) => {
		if (sortOrder.length === 0) return 0;

		const aIndex = sortOrder.findIndex((i) => i === a[identifierKey]);
		const bIndex = sortOrder.findIndex((i) => i === b[identifierKey]);

		if (aIndex === bIndex) return 0;
		if (aIndex === -1) return Infinity;
		if (bIndex === -1) return -Infinity;

		return aIndex - bIndex;
	};
