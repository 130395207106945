import { Loader2 } from 'lucide-react';
import { useNavigation } from 'react-router-dom';

type TransitionSpinnerProps = {};

const TransitionSpinner = ({}: TransitionSpinnerProps) => {
	const navigation = useNavigation();

	if (navigation.state === 'idle') return null;

	return (
		<div className="transition-spinner">
			<div className="transition-spinner__spinner">
				<Loader2 />
			</div>
		</div>
	);
};

export default TransitionSpinner;
