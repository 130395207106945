import Button from 'ui/components/Button';
import Flex from 'ui/components/Flex/Flex';
import Grid from 'ui/components/Grid/Grid';
import Modal, { ModalProps } from 'ui/components/Modal/Modal';
import SelectionButton from 'ui/components/SelectionButton/SelectionButton';
import { sortBySortOrder } from 'utils/helpers/sorting';
import { useWorksheetContext } from './WorksheetContext';

type WorksheetOptionsModalProps = {} & Omit<
	ModalProps,
	'title' | 'isOpen' | 'onClose'
>;

const WorksheetOptionsModal = ({
	...modalProps
}: WorksheetOptionsModalProps) => {
	const {
		worksheet,
		state,
		setDeepState,
		isReportOptionsOpen,
		setIsReportOptionsOpen,
		derivedState,
	} = useWorksheetContext();

	const activeOptionGroups = worksheet.config.optionGroups.filter(
		(optionGroup) =>
			optionGroup.options.length > 0 &&
			derivedState.selectedFields.some(
				(field) => field.optionGroup === optionGroup.id
			)
	);

	const { disabledGroupOptions } = derivedState;

	return (
		<Modal
			title="Report Options"
			size="lg"
			onClose={() => setIsReportOptionsOpen(false)}
			isOpen={isReportOptionsOpen}
			{...modalProps}
		>
			<Modal.Body>
				<Grid columns={2}>
					{activeOptionGroups.map((optionGroup) => {
						const disabledOptions = disabledGroupOptions[optionGroup.id] ?? [];

						return (
							<Flex direction="column" gap={8} key={optionGroup.id}>
								<b>{optionGroup.name}</b>
								<Flex direction="row" gap={8} wrap="wrap">
									{optionGroup.options.map((option) => (
										<SelectionButton
											key={option.id}
											checked={
												state.output.fieldOptionGroups[
													optionGroup.id
												]?.includes(option.id) ?? false
											}
											disabled={disabledOptions.includes(option.id)}
											onChange={(e) => {
												const fieldOptionGroup =
													state.output.fieldOptionGroups[optionGroup.id] ?? [];
												const shouldRemove = fieldOptionGroup.includes(
													option.id
												);

												if (shouldRemove && fieldOptionGroup.length === 1) {
													return;
												}

												if (shouldRemove) {
													setDeepState(
														`output.fieldOptionGroups.${optionGroup.id}`,
														(prevState: string[]) => {
															return prevState.filter(
																(value) => value !== option.id
															);
														}
													);
												} else {
													setDeepState(
														`output.fieldOptionGroups.${optionGroup.id}`,
														(prevState: string[]) => {
															return [...prevState, option.id].sort((a, b) => {
																const aOptionGroup = optionGroup.options.find(
																	(o) => o.id === a
																);
																const bOptionGroup = optionGroup.options.find(
																	(o) => o.id === b
																);

																if (!aOptionGroup || !bOptionGroup) {
																	return 0;
																}

																return sortBySortOrder(
																	aOptionGroup,
																	bOptionGroup
																);
															});
														}
													);
												}
											}}
										>
											{option.label}
										</SelectionButton>
									))}
								</Flex>
							</Flex>
						);
					})}
				</Grid>
			</Modal.Body>
			<Modal.Actions>
				<Button
					variant="secondary"
					onClick={() => setIsReportOptionsOpen(false)}
				>
					Close
				</Button>
			</Modal.Actions>
		</Modal>
	);
};

export default WorksheetOptionsModal;
