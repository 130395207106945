import { ChevronDown, Plus } from 'lucide-react';
import { Dispatch, MouseEvent, SetStateAction, useState } from 'react';
import Button from 'ui/components/Button';
import { LocationType } from 'utils/api/LocationAPI';
import TradelaneAPI from 'utils/api/TradelaneAPI';
import { WorksheetConfigWebToolGroup } from 'utils/api/WebToolAPI';
import {
	TradelaneOption,
	TradelaneOriginDestinationOption,
} from 'utils/api/WebToolGroupAPI';
import { Location } from 'utils/api/common';
import { catchWithError } from 'utils/helpers/catchHandlers';
import { createValidationToast } from 'utils/helpers/codeValidation';
import { createToast } from 'utils/helpers/toast';
import { flattenErrors } from 'utils/zod/zodErrors';
import Flex from '../Flex/Flex';
import FormField from '../FormField/FormField';
import Menu from '../Menu';
import { ToastType } from '../Toaster/Toast';
import Toggle from '../Toggle';
import TradelaneList from '../TradelaneList/TradelaneList';
import useValidation from '../ValidatedForm/useValidation';
import TradelanesDropdownEditor from './TradelanesDropdownEditor';
import TradelanesManualEditor from './TradelanesManualEditor';

const customGroupToTradelaneOption = (
	group: WorksheetConfigWebToolGroup
): TradelaneOption => ({
	itemType: 'custom-group',
	id: group.id,
	name: group.name,
});

type TradelanesFieldProps = {
	name: string;
	label: string;
	defaultValue?: TradelaneOption[];

	value?: TradelaneOption[];
	onChange?: Dispatch<SetStateAction<TradelaneOption[]>>;

	locationTypes: LocationType[];
	layout?: 'linear' | 'stacked';
	emptyText?: string;

	defaultManualMode?: boolean;
	customTradelaneGroups?: WorksheetConfigWebToolGroup[];
};

const TradelanesField = ({
	name,
	label,
	defaultValue = [],
	locationTypes = [],
	layout = 'linear',
	emptyText = 'No tradelanes',

	value: propsTradelanes,
	onChange: propsOnChange,

	defaultManualMode = false,
	customTradelaneGroups = [],
}: TradelanesFieldProps) => {
	const { errorTree } = useValidation(name);

	const [isManualModeEnabled, setIsManualModeEnabled] =
		useState(defaultManualMode);
	const [isLoading, setLoading] = useState(false);

	const [stateTradelanes, setStateTradelanes] =
		useState<TradelaneOption[]>(defaultValue);
	const [manualTradelanes, setManualTradelanes] = useState('');

	const [editorFrom, setEditorFrom] = useState<Location | null>(null);
	const [editorTo, setEditorTo] = useState<Location | null>(null);

	const tradelanes = propsTradelanes ?? stateTradelanes;
	const setTradelanes = propsOnChange ?? setStateTradelanes;

	const handleRemove = (index: number) => {
		const newTradelanes = [...tradelanes];
		newTradelanes.splice(index, 1);

		setTradelanes(newTradelanes);
	};

	const resetAllState = () => {
		setEditorFrom(null);
		setEditorTo(null);
		setManualTradelanes('');
		setLoading(false);
	};

	const onlyTradeLanes = (
		tradelane: TradelaneOption
	): tradelane is TradelaneOriginDestinationOption =>
		tradelane.itemType === 'tradelane';

	const validateManualTradelanes = async () => {
		setLoading(true);

		let tradelaneCodes: string[] = manualTradelanes
			.split(',')
			.map((code) => code.trim())
			.filter((code) => code.length > 0);

		if (tradelaneCodes.length === 0) {
			createToast(
				ToastType.WARNING,
				'Please enter at least one valid tradelane code'
			);
			setLoading(false);
			return;
		}

		const validationPromise = TradelaneAPI.validateTradelanes(
			tradelaneCodes,
			locationTypes
		);

		await createValidationToast(validationPromise, 'tradelane');
		const validationResult = await validationPromise;

		if (validationResult.options.length > 0) {
			setTradelanes((prevTradeLanesOrGroups) => {
				// Prevent duplicates
				const newTradelanes = [...prevTradeLanesOrGroups];
				const tradeLanesOnly = prevTradeLanesOrGroups.filter(onlyTradeLanes);
				validationResult.options.forEach((tradelane) => {
					if (
						!tradeLanesOnly.some(
							(t) =>
								t.origin.naturalKey === tradelane.origin.naturalKey &&
								t.destination.naturalKey === tradelane.destination.naturalKey
						)
					) {
						newTradelanes.push(tradelane);
					}
				});
				return newTradelanes;
			});
		}

		resetAllState();
	};

	const validateSelectedTradelanes = async () => {
		if (editorFrom !== null && editorTo !== null) {
			const lookupTradeLane: TradelaneOriginDestinationOption = {
				itemType: 'tradelane',
				origin: editorFrom,
				destination: editorTo,
			};

			setTradelanes((prevTradeLanesOrGroups) => {
				const newTradeLanes = [...prevTradeLanesOrGroups];
				const tradeLanesOnly = prevTradeLanesOrGroups.filter(onlyTradeLanes);
				if (
					!tradeLanesOnly.some(
						(t) =>
							t.origin.naturalKey === lookupTradeLane.origin.naturalKey &&
							t.destination.naturalKey ===
								lookupTradeLane.destination.naturalKey
					)
				) {
					newTradeLanes.push(lookupTradeLane);
				}
				return newTradeLanes;
			});
		}

		resetAllState();
	};

	const unselectedTradelaneGroups = customTradelaneGroups.filter(
		(group) =>
			!tradelanes.some(
				(tradelane) =>
					tradelane.itemType === 'custom-group' && tradelane.id === group.id
			)
	);

	return (
		<FormField label={label} errors={flattenErrors(errorTree)}>
			<div className="tradelanes">
				<div className="tradelanes__list">
					<TradelaneList
						tradelanes={tradelanes}
						emptyText={emptyText}
						onTradelaneRemoved={handleRemove}
					/>
				</div>

				<div className="tradelanes__input">
					{isManualModeEnabled ? (
						<TradelanesManualEditor
							value={manualTradelanes}
							onChange={setManualTradelanes}
							onSubmit={() => {
								validateManualTradelanes().catch(catchWithError);
							}}
							isLoading={isLoading}
						/>
					) : (
						<TradelanesDropdownEditor
							from={editorFrom}
							onFromChange={setEditorFrom}
							to={editorTo}
							onToChange={setEditorTo}
							locationTypes={locationTypes}
							layout={layout}
						/>
					)}

					{tradelanes.map((tradelane) => (
						<input
							type="hidden"
							name={name}
							key={
								tradelane.itemType === 'custom-group'
									? `${name}.${tradelane.id}`
									: `${name}.${tradelane.origin.naturalKey}-${tradelane.destination.naturalKey}`
							}
							value={
								tradelane.itemType === 'custom-group'
									? `group-${tradelane.id}`
									: `${tradelane.origin.locationId}|${tradelane.destination.locationId}`
							}
						/>
					))}
				</div>

				<div className="tradelanes__actions">
					<Flex alignItems="center" gap={8}>
						<Toggle
							isChecked={isManualModeEnabled}
							onChange={(e) => setIsManualModeEnabled(e.target.checked)}
							size="small"
						/>
						<span>Manual mode</span>
					</Flex>

					{customTradelaneGroups.length > 0 && (
						<Menu>
							<Menu.Trigger>
								<Button
									size="small"
									variant="secondary"
									icon={ChevronDown}
									style={{ marginLeft: 'auto' }}
									isDisabled={unselectedTradelaneGroups.length === 0}
								>
									Add Group
								</Button>
							</Menu.Trigger>

							{unselectedTradelaneGroups.map((group) => (
								<Menu.Item
									key={group.id}
									label={group.name}
									onClick={() => {
										setTradelanes((prevTradelanes) => {
											if (
												prevTradelanes.some(
													(t) =>
														t.itemType === 'custom-group' && t.id === group.id
												)
											) {
												return prevTradelanes;
											}

											return [
												...prevTradelanes,
												customGroupToTradelaneOption(group),
											];
										});
									}}
								/>
							))}
						</Menu>
					)}

					<Button
						size="small"
						icon={Plus}
						variant="secondary"
						className="tradelanes__add"
						onClick={(e: MouseEvent) => {
							e.preventDefault();
							if (isManualModeEnabled) {
								validateManualTradelanes().catch(catchWithError);
							} else {
								validateSelectedTradelanes().catch(catchWithError);
							}
						}}
						isDisabled={
							isManualModeEnabled
								? !manualTradelanes.length
								: !editorFrom || !editorTo
						}
						isLoading={isLoading}
					>
						Add {isManualModeEnabled ? 'tradelane/s' : 'tradelane'}
					</Button>
				</div>
			</div>
		</FormField>
	);
};

export default TradelanesField;
