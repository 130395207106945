import { useContext, useEffect } from 'react';
import ValidatedFormContext from './ValidatedFormContext';

const useFormReset = (onReset: () => void) => {
	const formContext = useContext(ValidatedFormContext);

	useEffect(() => {
		if (!formContext?.formRef?.current) return undefined;
		const form = formContext.formRef.current;

		form.addEventListener('reset', onReset);

		return () => {
			form.removeEventListener('reset', onReset);
		};
	}, [formContext?.formRef]);
};

export default useFormReset;
