import {
	autoUpdate,
	flip,
	FloatingContext,
	offset,
	size,
	useClick,
	useDismiss,
	useFloating,
	UseFloatingOptions,
	useInteractions,
	useListNavigation,
	useRole,
	useTypeahead,
} from '@floating-ui/react';
import React, { MutableRefObject, useLayoutEffect } from 'react';

type UseDefaultFloatingProps = Pick<
	UseFloatingOptions,
	'open' | 'onOpenChange'
>;

export const useDefaultFloating = ({
	open,
	onOpenChange,
}: UseDefaultFloatingProps) => {
	return useFloating({
		open,
		onOpenChange,
		whileElementsMounted: autoUpdate,
		placement: 'bottom-start',
		middleware: [
			offset(5),
			flip({ padding: 8 }),
			size({
				apply({ rects, availableHeight, elements, availableWidth }) {
					Object.assign(elements.floating.style, {
						minWidth: `${rects.reference.width}px`,
						maxHeight: `${availableHeight - 20}px`,
						maxWidth: `${availableWidth - 20}px`,
					});
				},
				padding: 8,
			}),
		],
	});
};

type UseDefaultFloatingInteractionsProps = {
	context: FloatingContext;
	listItemsRef: React.MutableRefObject<Array<HTMLElement | null>>;
	contentRef: MutableRefObject<string[]>;
	activeIndex: number | null;
	setActiveIndex: (index: number | null) => void;
	isOpen: boolean;
	onTypingChange: (typing: boolean) => void;
};

export const useDefaultFloatingInteractions = ({
	context,
	listItemsRef,
	contentRef,
	activeIndex,
	setActiveIndex,
	isOpen,
	onTypingChange,
}: UseDefaultFloatingInteractionsProps) => {
	return useInteractions([
		useClick(context),
		useRole(context, { role: 'listbox' }),
		useDismiss(context),
		useListNavigation(context, {
			listRef: listItemsRef,
			activeIndex,
			onNavigate: setActiveIndex,
			loop: true,
		}),
		useTypeahead(context, {
			listRef: contentRef,
			activeIndex: activeIndex,
			onMatch: isOpen ? setActiveIndex : undefined,
			onTypingChange,
		}),
	]);
};

type UseFloatingAutoScrollProps = {
	listItemsRef: React.MutableRefObject<Array<HTMLElement | null>>;
	activeIndex: number | null;
	open: boolean;
	pointer: boolean;
};

export const useFloatingAutoScroll = ({
	listItemsRef,
	activeIndex,
	open,
	pointer,
}: UseFloatingAutoScrollProps) => {
	useLayoutEffect(() => {
		if (open && activeIndex !== null && !pointer) {
			requestAnimationFrame(() => {
				listItemsRef.current[activeIndex]?.scrollIntoView({
					block: 'nearest',
				});
			});
		}
	}, [open, activeIndex, pointer]);
};
