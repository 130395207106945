import { ColumnSort, SortDirection, SortingState } from '@tanstack/react-table';
import { Day } from 'date-fns';
import { ENVIRONMENT_SHARED } from 'environment';
import { ExecuteReportsSchedule } from 'iata-cargois-admin-fe/src/api/ReportNotificationsAPI';
import { MonthAndDay } from 'ui/components/MonthDayPicker/MonthDayPicker';
import API from 'utils/api/API';
import { AirlineOption } from 'utils/api/AirlinesAPI';
import { CASSAreaOption } from 'utils/api/CASSAreasAPI';
import {
	FreightForwarderOption,
	FreightForwarderType,
} from 'utils/api/FreightForwarderAPI';
import { LocationType } from 'utils/api/LocationAPI';
import { Location } from 'utils/api/common';
import {
	CurrencyCode,
	LabeledValue,
	Option,
	Paginated,
} from 'utils/types/common';
import { CustomerOption } from '../../../apps/admin/src/api/CustomerAPI';
import { SubscriptionOption } from '../../../apps/admin/src/api/SubscriptionAPI';
import { UserOption } from '../../../apps/admin/src/api/UserAPI';
import { TradelaneOption } from './WebToolGroupAPI';

export type WorksheetConfigField = {
	id: string;
	label: string;
	isAdvanced?: boolean;
	sortOrder: number;
	optionGroup?: string;
	hint?: string;
	tooltip?: string;
	groupType?: string;
};

export type WorksheetConfigFieldGroup = {
	name: string;
	sortOrder: number;
	isAdvanced?: boolean;
	fields: WorksheetConfigField[];
};

type WorksheetConfigOption = {
	id: string;
	label: string;
	sortOrder: number;
	defaultSelected: boolean;
};

type WorksheetConfigOptionGroup = {
	id: string;
	name: string;
	options: WorksheetConfigOption[];
};

export type WorksheetConfigDataField = {
	id: string;
	label: string;
	sortOrder: number;
	isCurrencyBased: boolean;
	tooltip?: string;

	isMarket?: boolean;
	isCustomer?: boolean;
	isCustomerShare?: boolean;
};

export type WorksheetConfigCurrency = {
	id: CurrencyCode;
	label: string;
	defaultSelected: boolean;
};

export type DistortionId = 'distorted' | 'undistorted';

export type WorksheetConfigDistortionUi = {
	id: DistortionId;
	label: string;
	defaultSelected: boolean;
};

export type DateUnit = 'day' | 'week' | 'month';

export type DateRangeRelative = {
	period: 'day' | 'week' | 'month' | 'year' | 'year-to-date';
	periodCount: number;
};

export type DateRangeCompare = {
	startDate: Date;
	endDate: Date;
	compareStartDate: Date;
	compareEndDate: Date;
	unit: DateUnit;
};

export type WorksheetParametersState = {
	advancedMode: boolean;

	location: {
		originLocations: Location[];
		destinationLocations: Location[];
		includeReverseLocations: boolean;
		cassAreas: CASSAreaOption[];
		releasedCountriesOnly: boolean;
		tradelanes: TradelaneOption[];
	};

	dateRange: {
		selectedType: 'absolute' | 'relative' | 'compare';

		absolute: {
			startDate: Date;
			endDate: Date;
			unit: DateUnit;
		};

		relative: DateRangeRelative;

		compare: DateRangeCompare;
	};

	awb: {
		serialNumber: string;
		airlinePrefix: string;
	};

	breakdown: {
		airlines: AirlineOption[];
		freightForwarders: FreightForwarderOption[];
		weightBreakGroupId?: string;
		weightBreakGroupEmbedded?: number[];
		weightBreaks: number[];
		specialHandlingCodes: string;
	};

	specialHandling: {
		highLevels: LabeledValue[];
		commodityMidLevels: LabeledValue[];
		serviceMidLevels: LabeledValue[];
	};
};

export type WorksheetConfigWebToolGroupType =
	| 'airline'
	| 'station'
	| 'city'
	| 'geography'
	| 'freight-forwarder'
	| 'trade-lane'
	| 'weight-break';

export type WorksheetConfigWebToolGroup = {
	id: string;
	name: string;
	description: string;
	groupType: WorksheetConfigWebToolGroupType;
	overlaps: string[];
};

type WorksheetDataFieldTimeSeriesConfig = {
	weekOverWeek: boolean;
};

export type WorksheetConfig = {
	parameters: WorksheetParametersConfig;
	fieldGroups: WorksheetConfigFieldGroup[];
	optionGroups: WorksheetConfigOptionGroup[];
	dataFields: WorksheetConfigDataField[];
	currencies: WorksheetConfigCurrency[];
	distortionOptions: WorksheetConfigDistortionUi[];
	dimensions: { [key: string]: Dimension };
	restrictions: WorksheetRestriction[];
	webToolGroups: WorksheetConfigWebToolGroup[];
	dataFieldTimeSeries: WorksheetDataFieldTimeSeriesConfig;
};

type WorksheetRestriction = {
	parameters: WorksheetParameter[];
	fields: string[];
	disabledParameters: WorksheetParameter[];
	disabledFields: string[];
	disabledFieldGroupOptions: { id: string; options: string[] }[];
	disabledDataFields: { id: string; segments: [boolean, boolean, boolean] }[];
};

export type WorksheetParameter =
	| 'origin'
	| 'destination'
	| 'tradelane'
	| 'cass-area'
	| 'compare-date-range'
	| 'air-waybill'
	| 'airline'
	| 'freight-forwarder'
	| 'weight-break-set'
	| 'weight-break'
	| 'sph-high-level'
	| 'commodity-mid-level'
	| 'service-mid-level'
	| 'released-countries-only'
	| 'special-handling-code';

export type WorksheetParameterType = 'simple' | 'advanced';

export type DateRangeFormats = {
	day: boolean;
	week: boolean;
	month: boolean;
	year: boolean;
	yearToDate: boolean;
};

type WorksheetParametersConfig = {
	defaultDateUnit: DateUnit;
	defaultFromDate: Date;
	defaultToDate: Date;
	minimumDate: Date;
	maximumDate: Date;
	originLocationTypes: LocationType[];
	destinationLocationTypes: LocationType[];
	freightForwarderTypes: FreightForwarderType[];
	tradeLaneLocationTypes: LocationType[];
	dateRange: {
		formats: DateRangeFormats;
	};
	airlineOptions: AirlineOption[] | null;
	enabledParameters: Partial<
		Record<WorksheetParameter, WorksheetParameterType>
	>;

	specialHandlingHighLevelOptions: LabeledValue[];
	commodityMidLevelOptions: LabeledValue[];
	serviceMidLevelOptions: LabeledValue[];
};

type WorksheetOutputState = {
	advancedMode: boolean;
	fields: string[];
	fieldOptionGroups: WorksheetFieldOptionGroups;
	dataFieldTimeSeries: {
		simple: WorksheetDataFieldTimeSeriesState;
		advanced: WorksheetDataFieldTimeSeriesState;
	};
	dataFields: WorksheetDataFieldsState;
	currency: CurrencyCode[];
	distortion: DistortionId[];
	webToolGroups: Record<string, string[]>;
};

type WorksheetLayoutState = {
	displayReportParameters: boolean;
	fieldSortOrder: string[];
	dataFieldSortOrder: string[];

	sorting: SortingState;

	columnsSortOrder: string[];
	columnsSortDirections: Record<string, SortDirection>;
};

export type WorksheetState = {
	parameters: WorksheetParametersState;
	output: WorksheetOutputState;
	layout: WorksheetLayoutState;
};

type WorksheetFieldOptionGroups = {
	[optionGroupName: string]: string[];
};

export declare type QueryCompleted = {
	id: string; // This is the report execution ID
	executionStatus: 'SUCCESS';
	snowflakeExecutionTimeMs: number;
	totalRows: number;
};

export declare type QueryExpired = {
	id: string; // This is the report execution ID
	executionStatus: 'EXPIRED';
	snowflakeExecutionTimeMs: number;
	totalRows: number;
};

export declare type QueryIncomplete = {
	id: string; // This is the report execution ID
	executionStatus: 'INCOMPLETE';
};

export declare type QueryFailed = {
	id: string; // This is the report execution ID
	executionStatus: 'FAILED';
	errorCode: number;
	errorMessage: string;
};

export declare type QueryStatus =
	| QueryCompleted
	| QueryIncomplete
	| QueryFailed
	| QueryExpired;

export type WorksheetCustomer = {
	id: string;
	name: string;
};

export type WorksheetBase = {
	id: string;
	name: string;
	schedule: WorksheetSchedule | null;
	subscriptionId: string;
	subscriptionName: string;
	subscriptionNumber?: Option<string>;
	createdAt: Date;
	updatedAt?: Option<Date>;

	supportWorksheet?: boolean;
	customer: WorksheetCustomer;
	shareDetails?: WorksheetShareDetails | null;
};

export type WorksheetScheduleWeekly = {
	frequency: 'weekly';
	dayOfWeek: Day;
	time: number;
};

export type WorksheetScheduleMonthly = {
	frequency: 'monthly';
	dayOfMonth: number;
	time: number;
};

export type WorksheetScheduleYearly = {
	frequency: 'yearly';
	time: number;
	monthAndDay: MonthAndDay;
};

export type WorksheetScheduleOnNewData = {
	frequency: 'on-new-data';
};

export type WorksheetSchedule = {
	activeFrom: Date;
	activeTo: Option<Date>;
	frequencyDisplay?: Option<string>;
	suspended?: Option<boolean>;
	suspendedMessage?: Option<string>;
	outputFormat?: 'excel' | 'csv' | 'tsv';
} & (
	| WorksheetScheduleWeekly
	| WorksheetScheduleMonthly
	| WorksheetScheduleYearly
	| WorksheetScheduleOnNewData
);

export type WorksheetSharing = {
	availableUsers: UserOption[];
};

export type WorksheetShareUser = {
	userId: string;
	name: string;
	email: Option<string>; // Only provided for non support users
	isSupportUser: boolean;
};

export type WorksheetShareDetails = WorksheetShareUser & {
	isModified: boolean;
};

export type Worksheet = WorksheetBase & {
	config: WorksheetConfig;
	initialState: WorksheetState;
	queryStatus?: Option<QueryStatus>;
	schedule: Option<WorksheetSchedule>;
	sharing: WorksheetSharing;
};

export type DataFieldMetricSegment = 'customer' | 'customer-share' | 'market';

export type DataFieldMetricType = 'ty' | 'ly' | 'yoy' | 'yoyc' | 'wow' | 'wowc';

export type ReportDataFormat =
	| 'weight'
	| 'distance'
	| 'currency'
	| 'exchange-rate'
	| 'date'
	| 'month'
	| 'number'
	| 'text'
	| 'ratio'
	| 'difference'
	| 'percentage';

export declare type Dimension = {
	id: string;
	name: string;
	format: ReportDataFormat;
	requiresServiceWeightBreaks?: true;
};

export type SetWorksheetExportToNotifyResponse = {
	id: string;
};

export type GetWorksheetExportStatusResponse = {
	id: string;
	status: 'CANCELLED' | 'IN-PROGRESS' | 'SUCCESS' | 'FAILED';
	queryStatus?: QueryStatus;
};

export type GetWorksheetExportDownloadResponse = {
	url: string;
	filename: string;
	originalFileName: string;
};

export type ReportColumnGroup = {
	name: string;
	headerAlignment?: 'left' | 'center' | 'right';
	items: (ReportColumn | ReportColumnGroup)[];
};

export type ReportColumn = {
	id: string;
	name: string;
	format: ReportDataFormat;
	totalValue?: number | null;
};

export type ReportParameterUsed = { name: string; value: string[] };

export type GetWorksheetPreviewResponse = {
	queryId: string;
	// Either this
	items: Record<string, unknown>[];
	columns: ReportColumnGroup[] | ReportColumn[];
	displayReportParameters: boolean;
	reportGeneratedAt: Date;
	reportGeneratedBy: string;
	reportParameters: ReportParameterUsed[];
	reportMessages: string[];
	// This with no items
	previewTimeout: boolean;
	// Or this due to an unexpected error
	errorMessage?: string;
};

export enum WorksheetAction {
	SAVE = 'save',
	RUN = 'run',
	EXPORT = 'export',
}

export type GetWorksheetsResponse = {
	worksheets: WorksheetBase[];
	subscriptionOptions: SubscriptionOption[];
	sharedUserOptions: WorksheetShareUser[];
};

type CreateWorksheetResponse = {
	id: string;
};

export type WorksheetExportFormat = 'csv' | 'tsv' | 'excel' | 'screen';

export type UpdateWorksheetResponse = {
	id: string;
	reportId?: string;
};

export type ScheduleTypeOption = {
	id: string;
	name: string;
};

export type SchedulesDataRow = {
	id: string;
	schedule: WorksheetSchedule;
	lastExecutionStatus: WebToolReportStatus | null;
	lastExecution: Date;
	nextExecution: Date;
	suspended: boolean;
	suspendedMessage?: string;
	user: string;
	customer: string;
	name: string;
};

type GetWorksheetSchedulesResponse = {
	schedulesData: Paginated<SchedulesDataRow>;
	subscriptionsSelected: SubscriptionOption[];
	usersSelected: UserOption[];
	customersSelected: CustomerOption[];
	executedDateDefault: LabeledValue;
	executedDateOptions: LabeledValue[];
	executionDateDefault: LabeledValue;
	executionDateOptions: LabeledValue[];
	scheduleTypeOptions: ScheduleTypeOption[];
};

export type WebtoolSubscription = {
	id: string;
	name: string;
	customer: {
		id: string;
		name: string;
	};
	supportSubscription?: true;
};

export const WORKSHEET_DATA_FIELD_DIMENSIONS = [
	'This Year',
	'Last Year',
	'Year over Year',
	'Year over Year Percent',
	'Week over Week',
	'Week over Week Percent',
];

export const WORKSHEET_DATA_FIELD_DIMENSIONS_SHORT = [
	'TY',
	'LY',
	'YoY',
	'YoY%',
	'WoW',
	'WoW%',
];

export type WorksheetDataFieldVariationState = {
	simple: boolean;
	advanced: WorksheetDataFieldTimeSeriesState;
};

export type WorksheetDataFieldTimeSeriesState = [
	boolean, // This year
	boolean, // Last year
	boolean, // Year over year
	boolean, // Year over year percent
	boolean, // Week over week
	boolean // Week over week percent
];

export type WorksheetDataFieldState = {
	customer: WorksheetDataFieldVariationState;
	market: WorksheetDataFieldVariationState;
	customerShare: WorksheetDataFieldVariationState;
};

export type WorksheetDataFieldsState = {
	[key: string]: WorksheetDataFieldState;
};

export type GetWebtoolSubscriptionsResponse = {
	subscriptions: WebtoolSubscription[];
};

type WebToolReportStatus = {
	label: string;
	value: string;
};

export type WebToolReportRunResult = {
	id: string;
	executedAt: Date;
	status: WebToolReportStatus;
	filename: string;
	fileSizeInBytes: number;
	parameters: { name: string; value: string[] }[];
	columns: string[];
};

type GetScheduledWebToolHistoryResponse = {
	history: WebToolReportRunResult[];
};

type ShareWorksheetResponse = {
	ids: string[];
};

class WebToolAPI extends API {
	protected static readonly apiEndpoint =
		ENVIRONMENT_SHARED.subscriptionsApiEndpoint;

	static getWorksheets() {
		return WebToolAPI.get<GetWorksheetsResponse>(`/web-tool/worksheets`);
	}

	static async getWorksheetById(id: string): Promise<Worksheet> {
		const worksheet = await WebToolAPI.get<Worksheet>(
			`/web-tool/worksheets/${id}`
		);

		return worksheet;
	}

	static getWorksheetReportStatus(reportId: string) {
		return WebToolAPI.get<GetWorksheetExportStatusResponse>(
			`/web-tool/worksheet-reports/${reportId}/status`
		);
	}

	static moveReportToBackground(reportId: string) {
		return WebToolAPI.put<SetWorksheetExportToNotifyResponse>(
			`/web-tool/worksheet-reports/${reportId}/notify`
		);
	}

	static getWorksheetExportDownload(reportId: string) {
		return WebToolAPI.get<GetWorksheetExportDownloadResponse>(
			`/web-tool/worksheet-reports/${reportId}/download`
		);
	}

	static getWorksheetPreview(id: string, reportId: string, sort: ColumnSort[]) {
		const searchParams = new URLSearchParams();
		searchParams.set('sort', JSON.stringify(sort));
		return WebToolAPI.get<GetWorksheetPreviewResponse>(
			`/web-tool/worksheets/${id}/preview/${reportId}?${searchParams.toString()}`
		);
	}

	static createWorksheet(name: string, subscriptionId: string) {
		return WebToolAPI.post<CreateWorksheetResponse>(`/web-tool/worksheets`, {
			name,
			subscriptionId,
		});
	}

	static duplicateWorksheet(id: string, name: string, retainSchedule = true) {
		return WebToolAPI.post<CreateWorksheetResponse>(
			`/web-tool/worksheets/${id}/duplicate`,
			{
				name,
				retainSchedule,
			}
		);
	}

	static renameWorksheet(id: string, name: string) {
		return WebToolAPI.put<UpdateWorksheetResponse>(
			`/web-tool/worksheets/${id}/rename`,
			{ name }
		);
	}

	static updateWorksheetWithAction(
		id: string,
		worksheet: WorksheetState,
		action: WorksheetAction,
		format?: WorksheetExportFormat
	) {
		return WebToolAPI.put<UpdateWorksheetResponse>(
			`/web-tool/worksheets/${id}`,
			{
				...worksheet,
				// TODO: Because the selected dates are local ()Apr 01 2023 00:00 +1),
				// when the serializer converts to JSON it changes to UTC and drops an hour (Mar 31 2023 23:00 UTC)
				// The picker either needs to change to only pass back UTC dates, instead of local
				// (which can fail when re-loading the page) OR apply the datetime conversion here
				action,
				format,
			}
		);
	}

	static deleteWorksheet(id: string) {
		return WebToolAPI.delete(`/web-tool/worksheets/${id}`);
	}

	static getWebtoolSubscriptions() {
		return WebToolAPI.get<GetWebtoolSubscriptionsResponse>(
			`/web-tool/subscriptions`
		);
	}

	static updateWorksheetSchedule(id: string, schedule: WorksheetSchedule) {
		return WebToolAPI.patch<UpdateWorksheetResponse>(
			`/web-tool/worksheets/${id}/schedule`,
			schedule
		);
	}

	static deleteWorksheetSchedule(id: string) {
		return WebToolAPI.delete(`/web-tool/worksheets/${id}/schedule`);
	}

	static getWorksheetSchedules(request: URLSearchParams) {
		return WebToolAPI.get<GetWorksheetSchedulesResponse>(
			`/web-tool/scheduled?${request}`
		);
	}

	static getScheduledWebToolHistory(
		id: string
	): Promise<GetScheduledWebToolHistoryResponse> {
		return WebToolAPI.get<GetScheduledWebToolHistoryResponse>(
			`/web-tool/worksheets/${id}/history`
		);
	}

	static downloadWeekCalendar(id: string) {
		return WebToolAPI.get<{
			filename: string;
			url: string;
			originalFileName: string;
		}>(`/web-tool/worksheets/${id}/week-calendar`);
	}

	static downloadWebToolReport(id: string) {
		return WebToolAPI.get<{
			filename: string;
			url: string;
			originalFileName: string;
		}>(`/web-tool/worksheets/${id}/admin-download`);
	}

	static executeReportNotifications() {
		return WebToolAPI.post<ExecuteReportsSchedule>(
			'/web-tool/worksheets/data-imported'
		);
	}

	static shareWebToolReport(
		id: string,
		userIds: string[],
		worksheetName: string
	) {
		return WebToolAPI.post<ShareWorksheetResponse>(
			`/web-tool/worksheets/${id}/share`,
			{
				userIds,
				worksheetName,
			}
		);
	}
}

export default WebToolAPI;
