import { Pencil, Save, Trash2 } from 'lucide-react';
import { useState } from 'react';
import Button from 'ui/components/Button/Button';
import { WorksheetScheduleModalMode } from './WorksheetScheduleModal';

type WorksheetScheduleToolbarProps = {
	formId: string;
	mode: WorksheetScheduleModalMode;
	onDelete?: () => Promise<void>;
	onSave?: () => Promise<void>;
};

const WorksheetScheduleToolbar = ({
	mode,
	onDelete,
	onSave,
	formId,
}: WorksheetScheduleToolbarProps) => {
	const [isSaving, setSaving] = useState(false);
	const [isDeleting, setDeleting] = useState(false);

	const handleDelete = async () => {
		setDeleting(true);
		await onDelete?.();
		setDeleting(false);
	};

	const handleSave = async () => {
		setSaving(true);
		await onSave?.();
		setSaving(false);
	};

	if (mode === WorksheetScheduleModalMode.CREATE) {
		return (
			<>
				<p />

				<Button
					variant="primary"
					type="submit"
					form={formId}
					icon={Pencil}
					onClick={handleSave}
					isLoading={isSaving}
				>
					Create schedule
				</Button>
			</>
		);
	} else {
		return (
			<>
				<Button
					variant="secondary"
					intent="danger"
					icon={Trash2}
					onClick={handleDelete}
					isLoading={isDeleting}
					isDisabled={isSaving}
				>
					Delete
				</Button>

				<Button
					variant="primary"
					type="submit"
					form={formId}
					icon={Save}
					onClick={handleSave}
					isLoading={isSaving}
					isDisabled={isDeleting}
				>
					Update schedule
				</Button>
			</>
		);
	}
};

export default WorksheetScheduleToolbar;
