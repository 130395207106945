import { ComponentProps, useEffect, useRef } from 'react';

type IndeterminateCheckboxProps = {
	indeterminate?: boolean;
} & ComponentProps<'input'>;

const IndeterminateCheckbox = ({
	indeterminate,
	...rest
}: IndeterminateCheckboxProps) => {
	const ref = useRef<HTMLInputElement>(null!);

	useEffect(() => {
		if (typeof indeterminate === 'boolean') {
			ref.current.indeterminate = !rest.checked && indeterminate;
		}
	}, [ref, indeterminate]);

	return <input type="checkbox" ref={ref} {...rest} />;
};

export default IndeterminateCheckbox;
