import { ENVIRONMENT_SHARED } from 'environment';

const FOOTER_LINKS = [
	{
		href: 'https://www.iata.org/en/privacy/',
		label: 'Privacy Policy',
		isExternal: true,
	},
	{
		href: ENVIRONMENT_SHARED.termsAndConditionsUrl,
		label: 'Terms of Use',
		isExternal: true,
		isAuthenticationRequired: true,
	},
	{
		href: 'https://portal.iata.org/faq/s/?language=en_US',
		label: 'Troubleshooting',
		isExternal: true,
	},
];

type FooterProps = {
	showQuickLinks?: boolean;
	hideAuthenticatedLinks?: boolean;
	isAuthenticated?: boolean;
};

const Footer = ({
	showQuickLinks = true,
	hideAuthenticatedLinks = false,
	isAuthenticated = false,
}: FooterProps) => {
	const activeLinks = FOOTER_LINKS.filter((link) => {
		if (link.isAuthenticationRequired) {
			return isAuthenticated && !hideAuthenticatedLinks;
		}

		return true;
	});

	return (
		<footer className="footer">
			<img
				className="footer__logo"
				src="/img/iata-logo.svg"
				loading="lazy"
				alt="IATA Logo"
			/>
			<div className="footer__content">
				<div className="footer__primary">
					<div className="footer__slogan">
						Our mission is to represent, lead and serve the airline industry
					</div>
				</div>
				<div className="footer__secondary">
					<div className="footer__copyright">
						&copy; International Air Transport Association (IATA)&nbsp;
						{new Date().getFullYear()}. All rights reserved.
					</div>
					{showQuickLinks && (
						<ul className="footer__links">
							{activeLinks.map((link, linkIndex) => (
								<li className="footer__link" key={linkIndex}>
									<a
										href={link.href}
										target={link.isExternal ? '_blank' : undefined}
										rel="noreferrer"
									>
										{link.label}
									</a>
								</li>
							))}
						</ul>
					)}
				</div>
			</div>
		</footer>
	);
};

export default Footer;
