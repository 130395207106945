import FormField, { FormFieldProps } from 'ui/components/FormField/FormField';
import MultiSelect, {
	MultiSelectProps,
} from 'ui/components/MultiSelect/MultiSelect';
import useValidation from 'ui/components/ValidatedForm/useValidation';

/******************************************
  WARNING: Temporary component to get started
  needs improvement to bring the native select options with it
	rather than accepting one by one
******************************************/

interface MultiDropdownFieldProps<T>
	extends FormFieldProps,
		MultiSelectProps<T> {
	name: string;
}

export function MultiDropdownField<T>(
	props: Omit<MultiDropdownFieldProps<T>, 'children'>
) {
	const { label } = props;

	const { errorTree, revalidate } = useValidation(props.name);

	return (
		<FormField
			label={label}
			errors={errorTree?._errors}
			isRequired={props.isRequired}
		>
			<MultiSelect
				{...props}
				onOptionsSelected={(o) => {
					props.onOptionsSelected?.(o);
					revalidate();
				}}
			/>
		</FormField>
	);
}
