import { useCallback, useState } from 'react';

export type OrderedSet<T> = {
	add: (item: T) => void;
	remove: (item: T) => boolean;
	contains: (item: T) => boolean;
	peek: (nth?: number) => T | undefined;
	size: () => number;
	clear: () => void;
	set: readonly T[];
};

const useOrderedSet = <T>(): OrderedSet<T> => {
	const [orderedSet, setOrderedSet] = useState<T[]>([]);

	const add = useCallback((item: T) => {
		setOrderedSet((prevOrderedSet) => {
			const itemIndex = prevOrderedSet.indexOf(item);
			if (itemIndex !== -1) {
				return [
					...prevOrderedSet.slice(0, itemIndex),
					...prevOrderedSet.slice(itemIndex + 1),
					item,
				];
			} else {
				return [...prevOrderedSet, item];
			}
		});
	}, []);

	const remove = useCallback((item: T): boolean => {
		let itemRemoved = false;
		setOrderedSet((prevOrderedSet) => {
			const itemIndex = prevOrderedSet.indexOf(item);
			if (itemIndex !== -1) {
				itemRemoved = true;
				return [
					...prevOrderedSet.slice(0, itemIndex),
					...prevOrderedSet.slice(itemIndex + 1),
				];
			}
			return prevOrderedSet;
		});
		return itemRemoved;
	}, []);

	const contains = useCallback(
		(item: T): boolean => {
			return orderedSet.includes(item);
		},
		[orderedSet]
	);

	const size = useCallback(() => {
		return orderedSet.length;
	}, [orderedSet]);

	const clear = useCallback(() => {
		setOrderedSet([]);
	}, []);

	const peek = useCallback(
		(nth = 1): T | undefined => {
			return orderedSet[orderedSet.length - nth];
		},
		[orderedSet]
	);

	return {
		add,
		remove,
		contains,
		size,
		clear,
		peek,
		set: Object.freeze([...orderedSet]),
	};
};

export default useOrderedSet;
