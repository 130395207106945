import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

type CardHeaderProps = PropsWithChildren<{
	className?: string;
}>;

const CardHeader = ({ children, className }: CardHeaderProps) => {
	return (
		<div className={classNames('card__header', className)}>{children}</div>
	);
};

export default CardHeader;
