import { createColumnHelper } from '@tanstack/react-table';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import DateFragment from 'ui/components/DateFragment';
import { AirImpactReportModel } from 'utils/schemas/airImpactReportSchema';

const columnHelper = createColumnHelper<AirImpactReportModel>();

export const titleColumn = columnHelper.accessor('title', {
	header: 'Title',
});

export const editionColumn = columnHelper.accessor('edition', {
	header: 'Edition',
	cell: (info) => {
		const { publicationId } = useParams<{ publicationId: string }>();
		const [searchParams] = useSearchParams();

		return (
			<Link to={`/publications/${info.row.original.id}?${searchParams}`}>
				{publicationId === info.row.original.id ? (
					<b>{info.getValue()}</b>
				) : (
					info.getValue()
				)}
			</Link>
		);
	},
});

export const publicationDateColumn = columnHelper.accessor('publicationDate', {
	header: 'Publication Date',
	cell: (info) => <DateFragment date={info.getValue()} />,
});
