import classNames from 'classnames';
import { MoreHorizontal } from 'lucide-react';

type PaginationEllipsisProps = {};

const PaginationEllipsis = ({}: PaginationEllipsisProps) => {
	return (
		<li className={classNames('pagination__item pagination__item--ellipsis')}>
			<MoreHorizontal size={14} />
		</li>
	);
};

export default PaginationEllipsis;
