import { ComponentProps, createContext } from 'react';
import { Form as RouterForm } from 'react-router-dom';
import { ZodFormattedErrors } from 'utils/zod/zodErrors';

type ValidatedFormContextValue = {
	hasSubmitted: boolean;
	errorTree?: ZodFormattedErrors;
	revalidate: () => void;
	method: ComponentProps<typeof RouterForm>['method'];
	formRef: React.RefObject<HTMLFormElement>;
};

const ValidatedFormContext = createContext<ValidatedFormContextValue>(null!);

export default ValidatedFormContext;
