import { createColumnHelper } from '@tanstack/react-table';
import AttachmentLink from 'ui/components/AttachmentLink';
import DateFragment from 'ui/components/DateFragment';
import { AdhocReport } from '../../api/ReportHubAPI';

const columnHelper = createColumnHelper<AdhocReport>();

export const titleColumn = columnHelper.accessor('title', {
	header: 'Title',
	enableSorting: true,
});

export const reportDateColumn = columnHelper.accessor('reportDate', {
	header: 'Report Date',
	cell: (info) => <DateFragment includeTime={false} date={info.getValue()} />,
	enableSorting: true,
});

export const attachmentColumn = columnHelper.accessor('filename', {
	header: 'Attachment',
	cell: (info) => {
		return (
			<AttachmentLink
				url={`/report-hub/adhoc-reports/download/${info.row.original.id}`}
				target="_blank"
				name={info.row.original.filename}
				size={info.row.original.fileSizeInBytes}
			/>
		);
	},
});
