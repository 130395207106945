import { ENVIRONMENT_SHARED } from 'environment';
import API from 'utils/api/API';
import { ValidationResult } from '../helpers/codeValidation';

export type CASSAreaOption = {
	name: string;
	code: string;
	agentCountryCode: string;
	reportingCurrency: string;
};

export type GetCASSAreasResponse = {
	keyword: string;
	options: CASSAreaOption[];
};

export type ValidateCASSAreaCodesResponse = ValidationResult<CASSAreaOption>;
class CASSAreasAPI extends API {
	protected static readonly apiEndpoint =
		ENVIRONMENT_SHARED.subscriptionsApiEndpoint;

	static async getCASSAreas(keyword: string) {
		return CASSAreasAPI.get<GetCASSAreasResponse>(
			`/cass-areas/lookup?Keyword=${keyword}`
		);
	}

	static async validateCASSAreaCodes(codes: string[]) {
		const params = new URLSearchParams({
			codes: codes.join(','),
		});

		return CASSAreasAPI.get<ValidateCASSAreaCodesResponse>(
			`/cass-areas/validate?${params.toString()}`
		);
	}
}

export default CASSAreasAPI;
