import { FreightForwarderSingleOption } from 'utils/api/FreightForwarderAPI';

type FreightForwarderListProps = {
	freightForwarders: Pick<FreightForwarderSingleOption, 'name' | 'code'>[];
};

const FreightForwarderList = ({
	freightForwarders,
}: FreightForwarderListProps) => {
	return (
		<>
			{freightForwarders.map((freightForwarder, index) => {
				const hasCode = !!freightForwarder.code;
				const isLast = index === freightForwarders.length - 1;

				return (
					<>
						<span
							title={
								hasCode
									? `${freightForwarder.name} (${freightForwarder.code})`
									: undefined
							}
						>
							{hasCode ? freightForwarder.code : freightForwarder.name}
						</span>
						{!isLast && ', '}
					</>
				);
			})}
		</>
	);
};

export default FreightForwarderList;
