import { LayoutRouteProps, LoaderFunctionArgs } from 'react-router-dom';
import DownloadPage from 'ui/pages/DownloadPage/DownloadPage';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import ReportHubAPI from '../../api/ReportHubAPI';

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	const reportIds = params.reportIds as string;
	const url = new URL(request.url);

	const downloadAll = reportIds === 'all';

	const file = downloadAll
		? await ReportHubAPI.downloadAllAdhocReports(url.searchParams)
		: await ReportHubAPI.downloadAdhocReports(reportIds.split(','));

	downloadFile(file.url, file.filename);

	return null;
};

function downloadFile(exportFileUrl: string, fileName: string): void {
	const link = document.createElement('a');
	link.setAttribute('href', exportFileUrl);
	link.setAttribute('download', fileName);
	link.click();
}

export const REPORT_HUB_ADHOC_REPORTS_DOWNLOAD_ROUTE: LayoutRouteProps = {
	loader: loader,
	element: <DownloadPage />,
};
