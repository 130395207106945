import Pill from 'ui/components/Pill';
import { Location } from 'utils/api/common';

type LocationPickerContentProps = {
	location: Location;
};

const LocationPickerContent = ({ location }: LocationPickerContentProps) => {
	/**
	 * Styles:
	 * Station → <code> | <Name>,<Country Code>
	 *
	 * City → <City Name> | <Country Name>
	 * Subline: “Includes (“<Station Codes in City>”)”
	 *
	 * State → <State Code> | <State Name>
	 *
	 * Country → <Country Code> | <Country Name>
	 *
	 * Region → <CargoIS Region Name or TACT Area Name>
	 * Subline: “Includes (“<Country Codes in Region>”)”
	 */

	switch (location.itemType) {
		case 'station':
			return (
				<Pill
					code={location.code}
					name={`${location.name}, ${location.countryCode}`}
				/>
			);
		case 'country':
			return <Pill name={location.name} code={location.code} />;
		case 'city':
			return (
				<>
					<Pill
						code={location.code}
						name={`${location.name}, ${location.countryName}`}
					/>
					<p>Includes ({location.stations.map((m) => m.code).join(', ')})</p>
				</>
			);
		case 'province':
			return <Pill code={location.code} name={location.name} />;
		case 'region':
			return (
				<>
					<Pill name={location.name} />
					<p>Includes ({location.countries.map((m) => m.code).join(', ')})</p>
				</>
			);
		case 'custom-group':
			return <>{location.name}</>;
	}
};

export default LocationPickerContent;
