import APIError from 'utils/errors/APIError';
import Alert from '../Alert';

type ErrorListProps = {
	title?: string;
	text?: string | null;
	error?: unknown;
};

const ErrorList = ({
	title = 'There was a problem with your request',
	text = 'The following error(s) occured during your request:',
	error,
}: ErrorListProps) => {
	if (!error) return null;
	if (!(error instanceof Error)) return null;

	return (
		<Alert title={title} intent="error">
			{text}
			{error instanceof APIError && error.fieldErrors ? (
				<ul>
					{error.fieldErrors.map(([key, value]) => (
						<li key={key}>
							{key && (
								<>
									<b>{key}</b>:{' '}
								</>
							)}
							{value}
						</li>
					))}
				</ul>
			) : (
				<div>
					<b>An unknown error occured.</b>
				</div>
			)}
		</Alert>
	);
};

export default ErrorList;
