import { Trash2 } from 'lucide-react';
import Button from 'ui/components/Button';
import Modal, { ModalProps } from 'ui/components/Modal/Modal';
import ValidatedForm from 'ui/components/ValidatedForm';
import { WebToolGroupItem } from 'utils/api/WebToolGroupAPI';
import useSubmitting from 'utils/hooks/useSubmitting';

type DeleteWebAnnouncementProps = {
	action: string;
	webToolGroup: WebToolGroupItem;
} & Omit<ModalProps, 'title'>;

const DeleteWebToolGroupModal = ({
	webToolGroup: webToolGroup,
	...modalProps
}: DeleteWebAnnouncementProps) => {
	const isSubmitting = useSubmitting();

	return (
		<Modal title="Delete Web Tool Group" {...modalProps}>
			<ValidatedForm action={modalProps.action} method="delete">
				<Modal.Body>
					<p>
						Are you sure you wish to delete the web tool group{' '}
						<b>{webToolGroup.name}</b>? This action cannot be undone.
					</p>
				</Modal.Body>
				<Modal.Actions>
					<Button
						type="submit"
						intent="danger"
						icon={Trash2}
						data-cy="deleteButton"
						isLoading={isSubmitting}
					>
						Delete Web Tool Group
					</Button>
				</Modal.Actions>
			</ValidatedForm>
		</Modal>
	);
};

export default DeleteWebToolGroupModal;
