import classNames from 'classnames';
import { CSSProperties, PropsWithChildren } from 'react';

type ModalBodyProps = PropsWithChildren<{
	className?: string;
	style?: CSSProperties;
	isMuted?: boolean;
}>;

const ModalBody = ({ children, className, style, isMuted }: ModalBodyProps) => {
	return (
		<div
			className={classNames(
				'modal__body',
				isMuted && 'modal__body--muted',
				className
			)}
			data-testid="modal-body"
			style={style}
		>
			{children}
		</div>
	);
};

export default ModalBody;
