import { RefreshCcw, Search } from 'lucide-react';
import {
	LayoutRouteProps,
	LoaderFunctionArgs,
	useLoaderData,
	useLocation,
} from 'react-router-dom';
import Button from 'ui/components/Button';
import ExportButton from 'ui/components/ExportButton';
import Flex from 'ui/components/Flex';
import FormField from 'ui/components/FormField';
import LocationPicker from 'ui/components/LocationPicker/LocationPicker';
import PageHeader from 'ui/components/PageHeader';
import Pagination from 'ui/components/Pagination';
import Select from 'ui/components/Select';
import Sidebar from 'ui/components/Sidebar';
import Table from 'ui/components/Table';
import TextField from 'ui/components/TextField';
import ValidatedForm from 'ui/components/ValidatedForm';
import { LocationType } from 'utils/api/LocationAPI';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { LoaderData } from 'utils/types/loaderData';
import ReferenceDataAPI from '../../api/ReferenceDataAPI';
import * as regionReferenceDataColumns from '../../utils/columns/regionReferenceDataColumns';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);

	const requestUrl = new URL(request.url);
	const getRegionsResponse = await ReferenceDataAPI.getRegions(
		requestUrl.searchParams
	);

	return {
		regions: getRegionsResponse.regions,
	};
};

export function ListRegionsPage() {
	const data = useLoaderData() as LoaderData<typeof loader>;
	const location = useLocation();

	const columns = [
		regionReferenceDataColumns.countryNameColumn,
		regionReferenceDataColumns.countryCodeColumn,
		regionReferenceDataColumns.provinceNameColumn,
		regionReferenceDataColumns.provinceCodeColumn,
		regionReferenceDataColumns.cargoISRegionColumn,
		regionReferenceDataColumns.tactRegionColumn,
		regionReferenceDataColumns.isActiveColumn,
	];

	const exportData = (format: string) => {
		const searchParams = new URLSearchParams(location.search);
		const newWindow = window.open(
			`/reference-data/region/export?exportType=${format}&${searchParams}`,
			'_blank'
		);

		newWindow?.blur();
		window.focus();
	};

	return (
		<Sidebar.Wrapper>
			<div className="content">
				<PageHeader title="Region Reference Data">
					<ExportButton onExport={exportData} fallbackFormatId="xlsx" />
				</PageHeader>

				<Flex direction="column" gap={16}>
					<Table
						columns={columns}
						data={data.regions.items}
						identifierKey="key"
						emptyText="No regions found."
					/>

					<Pagination
						baseUrl={new URL(window.location.href)}
						page={data.regions.page}
						pageParameterName="page"
						pageSize={data.regions.pageSize}
						itemCount={data.regions.totalCount}
					/>
				</Flex>
			</div>

			<Sidebar title="Region filters">
				<ValidatedForm resetOnNavigation>
					<TextField name="keyword" label="Keyword" />

					<LocationPicker
						name="locationCodes"
						label="Locations"
						isMulti
						locationTypes={[
							LocationType.COUNTRY,
							LocationType.REGION,
							LocationType.PROVINCE,
						]}
					/>

					<FormField label="Active Status">
						<Select
							name="status"
							placeholder="Select status..."
							options={[
								{ label: 'Active', value: 'active' },
								{ label: 'Inactive', value: 'inactive' },
							]}
							identifierKey="value"
							contentSource={(o) => o.label}
						/>
					</FormField>

					<Sidebar.Actions>
						<Button variant="secondary" icon={RefreshCcw} type="reset">
							Clear search
						</Button>
						<Button variant="primary" icon={Search} type="submit">
							Search
						</Button>
					</Sidebar.Actions>
				</ValidatedForm>
			</Sidebar>
		</Sidebar.Wrapper>
	);
}

export const LIST_REGIONS_ROUTE: LayoutRouteProps = {
	loader: loader,
	element: <ListRegionsPage />,
	handle: {
		crumb: () => 'Regions',
	},
};
