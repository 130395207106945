import classNames from 'classnames';
import { useState } from 'react';
import Flex from 'ui/components/Flex';
import Tooltip from 'ui/components/Tooltip/Tooltip';
import pluralize from 'utils/helpers/pluralize';

type ReportOutputPopoverProps = {
	parameters: { name: string; value: string[] }[];
	columns: string[];
};

const ReportOutputPopover = ({
	parameters,
	columns,
}: ReportOutputPopoverProps) => {
	const [isPopoverOpen, setPopoverOpen] = useState(false);
	const [isOutputPopoverOpen, setOutputPopoverOpen] = useState(false);

	return (
		<>
			<Tooltip
				open={isPopoverOpen}
				onOpenChange={setPopoverOpen}
				disabled={parameters.length === 0}
			>
				<Tooltip.Trigger>
					<span
						className={classNames(
							'tooltip-link',
							parameters.length === 0 && 'tooltip-link--disabled'
						)}
						onClick={() => setPopoverOpen(true)}
					>
						{parameters.length} parameters
					</span>
				</Tooltip.Trigger>
				<Tooltip.Content>
					<Flex direction="column" gap={12}>
						<b>Report Parameters:</b>
						<ul>
							{parameters.map((param) => (
								<li key={param.name}>
									<i>{param.name}</i>: {param.value.join(', ')}
								</li>
							))}
						</ul>
					</Flex>
				</Tooltip.Content>
			</Tooltip>
			,{' '}
			<Tooltip
				open={isOutputPopoverOpen}
				onOpenChange={setOutputPopoverOpen}
				disabled={columns.length === 0}
			>
				<Tooltip.Trigger>
					<span
						className={classNames(
							'tooltip-link',
							columns.length === 0 && 'tooltip-link--disabled'
						)}
						onClick={() => setOutputPopoverOpen(true)}
					>
						Output: {columns.length} {pluralize('Column', columns.length)}
					</span>
				</Tooltip.Trigger>
				<Tooltip.Content size="large">
					<ul>
						{columns.map((column) => (
							<li key={column}>{column}</li>
						))}
					</ul>
				</Tooltip.Content>
			</Tooltip>
		</>
	);
};

export default ReportOutputPopover;
