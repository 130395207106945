import { ChevronLeft, ChevronRight } from 'lucide-react';
import React, { PropsWithChildren, useMemo, useState } from 'react';
import Tooltip from '../Tooltip/Tooltip';

const defaultMax: number = 3;

export type PillDrawerProps = PropsWithChildren<{
	maxItems?: number;
}>;

const PillDrawer = ({ children, maxItems = defaultMax }: PillDrawerProps) => {
	const [showAll, setShowAll] = useState(false);

	const toggleShowAll = () => {
		setShowAll(!showAll);
	};

	const shouldTruncate = React.Children.count(children) > maxItems + 1;
	const remainingItems = React.Children.count(children) - maxItems;

	const truncatedChildren = useMemo(
		() => React.Children.toArray(children).slice(0, maxItems),
		[children, maxItems]
	);

	const ChevronIcon = showAll ? ChevronLeft : ChevronRight;

	return (
		<div className="pill-drawer">
			{!showAll && shouldTruncate ? truncatedChildren : children}

			{/* Subtract 1, because once we show the button we have an extra item. Not worth showing expand for 1 item. */}
			{shouldTruncate && (
				<Tooltip disabled={showAll} detachTriggerWhileDisabled>
					<Tooltip.Trigger>
						<button className="pill-drawer__more" onClick={toggleShowAll}>
							<ChevronIcon size="16" />
						</button>
					</Tooltip.Trigger>
					<Tooltip.Content>Show {remainingItems} more</Tooltip.Content>
				</Tooltip>
			)}
		</div>
	);
};

export default PillDrawer;
