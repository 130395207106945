import { useId } from 'react';
import FormField, { FormFieldProps } from 'ui/components/FormField';
import useValidation from 'ui/components/ValidatedForm/useValidation';
import WebToolDatePicker, {
	WebToolDatePickerProps,
} from '../WebToolDatePicker/WebToolDatePicker';

type WebToolDateFieldProps = FormFieldProps & WebToolDatePickerProps;

const WebToolDateField = ({
	name,
	label,
	isRequired,
	secondaryLabel,
	warnings,
	errors,
	...restProps
}: WebToolDateFieldProps) => {
	const { errorTree } = useValidation(name);
	const id = useId();

	return (
		<FormField
			id={id}
			label={label}
			errors={errorTree?._errors || errors}
			warnings={warnings}
			isRequired={isRequired}
			secondaryLabel={secondaryLabel}
		>
			<WebToolDatePicker name={name} id={id} {...restProps} />
		</FormField>
	);
};

export default WebToolDateField;
