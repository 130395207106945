import classNames from 'classnames';
import { RefreshCw } from 'lucide-react';
import { MouseEvent, useState } from 'react';
import { useRevalidator } from 'react-router-dom';
import Button, { ButtonProps } from '../Button/Button';

type RevalidationButtonProps = {} & ButtonProps;

const RevalidationButton = ({
	children,
	className,
	onClick,
	...buttonProps
}: RevalidationButtonProps) => {
	const revalidator = useRevalidator();
	const [isAnimationPlaying, setAnimationPlaying] = useState(false);

	const handleClick = (
		e: MouseEvent<HTMLButtonElement> | MouseEvent<HTMLAnchorElement>
	) => {
		setAnimationPlaying(true);
		revalidator.revalidate();
		onClick?.(e as any);
	};

	// We want to only do full animation iterations, so we control when the animation can end
	const handleAnimationIteration = () => {
		if (revalidator.state !== 'loading') {
			setAnimationPlaying(false);
		}
	};

	return (
		<Button
			variant="secondary"
			icon={RefreshCw}
			iconPosition="left"
			className={classNames(
				'revalidation-button',
				isAnimationPlaying && 'revalidation-button--loading',
				className
			)}
			onClick={handleClick}
			onAnimationIterationCapture={handleAnimationIteration}
			{...buttonProps}
		>
			{children || 'Revalidate'}
		</Button>
	);
};

export default RevalidationButton;
