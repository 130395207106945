import { Loader2 } from 'lucide-react';
import { useMemo } from 'react';
import { useAsyncError } from 'react-router-dom';

type WebToolWorksheetLoaderProps = {
	text?: string;
};

const WebToolWorksheetLoader = ({
	text = 'Loading worksheet',
}: WebToolWorksheetLoaderProps) => {
	const error = useAsyncError();

	useMemo(() => {
		if (error) {
			console.error(error);
		}
	}, [error]);

	return (
		<div className="webtool__loader loader">
			<Loader2 className="loader__icon" size={24} />
			<h1 className="loader__title">{text}</h1>
		</div>
	);
};

export default WebToolWorksheetLoader;
