import z from 'zod';
import {
	zArrayOfStringRequired,
	zEnsureArrayPreprocessor,
} from 'utils/zod/zodValidation';

const stationsSchema = z.preprocess(
	zEnsureArrayPreprocessor,
	zArrayOfStringRequired('Airports is required')
);

export const webToolStationGroupSchema = z.object({
	id: z.string(),
	name: z
		.string({})
		.min(1, { message: 'Name is required' })
		.max(100, { message: 'Name must not be more than 100 characters' }),
	description: z
		.string({})
		.min(1, { message: 'Description is required' })
		.max(250, { message: 'Description must not be more than 250 characters' }),
	locations: stationsSchema,
});

export const createWebToolStationGroupSchema = webToolStationGroupSchema.pick({
	name: true,
	description: true,
	locations: true,
});

export const updateWebToolStationGroupSchema = webToolStationGroupSchema.pick({
	name: true,
	description: true,
	locations: true,
});

export type CreateWebToolStationGroupModel = z.infer<
	typeof createWebToolStationGroupSchema
>;
export type UpdateWebToolStationGroupModel = z.infer<
	typeof updateWebToolStationGroupSchema
>;
