import { ReactNode } from 'react';

export type BulletIndicatorProps = {
	intent: 'success' | 'warning' | 'error' | 'info';
	label?: string | ReactNode;
	toolTip?: string;
};

export function matchIntent<T, K extends keyof T>(
	value: keyof T,
	valueMatch: {
		success?: K;
		info?: K;
		warning?: K;
		error?: K;
		catchAll?: BulletIndicatorProps['intent'];
	}
): BulletIndicatorProps['intent'] {
	switch (value) {
		case valueMatch['success']:
			return 'success';
		case valueMatch['info']:
			return 'info';
		case valueMatch['warning']:
			return 'warning';
		case valueMatch['error']:
			return 'error';
		default:
			return valueMatch['catchAll'] ?? 'error';
	}
}

const BulletIndicator = ({ intent, label, toolTip }: BulletIndicatorProps) => {
	return (
		<div className="bullet-indicator" title={toolTip}>
			<span
				className={`bullet-indicator__bullet bullet-indicator__bullet--${intent}`}
			/>
			{label}
		</div>
	);
};

export default BulletIndicator;

export function getIntentForSubscriptionStatus(status: string) {
	switch (status.toLocaleLowerCase()) {
		case 'enabled':
		case 'publishing':
			return 'success';
		case 'publishing-paused':
			return 'warning';
		case 'disabled':
			return 'error';
		case 'data-testing':
		default:
			return 'info';
	}
}
