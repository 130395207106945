type KeysMatching<T, V> = {
	[K in keyof T]-?: T[K] extends V ? K : never;
}[keyof T];

export type DataSource<T, U = string> = ((data: T) => U) | KeysMatching<T, U>;

export const readByDataSource = <T, U = string>(
	object: T,
	dataSource: DataSource<T, U>
) => {
	if (typeof dataSource === 'function') {
		return dataSource(object);
	}

	return object[dataSource] as U;
};
