import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import formatBytes from 'utils/helpers/formatBytes';

type FilePickerProps = {
	allowedExtensions?: string[];
	multiple?: boolean;
	name?: string;
	hintText?: string;
	id?: string;
	defaultValue?: File[];
};

const FilePicker = ({
	allowedExtensions,
	multiple,
	name,
	id,
	hintText = 'Click here or drop files to select',
	defaultValue = [],
}: FilePickerProps) => {
	const [files, setFiles] = useState<File[]>(defaultValue);
	const [hasFileHover, setFileHover] = useState(false);
	const inputRef = useRef<HTMLInputElement>(null!);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files;
		if (files?.length && files.length > 0) {
			setFiles([...files]);
		}
	};

	const hasExtensionRestriction =
		allowedExtensions && allowedExtensions.length > 0;
	const allowedExtensionsList = hasExtensionRestriction
		? allowedExtensions.map((ext) => ext.toUpperCase()).join(', ')
		: null;

	const handleDragOver = (event: React.DragEvent<HTMLLabelElement>) => {
		event.preventDefault();
	};

	const handleDragEnter = (event: React.DragEvent<HTMLLabelElement>) => {
		event.preventDefault();
		setFileHover(true);
	};

	const handleDragExit = (event: React.DragEvent<HTMLLabelElement>) => {
		event.preventDefault();
		setFileHover(false);
	};

	const handleDrop = (event: React.DragEvent<HTMLLabelElement>) => {
		event.preventDefault();
		setFileHover(false);

		const transfer = event.dataTransfer;
		inputRef.current.files = transfer.files;
		setFiles([...transfer.files]);
	};

	const handleRemoveFile = (file: File) => {
		const newFiles = files.filter((f) => f !== file);

		// Need to reconstruct files because the original file list is immutable
		const transfer = new DataTransfer();

		for (let file of newFiles) transfer.items.add(file);

		if (inputRef.current) {
			inputRef.current.files = transfer.files;
			setFiles([...transfer.files]);
		}
	};

	return (
		<div className="filepicker">
			<label
				className={classNames(
					'filepicker__dropzone',
					hasFileHover && 'filepicker__dropzone--hovered'
				)}
				onDragEnter={handleDragEnter}
				onDragLeave={handleDragExit}
				onDragOver={handleDragOver}
				onDrop={handleDrop}
			>
				<span className="filepicker__hint">{hintText}</span>
				{allowedExtensionsList && allowedExtensionsList.length > 0 && (
					<span className="filepicker__extensions">
						Allowed extensions: {allowedExtensionsList}
					</span>
				)}
				<input
					className="filepicker__input"
					onChange={handleChange}
					multiple={multiple}
					name={name}
					id={id}
					type="file"
					ref={inputRef}
					accept={
						hasExtensionRestriction
							? allowedExtensions.map((ext) => `.${ext}`).join(',')
							: undefined
					}
				/>
			</label>
			{files.length > 0 && (
				<ul className="filepicker__list">
					{files.map((file, fileIndex) => (
						<li className="filepicker__item file" key={fileIndex}>
							<span className="file__name">{file.name}&nbsp;</span>
							<span className="file__size">({formatBytes(file.size)})</span>
							{/* <button
								type="button"
								className="file__delete"
								onClick={() => handleRemoveFile(file)}
							>
								<Trash2 size={18} />
							</button> */}
						</li>
					))}
				</ul>
			)}
		</div>
	);
};

export default FilePicker;
