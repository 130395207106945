import { useMemo } from 'react';
import { LoaderFunctionArgs, Navigate, useOutlet } from 'react-router-dom';
import Tabs, { Tab } from 'ui/components/Tabs/Tabs';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import useRootLoader from 'utils/hooks/useRootLoader';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import { rootLoader } from '../../main';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);

	return null;
};

export function WebToolGroupPage() {
	const rootLoaderData = useRootLoader() as LoaderData<typeof rootLoader>;
	const outlet = useOutlet();

	const station: Tab = {
		name: 'Airports',
		to: '/webtool/groups/station',
		end: false,
	};
	const airlines: Tab = {
		name: 'Airlines',
		to: '/webtool/groups/airline',
		end: false,
	};

	const tabs = useMemo(
		() =>
			rootLoaderData
				? [
						{
							name: 'Weight Breaks',
							to: '/webtool/groups/breaks',
							end: false,
						},
						rootLoaderData.userProfile.sources.totalCass > 0 && station,
						rootLoaderData.userProfile.webToolAirlineCount !== 1 && airlines,
						{
							name: 'Cities',
							to: '/webtool/groups/city',
							end: false,
						},
						{
							name: 'Freight Forwarders',
							to: '/webtool/groups/freight',
							end: false,
						},
						{
							name: 'Trade Lanes',
							to: '/webtool/groups/trade',
							end: false,
						},
						{
							name: 'Geography',
							to: '/webtool/groups/geography',
							end: false,
						},
				  ].filter((tab: Tab | false): tab is Tab => tab !== false)
				: [],
		[rootLoaderData]
	);

	if (tabs.length === 0) {
		return <Navigate to="/" />;
	} else if (!outlet) {
		return <Navigate to={tabs[0].to} />;
	}

	return (
		<>
			{tabs.length > 1 && (
				<Tabs className="page-tabs" tabs={tabs} renderOutlet={false} />
			)}
			{outlet}
		</>
	);
}

export const WEBTOOL_GROUP_AREA_ROUTE: DecentralizedRouteProps = {
	element: <WebToolGroupPage />,
};
