import classNames from 'classnames';
import {
	ArrowLeft,
	DownloadCloud,
	Eye,
	EyeOff,
	Info,
	Loader2,
	RefreshCw,
	XCircle,
} from 'lucide-react';
import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import {
	isRouteErrorResponse,
	useNavigate,
	useRouteError,
} from 'react-router-dom';
import NotAuthenticatedPage from 'ui/pages/NotAuthenticatedPage/NotAuthenticatedPage';
import APIError from 'utils/errors/APIError';
import { checkForUpdates } from 'utils/hooks/useUpdateNotifications';
import Button from '../Button/Button';

type ErrorBoundaryProps = PropsWithChildren<{
	isRoot?: boolean;
}>;

// HTTP error codes
const apiErrorTitles: { [key: number]: string } = {
	400: 'Bad request',
	401: 'Unauthorized',
	403: 'Forbidden',
	404: 'Not found',
	405: 'Method not allowed',
	406: 'Not acceptable',
	408: 'Request timeout',
	409: 'Conflict',
	410: 'Gone',
	411: 'Length required',
	412: 'Precondition failed',
	413: 'Payload too large',
	414: 'URI too long',
	415: 'Unsupported media type',
	416: 'Range not satisfiable',
	417: 'Expectation failed',
	418: "I'm a teapot",
	421: 'Misdirected request',
	422: 'Unprocessable entity',
	423: 'Locked',
	424: 'Failed dependency',
	425: 'Too early',
	426: 'Upgrade required',
	428: 'Precondition required',
	500: 'Internal server error',
	501: 'Not implemented',
	502: 'Bad gateway',
	503: 'Service unavailable',
	504: 'Gateway timeout',
	505: 'HTTP version not supported',
	506: 'Variant also negotiates',
	507: 'Insufficient storage',
	508: 'Loop detected',
	510: 'Not extended',
	511: 'Network authentication required',
};

// HTTP error descriptions in a beginner friendly format
const apiErrorMessages: { [key: number]: string } = {
	400: 'The request was not understood by the server.',
	401: 'You are not authorized to view this page.',
	403: 'Access to this page is forbidden.',
	404: 'The page you are looking for does not exist.',
	405: 'The method specified in the Request-Line is not allowed for the resource identified by the Request-URI.',
	406: 'The resource identified by the request is only capable of generating response entities which have content characteristics not acceptable according to the accept headers sent in the request.',
	408: 'The client did not produce a request within the time that the server was prepared to wait.',
	409: 'The request could not be completed due to a conflict with the current state of the resource.',
	410: 'The requested resource is no longer available at the server and no forwarding address is known.',
	411: 'The server refuses to accept the request without a defined Content- Length.',
	412: 'The precondition given in one or more of the request-header fields evaluated to false when it was tested on the server.',
	413: 'The server is refusing to process a request because the request entity is larger than the server is willing or able to process.',
	414: 'The server is refusing to service the request because the Request-URI is longer than the server is willing to interpret.',
	415: 'The server is refusing to service the request because the entity of the request is in a format not supported by the requested resource for the requested method.',
	416: 'A server SHOULD return a response with this status code if a request included a Range request-header field, and none of the range-specifier values in this field overlap the current extent of the selected resource, and the request did not include an If-Range request-header field.',
	417: 'The expectation given in an Expect request-header field could not be met by this server.',
	418: "This code was defined in 1998 as one of the traditional IETF April Fools' jokes, in RFC 2324, Hyper Text Coffee Pot Control Protocol, and is not expected to be implemented by actual HTTP servers.",
	421: 'The request was directed at a server that is not able to produce a response (for example because of connection reuse).',
	422: 'The request was well-formed but was unable to be followed due to semantic errors.',
	423: 'The resource that is being accessed is locked.',
	424: 'The request failed due to failure of a previous request.',
	425: 'The server is unwilling to risk processing a request that might be replayed.',
	426: 'The server refuses to perform the request using the current protocol but might be willing to do so after the client upgrades to a different protocol.',
	428: 'The origin server requires the request to be conditional.',
	500: 'The server encountered an unexpected condition which prevented it from fulfilling the request.',
	501: 'The server does not support the functionality required to fulfill the request.',
	502: 'The server, while acting as a gateway or proxy, received an invalid response from the upstream server it accessed in attempting to fulfill the request.',
	503: 'The server is currently unable to handle the request due to a temporary overloading or maintenance of the server.',
	504: 'The server, while acting as a gateway or proxy, did not receive a timely response from the upstream server specified by the URI or some other auxiliary server (e.g. DNS) it needed to access in attempting to complete the request.',
	505: 'The server does not support, or refuses to support, the HTTP protocol version that was used in the request message.',
	506: 'The server has an internal configuration error: the chosen variant resource is configured to engage in transparent content negotiation itself, and is therefore not a proper end point in the negotiation process.',
	507: 'The method could not be performed on the resource because the server is unable to store the representation needed to successfully complete the request.',
	508: 'The server detected an infinite loop while processing the request.',
	510: 'Further extensions to the request are required for the server to fulfill it.',
	511: 'The 511 status code indicates that the client needs to authenticate to gain network access.',
};

const ErrorBoundary = ({ isRoot = false }: ErrorBoundaryProps) => {
	const error = useRouteError();
	const navigate = useNavigate();

	const isError = error instanceof Error;
	const isAPIError = error instanceof APIError;

	let errorTitle = 'Unexpected error';
	if (isAPIError && error.status && error.status in apiErrorTitles) {
		errorTitle = apiErrorTitles[error.status];
	} else if (isRouteErrorResponse(error) && error.status in apiErrorTitles) {
		errorTitle = apiErrorTitles[error.status];
	}

	let errorMessage =
		'Please reload the page and try again. If the problem persists, please contact us.';
	if (
		(isAPIError || isRouteErrorResponse(error)) &&
		error.status &&
		error.status in apiErrorMessages
	) {
		errorMessage = apiErrorMessages[error.status];
	} else if (isError) {
		errorMessage = error.message;
	}

	let errorStatus: number | undefined = undefined;
	if ((isAPIError && error.status) || isRouteErrorResponse(error)) {
		errorStatus = error.status;
	}

	const showActualError = import.meta.env.DEV;
	if (showActualError) {
		console.error(error);
	}

	const isUnexpectedError = !errorStatus || errorStatus >= 500;

	const [isCheckingForUpdates, setCheckingForUpdates] =
		useState(isUnexpectedError);
	const [isUpdateAvailable, setUpdateAvailable] = useState(false);
	const [errorDetailsVisible, setErrorDetailsVisible] = useState(false);

	useEffect(() => {
		const asyncWrapper = async () => {
			const updateAvailable = await checkForUpdates();
			setUpdateAvailable(updateAvailable);
			setCheckingForUpdates(false);
		};

		if (isUnexpectedError) {
			asyncWrapper();
		}
	}, []);

	const UpdateIcon = useMemo(() => {
		if (isCheckingForUpdates) return Loader2;
		return isUpdateAvailable ? DownloadCloud : Info;
	}, [isCheckingForUpdates, isUpdateAvailable]);

	const updateText = useMemo(() => {
		if (isCheckingForUpdates)
			return 'Please wait while we are checking whether there are any updates available...';

		return isUpdateAvailable
			? 'A new version of the application is available, which might solve this issue. Reload the page to apply the update.'
			: 'There are no updates available. If this error persists, please contact us.';
	}, [isCheckingForUpdates, isUpdateAvailable]);

	if (isAPIError && error.status === 401) {
		// Needs to render at highest level
		if (isRoot) {
			return <NotAuthenticatedPage />;
		} else {
			throw error;
		}
	}

	return (
		<div
			className={classNames('errorboundary', isRoot && 'errorboundary--root')}
		>
			<div className="errorboundary__content">
				<div className="errorboundary__card">
					{!errorDetailsVisible && (
						<div className="errorboundary__error">
							<XCircle
								className="errorboundary__icon"
								size={32}
								strokeWidth={1.5}
							/>

							<h1 className="errorboundary__title">{errorTitle}</h1>

							<p className="errorboundary__message">{errorMessage}</p>
						</div>
					)}

					{errorDetailsVisible && (
						<pre className="errorboundary__details">
							<code>{isError && error.stack?.trim()}</code>
						</pre>
					)}

					{isUnexpectedError && (
						<div
							className={classNames(
								'errorboundary__update',
								isCheckingForUpdates &&
									'errorboundary__update--check-in-progress'
							)}
						>
							<UpdateIcon className="errorboundary__update-icon" />
							<p>{updateText}</p>
						</div>
					)}

					<div className="errorboundary__actions">
						{isUnexpectedError ? (
							<Button
								variant="secondary"
								size="small"
								icon={errorDetailsVisible ? EyeOff : Eye}
								onClick={() => setErrorDetailsVisible(!errorDetailsVisible)}
								isDisabled={!isError || !error.stack}
							>
								{errorDetailsVisible ? 'Hide details' : 'Show details'}
							</Button>
						) : (
							<Button
								variant="secondary"
								size="small"
								icon={ArrowLeft}
								onClick={() => navigate(-1)}
							>
								Go back
							</Button>
						)}

						<Button
							variant="secondary"
							size="small"
							icon={RefreshCw}
							onClick={() => window.location.reload()}
						>
							Reload page
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ErrorBoundary;
