import { LayoutRouteProps, LoaderFunctionArgs } from 'react-router-dom';
import DownloadPage from 'ui/pages/DownloadPage/DownloadPage';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import portalUsageTrackingApi from '../../api/PortalUsageTrackingAPI';
import ReportHubAPI from '../../api/ReportHubAPI';
import urlPathName from '../../utils/urlPathName';

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	const reportIds = params.reportIds as string;
	const path = urlPathName(request);
	const url = new URL(request.url);

	const downloadAll = reportIds === 'all';

	const file = downloadAll
		? await ReportHubAPI.downloadAllReports(url.searchParams)
		: await ReportHubAPI.downloadReports(reportIds.split(','));

	if (file.originalFileName !== undefined && file.originalFileName.length > 0) {
		await portalUsageTrackingApi.sendUsageTrackingDownloadRequest(
			path,
			file.originalFileName
		);
	}

	downloadFile(file.url, file.filename);

	return null;
};

function downloadFile(exportFileUrl: string, fileName: string): void {
	const link = document.createElement('a');
	link.setAttribute('href', exportFileUrl);
	link.setAttribute('download', fileName);
	link.click();
}

export const REPORT_HUB_DOWNLOAD_ROUTE: LayoutRouteProps = {
	loader: loader,
	element: <DownloadPage />,
};
