import z from 'zod';
import { weightBreaksSchema } from 'utils/schemas/weightBreakSchema';

export const webToolBreaksGroupSchema = z.object({
	id: z.string(),
	name: z
		.string({})
		.min(1, { message: 'Name is required' })
		.max(100, { message: 'Name must not be more than 100 characters' }),
	description: z
		.string({})
		.min(1, { message: 'Description is required' })
		.max(250, { message: 'Description must not be more than 250 characters' }),
	weightBreaks: weightBreaksSchema.required(
		"'Weight Breaks' must be different to the IATA default"
	),
});

export const createWebToolBreaksGroupSchema = webToolBreaksGroupSchema.pick({
	name: true,
	description: true,
	weightBreaks: true,
});

export const updateWebToolBreaksGroupSchema = webToolBreaksGroupSchema.pick({
	name: true,
	description: true,
	weightBreaks: true,
});

export type CreateWebToolBreaksGroupModel = z.infer<
	typeof createWebToolBreaksGroupSchema
>;
export type UpdateWebToolBreaksGroupModel = z.infer<
	typeof updateWebToolBreaksGroupSchema
>;
