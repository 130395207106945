export const isPrimitiveArrayEqual = <T extends string | number | boolean>(
	arr1: T[],
	arr2: T[],
	isOrderRelevant = true
): boolean => {
	if (arr1.length !== arr2.length) {
		return false;
	}

	if (isOrderRelevant) {
		return arr1.every((item, index) => item === arr2[index]);
	}

	// Consider duplicate values, so cannot use includes for every item
	const arr1Json = JSON.stringify([...arr1].sort());
	const arr2Json = JSON.stringify([...arr2].sort());

	return arr1Json === arr2Json;
};

export function distinct<T>(arr: T[]) {
	return [...new Set(arr)];
}
