import { PlusCircle } from 'lucide-react';
import React, { useCallback } from 'react';
import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import Alert from 'ui/components/Alert';
import BulletIndicator from 'ui/components/BulletIndicator';
import Button from 'ui/components/Button/Button';
import DateFragment from 'ui/components/DateFragment';
import Flex from 'ui/components/Flex/Flex';
import Grid from 'ui/components/Grid';
import KeyValuePairs from 'ui/components/KeyValuePairs/KeyValuePairs';
import PageHeader from 'ui/components/PageHeader';
import Pagination from 'ui/components/Pagination';
import RevalidationButton from 'ui/components/RevalidationButton';
import Table from 'ui/components/Table';
import CddContributorAPI, { CddErrorCode } from 'utils/api/CddContributorAPI';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import * as cddContributorColumns from '../../utils/columns/cddContributorColumns';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	return await CddContributorAPI.getCddContributorList(
		new URL(request.url).searchParams
	);
};

export function CddContributorListPage() {
	const data = useLoaderData() as LoaderData<typeof loader>;
	const codeDescriptions = React.useMemo(() => {
		const codesMap: Record<string, CddErrorCode> = {};
		for (const errorCode of data.errorCodes) {
			codesMap[errorCode.code] = errorCode;
		}
		return codesMap;
	}, [data.errorCodes]);

	const columns = React.useMemo(
		() => [
			cddContributorColumns.contributorPrefixColumn,
			cddContributorColumns.externalFileColumn,
			cddContributorColumns.receivedAtColumn,
			cddContributorColumns.statusColumn(codeDescriptions),
			cddContributorColumns.statusContributorColumn,
			cddContributorColumns.startedAtColumn,
			cddContributorColumns.completedAtColumn,
			cddContributorColumns.rowCountColumn,
			cddContributorColumns.downloadReportColumn,
		],
		[codeDescriptions]
	);

	const getIntentForSubscriptionStatus = useCallback((status: string) => {
		switch (status.toLocaleLowerCase()) {
			case 'publishing':
				return 'success';
			case 'publishing-paused':
				return 'warning';
			case 'data-testing':
			default:
				return 'info';
		}
	}, []);

	return (
		<>
			<div className="content">
				<PageHeader
					title={
						<Flex direction="column" gap={12}>
							<span>CDD Submissions</span>
							{data.subscriptions.length === 1 && (
								<BulletIndicator
									intent={getIntentForSubscriptionStatus(
										data.subscriptions[0].status.value
									)}
									label={data.subscriptions[0].status.label}
								/>
							)}
						</Flex>
					}
				>
					<RevalidationButton>Refresh</RevalidationButton>
					<Button
						variant="secondary"
						icon={PlusCircle}
						to="/cdd-contributor/upload"
					>
						Add CDD Submission
					</Button>
				</PageHeader>

				{data.subscriptions.length > 1 && (
					<KeyValuePairs
						entries={data.subscriptions.map((subscription) => ({
							key: subscription.name,
							value: (
								<BulletIndicator
									intent={getIntentForSubscriptionStatus(
										subscription.status.value
									)}
									label={subscription.status.label}
								/>
							),
						}))}
					/>
				)}

				{data.pending.length > 0 &&
					data.pending.map((file) => (
						<Alert intent="info" key={file.id} title="">
							{file.externalFile} uploaded at{' '}
							<DateFragment date={file.receivedAt} includeTime />, adding to the
							queue...
						</Alert>
					))}
				<Grid columns={undefined}>
					<Flex direction="column" gap={16}>
						{data.paused && (
							<>
								<Alert title="CDD Submissions paused" intent="warning">
									CDD Submission processing is currently paused
								</Alert>
							</>
						)}
						<Table
							columns={columns}
							data={data.submissions.items}
							identifierKey="jobId"
							emptyText="No submissions found."
						/>
						<Pagination
							baseUrl={new URL(window.location.href)}
							page={data.submissions.page}
							pageParameterName="page"
							pageSize={data.submissions.pageSize}
							itemCount={data.submissions.totalCount}
						/>
					</Flex>
				</Grid>
			</div>
		</>
	);
}

export const LIST_CDD_CONTRIBUTOR_ROUTE: DecentralizedRouteProps = {
	loader: loader,
	element: <CddContributorListPage />,
};
