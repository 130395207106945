import { FloatingOverlay } from '@floating-ui/react';
import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';
import { ArrowLeft, X } from 'lucide-react';
import { MouseEvent, PropsWithChildren, useEffect, useId } from 'react';
import Portal from '../Portal';
import ModalActions from './ModalActions';
import ModalBody from './ModalBody';

type SoftStoppableMouseEvent = MouseEvent & {
	softStopPropagation?: boolean;
};

export type ModalProps = PropsWithChildren<{
	title: string;
	description?: string;
	size?: 'sm' | 'md' | 'lg' | 'xl';
	isOpen: boolean;
	onClose: () => void;
	onBack?: () => void;
}>;

const Modal = ({
	children,
	title,
	size = 'md',
	description,
	isOpen,
	onClose,
	onBack,
}: ModalProps) => {
	const wrapperId = useId();

	const handleKeyDown = (event: KeyboardEvent) => {
		if (isOpen && event.key === 'Escape') {
			onClose();
		}
	};

	useEffect(() => {
		document.body.style.overflow = isOpen ? 'hidden' : 'unset';

		if (isOpen) {
			document.body.addEventListener('keydown', handleKeyDown);
		} else {
			document.body.removeEventListener('keydown', handleKeyDown);
		}

		return () => {
			document.body.style.overflow = 'unset';
			document.body.removeEventListener('keydown', handleKeyDown);
		};
	}, [isOpen]);

	if (!isOpen) return null;

	return (
		<Portal wrapperId={wrapperId}>
			<FloatingOverlay
				lockScroll
				className={classNames('modal', `modal--size-${size}`)}
				onMouseDown={(e: SoftStoppableMouseEvent) => {
					if (!e.softStopPropagation) {
						onClose();
					}
				}}
			>
				<FocusTrap
					focusTrapOptions={{
						fallbackFocus: document.body,
						allowOutsideClick: true,
					}}
				>
					<div
						className="modal__content"
						onMouseDown={(e: SoftStoppableMouseEvent) => {
							e.softStopPropagation = true;
						}}
					>
						<div className="modal__header">
							<div className="modal__header-content">
								<div className="modal__header-main">
									{onBack && (
										<button
											className="modal__back"
											onClick={(e) => {
												e.preventDefault();
												e.stopPropagation();
												onBack();
											}}
										>
											<ArrowLeft size={16} />
										</button>
									)}

									<h2 className="modal__title">{title}</h2>
								</div>
								{description && (
									<p className="modal__description">{description}</p>
								)}
							</div>
							<button className="modal__close" onClick={onClose}>
								<X size={16} />
							</button>
						</div>
						{children}
					</div>
				</FocusTrap>
			</FloatingOverlay>
		</Portal>
	);
};

Modal.Actions = ModalActions;
Modal.Body = ModalBody;

export default Modal;
