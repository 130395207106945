import { formatInTimeZone, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { useMemo, useState } from 'react';
import ReactDatepicker from 'react-datepicker';
import useFormReset from '../ValidatedForm/useFormReset';
import MonthPickerHeader from './MonthPickerHeader';
import { POPPER_MODIFIER } from '../DatePicker/DatePicker';

export type MonthPickerProps = {
	value?: Date | null;

	name?: string;
	onChange?: (date: Date | null) => void;
	initialValue?: Date;
	id?: string;
	isDisabled?: boolean;
	placeholder?: string;

	minDate?: Date;
	maxDate?: Date;

	dateFormat?: string;
	dateFormatCalendar?: string;
};

const MonthPicker = ({
	value: controlledSelectedDate,

	name,
	onChange: onConntrolledSelectedDateChange,
	id,
	initialValue,
	isDisabled,
	placeholder,

	minDate,
	maxDate,

	dateFormat = 'MMMM yyyy',
	dateFormatCalendar = 'MMMM yyyy',
}: MonthPickerProps) => {
	const [uncontrolledSelectedDate, setUncontrolledSelectedDate] =
		useState<Date | null>(initialValue ?? null);

	const selectedDate = controlledSelectedDate ?? uncontrolledSelectedDate;
	const onSelectedDateChange =
		onConntrolledSelectedDateChange ?? setUncontrolledSelectedDate;

	const clampedSelectedDate = useMemo(() => {
		if (!selectedDate) return null;

		if (minDate && selectedDate < minDate) {
			return minDate;
		}

		if (maxDate && selectedDate > maxDate) {
			return maxDate;
		}

		return utcToZonedTime(selectedDate, 'UTC');
	}, [selectedDate, minDate, maxDate]);

	const formattedValue = useMemo(() => {
		if (!clampedSelectedDate) return null;

		const dummyDate = new Date(clampedSelectedDate);
		dummyDate.setDate(1);

		return formatInTimeZone(dummyDate, 'UTC', "yyyy-MM-dd'T'HH:mm:ss'Z'");
	}, [clampedSelectedDate]);

	useFormReset(() => {
		onSelectedDateChange(null);
	});

	return (
		<>
			<ReactDatepicker
				selected={clampedSelectedDate}
				onChange={(date) => {
					if (!date) {
						onSelectedDateChange(null);
						return;
					}

					const utcDate = zonedTimeToUtc(date, 'UTC');
					onSelectedDateChange(utcDate);
				}}
				onMonthChange={(date) => {}}
				customInput={<input className="control__input" />}
				minDate={minDate}
				maxDate={maxDate}
				id={id}
				renderCustomHeader={MonthPickerHeader}
				monthsShown={1}
				dateFormat={dateFormat}
				disabled={isDisabled}
				dateFormatCalendar={dateFormatCalendar}
				showFullMonthYearPicker
				placeholderText={placeholder}
				popperModifiers={POPPER_MODIFIER}
				showMonthYearPicker
			/>
			{formattedValue && name && (
				<input type="hidden" name={name} value={formattedValue} />
			)}
		</>
	);
};

export default MonthPicker;
