import z from 'zod';
import {
	zArrayOfStringRequired,
	zEnsureArrayPreprocessor,
} from 'utils/zod/zodValidation';

const officesSchema = z.preprocess(
	zEnsureArrayPreprocessor,
	zArrayOfStringRequired('Head Offices is required')
);

export const webToolFreightGroupSchema = z.object({
	id: z.string(),
	name: z
		.string({})
		.min(1, { message: 'Name is required' })
		.max(100, { message: 'Name must not be more than 100 characters' }),
	description: z
		.string({})
		.min(1, { message: 'Description is required' })
		.max(250, { message: 'Description must not be more than 250 characters' }),
	offices: officesSchema,
});

export const createWebToolFreightGroupSchema = webToolFreightGroupSchema.pick({
	name: true,
	description: true,
	offices: true,
});

export const updateWebToolFreightGroupSchema = webToolFreightGroupSchema.pick({
	name: true,
	description: true,
	offices: true,
});

export type CreateWebToolFreightGroupModel = z.infer<
	typeof createWebToolFreightGroupSchema
>;
export type UpdateWebToolFreightGroupModel = z.infer<
	typeof updateWebToolFreightGroupSchema
>;
