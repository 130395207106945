import Pill from 'ui/components/Pill';
import type { FreightForwarderSingleOption } from 'utils/api/FreightForwarderAPI';

interface FreightForwarderPillProps {
	freightForwarder: FreightForwarderSingleOption;
}

export const FreightForwarderPill = ({
	freightForwarder,
}: FreightForwarderPillProps) => {
	return freightForwarder.itemType == 'group' ? (
		<Pill name={freightForwarder.name} />
	) : (
		<Pill name={freightForwarder.code} title={freightForwarder.name} />
	);
};
