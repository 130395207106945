import classNames from 'classnames';
import { ComponentProps, useId } from 'react';
import Tooltip from '../Tooltip';

type SelectionButtonProps = {
	hint?: string;
	isHighlighted?: boolean;
	children: string;
	className?: string;
} & ComponentProps<'input'>;

const SelectionButton = ({
	children,
	hint,
	isHighlighted,
	className,
	...inputProps
}: SelectionButtonProps) => {
	const id = useId();

	return (
		<div
			className={classNames(
				'selection-button',
				inputProps.disabled && 'selection-button--disabled',
				!inputProps.disabled &&
					isHighlighted &&
					'selection-button--highlighted',
				className
			)}
		>
			<input
				type="checkbox"
				className="selection-button__input"
				id={id}
				{...inputProps}
			/>
			<Tooltip disabled={!hint} delay={300}>
				<Tooltip.Trigger>
					<label
						className="selection-button__label"
						htmlFor={id}
						tabIndex={0}
						role="button"
					>
						<span className="selection-button__text">{children}</span>
					</label>
				</Tooltip.Trigger>
				<Tooltip.Content>{hint}</Tooltip.Content>
			</Tooltip>
		</div>
	);
};

export default SelectionButton;
