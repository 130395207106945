import { EyeOff, Folder, Plus } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Button from 'ui/components/Button';
import Menu from 'ui/components/Menu/Menu';
import Tabs from 'ui/components/Tabs';
import { Tab } from 'ui/components/Tabs/Tabs';
import { WorksheetBase } from 'utils/api/WebToolAPI';
import { catchWithError } from 'utils/helpers/catchHandlers';
import CreateWorksheetModal from '../../components/CreateWorksheetModal';
import WorksheetBrowserModal from '../../components/WorksheetBrowserModal';
import DuplicateWorksheetModal from './DuplicateWorksheetModal';
import RenameWorksheetModal from './RenameWorksheetModal';
import { useWebToolContext } from './WebToolContext';
import WebToolEmptyState from './WebToolEmptyState';

type WorksheetTab = Tab & {
	id: string;
};

type WebToolProps = {};

const WebTool = ({}: WebToolProps) => {
	const params = useParams();
	const location = useLocation();
	const activeWorksheetId = params.id;

	const {
		worksheets,
		navigateToPreviousWorksheet,
		deleteWorksheet,
		openWorksheetIds,
		setOpenWorksheetIds,
		history,
		isLoadingWorksheets,
	} = useWebToolContext();

	const [isCreateModalOpen, setCreateModalOpen] = useState(false);
	const [isBrowseModalOpen, setBrowseModalOpen] = useState(false);
	const [renameWorksheet, setRenameWorksheet] = useState<WorksheetBase | null>(
		null
	);
	const [duplicatingWorksheet, setDuplicatingWorksheet] =
		useState<WorksheetBase | null>(null);

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const action = searchParams.get('action');

		if (!action) return;

		if (action === 'create') {
			setCreateModalOpen(true);
		} else if (action === 'browse') {
			setBrowseModalOpen(true);
		}

		searchParams.delete('action');
		navigate({ search: searchParams.toString() }, { replace: true });
	}, [location.search]);

	const navigate = useNavigate();

	const handleTabClose = (tab: WorksheetTab) => {
		if (!openWorksheetIds.includes(tab.id)) return;

		history.remove(tab.id);
		setOpenWorksheetIds(openWorksheetIds.filter((id) => id !== tab.id));

		if (activeWorksheetId === tab.id) {
			navigateToPreviousWorksheet(tab.id);
		}
	};

	const worksheetTabs = useMemo<WorksheetTab[]>(() => {
		if (isLoadingWorksheets) {
			return openWorksheetIds.map(
				(id): WorksheetTab => ({
					id,
					name: '–',
					to: `/webtool/${id}`,
					isCloseable: true,
				})
			);
		}

		const openWorksheets = openWorksheetIds
			.map<WorksheetBase | undefined>((id) =>
				worksheets.find((sheet) => sheet.id === id)
			)
			.filter((sheet): sheet is WorksheetBase => sheet !== undefined);

		const tabs = openWorksheets.map(
			(sheet): WorksheetTab => ({
				id: sheet.id,
				name: sheet.name,
				to: `/webtool/${sheet.id}`,
				isCloseable: true,
				actions: [
					{
						label: 'Duplicate',
						onExecute: () => setDuplicatingWorksheet(sheet),
					},
					{
						label: 'Delete',
						isDestructive: true,
						onExecute: () => deleteWorksheet(sheet.id).catch(catchWithError),
					},
					{
						label: 'Rename',
						onExecute: () => setRenameWorksheet(sheet),
					},
				],
				end: false,
			})
		);

		return tabs;
	}, [openWorksheetIds, worksheets, isLoadingWorksheets]);

	return (
		<div className="webtool">
			<Tabs
				className="webtool__tabs"
				tabs={worksheetTabs}
				onTabClose={handleTabClose}
				emptyState={
					<WebToolEmptyState
						onWorksheetBrowserOpen={() => setBrowseModalOpen(true)}
					/>
				}
			>
				<Menu>
					<Menu.Trigger>
						<Button
							className="webtool__tab-button"
							size="small"
							variant="secondary"
							icon={Plus}
							type="button"
						/>
					</Menu.Trigger>

					<Menu.Item
						onClick={() => setCreateModalOpen(true)}
						icon={Plus}
						label="Create report"
					/>
					<Menu.Item
						onClick={() => setBrowseModalOpen(true)}
						icon={Folder}
						label="Browse reports"
					/>
					<Menu.List icon={EyeOff} label="Close reports">
						<Menu.Item
							onClick={() => {
								setOpenWorksheetIds([]);
								navigate('/webtool');
							}}
							label="All"
						/>
						<Menu.Item
							onClick={() =>
								setOpenWorksheetIds(
									activeWorksheetId ? [activeWorksheetId] : []
								)
							}
							label="Others"
						/>
					</Menu.List>
				</Menu>
			</Tabs>
			<CreateWorksheetModal
				isOpen={isCreateModalOpen}
				onClose={() => setCreateModalOpen(false)}
			/>
			<WorksheetBrowserModal
				isOpen={isBrowseModalOpen}
				onClose={() => setBrowseModalOpen(false)}
				worksheets={worksheets}
			/>
			{renameWorksheet && (
				<RenameWorksheetModal
					worksheet={renameWorksheet}
					isOpen
					onClose={() => setRenameWorksheet(null)}
				/>
			)}
			{duplicatingWorksheet && (
				<DuplicateWorksheetModal
					worksheet={duplicatingWorksheet}
					isOpen
					onClose={() => setDuplicatingWorksheet(null)}
				/>
			)}
		</div>
	);
};

export default WebTool;
