import AsyncMultiSelect from 'ui/components/AsyncMultiSelect';
import AsyncSelect from 'ui/components/AsyncSelect';
import FormField from 'ui/components/FormField';
import Pill from 'ui/components/Pill';
import useValidation from 'ui/components/ValidatedForm/useValidation';
import { Country } from 'utils/api/common';
import LocationAPI, { LocationType } from 'utils/api/LocationAPI';
import { catchSilently } from 'utils/helpers/catchHandlers';
import {
	createValidationToast,
	parseTextToCodes,
} from 'utils/helpers/codeValidation';

type SingleCountryPickerProps = {
	isMulti?: false;
	initialValue?: Country;
	initialValues?: undefined;
	onSelectedCountryChange?: (country: Country | null) => void;
	onSelectedCountriesChange?: undefined;
	selectedCountry?: Country | null;
	selectedCountries?: undefined;
};

type MultiCountryPickerProps = {
	isMulti: true;
	initialValue?: undefined;
	initialValues?: Country[];
	onSelectedCountryChange?: undefined;
	onSelectedCountriesChange?: (countries: Country[]) => void;
	selectedCountry?: undefined;
	selectedCountries?: Country[];
};

type CountryPickerProps = {
	name?: string;
	label?: string;
	isRequired?: boolean;
} & (SingleCountryPickerProps | MultiCountryPickerProps);

const CountryPicker = ({
	name,
	label,
	isRequired,
	...props
}: CountryPickerProps) => {
	const { errorTree, revalidate } = useValidation(name);

	const getCountries = async (searchTerm: string) => {
		const countries = await LocationAPI.getCountries(searchTerm);
		return countries.options;
	};

	const handlePaste = async (text: string) => {
		const codes = parseTextToCodes(text);

		if (codes) {
			const validatePromise = LocationAPI.validateCodesForLocationType(
				codes,
				LocationType.COUNTRY
			);

			createValidationToast(validatePromise, 'country').catch(catchSilently);

			const validationResult = await validatePromise;
			return validationResult.options as Country[];
		}

		return false;
	};

	return (
		<FormField
			label={label}
			errors={errorTree?._errors}
			isRequired={isRequired}
		>
			{props.isMulti ? (
				<AsyncMultiSelect<Country>
					defaultOptions={[]}
					onSearch={getCountries}
					identifierKey="code"
					initialValues={props.initialValues ?? []}
					onOptionsSelected={revalidate}
					handlePasteSelection={handlePaste}
					handleSearchSubmitSelection={handlePaste}
					selectedOptions={props.selectedCountries}
					onSelectedOptionsChange={props.onSelectedCountriesChange}
					contentSource={(country) => (
						<>
							<Pill code={country.code} /> {country.name}
						</>
					)}
					pillComponent={({ value, onRemove }) => (
						<Pill name={value.name} code={value.code} onRemove={onRemove} />
					)}
					placeholder="Select..."
					name={name}
				/>
			) : (
				<AsyncSelect<Country>
					defaultOptions={[]}
					onSearch={getCountries}
					identifierKey="code"
					handlePasteSelection={handlePaste}
					initialValue={props.initialValue}
					onOptionSelected={props.onSelectedCountryChange}
					selectedOption={props.selectedCountry}
					contentSource={(country) => (
						<div className="pills">
							<Pill code={country.code} />
							{country.name}
						</div>
					)}
					placeholder="Select..."
					name={name}
				/>
			)}
		</FormField>
	);
};

export default CountryPicker;
