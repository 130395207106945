import { useEffect, useState } from 'react';
import useDebounce from 'utils/hooks/useDebounce';
import { OptionGroup } from '../Dropdown/types';

import Select from '../Select';
import { SelectProps } from '../Select/Select';

export type AsyncSelectProps<T> = {
	onSearch: (search: string) => Promise<OptionGroup<T>[] | T[]>;
	defaultOptions?: OptionGroup<T>[] | T[];
} & Omit<SelectProps<T>, 'options' | 'disableWhenEmpty'>;

const AsyncSelect = <T,>({
	onSearch,
	defaultOptions = [],
	...restProps
}: AsyncSelectProps<T>) => {
	const [options, setOptions] = useState<OptionGroup<T>[] | T[]>(
		defaultOptions
	);

	const [searchTerm, setSearchTerm] = useState('');
	const debouncedSearchTerm = useDebounce(searchTerm, 500);

	const [isOpen, setOpen] = useState(false);
	const [isLoading, setLoading] = useState(false);

	useEffect(() => {
		if (!isOpen) {
			setSearchTerm('');
			setOptions(defaultOptions);
		}
	}, [isOpen]);

	useEffect(() => {
		async function loadResults() {
			setLoading(true);
			const results = await onSearch(searchTerm);
			setLoading(false);
			setOptions(results);
		}

		if (debouncedSearchTerm.length >= 2) {
			loadResults();
		}
	}, [debouncedSearchTerm]);

	return (
		<Select
			isOpen={isOpen}
			onOpenChange={setOpen}
			options={options}
			inputValue={searchTerm}
			isSearchable
			onInputChange={setSearchTerm}
			isLoading={isLoading}
			disableWhenEmpty={false}
			{...restProps}
		/>
	);
};

export default AsyncSelect;
