import classNames from 'classnames';
import { ChevronDown, LucideIcon } from 'lucide-react';
import React, { MouseEvent, useMemo } from 'react';
import Button from '../Button';
import Menu from '../Menu/Menu';

type SplitButtonAction = {
	id: string;
	name?: string;
	label: string;
	onClick: () => void;
	icon: LucideIcon;
};

export type SplitButtonProps = {
	actions: SplitButtonAction[];
	defaultActionId: string;
	isDisabled?: boolean;
	variant?: 'primary' | 'secondary';
	size?: 'medium' | 'small';

	isLoading?: boolean;
	loadingText?: string;
};

const SplitButton = ({
	actions,
	defaultActionId,
	isDisabled = false,
	variant = 'secondary',
	size = 'medium',
	isLoading = false,
	loadingText,
}: SplitButtonProps) => {
	const defaultAction = useMemo(
		() => actions.find((a) => a.id === defaultActionId) ?? actions[0],
		[actions, defaultActionId]
	);

	return (
		<div className={classNames('splitbutton', `splitbutton--${variant}`)}>
			<Button
				className="splitbutton__button splitbutton__button--main"
				size={size}
				type="button"
				variant={variant}
				icon={defaultAction.icon}
				name={defaultAction.name}
				value={defaultAction.id}
				onClick={(e: MouseEvent<HTMLButtonElement>) => {
					defaultAction.onClick?.();
					e.stopPropagation();
				}}
				isDisabled={isDisabled}
				isLoading={isLoading}
				loadingText={loadingText}
			>
				{defaultAction.label}
			</Button>
			<Menu alignment="end">
				<Menu.Trigger>
					<Button
						className="splitbutton__button splitbutton__button--dropdown"
						size={size}
						type="button"
						variant="secondary"
						isDisabled={isLoading || isDisabled}
					>
						<ChevronDown size={size === 'small' ? 14 : 16} />
					</Button>
				</Menu.Trigger>
				{actions
					.filter((action) => action.id !== defaultActionId)
					.map((action) => (
						<Menu.Item
							label={action.label}
							onClick={action.onClick}
							icon={action.icon}
							key={action.id}
						/>
					))}
			</Menu>
		</div>
	);
};

export default SplitButton;
