import { AirlineOption } from 'utils/api/AirlinesAPI';
import { SubscriptionAirlinesGroup } from 'utils/api/WebToolGroupAPI';

export function subscriptionsApplicableForAirlines(
	subscriptionsWithAirlines: SubscriptionAirlinesGroup[],
	airlines: AirlineOption[]
) {
	if (airlines.length === 0) return [];

	return subscriptionsWithAirlines.filter((subscription) => {
		return airlines.every((airline) =>
			subscription.options.some((groupAirline) => {
				return groupAirline.code === airline.code;
			})
		);
	});
}
