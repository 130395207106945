import { createColumnHelper } from '@tanstack/react-table';
import AttachmentLink from 'ui/components/AttachmentLink/AttachmentLink';
import DateFragment from 'ui/components/DateFragment/DateFragment';
import Modal, { ModalProps } from 'ui/components/Modal/Modal';
import Table from 'ui/components/Table/Table';
import { WebToolReportRunResult } from '../../api/ReportHubAPI';
import ReportOutputPopover from '../ReportOutputPopover/ReportOutputPopover';
import WebToolStatusIndicator from 'ui/components/WebToolStatusIndicator/WebToolStatusIndicator';

type WorksheetScheduleHistoryModalProps = {
	worksheetId: string;
	history: WebToolReportRunResult[];
	getDownloadUrl?: (id: string) => string;
} & Omit<ModalProps, 'size' | 'title'>;

const WorksheetScheduleHistoryModal = ({
	worksheetId,
	history,
	getDownloadUrl,
	...modalProps
}: WorksheetScheduleHistoryModalProps) => {
	const columnHelper = createColumnHelper<WebToolReportRunResult>();

	const columns = [
		columnHelper.accessor('executedAt', {
			header: 'Date',
			cell: (info) => <DateFragment date={info.getValue()} includeTime />,
		}),
		columnHelper.accessor('status', {
			header: 'Status',
			cell: ({ row }) => {
				return (
					<WebToolStatusIndicator
						intent={row.original.status.value}
						label={row.original.status.label}
					/>
				);
			},
		}),
		columnHelper.display({
			header: 'Details',
			cell: (info) => (
				<ReportOutputPopover
					parameters={info.row.original.parameters}
					columns={info.row.original.columns}
				/>
			),
		}),
		columnHelper.accessor('filename', {
			header: 'File',
			cell: (info) => {
				if (info.row.original.filename === '') {
					return null;
				}

				const downloadUrl =
					getDownloadUrl?.(info.row.original.id) ??
					`/report-hub/webtool-reports/${info.row.original.id}/download`;

				return (
					<AttachmentLink
						url={downloadUrl}
						target="_blank"
						name={info.row.original.filename}
						size={info.row.original.fileSizeInBytes}
					/>
				);
			},
		}),
	];

	return (
		<Modal title="Report history" size="lg" {...modalProps}>
			<Modal.Body>
				<Table columns={columns} data={history} identifierKey="filename" />
			</Modal.Body>
		</Modal>
	);
};

export default WorksheetScheduleHistoryModal;
