import classNames from 'classnames';
import { ComponentProps } from 'react';

type WeightBreakItemProps = {
	from: number;
	to: number;
	isSelected?: boolean;
} & ComponentProps<'div'>;

const WeightBreakItem = ({
	from,
	to,
	isSelected = false,
	...divProps
}: WeightBreakItemProps) => {
	return (
		<div
			{...divProps}
			className={classNames(
				'weightbreaks__item',
				isSelected && 'weightbreaks__item--selected'
			)}
		>
			<span>
				{from.toFixed(2)}
				{to === Infinity && ' +'}
			</span>
			<span>{to !== Infinity && to.toString()}</span>
		</div>
	);
};

export default WeightBreakItem;
