import classNames from 'classnames';
import {
	FormEvent,
	ForwardedRef,
	forwardRef,
	RefObject,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { Form as RouterForm, useLocation } from 'react-router-dom';
import { formDataAsObject } from 'utils/formData/formData';
import { ZodFormattedErrors } from 'utils/zod/zodErrors';
import z from 'zod';
import ValidatedFormContext from './ValidatedFormContext';

export type ValidatedFormProps = {
	validator?: z.ZodSchema<any>;
	resetOnNavigation?: boolean;
} & React.ComponentProps<typeof RouterForm>;

const ValidatedForm = (
	{
		children,
		className,
		onSubmit,
		validator,
		method,
		resetOnNavigation,
		...restProps
	}: ValidatedFormProps,
	ref: ForwardedRef<HTMLFormElement>
) => {
	const stateFormRef = useRef<HTMLFormElement>(null);
	const formRef = (ref as RefObject<HTMLFormElement>) || stateFormRef;
	const location = useLocation();

	const [hasSubmitted, setHasSubmitted] = useState(false);
	const [errorTree, setErrorTree] = useState<ZodFormattedErrors | undefined>(
		undefined
	);

	const validateForm = useCallback(() => {
		if (!formRef.current) return false;
		if (!validator) return true;

		const formData = new FormData(formRef.current);
		const data = formDataAsObject(formData);

		const validationResult = validator.safeParse(data);

		const validationErrors = validationResult.success
			? undefined
			: (validationResult.error.format() as ZodFormattedErrors);

		setErrorTree(validationErrors);
		return validationResult.success;
	}, [validator]);

	const handleSubmit = useCallback(
		(event: FormEvent<HTMLFormElement>) => {
			setHasSubmitted(true);
			const validationSuccessful = validateForm();
			if (!validationSuccessful) {
				event.preventDefault();
			} else {
				if (onSubmit) onSubmit(event);
			}
		},
		[validateForm]
	);

	const contextValue = useMemo(
		() => ({
			method: method || 'get',
			errorTree,
			hasSubmitted,
			revalidate: validateForm,
			formRef,
		}),
		[errorTree, hasSubmitted, method, formRef]
	);

	useEffect(() => {
		const isSearchEmpty = location.search === '';

		if (resetOnNavigation && isSearchEmpty) {
			setHasSubmitted(false);
			setErrorTree(undefined);
			formRef.current?.reset();
		}
	}, [location.key]);

	return (
		<RouterForm
			ref={formRef}
			onSubmit={handleSubmit}
			className={classNames('form', className)}
			method={method}
			{...restProps}
		>
			<ValidatedFormContext.Provider value={contextValue}>
				{children}
			</ValidatedFormContext.Provider>
		</RouterForm>
	);
};

export default forwardRef(ValidatedForm);
