import { useContext } from 'react';
import ValidatedFormContext from './ValidatedFormContext';

const useFieldCounter = (name: string) => {
	const context = useContext(ValidatedFormContext);

	if (context?.method === 'get') {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		return (i: number) => name;
	} else {
		return (i: number) => `${name}.${i}`;
	}
};

export default useFieldCounter;
