import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import WebToolWorksheetError from '../../components/WebToolWorksheetError';
import WebToolWorksheetLoader from '../../components/WebToolWorksheetLoader';
import WorksheetComponent from '../../components//Worksheet/Worksheet';
import WebToolAPI, { Worksheet } from 'utils/api/WebToolAPI';
import WebToolGroupAPI, {
	GetBreaksGroupsResponse,
} from 'utils/api/WebToolGroupAPI';
import APIError from 'utils/errors/APIError';
import usePrevious from 'utils/hooks/usePrevious';
import { DecentralizedRouteProps } from 'utils/types/common';
import { useWebToolContext } from './WebToolContext';

const WebToolItem = () => {
	const { openWorksheetIds, setOpenWorksheetIds } = useWebToolContext();
	const params = useParams();

	const [isLoading, setLoading] = useState(true);
	const [error, setError] = useState<APIError | null>(null);

	const [worksheet, setWorksheet] = useState<Worksheet | null>(null);
	const [weightBreakGroups, setWeightBreakGroups] =
		useState<GetBreaksGroupsResponse | null>(null);

	async function loadWorksheet() {
		const worksheetId = params.id as string;

		const isWorksheetOpen = openWorksheetIds.includes(worksheetId);
		if (!isWorksheetOpen) {
			setOpenWorksheetIds([...openWorksheetIds, worksheetId]);
		}

		try {
			setLoading(true);

			const worksheetPromise = WebToolAPI.getWorksheetById(worksheetId);
			const weightBreakGroupsPromise = WebToolGroupAPI.getBreaksGroups();

			const [worksheet, weightBreakGroups] = await Promise.all([
				worksheetPromise,
				weightBreakGroupsPromise,
			]);

			setWorksheet(worksheet);
			setWeightBreakGroups(weightBreakGroups);
		} finally {
			setLoading(false);
		}
	}

	const previousId = usePrevious(params.id);

	useEffect(() => {
		if (params.id !== previousId) {
			loadWorksheet().catch((err) => setError(err));
		}
	}, [params.id]);

	if (isLoading) return <WebToolWorksheetLoader />;
	if (!worksheet || weightBreakGroups === null || error)
		return <WebToolWorksheetError error={error ?? undefined} />;

	return (
		<>
			<WorksheetComponent
				worksheet={worksheet}
				weightBreakGroups={weightBreakGroups.groups.items}
			/>
		</>
	);
};

export const WEBTOOL_REPORT_ROUTE: DecentralizedRouteProps = {
	element: <WebToolItem />,
};
