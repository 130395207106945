import { Download } from 'lucide-react';
import { useMemo, useState } from 'react';
import SplitButton from '../SplitButton';

export type ExportFormat = {
	id: string;
	name: string;
};

export type ExportButtonProps = {
	availableFormats?: ExportFormat[];
	onExport: (format: string) => void;
	fallbackFormatId?: string;
	isDisabled?: boolean;
};

const ExportButton = ({
	availableFormats,
	onExport,
	fallbackFormatId,
	isDisabled,
}: ExportButtonProps) => {
	const formats = availableFormats ?? [
		{ id: 'csv', name: 'CSV' },
		{ id: 'tsv', name: 'TSV' },
		{ id: 'xlsx', name: 'XLSX' },
	];

	const localStorageKey = 'export-format';
	const localStorageSmartFormat = localStorage.getItem(localStorageKey);

	const [defaultFormatId, setDefaultFormatId] = useState(
		localStorageSmartFormat ?? fallbackFormatId ?? formats[0].id
	);

	const actions = useMemo(
		() =>
			formats.map((format) => ({
				id: format.id,
				label: `Download ${format.name}`,
				onClick: () => {
					onExport(format.id);
					setDefaultFormatId(format.id);
					localStorage.setItem(localStorageKey, format.id);
				},
				icon: Download,
			})),
		[availableFormats, onExport]
	);

	return (
		<SplitButton
			isDisabled={isDisabled}
			actions={actions}
			defaultActionId={defaultFormatId}
			variant="primary"
		/>
	);
};

export default ExportButton;
