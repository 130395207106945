import React from 'react';
import PillDrawer from 'ui/components/PillDrawer';
import { Location } from 'utils/api/common';
import LocationPill, { LocationPillProps } from './LocationPill';

type LocationListProps<T> = {
	locations: T[] | undefined;
	emptyText: string;
} & Pick<LocationPillProps, 'variant'>;

export const LocationList = ({
	locations,
	emptyText,
	variant,
}: LocationListProps<Location>) => {
	if (locations && locations.length > 0) {
		return (
			<>
				<PillDrawer maxItems={5}>
					{locations?.map((location) => (
						<React.Fragment key={location.naturalKey}>
							<LocationPill location={location} variant={variant} />
						</React.Fragment>
					))}
				</PillDrawer>
			</>
		);
	}

	return <>{emptyText}</>;
};

export const LocationCodeList = ({
	locations,
	emptyText,
}: LocationListProps<string>) => {
	if (locations && locations.length > 0) {
		return <>{locations.join(', ')}</>;
	}

	return <>{emptyText}</>;
};
