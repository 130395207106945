import { PropsWithChildren, ReactNode } from 'react';

export type FormFieldProps = PropsWithChildren<{
	/* TODO: Need to get these options automatically, but passing manually for now */
	isRequired?: boolean;

	id?: string;
	name?: string;
	label?: string;
	secondaryLabel?: string | ReactNode;
	description?: string;
	errors?: string | string[];
	warnings?: string | string[];
	style?: React.CSSProperties;
}>;

const FormField = (props: FormFieldProps) => {
	let {
		isRequired,
		children,

		name,
		label,
		secondaryLabel,
		description,
		errors,
		warnings,

		id,
		style,
	} = props;

	return (
		<div className="control" style={style}>
			{label && (
				<label className="control__label" htmlFor={id}>
					<span>
						{label} {isRequired ? '*' : null}
					</span>
					{secondaryLabel && <span>{secondaryLabel}</span>}
				</label>
			)}
			<div className="control__field">{children}</div>
			{description && <div className="control__description">{description}</div>}
			{errors && errors.length > 0 && (
				<div className="control__error">
					{typeof errors === 'string' ? errors : errors.join(', ')}
				</div>
			)}
			{warnings && warnings.length > 0 && !(errors && errors.length > 0) && (
				<div className="control__warning">
					{typeof warnings === 'string' ? warnings : warnings.join(', ')}
				</div>
			)}
		</div>
	);
};

export default FormField;
