import Badge from 'ui/components/Badge/Badge';
import Card from 'ui/components/Card';
import DateFragment from 'ui/components/DateFragment';
import YearAndMonthFragment from 'ui/components/YearAndMonthFragment';
import {
	GetHomepageResponse,
	ProfileSubscriptionDataGroup,
} from '../../api/ProfileAPI';
import FreightForwarderList from './FreightForwarderList';
import { SubscriptionLocationsList } from './SubscriptionLocationsList';

type MySubscriptionPanelProps = {
	profile: GetHomepageResponse;
};

const MySubscriptionPanel = ({ profile }: MySubscriptionPanelProps) => {
	const getFreightForwarders = (group: ProfileSubscriptionDataGroup) => {
		const allForwarders: { name: string; code: string }[] = [];

		if (group?.freightForwarderGroups) {
			allForwarders.push(
				...group.freightForwarderGroups.map((g) => ({
					...g,
					code: g.code ?? g.name,
				}))
			);
		}

		if (group?.freightForwarderHeadOffices) {
			allForwarders.push(...group.freightForwarderHeadOffices);
		}

		if (group?.freightForwarderBranches) {
			allForwarders.push(...group.freightForwarderBranches);
		}

		return allForwarders;
	};

	return (
		<Card className="my-subscription">
			<header className="my-subscription__header">
				<h2 className="my-subscription__title">My subscription</h2>
			</header>

			<div className="my-subscription__stats">
				<div className="my-subscription__stat">
					<b>Customer Name</b>
					<span>{profile.customerName}</span>
				</div>
				<div className="my-subscription__stat">
					<b>Customer Number</b>
					<span>{profile.customerNumber}</span>
				</div>
				<div className="my-subscription__stat">
					<b>Access Expiry</b>
					<span>
						<DateFragment date={profile.activeTo} />
					</span>
				</div>
			</div>

			{profile.subscriptions.length > 0 && (
				<>
					<hr />
					<div className="my-subscription__reports reports">
						<ul className="reports__list">
							{profile.subscriptions.map((subscription) => (
								<li
									className="reports__item report"
									key={subscription.subscriptionNumber}
								>
									<div className="report__header">
										<b className="report__name">
											{subscription.title}{' '}
											{subscription.supportSubscription && (
												<Badge>Support</Badge>
											)}
										</b>{' '}
										<span className="report__access">
											<YearAndMonthFragment value={subscription.startPeriod} />
											{' - '}
											<YearAndMonthFragment value={subscription.endPeriod} />
										</span>
									</div>

									{subscription.dataGroups.map((group, groupIndex) => (
										<div className="report__item" key={groupIndex}>
											{group.carriers?.length > 0 && (
												<div className="report__carrier">
													<b>Carrier: </b>
													<span>
														{group.carriers.map((c) => c.code).join(', ')}
													</span>
												</div>
											)}
											{group.freightForwarderGroups?.length +
												group.freightForwarderHeadOffices?.length +
												group.freightForwarderBranches?.length >
												0 && (
												<div className="report__carrier">
													<b>Freight Forwarder: </b>
													<FreightForwarderList
														freightForwarders={getFreightForwarders(group)}
													/>
												</div>
											)}
											<div className="report__locations">
												<div className="report__location">
													<b>Origin: </b>
													<SubscriptionLocationsList
														locations={group.origin}
														emptyText="World"
													/>
												</div>
												<div className="report__location">
													<b>Destination: </b>
													<SubscriptionLocationsList
														locations={group.destination}
														emptyText="World"
													/>
												</div>
											</div>
										</div>
									))}
								</li>
							))}
						</ul>
					</div>
				</>
			)}
		</Card>
	);
};

export default MySubscriptionPanel;
