import classNames from 'classnames';
import React, { ComponentProps } from 'react';

type SkeletonProps = ComponentProps<'span'>;

const Skeleton = ({ className, ...props }: SkeletonProps) => {
	return <span className={classNames('skeleton', className)} {...props} />;
};

export default Skeleton;
