import { LayoutRouteProps, LoaderFunctionArgs } from 'react-router-dom';
import DownloadPage from 'ui/pages/DownloadPage/DownloadPage';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import ReferenceDataAPI from '../../api/ReferenceDataAPI';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);

	const url = new URL(request.url);
	const exportData = await ReferenceDataAPI.exportAirports(url.searchParams);

	downloadFile(exportData.url, exportData.filename);

	return null;
};

function downloadFile(exportFileUrl: string, fileName: string): void {
	const link = document.createElement('a');
	link.setAttribute('href', exportFileUrl);
	link.setAttribute('download', fileName);
	link.click();
}

export const EXPORT_AIRPORTS_ROUTE: LayoutRouteProps = {
	loader: loader,
	element: <DownloadPage />,
};
