import {
	CalendarClock,
	CalendarPlus,
	CalendarRange,
	Download,
	RotateCcw,
	Save,
	Share,
	SlidersHorizontal,
	Table,
} from 'lucide-react';
import { useCallback, useMemo, useState } from 'react';
import { catchWithError } from 'utils/helpers/catchHandlers';
import { createToast } from 'utils/helpers/toast';
import Button from 'ui/components/Button/Button';
import Flex from 'ui/components/Flex/Flex';
import Separator from 'ui/components/Separator/Separator';
import SplitButton from 'ui/components/SplitButton/SplitButton';
import { ToastType } from 'ui/components/Toaster/Toast';
import { useWorksheetContext } from './WorksheetContext';
import { WorksheetPreviewStatus } from './WorksheetPreview';
import WebToolAPI, {
	ReportColumn,
	ReportColumnGroup,
} from 'utils/api/WebToolAPI';
import { downloadFile } from 'utils/helpers/file';
import toast from 'react-hot-toast';

type WorksheetToolbarProps = {
	showPreviewSummary?: boolean;
};

function columnCount(columns: (ReportColumnGroup | ReportColumn)[]): number {
	return columns.reduce((count, column) => {
		if ('items' in column) {
			return count + columnCount(column.items);
		}

		return count + 1;
	}, 0);
}

const WorksheetToolbar = ({
	showPreviewSummary = false,
}: WorksheetToolbarProps) => {
	const {
		setIsReportOptionsOpen,
		derivedState,
		status,
		worksheet,
		runWorksheet,
		saveWorksheet,
		queryStatus,
		previewData,
		setIsScheduleModalOpen,
		worksheetHasChanges,
		schedule,
		setIsShareModalOpen,
	} = useWorksheetContext();

	const weekDownloadAvailable =
		worksheet.config.parameters.dateRange.formats.week;
	const [isLoadingWeekCalendar, setIsLoadingWeekCalendar] = useState(false);
	async function weekDownloadFile() {
		if (isLoadingWeekCalendar) return;

		setIsLoadingWeekCalendar(true);

		await WebToolAPI.downloadWeekCalendar(worksheet.id)
			.then((data) => downloadFile(data.url, data.originalFileName))
			.catch(() =>
				toast.error(
					'An unexpected problem occurred while downloading week calendar'
				)
			)
			.finally(() => setIsLoadingWeekCalendar(false));
	}

	const [isSaving, setIsSaving] = useState(false);

	const reportOptionsAvailable = useMemo(() => {
		return worksheet.config.optionGroups.some((optionGroup) =>
			derivedState.selectedFields.some(
				(field) => field.optionGroup === optionGroup.id
			)
		);
	}, [worksheet.config.optionGroups, derivedState.selectedFields]);

	const previewSummary = useMemo(() => {
		if (
			!showPreviewSummary ||
			!previewData ||
			previewData.previewTimeout ||
			previewData.errorMessage ||
			queryStatus?.executionStatus !== 'SUCCESS'
		) {
			return null;
		}

		const isTruncated = queryStatus.totalRows > 100;

		return (
			<b>
				{isTruncated ? 'Previewing 100 of' : 'Found'} {queryStatus.totalRows}{' '}
				rows, {columnCount(previewData.columns)} columns
			</b>
		);
	}, [
		previewData?.previewTimeout,
		previewData,
		previewData?.errorMessage,
		showPreviewSummary,
		queryStatus?.executionStatus,
	]);

	const handleSave = useCallback(async () => {
		setIsSaving(true);
		await saveWorksheet();
		setIsSaving(false);
	}, [saveWorksheet]);

	const openScheduleModal = useCallback(() => {
		const hasSchedule = !!schedule;

		if (!hasSchedule && derivedState.selectedDataFieldDimensions.length === 0) {
			createToast(
				ToastType.WARNING,
				'You can only schedule a report with at least one data field selected.'
			);
			return;
		}

		setIsScheduleModalOpen(true);
	}, [
		derivedState.selectedDataFieldDimensions.length,
		schedule,
		setIsScheduleModalOpen,
	]);

	const isSchedulingEnabled = useMemo(() => {
		return !worksheet.supportWorksheet;
	}, [worksheet]);

	return (
		<Flex
			alignItems="center"
			justifyContent={previewSummary ? 'space-between' : 'end'}
			gap={8}
		>
			{previewSummary && <div>{previewSummary}</div>}
			<Flex gap={8} alignItems="center">
				{weekDownloadAvailable && (
					<Button
						type="button"
						variant="secondary"
						icon={CalendarRange}
						isLoading={isLoadingWeekCalendar}
						onClick={weekDownloadFile}
					>
						Download Week Calendar
					</Button>
				)}
				<Button
					type="button"
					variant="secondary"
					onClick={() => setIsReportOptionsOpen(true)}
					isDisabled={!reportOptionsAvailable}
					icon={SlidersHorizontal}
				>
					Report Options
				</Button>
				<Button type="reset" variant="secondary" icon={RotateCcw}>
					Clear
				</Button>

				<Separator />

				{worksheet.sharing.availableUsers.length > 0 && (
					<Button
						type="button"
						variant="secondary"
						onClick={() => setIsShareModalOpen(true)}
						icon={Share}
					>
						Share&hellip;
					</Button>
				)}

				{isSchedulingEnabled && (
					<Button
						type="button"
						variant="secondary"
						onClick={openScheduleModal}
						icon={schedule ? CalendarClock : CalendarPlus}
					>
						{schedule ? 'Edit Schedule' : 'Schedule...'}
					</Button>
				)}

				{schedule && isSchedulingEnabled && (
					<Button
						type="button"
						variant="secondary"
						disabled={
							!worksheetHasChanges ||
							status === WorksheetPreviewStatus.ReportRunning
						}
						onClick={handleSave}
						isLoading={isSaving}
						icon={Save}
					>
						<Flex alignItems="center" gap={8}>
							Save
							{worksheetHasChanges && (
								<div
									style={{
										backgroundColor: 'var(--color-blue-500)',
										borderRadius: '50%',
										width: '6px',
										height: '6px',
									}}
								/>
							)}
						</Flex>
					</Button>
				)}

				<SplitButton
					defaultActionId="run"
					actions={[
						{
							id: '1',
							icon: Download,
							label: 'Export to Excel',
							onClick: () => {
								runWorksheet('excel').catch(catchWithError);
							},
						},
						{
							id: '2',
							icon: Download,
							label: 'Export to CSV',
							onClick: () => {
								runWorksheet('csv').catch(catchWithError);
							},
						},
						{
							id: '3',
							icon: Download,
							label: 'Export to TSV',
							onClick: () => {
								runWorksheet('tsv').catch(catchWithError);
							},
						},
						{
							id: 'run',
							icon: Table,
							label: 'Run Report',
							name: 'action',
							onClick: () => {
								runWorksheet('screen').catch(catchWithError);
							},
						},
					]}
				/>
			</Flex>
		</Flex>
	);
};

export default WorksheetToolbar;
