import { ENVIRONMENT_SHARED } from 'environment';
import API from 'utils/api/API';
import { UsageTrackingType } from '../../../admin/src/api/UsageTrackingApi';

export type SendPortalUsageTrackingResponse = {
	success: boolean;
};
class PortalUsageTrackingAPI extends API {
	protected static readonly apiEndpoint =
		ENVIRONMENT_SHARED.subscriptionsApiEndpoint;

	private static constructUrl(
		type: UsageTrackingType,
		url: string,
		extraFields: {
			[key: string]: string | number | boolean | undefined;
		} = {}
	) {
		const baseUrl = '/usage-tracking/record';
		const urlParams = new URLSearchParams({
			type,
			url,
		});

		for (const [key, value] of Object.entries(extraFields)) {
			if (value !== undefined) urlParams.append(key, value.toString());
		}

		return `${baseUrl}?${urlParams.toString()}`;
	}

	static sendUsageTrackingViewRequest(
		url: string,
		details?: string,
		parameters?: string
	) {
		const trackingUrl = PortalUsageTrackingAPI.constructUrl(
			UsageTrackingType.VIEW,
			url,
			{
				details,
				parameters,
			}
		);

		return PortalUsageTrackingAPI.get<SendPortalUsageTrackingResponse>(
			trackingUrl
		);
	}

	static sendUsageTrackingDownloadRequest(
		url: string,
		details?: string,
		parameters?: string
	) {
		const trackingUrl = PortalUsageTrackingAPI.constructUrl(
			UsageTrackingType.DOWNLOAD,
			url,
			{
				details,
				parameters,
			}
		);

		return PortalUsageTrackingAPI.get<SendPortalUsageTrackingResponse>(
			trackingUrl
		);
	}

	static sendUsageTrackingExternalUrlRequest(url: string, details: string) {
		const trackingUrl = PortalUsageTrackingAPI.constructUrl(
			UsageTrackingType.EXTERNAL_URL,
			url,
			{
				details,
			}
		);

		return PortalUsageTrackingAPI.get<SendPortalUsageTrackingResponse>(
			trackingUrl
		);
	}
}

export default PortalUsageTrackingAPI;
