import { createColumnHelper } from '@tanstack/react-table';
import { ReferenceFreightForwarder } from '../../api/ReferenceDataAPI';

const columnHelper = createColumnHelper<ReferenceFreightForwarder>();

export const branchOfficeCodeColumn = columnHelper.accessor(
	'branchOfficeCode',
	{
		header: 'Branch Office Code',
		enableSorting: true,
		enableMultiSort: false,
	}
);

export const branchOfficeNameColumn = columnHelper.accessor(
	'branchOfficeName',
	{
		header: 'Branch Office Name',
		enableSorting: true,
		enableMultiSort: false,
	}
);

export const headOfficeCodeColumn = columnHelper.accessor('headOfficeCode', {
	header: 'Head Office Code',
	enableSorting: true,
	enableMultiSort: false,
});

export const headOfficeNameColumn = columnHelper.accessor('headOfficeName', {
	header: 'Head Office Name',
	enableSorting: true,
	enableMultiSort: false,
});

export const countryCodeColumn = columnHelper.accessor('countryCode', {
	header: 'Country Code',
});

export const cityNameColumn = columnHelper.accessor('cityName', {
	header: 'City Name',
});

export const postalCodeColumn = columnHelper.accessor('postalCode', {
	header: 'Postal Code',
});

export const freightForwarderGroupNameColumn = columnHelper.accessor(
	'iataFreightForwarderGroupName',
	{
		header: 'Freight Forwarder Group Name',
		enableSorting: true,
		enableMultiSort: false,
	}
);
