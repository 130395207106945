import classNames from 'classnames';
import { ArrowRight } from 'lucide-react';
import { ComponentProps, ForwardedRef, forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { NavigationRootItem } from './Navigation';

type NavigationDropdownProps<Roles extends string> = {
	isOpen: boolean;
	item: NavigationRootItem<Roles>;
	arrowProps: ComponentProps<'div'>;
	onClose: () => void;
} & ComponentProps<'div'>;

const NavigationDropdown = <Roles extends string>(
	{
		isOpen,
		item,
		arrowProps,
		onClose,
		...divProps
	}: NavigationDropdownProps<Roles>,
	ref: ForwardedRef<HTMLDivElement>
) => {
	const { sections } = item;

	if (!sections) return null;

	return (
		<div
			className={classNames(
				'navigation__dropdown',
				isOpen && 'navigation__dropdown--open'
			)}
			ref={ref}
			{...divProps}
		>
			<div className="navigation__arrow" {...arrowProps} />
			<ul className="navigation__children">
				{sections.map((section, sectionIndex) => (
					<section key={sectionIndex} className="navigation__section">
						{section.name && (
							<p className="navigation__section-name">{section.name}</p>
						)}
						{section.items.map((item, itemIndex) => {
							const LinkElement = item.path.startsWith('/') ? Link : 'a';

							return (
								<li className="navigation__child child" key={itemIndex}>
									<LinkElement
										to={item.path}
										href={item.path}
										target={item.isExternal ? '_blank' : undefined}
										className="child__link"
										onClick={() => {
											item.onClick?.();
											onClose();
										}}
									>
										<item.icon size={20} className="child__icon" />
										<div className="child__details">
											<b className="child__name">
												{item.name}
												<ArrowRight className="child__arrow" size={12} />
											</b>
											{item.description && (
												<p className="child__description">{item.description}</p>
											)}
										</div>
									</LinkElement>
								</li>
							);
						})}
					</section>
				))}
			</ul>
		</div>
	);
};

export default forwardRef(NavigationDropdown);
