import z from 'zod';
import {
	zArrayOfStringRequired,
	zEnsureArrayPreprocessor,
} from 'utils/zod/zodValidation';

const locationsSchema = z.preprocess(
	zEnsureArrayPreprocessor,
	zArrayOfStringRequired('Locations is required')
);

export const webToolGeographyGroupSchema = z.object({
	id: z.string(),
	name: z
		.string({})
		.min(1, { message: 'Name is required' })
		.max(100, { message: 'Name must not be more than 100 characters' }),
	description: z
		.string({})
		.min(1, { message: 'Description is required' })
		.max(250, { message: 'Description must not be more than 250 characters' }),
	locations: locationsSchema,
});

export const createWebToolGeographyGroupSchema =
	webToolGeographyGroupSchema.pick({
		name: true,
		description: true,
		locations: true,
	});

export const updateWebToolGeographyGroupSchema =
	webToolGeographyGroupSchema.pick({
		name: true,
		description: true,
		locations: true,
	});

export type CreateWebToolGeographyGroupModel = z.infer<
	typeof createWebToolGeographyGroupSchema
>;
export type UpdateWebToolGeographyGroupModel = z.infer<
	typeof updateWebToolGeographyGroupSchema
>;
