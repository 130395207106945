import { PropsWithChildren, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';

type PortalProps = PropsWithChildren<{
	wrapperId: string;
}>;

const createWrapperElement = (id: string) => {
	const wrapperElement = document.createElement('div');
	wrapperElement.setAttribute('id', id);
	document.body.appendChild(wrapperElement);
	return wrapperElement;
};

const Portal = ({ children, wrapperId }: PortalProps) => {
	const [wrapperElement, setWrapperElement] = useState<HTMLElement | null>(
		null
	);

	useLayoutEffect(() => {
		let element = document.getElementById(wrapperId);
		let isAutoCreated = false;

		if (!element) {
			element = createWrapperElement(wrapperId);
			isAutoCreated = true;
		}

		setWrapperElement(element);

		return () => {
			if (isAutoCreated && element?.parentNode) {
				element.parentNode.removeChild(element);
			}
		};
	}, [wrapperId]);

	if (!wrapperElement) return null;
	return createPortal(children, wrapperElement);
};

export default Portal;
