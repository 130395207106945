export type SubscriptionLocations = {
	stations?: string[];
	cities?: string[];
	provinces?: string[];
	countries?: string[];
	regions?: string[];
	stationWebToolGroups?: string[];
	geographyWebToolGroups?: string[];
};

export type LocationNaturalKeyListProps = { locations: SubscriptionLocations };

export function LocationNaturalKeyList({
	locations,
}: LocationNaturalKeyListProps) {
	const stations = locations.stations ?? [];
	const cities = locations.cities ?? [];
	const provinces = locations.provinces ?? [];
	const countries = locations.countries ?? [];
	const allEmpty =
		stations.length === 0 &&
		cities.length === 0 &&
		provinces.length === 0 &&
		countries.length === 0;

	return (
		<div>
			{stations.map((stationNk) => (
				<span
					title={`Airport: ${stationNk.substring(0, 3)} (${stationNk.substring(
						3
					)}) `}
					key={stationNk}
				>
					{stationNk.substring(0, 3)}{' '}
				</span>
			))}
			{cities.map((cityNk) => (
				<span
					title={`City: ${cityNk.substring(0, 3)} (${cityNk.substring(3)})`}
					key={cityNk}
				>
					{cityNk.substring(0, 3)}{' '}
				</span>
			))}
			{provinces.map((provinceNk) => (
				<span
					title={`Province: ${provinceNk.substring(
						0,
						2
					)} (${provinceNk.substring(2)})`}
					key={provinceNk}
				>
					{provinceNk.substring(0, 2)}{' '}
				</span>
			))}
			{countries.map((countryNk) => (
				<span title={`Country: ${countryNk}`} key={countryNk}>
					{countryNk}{' '}
				</span>
			))}
			{allEmpty && 'World'}
		</div>
	);
}
