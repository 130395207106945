import { Save } from 'lucide-react';
import { useState } from 'react';
import Button from 'ui/components/Button/Button';
import Modal, { ModalProps } from 'ui/components/Modal/Modal';
import TextField from 'ui/components/TextField/TextField';
import { ToastType } from 'ui/components/Toaster/Toast';
import WebToolAPI, { WorksheetBase } from 'utils/api/WebToolAPI';
import { catchWithError } from 'utils/helpers/catchHandlers';
import { createToast } from 'utils/helpers/toast';
import { useWebToolContext } from './WebToolContext';

type RenameWorksheetModalProps = {
	worksheet: WorksheetBase;
} & Omit<ModalProps, 'title'>;

const RenameWorksheetModal = ({
	worksheet,
	...modalProps
}: RenameWorksheetModalProps) => {
	const [name, setName] = useState(worksheet.name);
	const [isLoading, setLoading] = useState(false);

	const { setWorksheets } = useWebToolContext();

	const renameWorksheet = async () => {
		setLoading(true);

		try {
			if (worksheet && name.length > 0) {
				const response = await WebToolAPI.renameWorksheet(worksheet.id, name);

				if (response instanceof Error) {
					createToast(
						ToastType.ERROR,
						'Failed to rename worksheet. Please enter a different name.'
					);
					return;
				}

				setWorksheets((worksheets: WorksheetBase[]) => {
					const worksheetIndex = worksheets.findIndex(
						(w) => w.id === worksheet?.id
					);

					if (worksheetIndex !== -1) {
						const newWorksheets = [...worksheets];
						newWorksheets[worksheetIndex] = {
							...newWorksheets[worksheetIndex],
							name,
						};
						return newWorksheets;
					}

					return worksheets;
				});

				modalProps.onClose();
			}
		} finally {
			setLoading(false);
		}
	};

	return (
		<Modal title="Rename report" {...modalProps}>
			<Modal.Body>
				<input type="hidden" name="_action" value="rename" />
				<TextField
					label="New Report Name"
					name="name"
					value={name}
					onChange={(e) => setName(e.target.value)}
					autoFocus
				/>
			</Modal.Body>
			<Modal.Actions>
				<Button
					type="submit"
					icon={Save}
					onClick={() => renameWorksheet().catch(catchWithError)}
					isLoading={isLoading}
					disabled={name === worksheet.name || name.length === 0}
				>
					Save
				</Button>
			</Modal.Actions>
		</Modal>
	);
};

export default RenameWorksheetModal;
