import classNames from 'classnames';
import { memo, useMemo } from 'react';
import { WorksheetConfigField } from 'utils/api/WebToolAPI';
import { sortBySortOrder } from 'utils/helpers/sorting';
import Flex from 'ui/components/Flex/Flex';
import VisuallyHidden from 'ui/components/VisuallyHidden/VisuallyHidden';
import { useWorksheetContext } from '../WorksheetContext';
import WorksheetCheckboxGroupItem from './WorksheetCheckboxGroupItem';

type WorksheetCheckboxGroupProps = {
	name: string;
	options: WorksheetConfigField[];
	disabledFields: Record<string, true>;
};

const WorksheetCheckboxGroup = ({
	name,
	options,
	disabledFields,
}: WorksheetCheckboxGroupProps) => {
	const { state, setDeepState } = useWorksheetContext();

	const paymentCurrencyCodeOption = useMemo(
		() => options.find((option) => option.id === 'payment-currency-code'),
		[options]
	);

	const handleChange = (option: WorksheetConfigField) => {
		setDeepState(`output.fields`, (prevState: string[]) => {
			if (prevState.includes(option.id)) {
				return prevState.filter((value) => value !== option.id);
			} else {
				return [...prevState, option.id];
			}
		});
	};

	const sortedOptions = useMemo(
		() =>
			[...options]
				.sort(sortBySortOrder)
				.filter((option) => option.id !== 'payment-currency-code'),
		[options]
	);

	return (
		<Flex direction="column" gap={8}>
			<b>{name}</b>
			<div className={classNames('worksheet__checkbox-group checkbox-group')}>
				{sortedOptions.map((option) => (
					<WorksheetCheckboxGroupItem
						key={option.id}
						option={option}
						isChecked={state.output.fields.includes(option.id)}
						isDisabled={disabledFields[option.id] === true}
						onChange={handleChange}
					/>
				))}
			</div>

			{/* Special case for payment-currency-code... */}
			{paymentCurrencyCodeOption && (
				<VisuallyHidden>
					<input
						type="checkbox"
						name={paymentCurrencyCodeOption.id}
						checked={state.output.fields.includes(paymentCurrencyCodeOption.id)}
						onChange={() => {}}
					/>
				</VisuallyHidden>
			)}
		</Flex>
	);
};

export default memo(WorksheetCheckboxGroup);
