import {
	createContext,
	Dispatch,
	PropsWithChildren,
	SetStateAction,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastType } from 'ui/components/Toaster/Toast';
import WebToolAPI, {
	WebtoolSubscription,
	WorksheetBase,
} from 'utils/api/WebToolAPI';
import { catchWithMessage } from 'utils/helpers/catchHandlers';
import { createToast } from 'utils/helpers/toast';
import useOrderedSet, { OrderedSet } from 'utils/hooks/useOrderedSet';
import usePersistantState from 'utils/hooks/usePersistantState';

type WebToolContextValue = {
	openWorksheetIds: string[];
	setOpenWorksheetIds: (ids: string[]) => void;

	worksheets: WorksheetBase[];
	setWorksheets: Dispatch<SetStateAction<WorksheetBase[]>>;

	subscriptions: WebtoolSubscription[];
	history: OrderedSet<string>;

	loadWorksheets: () => Promise<void>;
	isLoadingWorksheets: boolean;

	createWorksheet: (name: string, subscriptionId: string) => Promise<void>;
	deleteWorksheet: (worksheetId: string) => Promise<void>;
	duplicateWorksheet: (
		worksheet: WorksheetBase,
		newName?: string,
		retainSchedule?: boolean
	) => Promise<void>;
	navigateToPreviousWorksheet: (closedWorksheetId: string) => void;
};

const WebToolContext = createContext<WebToolContextValue>(null!);

type WebToolProviderProps = PropsWithChildren<{
	activeWorksheetId?: string;
}>;

export const WebToolProvider = ({
	activeWorksheetId,
	children,
}: WebToolProviderProps) => {
	const [openWorksheetIds, setOpenWorksheetIds] = usePersistantState<string[]>(
		'webtool.openWorksheetIds',
		[]
	);
	const [worksheets, setWorksheets] = useState<WorksheetBase[]>([]);
	const [subscriptions, setSubscriptions] = useState<WebtoolSubscription[]>([]);
	const [isLoading, setLoading] = useState(true);

	const history = useOrderedSet<string>();
	const navigate = useNavigate();

	useEffect(() => {
		// Load worksheets on mount
		loadWorksheets().catch(catchWithMessage('Failed to load worksheets'));
	}, []);

	useEffect(() => {
		if (
			activeWorksheetId &&
			worksheets.some((w) => w.id === activeWorksheetId)
		) {
			localStorage.setItem('webtool.activeWorksheetId', activeWorksheetId);
		}
	}, [activeWorksheetId]);

	const loadWorksheets = async () => {
		if (worksheets.length === 0) {
			setLoading(true);
		}

		const [{ worksheets: newWorksheets }, { subscriptions }] =
			await Promise.all([
				WebToolAPI.getWorksheets(),
				WebToolAPI.getWebtoolSubscriptions(),
			]);

		setWorksheets(newWorksheets);
		setSubscriptions(subscriptions);
		setLoading(false);
	};

	const deleteWorksheet = async (worksheetId: string) => {
		history.remove(worksheetId);
		setOpenWorksheetIds(openWorksheetIds.filter((id) => id !== worksheetId));

		if (activeWorksheetId === worksheetId) {
			navigateToPreviousWorksheet(worksheetId);
		}

		const data = await WebToolAPI.deleteWorksheet(worksheetId);

		if (data instanceof Error) {
			createToast(ToastType.ERROR, 'Failed to delete report');
			console.error(data);
			return;
		}
		createToast(ToastType.SUCCESS, 'Report deleted');

		setWorksheets(
			worksheets.filter((worksheet) => worksheet.id !== worksheetId)
		);
	};

	const navigateToPreviousWorksheet = useCallback(
		(closedWorksheetId: string) => {
			// Peek the second last item in the history stack, as the last item is the closed worksheet
			// and the state of the history stack is not updated yet
			const previouslyActiveWorksheetId = history.peek(1);

			const otherOpenWorksheets = openWorksheetIds.filter(
				(id) => id !== closedWorksheetId
			);

			if (
				previouslyActiveWorksheetId &&
				otherOpenWorksheets.includes(previouslyActiveWorksheetId)
			) {
				navigate(`/webtool/${previouslyActiveWorksheetId}`);
			} else if (otherOpenWorksheets.length > 0) {
				// In case things aren't yet in the history stack, navigate to the first open worksheet
				navigate(`/webtool/${otherOpenWorksheets[0]}`);
			} else {
				navigate('/webtool');
			}
		},
		[openWorksheetIds]
	);

	const duplicateWorksheet = async (
		worksheet: WorksheetBase,
		newName?: string,
		retainSchedule: boolean = true
	) => {
		const duplicateName = newName ?? `${worksheet.name} (copy)`;

		const data = await WebToolAPI.duplicateWorksheet(
			worksheet.id,
			duplicateName,
			retainSchedule
		);

		if (data instanceof Error) {
			createToast(ToastType.ERROR, 'Failed to duplicate report');
			console.error(data);
			return;
		}

		createToast(ToastType.SUCCESS, {
			message: 'Report duplicated',
		});

		const newWorksheet: WorksheetBase = {
			schedule: worksheet.schedule,
			name: duplicateName,
			id: data.id,
			subscriptionId: worksheet.subscriptionId,
			subscriptionName: 'Unknown',
			createdAt: new Date(),
			supportWorksheet: worksheet.supportWorksheet,
			customer: worksheet.customer,
		};

		setWorksheets([...worksheets, newWorksheet]);
		setOpenWorksheetIds([...openWorksheetIds, data.id]);
		loadWorksheets().catch(catchWithMessage('Failed to load worksheets'));

		navigate(`/webtool/${data.id}`);
	};

	const createWorksheet = async (name: string, subscriptionId: string) => {
		const data = await WebToolAPI.createWorksheet(name, subscriptionId);

		if (data instanceof Error) {
			createToast(ToastType.ERROR, 'Failed to create report');
			console.error(data);
			return;
		}

		createToast(ToastType.SUCCESS, {
			message: 'Report created',
		});

		const subscription = subscriptions.find((s) => s.id === subscriptionId);
		if (!subscription) return;

		const newWorksheet: WorksheetBase = {
			schedule: null,
			name,
			id: data.id,
			subscriptionId: subscriptionId,
			subscriptionName: 'Unknown',
			createdAt: new Date(),
			supportWorksheet: undefined,
			customer: subscription.customer,
		};

		setWorksheets([...worksheets, newWorksheet]);
		setOpenWorksheetIds([...openWorksheetIds, data.id]);
		loadWorksheets().catch(catchWithMessage('Failed to load worksheets'));

		navigate(`/webtool/${data.id}`);
	};

	const contextValue: WebToolContextValue = {
		openWorksheetIds,
		setOpenWorksheetIds,

		worksheets,
		setWorksheets,

		subscriptions,
		history,

		loadWorksheets,
		isLoadingWorksheets: isLoading,

		createWorksheet,
		deleteWorksheet,
		duplicateWorksheet,
		navigateToPreviousWorksheet,
	};

	return (
		<WebToolContext.Provider value={contextValue} key={activeWorksheetId}>
			{children}
		</WebToolContext.Provider>
	);
};

export const useWebToolContext = () => useContext(WebToolContext);

export default WebToolContext;
