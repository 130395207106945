import { Info, Pencil } from 'lucide-react';
import Button from 'ui/components/Button';
import DateFragment from 'ui/components/DateFragment';
import Tooltip from 'ui/components/Tooltip';
import WeightBreaks from 'ui/components/WeightBreaks';

export type WeightBreaksCardProps = {
	id: string;
	name: string;
	description?: string;
	createdAt?: Date;
	weightBreaks: number[];
};

const WeightBreaksCard = ({
	id,
	name,
	description,
	createdAt,
	weightBreaks,
}: WeightBreaksCardProps) => {
	return (
		<div key={name} className="weightbreaks-card">
			<div className="weightbreaks-card__header">
				<div className="weightbreaks-card__details">
					<h3 className="weightbreaks-card__name">
						{name}
						{description && (
							<Tooltip>
								<Tooltip.Trigger>
									<div className="weightbreaks-card__description-hint">
										<Info size={14} />
									</div>
								</Tooltip.Trigger>
								<Tooltip.Content size="small">
									<div className="weightbreaks-card__description">
										{description}
									</div>
								</Tooltip.Content>
							</Tooltip>
						)}
					</h3>
					<p className="weightbreaks-card__created-at">
						Created: <DateFragment date={createdAt} />
					</p>
				</div>
				<Button
					icon={Pencil}
					to={`/webtool/groups/breaks/${id}`}
					variant="secondary"
					size="small"
				/>
			</div>
			<div className="weightbreaks-card__content">
				<WeightBreaks initialValue={weightBreaks} isDisabled />
			</div>
		</div>
	);
};

export default WeightBreaksCard;
