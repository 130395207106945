import { Loader2 } from 'lucide-react';
import AttachmentIcon from './AttachmentIcon';

type AttachmentLinkContentProps = {
	label?: string;
	extension?: string;
	formattedSize?: string;
	isLoading?: boolean;
};

const AttachmentLinkContent = ({
	label,
	extension,
	formattedSize,
	isLoading = false,
}: AttachmentLinkContentProps) => {
	const textContent = label ?? extension?.toUpperCase();

	return (
		<>
			{isLoading ? (
				<Loader2
					size="16"
					className="attachment-link__icon attachment-link__icon--loading"
				/>
			) : (
				<AttachmentIcon
					className="attachment-link__icon"
					fileExtension={extension}
				/>
			)}
			{textContent && (
				<span className="attachment-link__name">{textContent}</span>
			)}
			{formattedSize && (
				<span className="attachment-link__size">({formattedSize})</span>
			)}
		</>
	);
};

export default AttachmentLinkContent;
