import { useMemo } from 'react';
import { LoaderFunctionArgs, Navigate, useOutlet } from 'react-router-dom';
import Tabs from 'ui/components/Tabs';
import { Tab } from 'ui/components/Tabs/Tabs';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import useRootLoader from 'utils/hooks/useRootLoader';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import { rootLoader } from '../../main';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	return null;
};

export function ReportHubPage() {
	const rootLoaderData = useRootLoader() as LoaderData<typeof rootLoader>;
	const outlet = useOutlet();

	const tabs = useMemo(
		() =>
			rootLoaderData
				? [
						rootLoaderData.userProfile.reportCounts.totalStatic > 0 && {
							name: 'Static Reports',
							to: '/report-hub/static-reports',
						},
						rootLoaderData.userProfile.reportCounts.totalBip > 0 && {
							name: 'BIP Exports',
							to: '/report-hub/bip-exports',
						},
						rootLoaderData.userProfile.reportCounts.totalAdhoc > 0 && {
							name: 'Adhoc Reports',
							to: '/report-hub/adhoc-reports',
						},
						rootLoaderData.userProfile.reportCounts.totalWebTool > 0 && {
							name: 'Web Tool Reports',
							to: '/report-hub/webtool-reports',
						},
						rootLoaderData.userProfile.reportCounts.totalWebTool > 0 && {
							name: 'Scheduled Reports',
							to: '/report-hub/scheduled-reports',
						},
				  ].filter((tab: Tab | false): tab is Tab => tab !== false)
				: [],
		[rootLoaderData]
	);

	if (tabs.length === 0) {
		return <Navigate to="/" />;
	} else if (!outlet) {
		return <Navigate to={tabs[0].to} />;
	}

	return (
		<>
			{tabs.length > 1 && (
				<Tabs className="page-tabs" tabs={tabs} renderOutlet={false} />
			)}
			{outlet}
		</>
	);
}

export const REPORT_HUB_ROUTE: DecentralizedRouteProps = {
	loader: loader,
	element: <ReportHubPage />,
};
