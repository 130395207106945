import { ColumnDef } from '@tanstack/react-table';
import { Download, RefreshCcw, Search } from 'lucide-react';
import { useCallback, useState } from 'react';
import {
	LoaderFunctionArgs,
	useLoaderData,
	useLocation,
} from 'react-router-dom';
import Button from 'ui/components/Button';
import DateField from 'ui/components/DateField/DateField';
import PageHeader from 'ui/components/PageHeader';
import Pagination from 'ui/components/Pagination';
import Sidebar from 'ui/components/Sidebar';
import Table from 'ui/components/Table';
import TextField from 'ui/components/TextField';
import ValidatedForm from 'ui/components/ValidatedForm';
import * as genericColumns from 'utils/columns/genericColumns';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import ReportHubAPI, { AdhocReport } from '../../api/ReportHubAPI';
import * as reportColumns from '../../utils/columns/reportHubAdhocListColumns';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	return ReportHubAPI.getAdhocList(new URL(request.url).searchParams);
};

export function ReportHubAdhocListPage() {
	const data = useLoaderData() as LoaderData<typeof loader>;
	const location = useLocation();

	const [selectedReportIdsForExport, setSelectedReportIdsForExport] = useState<
		string[]
	>([]);

	// Creating a key for the table so the checkbox selection is persisted across pages but not when new search filters are applied
	const searchParams = new URLSearchParams(location.search);
	searchParams.delete('page');
	const tableKey = searchParams.toString();

	const columns = [
		genericColumns.selectColumn as ColumnDef<AdhocReport, void>,
		reportColumns.titleColumn,
		reportColumns.reportDateColumn,
		reportColumns.attachmentColumn,
	];

	const handleRowSelection = useCallback(
		(rowSelection: Record<string, boolean>) => {
			const selectedReportIds = Object.keys(rowSelection);
			setSelectedReportIdsForExport(selectedReportIds);
		},
		[setSelectedReportIdsForExport]
	);

	const exportData = () => {
		const newWindow = window.open(
			`/report-hub/adhoc-reports/download/${selectedReportIdsForExport.join(
				','
			)}`,
			'_blank'
		);

		newWindow?.blur();
		window.focus();
	};

	const exportAll = () => {
		const newWindow = window.open(
			`/report-hub/adhoc-reports/download/all${location.search}`,
			'_blank'
		);
		newWindow?.blur();
		window.focus();
	};

	return (
		<Sidebar.Wrapper>
			<div className="content">
				<PageHeader title="Adhoc Reports">
					<Button
						variant="secondary"
						onClick={exportData}
						isDisabled={selectedReportIdsForExport.length === 0}
						icon={Download}
					>
						Export selected{' '}
						{selectedReportIdsForExport.length > 0
							? `(${selectedReportIdsForExport.length})`
							: ''}
					</Button>
					<Button variant="secondary" icon={Download} onClick={exportAll}>
						Export all
					</Button>
				</PageHeader>

				<Table
					identifierKey="id"
					key={tableKey}
					columns={columns}
					data={data.reports.items}
					onRowSelectionChange={handleRowSelection}
				/>

				{data.reports.totalCount > 0 && (
					<Pagination
						baseUrl={new URL(window.location.href)}
						page={data.reports.page}
						pageParameterName="page"
						pageSize={data.reports.pageSize}
						itemCount={data.reports.totalCount}
					/>
				)}
			</div>
			<Sidebar title="Report Filter">
				<ValidatedForm method="get" resetOnNavigation>
					<TextField label="Keyword Search" name="keyword" defaultValue={''} />
					<DateField
						label="From Report Date"
						name="fromReportDate"
						timezone="utc"
					/>
					<DateField
						label="To Report Date"
						name="toReportDate"
						timezone="utc"
					/>

					<Sidebar.Actions>
						<Button variant="secondary" icon={RefreshCcw} type="reset">
							Clear search
						</Button>
						<Button variant="primary" icon={Search} type="submit">
							Search
						</Button>
					</Sidebar.Actions>
				</ValidatedForm>
			</Sidebar>
		</Sidebar.Wrapper>
	);
}

export const REPORT_HUB_ADHOC_LIST_ROUTE: DecentralizedRouteProps = {
	loader: loader,
	element: <ReportHubAdhocListPage />,
};
