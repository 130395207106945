import { ENVIRONMENT_SHARED } from 'environment';
import API from 'utils/api/API';
import { Country, Location, LocationGroup } from 'utils/api/common';
import { ValidationResult } from '../helpers/codeValidation';

export type GetCountriesResponse = {
	keyword: string;
	options: Country[];
};

export type ValidateCodesForLocationTypeResponse = ValidationResult<Location>;

export type GetLocationsByOptionsResponse = {
	keyword: string;
	options: Location[];
	groups: null;
};

export type GetLocationsByGroupResponse = {
	keyword: string;
	options: null;
	groups: LocationGroup[];
};

export type GetLocationsResponse =
	| GetLocationsByOptionsResponse
	| GetLocationsByGroupResponse;

export enum LocationType {
	COUNTRY = 'country',
	REGION = 'region',
	CITY = 'city',
	STATION = 'station',
	PROVINCE = 'province',
}

export function describeLocationType(type: LocationType): string {
	return type === LocationType.STATION ? 'airport' : type;
}

class LocationAPI extends API {
	protected static readonly apiEndpoint =
		ENVIRONMENT_SHARED.subscriptionsApiEndpoint;

	static async getCountries(keyword: string) {
		return LocationAPI.get<GetCountriesResponse>(
			`/locations/lookup?Keyword=${keyword}&Type=country`
		);
	}

	static async getLocations(
		keyword: string,
		types: LocationType[] = [LocationType.COUNTRY, LocationType.REGION]
	) {
		const params = new URLSearchParams();
		params.append('Keyword', keyword);
		for (const type of types) {
			params.append('type', type.toString());
		}

		return LocationAPI.get<GetLocationsResponse>(
			`/locations/lookup?${params.toString()}`
		);
	}

	static async validateCodesForLocationType(
		codes: string[],
		type: LocationType | LocationType[]
	) {
		const params = new URLSearchParams({
			codes: codes.join(','),
		});

		const allTypes = Array.isArray(type) ? type : [type];
		for (const type of allTypes) {
			params.append('type', type);
		}

		return LocationAPI.get<ValidateCodesForLocationTypeResponse>(
			`/locations/validate?${params.toString()}`
		);
	}
}

export default LocationAPI;
