import {
	CollisionDetection,
	DndContext,
	DragEndEvent,
	DragOverEvent,
	DragOverlay,
	DragStartEvent,
	KeyboardSensor,
	PointerSensor,
	closestCenter,
	getFirstCollision,
	pointerWithin,
	rectIntersection,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Dimension, WorksheetState } from 'utils/api/WebToolAPI';
import { sortBySortOrderArray } from 'utils/helpers/sorting';
import Separator from 'ui/components/Separator/Separator';
import { useWorksheetContext } from '../WorksheetContext';
import LayoutBuilderItemPresentation from './LayoutBuilderItemPresentation';
import LayoutBuilderSection from './LayoutBuilderSection';

export type LayoutBuilderSortable = {
	id: string;
} & Dimension;

export enum LayoutBuilderMode {
	TABLE,
	PIVOT,
}

export type LayoutBuilderProps = {};

const LayoutBuilder = ({}: LayoutBuilderProps) => {
	const { state, worksheet, setDeepState, derivedState } =
		useWorksheetContext();

	const mode = derivedState.layoutBuilderMode;

	const [clonedLayoutState, setClonedLayoutState] = useState<
		WorksheetState['layout'] | null
	>(null);

	const [activeItem, setActiveItem] = useState<LayoutBuilderSortable | null>(
		null
	);

	const [sourceContainer, setSourceContainer] = useState<string | null>(null);

	const allSelectedDimensions = useMemo(
		() => [
			...derivedState.selectedFieldDimensions,
			...derivedState.selectedDataFieldDimensions,
		],
		[
			derivedState.selectedDataFieldDimensions,
			derivedState.selectedFieldDimensions,
		]
	);

	const isSortedByCustomPivotDimensions = state.layout.sorting.some(
		(i) => !Object.keys(worksheet.config.dimensions).includes(i.id)
	);

	useEffect(() => {
		const { fieldSortOrder, dataFieldSortOrder } = state.layout;
		const { selectedDataFieldDimensions, selectedFieldDimensions } =
			derivedState;

		// Make sure that sort orders are cleaned up if a dimension is removed
		const cleanedSortOrders = {
			fieldSortOrder: fieldSortOrder.filter((i) =>
				selectedFieldDimensions.find((j) => j.id === i)
			),
			dataFieldSortOrder: dataFieldSortOrder.filter((i) =>
				selectedDataFieldDimensions.find((j) => j.id === i)
			),
			sorting: state.layout.sorting.filter(
				(i) =>
					allSelectedDimensions.find((j) => j.id === i.id) ||
					!Object.keys(worksheet.config.dimensions).includes(i.id) // Ignore custom pivot dimensions
			),
			columnsSortOrder: state.layout.columnsSortOrder.filter((i) =>
				allSelectedDimensions.find((j) => j.id === i)
			),
		};

		if (
			cleanedSortOrders.fieldSortOrder.length !==
			state.layout.fieldSortOrder.length
		) {
			setDeepState('layout.fieldSortOrder', cleanedSortOrders.fieldSortOrder);
		}

		if (
			cleanedSortOrders.dataFieldSortOrder.length !==
			state.layout.dataFieldSortOrder.length
		) {
			setDeepState(
				'layout.dataFieldSortOrder',
				cleanedSortOrders.dataFieldSortOrder
			);
		}

		if (cleanedSortOrders.sorting.length !== state.layout.sorting.length) {
			setDeepState('layout.sorting', cleanedSortOrders.sorting);
		}

		if (
			cleanedSortOrders.columnsSortOrder.length !==
			state.layout.columnsSortOrder.length
		) {
			setDeepState(
				'layout.columnsSortOrder',
				cleanedSortOrders.columnsSortOrder
			);
		}
	}, [
		derivedState.selectedDataFieldDimensions,
		derivedState.selectedFieldDimensions,
		setDeepState,
		state.layout.dataFieldSortOrder,
		state.layout.fieldSortOrder,
	]);

	const selectedColumnsDimensions = useMemo(
		() =>
			allSelectedDimensions.filter((dimension) =>
				state.layout.columnsSortOrder.includes(dimension.id)
			),
		[allSelectedDimensions, state.layout.columnsSortOrder]
	);

	const selectedSortingDimensions = useMemo(
		() =>
			allSelectedDimensions.filter((dimension) =>
				state.layout.sorting.find((i) => i.id === dimension.id)
			),
		[allSelectedDimensions, state.layout.sorting]
	);

	const sensors = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 8,
			},
		}),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	);

	useEffect(() => {
		if (mode === LayoutBuilderMode.PIVOT) {
			// If the mode is pivoted, remove all sorting dimensions that are not in the fields
			const newState = state.layout.sorting.filter(
				(i) =>
					!state.layout.columnsSortOrder.includes(i.id) &&
					!derivedState.selectedDataFieldDimensions.some((d) => d.id === i.id)
			);

			if (JSON.stringify(newState) !== JSON.stringify(state.layout.sorting)) {
				setDeepState('layout.sorting', newState);
			}
		} else {
			// If the mode is not pivoted, remove all sorting dimensions that are pivot dimensions
			const newState = state.layout.sorting.filter((i) =>
				Object.keys(worksheet.config.dimensions).includes(i.id)
			);

			if (JSON.stringify(newState) !== JSON.stringify(state.layout.sorting)) {
				setDeepState('layout.sorting', newState);
			}
		}
	}, [
		mode,
		state.layout.columnsSortOrder,
		derivedState.selectedDataFieldDimensions,
	]);

	const getDimensionForItemId = (itemId: string) => {
		const itemDimension = itemId.includes('.')
			? (itemId.split('.').pop() as string)
			: itemId;

		return itemDimension;
	};

	const handleDragStart = (event: DragStartEvent) => {
		const sourceContainerId = event.active.data.current?.sortable
			?.containerId as string | undefined;

		if (!sourceContainerId) return;

		const itemId = event.active.id as string;
		const activeItem = allSelectedDimensions.find(
			(item) => item.id === getDimensionForItemId(itemId)
		);

		setClonedLayoutState(state.layout);
		setSourceContainer(sourceContainerId);
		setActiveItem(activeItem ?? null);
	};

	const containerIds = useMemo(
		() => ({
			fields: 'fields',
			dataFields: 'dataFields',
			sorting: 'sorting',
			columns: 'columns',
		}),
		[]
	);

	type ContainerId = keyof typeof containerIds;

	const getItemsForContainer = (containerId: ContainerId) => {
		switch (containerId) {
			case 'columns':
				return state.layout.columnsSortOrder;
			case 'fields':
				return state.layout.fieldSortOrder;
			case 'dataFields':
				return state.layout.dataFieldSortOrder;
			case 'sorting':
				return state.layout.sorting.map((i) => i.id);
			default:
				return null;
		}
	};

	const getLayoutKeyForContainer = (
		containerId: ContainerId
	): keyof WorksheetState['layout'] | null => {
		switch (containerId) {
			case 'columns':
				return 'columnsSortOrder';
			case 'fields':
				return 'fieldSortOrder';
			case 'dataFields':
				return 'dataFieldSortOrder';
			case 'sorting':
				return 'sorting';
			default:
				return null;
		}
	};

	function handleDragEnd(event: DragEndEvent) {
		setSourceContainer(null);
		setActiveItem(null);

		const { active, over } = event;

		const sourceContainerId = active.data.current?.sortable?.containerId as
			| string
			| undefined;

		if (!sourceContainerId || !activeItem) return;
		if (!active || !over || active.id === over?.id) return;

		const updateState = (sortOrder: string[]) => {
			const sourceItems = {
				columns: allSelectedDimensions.filter((item) =>
					state.layout.columnsSortOrder.includes(item.id)
				),
				fields: derivedState.selectedFieldDimensions.filter(
					(item) => !state.layout.columnsSortOrder.includes(item.id)
				),
				dataFields: derivedState.selectedDataFieldDimensions.filter(
					(item) => !state.layout.columnsSortOrder.includes(item.id)
				),
				sorting: allSelectedDimensions.filter((item) =>
					state.layout.sorting.find((i) => i.id === item.id)
				),
			}[sourceContainerId];

			if (!sourceItems) return sortOrder;

			const sortedDimensions = [...sourceItems].sort(
				sortBySortOrderArray(sortOrder, 'id')
			);

			const oldIndex = sortedDimensions.findIndex(
				(i) => i.id === getDimensionForItemId(active.id as string)
			);
			const newIndex = sortedDimensions.findIndex(
				(i) => i.id === getDimensionForItemId(over.id as string)
			);

			const updatedSortedDimensions = arrayMove(
				sortedDimensions,
				oldIndex,
				newIndex
			);

			const updatedSortOrder = updatedSortedDimensions.map((i) => i.id);

			return updatedSortOrder;
		};

		switch (sourceContainerId) {
			case 'columns':
				setDeepState(`layout.columnsSortOrder`, updateState);
				break;
			case 'fields':
				setDeepState(`layout.fieldSortOrder`, updateState);
				break;
			case 'dataFields':
				setDeepState(`layout.dataFieldSortOrder`, updateState);
				break;
			case 'sorting':
				{
					const newState = updateState(state.layout.sorting.map((i) => i.id));
					const newSortingState = newState.map((id) => ({
						id,
						desc: state.layout.sorting.some((i) => i.id === id)
							? state.layout.sorting.find((i) => i.id === id)?.desc ?? false
							: false,
					}));
					setDeepState(`layout.sorting`, newSortingState);
				}
				break;
		}
	}

	function handleDragOver(event: DragOverEvent) {
		const { active, over } = event;

		const overItem = over?.id;
		const overContainer =
			over?.data.current?.type === 'container'
				? over?.id
				: over?.data.current?.sortable?.containerId;

		const activeItem = active?.id;
		const activeContainer = active?.data.current?.sortable?.containerId;

		if (
			!overContainer ||
			!activeContainer ||
			!activeItem ||
			!overItem ||
			typeof activeItem !== 'string' ||
			typeof overItem !== 'string'
		) {
			return;
		}

		// If dragging occurs in the same container, we don't need to handle anything here
		if (activeContainer === overContainer) {
			return;
		}

		const activeItems = getItemsForContainer(activeContainer);
		const overItems = getItemsForContainer(overContainer);

		if (!activeItems || !overItems) {
			return;
		}

		const overIndex = overItems.indexOf(overItem);

		let newIndex: number;

		const isBelowOverItem =
			over &&
			active.rect.current.translated &&
			active.rect.current.translated.top > over.rect.top + over.rect.height;

		const modifier = isBelowOverItem ? 1 : 0;

		newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;

		recentlyMovedToNewContainer.current = true;

		const activeContainerKey = getLayoutKeyForContainer(activeContainer);
		const overContainerKey = getLayoutKeyForContainer(overContainer);

		if (!activeContainerKey || !overContainerKey) return;

		setDeepState(`layout`, (state) => ({
			...state,
			[activeContainerKey]: (state[activeContainerKey] as string[]).filter(
				(item) => item !== activeItem
			),
			[overContainerKey]: [
				...((state[overContainerKey] as string[]) || []).slice(0, newIndex),
				activeItem,
				...((state[overContainerKey] as string[]) || []).slice(
					newIndex,
					(state[overContainerKey] as string[])?.length
				),
			],
		}));
	}

	const handleDragCancel = () => {
		if (clonedLayoutState) {
			// Reset items to their original state in case items have been
			// Dragged across containers
			setDeepState('layout', clonedLayoutState);
		}

		setSourceContainer(null);
		setClonedLayoutState(null);
		setActiveItem(null);
	};

	const addToSort = (id: string) => {
		const newOrderBy = [...state.layout.sorting];

		if (!newOrderBy.find((i) => i.id === id)) {
			newOrderBy.push({ id, desc: false });
		}

		setDeepState('layout.sorting', newOrderBy);
	};

	const removeFromSort = (id: string) => {
		const newOrderBy = [...state.layout.sorting].filter((i) => i.id !== id);

		setDeepState('layout.sorting', newOrderBy);
	};

	const lastOverId = useRef<string | null>(null);
	const recentlyMovedToNewContainer = useRef(false);

	const collisionDetectionStrategy: CollisionDetection = useCallback(
		(args) => {
			// Start by finding any intersecting droppable
			const pointerIntersections = pointerWithin(args);
			const intersections =
				pointerIntersections.length > 0
					? // If there are droppables intersecting with the pointer, return those
					  pointerIntersections
					: rectIntersection(args);

			let overId = getFirstCollision(intersections, 'id') as string;
			if (overId !== null) {
				if (overId in containerIds) {
					const containerItems = getItemsForContainer(overId as ContainerId);
					// If a container is matched and it contains items (columns 'A', 'B', 'C')
					if (containerItems && containerItems.length > 0) {
						// Return the closest droppable within that container
						overId = closestCenter({
							...args,
							droppableContainers: args.droppableContainers.filter(
								(container) =>
									getDimensionForItemId(container.id as string) !== overId &&
									containerItems.includes(
										getDimensionForItemId(container.id as string)
									)
							),
						})[0]?.id as string;
					}
				}

				lastOverId.current = overId;

				return [{ id: overId }];
			}

			// When a draggable item moves to a new container, the layout may shift
			// and the `overId` may become `null`. We manually set the cached `lastOverId`
			// to the id of the draggable item that was moved to the new container, otherwise
			// the previous `overId` will be returned which can cause items to incorrectly shift positions
			if (recentlyMovedToNewContainer.current && activeItem) {
				lastOverId.current = activeItem.id;
			}

			// If no droppable is matched, return the last match
			return lastOverId.current ? [{ id: lastOverId.current }] : [];
		},
		[activeItem, state.layout]
	);

	const switchFieldsAndColumns = () => {
		const selectedFieldIds = derivedState.selectedFieldDimensions
			.filter((i) => !selectedColumnsDimensions.includes(i))
			.map((i) => i.id);

		const selectedColumnIds = selectedColumnsDimensions.map((i) => i.id);

		setDeepState('layout', (state) => ({
			...state,
			columnsSortOrder: selectedFieldIds,
			fieldSortOrder: selectedColumnIds,
			columnsSortDirections: {},
			sorting: state.sorting.filter(
				(i) =>
					!derivedState.selectedDataFieldDimensions.some(
						(d) => d.id === i.id
					) &&
					!state.columnsSortOrder.includes(i.id) &&
					!selectedFieldIds.includes(i.id)
			),
		}));
	};

	return (
		<DndContext
			sensors={sensors}
			collisionDetection={collisionDetectionStrategy}
			onDragEnd={handleDragEnd}
			onDragOver={handleDragOver}
			onDragStart={handleDragStart}
			onDragCancel={handleDragCancel}
		>
			<div className="worksheet-layout-builder layout-builder">
				<LayoutBuilderSection
					items={selectedColumnsDimensions}
					sortOrder={state.layout.columnsSortOrder}
					label="Columns"
					sortableId={containerIds.columns}
					onReset={() => {
						setDeepState(`layout.columnsSortOrder`, []);
					}}
					onSwitch={switchFieldsAndColumns}
					emptyText="Drag fields here to add them to the report as a pivot column."
					sortDirections={state.layout.columnsSortDirections}
					onSortDirectionChange={(id, sortDirection) => {
						setDeepState('layout.columnsSortDirections', (state) => ({
							...state,
							[id]: sortDirection,
						}));
					}}
					isDisabled={
						sourceContainer === 'sorting' || sourceContainer === 'dataFields'
					}
				/>

				<LayoutBuilderSection
					items={derivedState.selectedFieldDimensions.filter(
						(i) => !selectedColumnsDimensions.includes(i)
					)}
					sortOrder={state.layout.fieldSortOrder}
					label="Rows"
					sortableId={containerIds.fields}
					onReset={() => {
						setDeepState(`layout.fieldSortOrder`, []);
					}}
					isDisabled={
						sourceContainer === 'sorting' || sourceContainer === 'dataFields'
					}
					onAddToSort={addToSort}
					onRemoveFromSort={removeFromSort}
					isSorted={(id) => !!state.layout.sorting.find((i) => i.id === id)}
				/>

				<LayoutBuilderSection
					items={derivedState.selectedDataFieldDimensions}
					sortOrder={state.layout.dataFieldSortOrder}
					label="Data"
					sortableId={containerIds.dataFields}
					onReset={() => {
						setDeepState(`layout.dataFieldSortOrder`, []);
					}}
					isDisabled={!!sourceContainer && sourceContainer !== 'dataFields'}
					onAddToSort={mode === LayoutBuilderMode.TABLE ? addToSort : undefined}
					onRemoveFromSort={
						mode === LayoutBuilderMode.TABLE ? removeFromSort : undefined
					}
					isSorted={(id) => !!state.layout.sorting.find((i) => i.id === id)}
					description={
						mode === LayoutBuilderMode.PIVOT
							? 'Pivoted data fields cannot be sorted directly.\nSort these fields by clicking the columns in the preview table.'
							: undefined
					}
				/>

				<Separator orientation="vertical" />

				<LayoutBuilderSection
					items={
						isSortedByCustomPivotDimensions ? [] : selectedSortingDimensions
					}
					sortOrder={
						isSortedByCustomPivotDimensions
							? []
							: state.layout.sorting.map((i) => i.id)
					}
					label="Sorting"
					sortableId={containerIds.sorting}
					prefixItemIds
					onReset={() => {
						setDeepState(`layout.sorting`, []);
					}}
					showResetWhenEmpty={
						isSortedByCustomPivotDimensions && state.layout.sorting.length > 0
					}
					emptyText={
						isSortedByCustomPivotDimensions
							? 'You are currently sorting by pivoted columns, please continue managing it in the table below.'
							: 'Click the sort icon next to a field to add it to the report as a sort.'
					}
					sortDirections={state.layout.sorting.reduce((acc, cur) => {
						acc[cur.id] = cur.desc ? 'desc' : 'asc';
						return acc;
					}, {} as Record<string, 'asc' | 'desc'>)}
					onSortDirectionChange={(id, sortDirection) => {
						const newOrderBy = [...state.layout.sorting];
						const itemIndex = newOrderBy.findIndex((i) => i.id === id);
						if (itemIndex < 0) return;
						newOrderBy[itemIndex].desc = sortDirection === 'desc';
						setDeepState('layout.sorting', newOrderBy);
					}}
					onRemove={(id) => {
						const newState = state.layout.sorting.filter((i) => i.id !== id);
						setDeepState('layout.sorting', newState);
					}}
					isDisabled={!!sourceContainer && sourceContainer !== 'sorting'}
				/>
			</div>

			<DragOverlay>
				{activeItem ? (
					<LayoutBuilderItemPresentation name={activeItem.name} hasShadow />
				) : null}
			</DragOverlay>
		</DndContext>
	);
};

export default LayoutBuilder;
