import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import Flex from 'ui/components/Flex';
import Grid from 'ui/components/Grid';
import ImpactReportPanel from 'ui/components/ImpactReportPanel';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { LoaderData } from 'utils/types/loaderData';
import ProfileAPI from '../../api/ProfileAPI';
import ReportHubAPI from '../../api/ReportHubAPI';
import AdhocReportsPanel from '../../components/AdhocReportsPanel';
import BipReportsPanel from '../../components/BipReportsPanel';
import MySubscriptionPanel from '../../components/MySubscriptionPanel';
import StaticReportsPanel from '../../components/StaticReportsPanel';
import WebAnnouncementsPanel from '../../components/WebAnnouncementsPanel';
import WebToolScheduledReportsPanel from '../../components/WebToolScheduledReportsPanel/WebToolScheduledReportsPanel';
import WebToolWorksheetsPanel from '../../components/WebToolWorksheetsPanel';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);

	const homepage = await ProfileAPI.getHomepageProfile();
	const reports = await ReportHubAPI.getLastMonthsReports();
	const worksheets =
		await ReportHubAPI.getLatestWebToolWorksheetsAndSchedules();

	return {
		announcements: homepage.announcements,
		homepage,
		reports,
		worksheets,
		impactReport: homepage.report,
	};
};

export default function Home() {
	const { announcements, impactReport, homepage, reports, worksheets } =
		useLoaderData() as LoaderData<typeof loader>;

	return (
		<>
			<div className="content">
				<Flex direction="column" gap={16}>
					<Grid columns={2}>
						<Flex direction="column" gap={16}>
							<WebAnnouncementsPanel announcements={announcements.items} />
							<MySubscriptionPanel profile={homepage} />
						</Flex>
						<Flex direction="column" gap={16}>
							<ImpactReportPanel
								impactReport={impactReport || undefined}
								showDownloadLink
								showPreviousLink
							/>
							<WebToolWorksheetsPanel worksheets={worksheets.worksheets} />
							<WebToolScheduledReportsPanel reports={worksheets.scheduled} />
							<BipReportsPanel reports={reports.bipReports} />
							<StaticReportsPanel reports={reports.staticReports} />
							<AdhocReportsPanel reports={reports.adhocReports} />
						</Flex>
					</Grid>
				</Flex>
			</div>
		</>
	);
}

export const HOME_ROUTE = {
	element: <Home />,
	loader,
};
