import { createColumnHelper } from '@tanstack/react-table';
import IndeterminateCheckbox from 'ui/components/IndeterminateCheckbox';

const columnHelper = createColumnHelper<{}>();

export const selectColumn = columnHelper.display({
	id: 'selection',
	meta: {
		shrink: true,
	},
	header: ({ table }) => (
		<IndeterminateCheckbox
			{...{
				checked: table.getIsAllRowsSelected(),
				indeterminate: table.getIsSomeRowsSelected(),
				onChange: table.getToggleAllRowsSelectedHandler(),
			}}
		/>
	),
	cell: ({ row }) => (
		<IndeterminateCheckbox
			{...{
				checked: row.getIsSelected(),
				indeterminate: row.getIsSomeSelected(),
				onChange: row.getToggleSelectedHandler(),
				name: 'selectedRows',
				value: row.id,
			}}
		/>
	),
});
