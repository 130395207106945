import React from 'react';
import PillDrawer from 'ui/components/PillDrawer';
import { AirlineOption } from 'utils/api/AirlinesAPI';
import { AirlinePill, AirlinePillProps } from './AirlinePill';

type AirlineListProps<T> = {
	airlines: T[] | undefined;
	emptyText: string;
} & Pick<AirlinePillProps, 'variant'>;

export const AirlineList = ({
	airlines,
	variant,
	emptyText,
}: AirlineListProps<AirlineOption>) => {
	if (airlines && airlines.length > 0) {
		return (
			<>
				<PillDrawer maxItems={5}>
					{airlines?.map((airline) => (
						<React.Fragment key={airline.name}>
							<AirlinePill airline={airline} variant={variant} />
						</React.Fragment>
					))}
				</PillDrawer>
			</>
		);
	}

	return <>{emptyText}</>;
};

export const AirlineCodeList = ({
	airlines,
	emptyText,
}: AirlineListProps<string>) => {
	if (airlines && airlines.length > 0) {
		return <>{airlines.join(', ')}</>;
	}

	return <>{emptyText}</>;
};
