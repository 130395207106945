import classNames from 'classnames';
import { BringToFront, Info, Sliders } from 'lucide-react';
import { memo, useMemo, useState } from 'react';
import Menu from 'ui/components/Menu';
import Tooltip from 'ui/components/Tooltip/Tooltip';
import {
	WorksheetConfigField,
	WorksheetConfigWebToolGroup,
} from 'utils/api/WebToolAPI';
import { useWorksheetContext } from '../WorksheetContext';

type WorksheetCheckboxGroupItemProps = {
	option: WorksheetConfigField;
	isChecked: boolean;
	isDisabled: boolean;
	onChange: (option: WorksheetConfigField) => void;
};

const WorksheetCheckboxGroupItem = ({
	option,
	isChecked,
	isDisabled,
	onChange,
}: WorksheetCheckboxGroupItemProps) => {
	const { worksheet, state, setDeepState } = useWorksheetContext();

	const availableWebToolGroups = useMemo(() => {
		if (!option.groupType) return null;

		return worksheet.config.webToolGroups.filter(
			(group) => group.groupType === option.groupType
		);
	}, [option.groupType, worksheet.config.webToolGroups]);

	const selectedWebToolGroups = useMemo(() => {
		if (!availableWebToolGroups) return null;

		const selectedGroups = state.output.webToolGroups[option.id];
		if (!selectedGroups) return [];

		return availableWebToolGroups.filter((group) =>
			selectedGroups.includes(group.id)
		);
	}, [option.id, state.output.webToolGroups, availableWebToolGroups]);

	const isGroupSelected = (group: WorksheetConfigWebToolGroup) => {
		const selectedGroups = state.output.webToolGroups[option.id];
		if (!selectedGroups) return false;

		return selectedGroups.includes(group.id);
	};

	const handleGroupSelectionChange = (group: WorksheetConfigWebToolGroup) => {
		const selectedGroups = state.output.webToolGroups[option.id] ?? [];
		if (isGroupSelected(group)) {
			setDeepState(
				`output.webToolGroups.${option.id}`,
				selectedGroups.filter((id) => id !== group.id)
			);
		} else {
			setDeepState(`output.webToolGroups.${option.id}`, [
				...selectedGroups,
				group.id,
			]);
		}
	};

	const getOverlappingGroups = (group: WorksheetConfigWebToolGroup) => {
		if (isGroupSelected(group)) return [];

		const overlapIds = group.overlaps;
		const overlappingGroups = selectedWebToolGroups?.filter((group) =>
			overlapIds.includes(group.id)
		);

		return overlappingGroups;
	};

	const groupsPreview = useMemo(() => {
		if (!selectedWebToolGroups || !availableWebToolGroups) return null;

		if (selectedWebToolGroups.length === 0) {
			return 'No options selected';
		}

		return selectedWebToolGroups.map((group) => group.name).join(', ');
	}, [selectedWebToolGroups, availableWebToolGroups]);

	const [groupOptionsOpen, setGroupOptionsOpen] = useState(false);
	const showHint =
		option.hint &&
		(!availableWebToolGroups || (availableWebToolGroups && !isChecked));

	return (
		<label
			className={classNames(
				'checkbox-group__item',
				isDisabled && 'checkbox-group__item--disabled',
				isChecked && 'checkbox-group__item--checked'
			)}
			key={option.id}
		>
			<input
				type="checkbox"
				name={option.id}
				checked={isChecked}
				onChange={() => {
					onChange(option);
					if (
						availableWebToolGroups &&
						availableWebToolGroups.length > 0 &&
						selectedWebToolGroups?.length === 0
					) {
						setGroupOptionsOpen(true);
					}
				}}
				className="checkbox__group-input"
				disabled={isDisabled}
			/>
			<div className="checkbox-group__wrapper">
				<div className="checkbox-group__content">
					<Tooltip disabled={!option.tooltip}>
						<Tooltip.Trigger>
							<span className="checkbox-group__label">{option.label}</span>
						</Tooltip.Trigger>
						<Tooltip.Content>{option.tooltip}</Tooltip.Content>
					</Tooltip>

					{groupsPreview && (
						<span
							className={classNames(
								'checkbox-group__sub-label',
								(!selectedWebToolGroups ||
									selectedWebToolGroups.length === 0) &&
									!groupOptionsOpen &&
									'checkbox-group__sub-label--invalid'
							)}
							title={groupsPreview}
						>
							{groupsPreview}
						</span>
					)}
				</div>

				{availableWebToolGroups &&
					availableWebToolGroups.length > 0 &&
					isChecked && (
						<Menu
							closeOnClick={false}
							isOpen={groupOptionsOpen}
							onOpenChange={setGroupOptionsOpen}
						>
							<Menu.Trigger>
								<Sliders className="checkbox-group__icon-button" size={14} />
							</Menu.Trigger>

							{availableWebToolGroups.map((group) => {
								const overlappingGroups = getOverlappingGroups(group);
								const overlappingGroupsTooltip =
									overlappingGroups && overlappingGroups.length > 0
										? 'Overlaps with ' +
										  overlappingGroups.map((group) => group.name).join(', ')
										: undefined;

								return (
									<Menu.Item
										label={group.name}
										key={group.id}
										isChecked={isGroupSelected(group)}
										disabled={overlappingGroups && overlappingGroups.length > 0}
										onClick={() => handleGroupSelectionChange(group)}
										tooltip={overlappingGroupsTooltip}
										tooltipIcon={BringToFront}
									/>
								);
							})}
						</Menu>
					)}

				{showHint && (
					<Tooltip>
						<Tooltip.Trigger>
							<Info className="checkbox-group__icon" size={14} />
						</Tooltip.Trigger>
						<Tooltip.Content>{option.hint}</Tooltip.Content>
					</Tooltip>
				)}
			</div>
		</label>
	);
};

export default memo(WorksheetCheckboxGroupItem);
