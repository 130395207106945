import classNames from 'classnames';
import React, { ReactElement, ReactNode } from 'react';
import Sidebar, { SidebarProps } from './Sidebar';

type SidebarWrapperProps = {
	children: React.ReactNode;
};

const elementIsSidebar = (
	element: ReactNode
): element is ReactElement<SidebarProps> =>
	(element as ReactElement<SidebarProps>).type === Sidebar;

const SidebarWrapper = ({ children }: SidebarWrapperProps) => {
	// Search sidebar in children
	const existingSidebar =
		React.Children.toArray(children).find(elementIsSidebar);

	// Content is everything else
	const content = React.Children.toArray(children).filter(
		(child) => !elementIsSidebar(child)
	);

	if (!existingSidebar) {
		throw new Error('<SidebarWrapper> must have a <Sidebar> as a child');
	}

	const sidebar = React.cloneElement(existingSidebar, {
		className: classNames(
			'sidebar-wrapper__sidebar',
			existingSidebar.props.className
		),
	});

	return (
		<div className="sidebar-wrapper">
			<div className="sidebar-wrapper__content">{content}</div>
			{sidebar}
		</div>
	);
};

export default SidebarWrapper;
