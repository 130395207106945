import { Toaster as HotToaster } from 'react-hot-toast';

type ToasterProps = {};

const Toaster = ({}: ToasterProps) => {
	return (
		<HotToaster
			position="top-center"
			reverseOrder={false}
			gutter={8}
			containerClassName="toaster"
			containerStyle={{}}
		/>
	);
};

export default Toaster;
