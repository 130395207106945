import { Folder } from 'lucide-react';
import { FormEvent, useId, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'ui/components/Button/Button';
import { catchWithError } from 'utils/helpers/catchHandlers';
import CreateWorksheetForm from '../../components/CreateWorksheetModal/CreateWorksheetForm';
import WebToolWorksheetLoader from '../../components/WebToolWorksheetLoader/WebToolWorksheetLoader';
import { useWebToolContext } from './WebToolContext';

type WebToolEmptyStateProps = {
	onWorksheetBrowserOpen: () => void;
};

const WebToolEmptyState = ({
	onWorksheetBrowserOpen,
}: WebToolEmptyStateProps) => {
	const { worksheets, subscriptions, isLoadingWorksheets, createWorksheet } =
		useWebToolContext();

	const createFormId = useId();
	const [isLoading, setLoading] = useState(false);

	const mostRecentWorksheets = useMemo(() => {
		return [...worksheets]
			.sort((a, b) => {
				return (
					(b.updatedAt ?? b.createdAt).getTime() -
					(a.updatedAt ?? a.createdAt).getTime()
				);
			})
			.slice(0, 5);
	}, [worksheets]);

	const handleCreateWorksheet = async (e: FormEvent) => {
		e.preventDefault();
		setLoading(true);

		const form = e.target as HTMLFormElement;
		const formData = new FormData(form);

		const name = formData.get('name') as string;
		const subscriptionId = formData.get('subscriptionId') as string;

		await createWorksheet(name, subscriptionId);
		setLoading(false);
	};

	if (isLoadingWorksheets) {
		return <WebToolWorksheetLoader />;
	}

	return (
		<div className="webtool__empty empty">
			<div className="empty__left">
				<h2 className="empty__title">Create a report</h2>

				<div className="empty__create">
					<CreateWorksheetForm
						subscriptions={subscriptions}
						id={createFormId}
						onSubmit={(e) => handleCreateWorksheet(e).catch(catchWithError)}
					/>
					<Button
						variant="secondary"
						type="submit"
						form={createFormId}
						isLoading={isLoading}
					>
						Create report
					</Button>
				</div>
			</div>
			<div className="empty__right">
				<h2 className="empty__title">Recent reports</h2>

				<ul className="empty__list">
					{mostRecentWorksheets.map((worksheet) => (
						<li className="empty__item" key={worksheet.id}>
							<Link to={`/webtool/${worksheet.id}`}>
								<Folder size={16} />
								<span>{worksheet.name}</span>
							</Link>
						</li>
					))}
				</ul>

				<Button variant="secondary" onClick={onWorksheetBrowserOpen}>
					Browse all reports
				</Button>
			</div>
		</div>
	);
};

export default WebToolEmptyState;
