import {
	arrow,
	autoUpdate,
	offset,
	safePolygon,
	size,
	useDismiss,
	useFloating,
	useFocus,
	useHover,
	useInteractions,
} from '@floating-ui/react';
import classNames from 'classnames';
import { useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { NavigationRootItem } from './Navigation';
import NavigationDropdown from './NavigationDropdown';

type NavigationItemProps<Roles extends string> = {
	item: NavigationRootItem<Roles>;
};

const NavigationItem = <Roles extends string>({
	item,
}: NavigationItemProps<Roles>) => {
	const { name, path } = item;
	const hasDropdown = item.sections && item.sections.length > 0;

	const [isOpen, setOpen] = useState(false);
	const arrowRef = useRef(null);

	const {
		x,
		y,
		refs,
		middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
		strategy,
		context,
	} = useFloating({
		open: isOpen,
		onOpenChange: setOpen,
		placement: 'bottom',
		whileElementsMounted: autoUpdate,
		middleware: [
			offset(16),
			arrow({ element: arrowRef }),
			size({
				apply({ rects, availableHeight, elements, availableWidth }) {
					Object.assign(elements.floating.style, {
						minWidth: `${rects.reference.width}px`,
						maxHeight: `${availableHeight - 20}px`,
						maxWidth: `${availableWidth - 20}px`,
					});
				},
			}),
		],
	});

	const hover = useHover(context, {
		handleClose: safePolygon(),
	});
	const focus = useFocus(context);
	const dismiss = useDismiss(context);

	const { getReferenceProps, getFloatingProps } = useInteractions([
		hover,
		focus,
		dismiss,
	]);

	return (
		<li
			className="navigation__item"
			ref={hasDropdown ? refs.setReference : undefined}
			{...(hasDropdown ? getReferenceProps() : {})}
		>
			{path !== undefined ? (
				<NavLink
					className={({ isActive }) =>
						classNames(
							'navigation__link',
							isActive && 'navigation__link--active'
						)
					}
					to={path}
					onClick={item.onClick}
					end
				>
					{name}
				</NavLink>
			) : (
				<span
					className="navigation__link navigation__link--static"
					tabIndex={0}
				>
					{name}
				</span>
			)}
			{isOpen && hasDropdown && (
				<NavigationDropdown
					item={item}
					ref={refs.setFloating}
					isOpen={isOpen}
					onClose={() => setOpen(false)}
					arrowProps={{
						ref: arrowRef,
						style: {
							left: arrowX,
							top: arrowY,
						},
					}}
					style={{
						position: strategy,
						top: y ?? 0,
						left: x ?? 0,
						// width: 'min-content',
					}}
					{...getFloatingProps()}
				/>
			)}
		</li>
	);
};

export default NavigationItem;
