import { ChevronRight, Table2 } from 'lucide-react';
import Button from 'ui/components/Button';
import Card from 'ui/components/Card';
import { GetLatestWebToolWorksheetsItem } from '../../api/ReportHubAPI';
import DateFragment from 'ui/components/DateFragment/DateFragment';

type WebToolWorksheetsPanelProps = {
	worksheets: GetLatestWebToolWorksheetsItem[];
};

const WebToolWorksheetsPanel = ({
	worksheets,
}: WebToolWorksheetsPanelProps) => {
	if (worksheets.length === 0) {
		return null;
	}

	return (
		<Card className="webtool-worksheets">
			<div className="webtool-worksheets__header">
				<h2 className="webtool-worksheets__title">
					My Latest Web Tool Reports
				</h2>
				<Button
					variant="secondary"
					icon={ChevronRight}
					iconPosition="right"
					to="/webtool"
				>
					Web Tool
				</Button>
			</div>
			<ul className="webtool-worksheets__list">
				{worksheets.map((worksheet) => (
					<li key={worksheet.id} className="webtool-worksheets__item">
						<div className="webtool-worksheets__icon">
							<Table2 />
						</div>
						<div className="webtool-worksheets__details">
							<a href={`/webtool/${worksheet.id}`}>
								<b className="webtool-worksheets__name">{worksheet.name}</b>
								<span className="webtool-worksheets__date">
									Last Updated:{' '}
									<DateFragment
										date={worksheet.lastUpdatedAt}
										includeTime={true}
									/>
								</span>
							</a>
						</div>
					</li>
				))}
			</ul>
		</Card>
	);
};

export default WebToolWorksheetsPanel;
