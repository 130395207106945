import Pill from 'ui/components/Pill';
import { Location } from 'utils/api/common';
import { CountryPill } from '../CountryPicker';

export type LocationPillProps = {
	location: Location;
	variant?: 'full' | 'small';
};

const LocationPill = ({ location, variant }: LocationPillProps) => {
	switch (location.itemType) {
		case 'station': {
			const airportName = `${location.name}, ${location.countryCode}`;
			return (
				<Pill
					code={location.code}
					name={variant === 'full' ? airportName : undefined}
					title={variant === 'full' ? 'Airport' : `${airportName} - Airport`}
				/>
			);
		}
		case 'country':
			return <CountryPill country={location} variant={variant} />;
		case 'city': {
			const cityName = `${location.name}, ${location.countryName}`;
			return (
				<Pill
					code={location.code}
					name={variant === 'full' ? cityName : undefined}
					title={variant === 'full' ? 'City' : `${cityName} - City`}
				/>
			);
		}
		case 'province':
			return (
				<Pill
					code={location.code}
					name={variant === 'full' ? location.name : undefined}
					title={
						variant === 'full' ? 'Province' : `${location.name} - Province`
					}
				/>
			);
		case 'region':
			return <Pill name={location.name} title="Region" />;
		case 'custom-group':
			return <Pill name={location.name} title="Custom Group" />;
	}
};

export default LocationPill;
