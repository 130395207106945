import { ChevronRight, FileSpreadsheet } from 'lucide-react';
import Button from 'ui/components/Button';
import Card from 'ui/components/Card';
import YearAndMonthFragment from 'ui/components/YearAndMonthFragment';
import formatBytes from 'utils/helpers/formatBytes';
import { LastMonthReport } from '../../api/ReportHubAPI';

type StaticReportsPanelProps = {
	reports: LastMonthReport[];
};

const StaticReportsPanel = ({ reports }: StaticReportsPanelProps) => {
	if (reports.length === 0) {
		return null;
	}

	return (
		<Card className="static-reports">
			<div className="static-reports__header">
				<h2 className="static-reports__title">My Static Reports</h2>
				<Button
					variant="secondary"
					icon={ChevronRight}
					iconPosition="right"
					to="/report-hub"
				>
					Report Hub
				</Button>
			</div>
			<ul className="static-reports__list">
				{reports.map((report) => (
					<li key={report.id} className="static-reports__item">
						<div className="static-reports__icon">
							<FileSpreadsheet />
						</div>
						<div className="static-reports__details">
							<a
								href={`/report-hub/reports/download/${report.id}`}
								target="_blank"
							>
								<span className="static-reports__date">
									<YearAndMonthFragment value={report.reportDate} />
								</span>
								<b className="static-reports__name">
									{report.productName} ({formatBytes(report.fileSizeInBytes)})
								</b>
							</a>
						</div>
					</li>
				))}
			</ul>
		</Card>
	);
};

export default StaticReportsPanel;
