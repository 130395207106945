import classNames from 'classnames';
import { AlertCircle, AlertTriangle, CheckCircle, Info } from 'lucide-react';
import { PropsWithChildren, ReactNode } from 'react';

type AlertProps = PropsWithChildren<{
	intent?: 'success' | 'warning' | 'error' | 'info';
	title: string | ReactNode;
}>;

const Alert = ({ intent = 'info', title, children }: AlertProps) => {
	const iconMap = {
		success: CheckCircle,
		warning: AlertTriangle,
		error: AlertCircle,
		info: Info,
	};

	const Icon = iconMap[intent];

	return (
		<div className={classNames('alert', `alert--${intent}`)}>
			<Icon className="alert__icon" />
			<div className="alert__content">
				<span className="alert__title">{title}</span>
				{children && <div className="alert__text">{children}</div>}
			</div>
		</div>
	);
};

export default Alert;
