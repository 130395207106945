import { ColumnDef } from '@tanstack/react-table';
import { Download, RefreshCcw, Search } from 'lucide-react';
import { useCallback, useState } from 'react';
import {
	LoaderFunctionArgs,
	useLoaderData,
	useLocation,
} from 'react-router-dom';
import Button from 'ui/components/Button';
import DateField from 'ui/components/DateField/DateField';
import YearMonthPicker from 'ui/components/DatePicker/YearMonthPicker';
import FormField from 'ui/components/FormField';
import LocationPicker from 'ui/components/LocationPicker/LocationPicker';
import MultiSelectField from 'ui/components/MultiSelectField';
import PageHeader from 'ui/components/PageHeader';
import Pagination from 'ui/components/Pagination';
import Sidebar from 'ui/components/Sidebar';
import Table from 'ui/components/Table';
import ValidatedForm from 'ui/components/ValidatedForm';
import YearAndMonthFragment from 'ui/components/YearAndMonthFragment';
import { LocationType } from 'utils/api/LocationAPI';
import * as genericColumns from 'utils/columns/genericColumns';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import ReportHubAPI, { StaticReport } from '../../api/ReportHubAPI';
import * as reportColumns from '../../utils/columns/reportHubStaticListColumns';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	return ReportHubAPI.getStaticList(new URL(request.url).searchParams);
};

export function ReportHubStaticReports() {
	const data = useLoaderData() as LoaderData<typeof loader>;
	const location = useLocation();
	const [selectedReportIdsForExport, setSelectedReportIdsForExport] = useState<
		string[]
	>([]);

	const columns = [
		genericColumns.selectColumn as ColumnDef<StaticReport, void>,
		reportColumns.productNameColumn,
		reportColumns.reportMonthColumn,
		reportColumns.createdAtColumn,
		reportColumns.attachmentColumn,
		reportColumns.originsColumn,
		reportColumns.destinationsColumn,
	];

	const handleRowSelection = useCallback(
		(rowSelection: Record<string, boolean>) => {
			const selectedReportIds = Object.keys(rowSelection);
			setSelectedReportIdsForExport(selectedReportIds);
		},
		[setSelectedReportIdsForExport]
	);

	const exportData = () => {
		const newWindow = window.open(
			`/report-hub/download/${selectedReportIdsForExport.join(',')}`,
			'_blank'
		);

		newWindow?.blur();
		window.focus();
	};

	const exportLatest = () => {
		const newWindow = window.open(
			`/report-hub/download/all?fromReportDate.year=${data.latestPublishedMonth.year}&fromReportDate.month=${data.latestPublishedMonth.month}&toReportDate.year=${data.latestPublishedMonth.year}&toReportDate.month=${data.latestPublishedMonth.month}`,
			'_blank'
		);
		newWindow?.blur();
		window.focus();
	};

	const exportAll = () => {
		const newWindow = window.open(
			`/report-hub/download/all${location.search}`,
			'_blank'
		);
		newWindow?.blur();
		window.focus();
	};

	return (
		<Sidebar.Wrapper>
			<div className="content">
				<PageHeader title="Static Reports">
					<Button
						variant="secondary"
						onClick={exportData}
						isDisabled={selectedReportIdsForExport.length === 0}
						icon={Download}
					>
						Export selected{' '}
						{selectedReportIdsForExport.length > 0
							? `(${selectedReportIdsForExport.length})`
							: ''}
					</Button>
					<Button
						variant="secondary"
						icon={Download}
						onClick={exportLatest}
						isDisabled={data.latestPublishedCount === 0}
					>
						Export latest (
						{<YearAndMonthFragment value={data.latestPublishedMonth} />})
					</Button>
					<Button variant="secondary" icon={Download} onClick={exportAll}>
						Export all
					</Button>
				</PageHeader>

				<Table
					identifierKey="id"
					columns={columns}
					data={data.reports.items}
					onRowSelectionChange={handleRowSelection}
				/>

				{data.reports.totalCount > 0 && (
					<Pagination
						baseUrl={new URL(window.location.href)}
						page={data.reports.page}
						pageParameterName="page"
						pageSize={data.reports.pageSize}
						itemCount={data.reports.totalCount}
					/>
				)}
			</div>
			<Sidebar title="Report Filter">
				<ValidatedForm method="get" resetOnNavigation>
					<MultiSelectField
						label="Report Type"
						name="productTypes"
						options={data.productOptions}
						contentSource={(option) => option.name}
						identifierKey="productType"
						placeholder="Please select..."
						pillName={(item) => item.name}
					/>

					<LocationPicker
						label="Origin"
						name="origins"
						initialValues={data.originCountriesSelected}
						isMulti={true}
						locationTypes={[LocationType.COUNTRY]}
					/>

					<LocationPicker
						label="Destination"
						name="destinations"
						initialValues={data.destinationCountriesSelected}
						isMulti={true}
						locationTypes={[LocationType.COUNTRY]}
					/>

					<FormField label="From Report Date">
						<YearMonthPicker name="fromReportDate" />
					</FormField>

					<FormField label="To Report Date">
						<YearMonthPicker name="toReportDate" />
					</FormField>

					<DateField
						label="From Creation Date"
						name="fromCreationDate"
						timezone="utc"
					/>
					<DateField
						label="To Creation Date"
						name="toCreationDate"
						timezone="utc"
					/>

					<Sidebar.Actions>
						<Button variant="secondary" icon={RefreshCcw} type="reset">
							Clear search
						</Button>
						<Button variant="primary" icon={Search} type="submit">
							Search
						</Button>
					</Sidebar.Actions>
				</ValidatedForm>
			</Sidebar>
		</Sidebar.Wrapper>
	);
}

export const REPORT_HUB_STATIC_REPORTS_ROUTE: DecentralizedRouteProps = {
	loader: loader,
	element: <ReportHubStaticReports />,
};
