import { AccountInfo } from '@azure/msal-browser';
import { ENVIRONMENT_SHARED } from 'environment';
import { LogIn, LogOut } from 'lucide-react';
import { Link } from 'react-router-dom';
import { catchWithMessage } from 'utils/helpers/catchHandlers';
import Button from '../Button';
import Navigation, { NavigationRootItem } from '../Navigation/Navigation';

type HeaderProps<Role extends string> = {
	activeRole?: Role;
	navigationItems: NavigationRootItem<Role>[];
	showNavigation?: boolean;
	onLogin?: () => Promise<void>;
	onLogout?: () => Promise<void>;
	activeAccount?: AccountInfo;
};

const Header = <Roles extends string>({
	navigationItems,
	activeRole,
	showNavigation = true,
	onLogin,
	onLogout,
	activeAccount,
}: HeaderProps<Roles>) => {
	return (
		<header className="header">
			<Link to="/" className="header__home">
				<img
					className="header__logo"
					src="/img/cargois-logo.png"
					loading="lazy"
					alt="Go home"
				/>
			</Link>

			{activeAccount && activeRole && showNavigation && (
				<Navigation items={navigationItems} activeRole={activeRole} />
			)}

			{activeAccount && (
				<div className="header__actions">
					<span className="header__user">
						{activeAccount.username.substring(0, 2).toUpperCase()}
					</span>
					<button
						className="header__logout"
						onClick={(e) => {
							e.preventDefault();
							onLogout?.().catch(catchWithMessage('Failed to perform logout.'));
						}}
					>
						<LogOut />
					</button>
				</div>
			)}

			{!activeAccount && (
				<div className="header__actions">
					<Button href={ENVIRONMENT_SHARED.contactUsUrl}>Contact Us</Button>
					{onLogin && (
						<Button
							onClick={() => {
								onLogin().catch(
									catchWithMessage('Failed to redirect to login page.')
								);
							}}
							icon={LogIn}
							className="header__login"
						>
							Login
						</Button>
					)}
				</div>
			)}
		</header>
	);
};

export default Header;
