import classNames from 'classnames';
import { CSSProperties, useMemo } from 'react';
import PillSelection from 'ui/components/PillSelection/PillSelection';
import {
	WORKSHEET_DATA_FIELD_DIMENSIONS,
	WORKSHEET_DATA_FIELD_DIMENSIONS_SHORT,
	WorksheetConfig,
	WorksheetDataFieldState,
	WorksheetDataFieldTimeSeriesState,
	WorksheetDataFieldsState,
} from 'utils/api/WebToolAPI';
import { sortBySortOrder } from 'utils/helpers/sorting';
import { useWorksheetContext } from '../WorksheetContext';
import WorksheetDataPickerRow from './WorksheetDataPickerRow';

type WorksheetDataPickerProps = {
	isAdvanced: boolean;
};

const DEFAULT_DATA_FIELD_STATE: WorksheetDataFieldState = {
	customer: {
		advanced: [false, false, false, false, false, false],
		simple: false,
	},
	market: {
		advanced: [false, false, false, false, false, false],
		simple: false,
	},
	customerShare: {
		advanced: [false, false, false, false, false, false],
		simple: false,
	},
};

export const getDefaultDataFields = (config: WorksheetConfig) =>
	config.dataFields.reduce<WorksheetDataFieldsState>(
		(acc, dataField) => ({
			...acc,
			[dataField.id]: DEFAULT_DATA_FIELD_STATE,
		}),
		{}
	);

const WorksheetDataPicker = ({ isAdvanced }: WorksheetDataPickerProps) => {
	const { worksheet, state, setDeepState } = useWorksheetContext();

	const availablePeriods = useMemo(() => {
		const periods = WORKSHEET_DATA_FIELD_DIMENSIONS_SHORT.map(
			(dimension, dimensionIndex) => ({
				label: dimension,
				id: dimensionIndex,
				hint: WORKSHEET_DATA_FIELD_DIMENSIONS[dimensionIndex],
			})
		);

		if (!worksheet.config.dataFieldTimeSeries.weekOverWeek) {
			// Remove week over week options at index 4 and 5
			periods.splice(4, 2);
		}

		return periods;
	}, [worksheet.config.dataFieldTimeSeries.weekOverWeek]);

	const sortedDataFields = useMemo(
		() => [...worksheet.config.dataFields].sort(sortBySortOrder),
		[worksheet.config.dataFields]
	);

	const availableColumns = useMemo(() => {
		const anyCustomerOptionsAvailable = sortedDataFields.some(
			(dataField) => dataField.isCustomer
		);

		const anyMarketOptionsAvailable = sortedDataFields.some(
			(dataField) => dataField.isMarket
		);

		const anyCustomerShareOptionsAvailable = sortedDataFields.some(
			(dataField) => dataField.isCustomerShare
		);

		return [
			anyCustomerOptionsAvailable,
			anyMarketOptionsAvailable,
			anyCustomerShareOptionsAvailable,
		] as const;
	}, [worksheet.config.dataFields]);

	return (
		<div
			className={classNames(
				'webtool__datapicker datapicker',
				isAdvanced && 'datapicker--advanced'
			)}
			style={
				{
					'--data-picker-column-count': availableColumns.filter(Boolean).length,
				} as CSSProperties
			}
		>
			{isAdvanced && (
				<div className="datapicker__periods">
					<span>View:</span>
					<PillSelection
						options={availablePeriods}
						selectedOptions={Object.entries(
							state.output.dataFieldTimeSeries.advanced
						)
							.filter(([, selected]) => selected)
							.map(([index]) => ~~index)}
						onOptionSelected={(index) => {
							const selectedCount =
								state.output.dataFieldTimeSeries.advanced.filter(
									Boolean
								).length;

							if (
								selectedCount === 1 &&
								state.output.dataFieldTimeSeries.advanced[index]
							) {
								return;
							}

							setDeepState(
								'output.dataFieldTimeSeries.advanced',
								(dimensions: WorksheetDataFieldTimeSeriesState) => {
									const newPeriods = [
										...dimensions,
									] as WorksheetDataFieldTimeSeriesState;
									newPeriods[index] = !newPeriods[index];
									return newPeriods;
								}
							);
						}}
					/>
				</div>
			)}
			<div className="datapicker__body">
				<div className="datapicker__header">
					<b>Data</b>
					{isAdvanced && (
						<>
							{availableColumns[0] && <span>Customer</span>}
							{availableColumns[1] && <span>Market</span>}
							{availableColumns[2] && <span>Customer Share</span>}
						</>
					)}
				</div>
				<div className="datapicker__grid">
					{sortedDataFields.map((dataField) => (
						<WorksheetDataPickerRow
							key={dataField.id}
							dataField={dataField}
							availableColumns={availableColumns}
							selectionState={
								state.output.dataFields[dataField.id] ??
								DEFAULT_DATA_FIELD_STATE
							}
							onSelectionStateChange={(selectionState) => {
								setDeepState(
									`output.dataFields.${dataField.id}`,
									selectionState
								);
							}}
							isAdvanced={isAdvanced}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

export default WorksheetDataPicker;
