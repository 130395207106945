import DateFragment from 'ui/components/DateFragment';
import Grid from 'ui/components/Grid';
import Modal, { ModalProps } from 'ui/components/Modal/Modal';
import YearAndMonthFragment from 'ui/components/YearAndMonthFragment';
import formatBytes from 'utils/helpers/formatBytes';
import { BipReport, LastMonthReport } from '../../api/ReportHubAPI';

type BipReportDetailsModalProps = {
	report: LastMonthReport | BipReport | null;
} & Omit<ModalProps, 'title'>;

const BipReportDetailsModal = ({
	report,
	...modalProps
}: BipReportDetailsModalProps) => {
	if (!report) {
		return null;
	}

	return (
		<Modal title="BIP Report Details" {...modalProps}>
			<Modal.Body>
				<Grid columns="6fr 8fr" gap="12px 16px">
					<b>Report Date</b>
					<YearAndMonthFragment value={report.reportDate} />
					<b>Subscription name</b>
					<span>{report.subscriptionName}</span>
					<b>Subscription number</b>
					<span>{report.subscriptionNumber}</span>
					<b>Product Name</b>
					<span>{report.productName}</span>
					<b>File Name</b>
					<span>{report.filename}</span>
					<b>File Size</b>
					{formatBytes(report.fileSizeInBytes)}
					<b>iiNET Account</b>
					<span>{report.iiNetAccountId}</span>
					<b>Delivery Date</b>
					<DateFragment date={report.finishedAt} includeTime={true} />
				</Grid>
			</Modal.Body>
		</Modal>
	);
};

export default BipReportDetailsModal;
