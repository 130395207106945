import { useMemo } from 'react';
import AsyncMultiSelect from 'ui/components/AsyncMultiSelect';
import AsyncSelect from 'ui/components/AsyncSelect';
import FormField from 'ui/components/FormField';
import Pill from 'ui/components/Pill';
import useValidation from 'ui/components/ValidatedForm/useValidation';
import AirlinesAPI, {
	AirlineCustomGroupOption,
	AirlineOption,
	AirlineSingleOption,
	AirlineType,
} from 'utils/api/AirlinesAPI';
import { WorksheetConfigWebToolGroup } from 'utils/api/WebToolAPI';
import { catchSilently } from 'utils/helpers/catchHandlers';
import {
	createValidationToast,
	parseTextToCodes,
} from 'utils/helpers/codeValidation';
import { createToast } from 'utils/helpers/toast';
import { OptionGroup } from '../Dropdown/types';
import { ToastType } from '../Toaster/Toast';

export const customGroupToAirlineOption = (
	customGroup: WorksheetConfigWebToolGroup
): AirlineCustomGroupOption => ({
	code: customGroup.id,
	name: customGroup.name,
	itemType: 'custom-group',
});

type SingleAirlinePickerProps = {
	isMulti?: false;
	initialValue?: AirlineOption;
	initialValues?: undefined;
	onSelectedAirlineChange?: (value: AirlineOption | null) => void;
	onSelectedAirlinesChange?: undefined;
	selectedAirline?: AirlineOption | null;
	selectedAirlines?: undefined;
};

type MultiAirlinePickerProps = {
	isMulti: true;
	initialValue?: undefined;
	initialValues?: AirlineOption[];
	onSelectedAirlineChange?: undefined;
	onSelectedAirlinesChange?: (values: AirlineOption[]) => void;
	selectedAirline?: undefined;
	selectedAirlines?: AirlineOption[];
};

type AirlinePickerProps = {
	name: string;
	label?: string;
	isRequired?: boolean;
	isDisabled?: boolean;
	airlineCustomGroups?: WorksheetConfigWebToolGroup[];
	airlineTypes?: AirlineType[];
} & (SingleAirlinePickerProps | MultiAirlinePickerProps);

export const AirlinePickerPill = ({
	value,
	onRemove,
}: {
	value: AirlineOption;
	onRemove?: () => void;
}) => {
	if (value.itemType === 'custom-group') {
		return <Pill name={value.name} onRemove={onRemove} />;
	}

	return (
		<Pill
			name={value.airlinePrefix}
			code={value.iataCode}
			title={value.name}
			onRemove={onRemove}
		/>
	);
};

export const AirlineContentSource = (airline: AirlineOption) => {
	if (airline.itemType === 'custom-group') {
		return <>{airline.name}</>;
	}

	return (
		<>
			<Pill code={`${airline.iataCode}(${airline.airlinePrefix})`} />{' '}
			{airline.name}
		</>
	);
};

export const AirlinePickerPreviewSource = (value: AirlineOption) => {
	if (value.itemType === 'custom-group') {
		return <Pill name={value.name} />;
	}

	return (
		<Pill name={value.airlinePrefix} code={value.iataCode} title={value.name} />
	);
};

const AirlinePicker = (props: AirlinePickerProps) => {
	const {
		name,
		label,
		isRequired,
		isDisabled,
		airlineCustomGroups = [],
		airlineTypes = ['airline'],
	} = props;

	const { errorTree } = useValidation(name);
	const isGrouped = airlineTypes.length > 1;

	const searchAirlines = async (searchTerm: string) => {
		const data = await AirlinesAPI.getAirlines(searchTerm, airlineTypes);
		return data.options || data.groups;
	};

	const handleInsert = async (text: string) => {
		const codes = parseTextToCodes(text);

		if (codes) {
			const validatePromise = AirlinesAPI.validateAirlineCodes(codes);

			createValidationToast(validatePromise, 'airline').catch(catchSilently);

			const validationResult = await validatePromise;
			return validationResult.options;
		}

		return false;
	};

	const handleCopy = async (
		airlines: AirlineOption[] | AirlineOption | null
	) => {
		let value = '';
		let hasCustomGroups = false;

		if (Array.isArray(airlines)) {
			if (airlines.some((airline) => airline.itemType === 'custom-group')) {
				hasCustomGroups = true;
			}

			value = airlines
				.filter(
					(airline): airline is AirlineSingleOption =>
						airline.itemType !== 'custom-group'
				)
				.map((airline) => airline.iataCode)
				.join(', ');
		} else if (airlines) {
			if (airlines.itemType === 'custom-group') {
				hasCustomGroups = true;
			} else if ('code' in airlines) {
				value = airlines.code;
			}
		}

		if (value) {
			navigator.clipboard.writeText(value);
		}

		if (hasCustomGroups) {
			createToast(ToastType.WARNING, {
				message: 'Custom groups omitted',
				hint: 'The selected custom groups have been omitted. They must be manually selected from the dropdown.',
			});
		}
	};

	const defaultOptions = useMemo(():
		| AirlineOption[]
		| OptionGroup<AirlineOption>[]
		| undefined => {
		if (airlineCustomGroups.length === 0) {
			return undefined;
		}

		return [
			{
				label: 'Custom Groupings',
				options: airlineCustomGroups.map(customGroupToAirlineOption),
			},
		];
	}, [airlineCustomGroups]);

	return (
		<FormField
			label={label}
			errors={errorTree?._errors}
			isRequired={isRequired}
		>
			{props.isMulti ? (
				<AsyncMultiSelect<AirlineOption>
					defaultOptions={defaultOptions}
					isGrouped={isGrouped}
					isDisabled={isDisabled}
					onSearch={searchAirlines}
					identifierKey="code"
					name={name}
					placeholder="Search airlines..."
					handlePasteSelection={handleInsert}
					handleSearchSubmitSelection={handleInsert}
					onCopy={handleCopy}
					selectedOptions={props.selectedAirlines}
					onSelectedOptionsChange={props.onSelectedAirlinesChange}
					contentSource={AirlineContentSource}
					initialValues={props.initialValues}
					pillComponent={AirlinePickerPill}
				/>
			) : (
				<AsyncSelect<AirlineOption>
					defaultOptions={defaultOptions}
					isGrouped={isGrouped}
					isDisabled={isDisabled}
					onSearch={searchAirlines}
					identifierKey="code"
					name={name}
					placeholder="Select airline..."
					handlePasteSelection={handleInsert}
					handleSearchSubmitSelection={handleInsert}
					onCopy={handleCopy}
					contentSource={AirlineContentSource}
					isClearable={true}
					onOptionSelected={props.onSelectedAirlineChange}
					selectedOption={props.selectedAirline}
					initialValue={props.initialValue}
					previewSource={AirlinePickerPreviewSource}
				/>
			)}
		</FormField>
	);
};

export default AirlinePicker;
