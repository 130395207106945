import classNames from 'classnames';
import { ArrowUp } from 'lucide-react';
import { useEffect, useState } from 'react';

export type BackToTopProps = {
	minScrollOffset?: number;
};

const BackToTop = ({ minScrollOffset = 0 }: BackToTopProps) => {
	const [isPageScrolled, setPageScrolled] = useState(false);

	const handleScroll = () => {
		const scrollTop = document.documentElement.scrollTop || window.scrollY;
		setPageScrolled(scrollTop > minScrollOffset);
	};

	const handleBackToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	useEffect(() => {
		document.addEventListener('scroll', handleScroll);

		return () => {
			document.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<div
			className={classNames(
				'backtotop',
				isPageScrolled && 'backtotop--visible'
			)}
		>
			<button className="backtotop__button" onClick={handleBackToTop}>
				<ArrowUp size={16} />
				Back to top
			</button>
		</div>
	);
};

export default BackToTop;
