import {
	ColumnDef,
	RowSelectionState,
	createColumnHelper,
} from '@tanstack/react-table';
import { UserOption } from 'iata-cargois-admin-fe/src/api/UserAPI';
import { Share } from 'lucide-react';
import { useCallback, useState } from 'react';
import Button from 'ui/components/Button';
import Modal, { ModalProps } from 'ui/components/Modal/Modal';
import WebToolAPI from 'utils/api/WebToolAPI';
import * as genericColumns from 'utils/columns/genericColumns';
import { catchWithMessage } from 'utils/helpers/catchHandlers';
import pluralize from 'utils/helpers/pluralize';
import { createToast, createToastFromError } from 'utils/helpers/toast';
import Flex from 'ui/components/Flex/Flex';
import FormField from 'ui/components/FormField/FormField';
import Table from 'ui/components/Table/Table';
import TextField from 'ui/components/TextField/TextField';
import { ToastType } from 'ui/components/Toaster/Toast';
import { useWorksheetContext } from './WorksheetContext';

type WorksheetShareModalProps = {} & Omit<
	ModalProps,
	'title' | 'isOpen' | 'onClose'
>;

const WorksheetShareModal = ({ ...modalProps }: WorksheetShareModalProps) => {
	const { isShareModalOpen, setIsShareModalOpen, worksheet, saveWorksheet } =
		useWorksheetContext();

	const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
	const [reportName, setReportName] = useState<string>(worksheet.name);

	const [isSubmitting, setIsSubmitting] = useState(false);

	const columnHelper = createColumnHelper<UserOption>();

	const columns = [
		genericColumns.selectColumn as ColumnDef<UserOption, string>,
		columnHelper.accessor('name', {
			header: 'Name',
		}),
		columnHelper.accessor('email', {
			header: 'Email',
		}),
	];

	const handleClose = () => {
		setIsShareModalOpen(false);
		setSelectedUsers([]);
		setReportName(worksheet.name);
	};

	const handleUserSelectionChange = useCallback(
		(rowSelection: RowSelectionState) => {
			setSelectedUsers(
				Object.entries(rowSelection)
					.filter(([_, isSelected]) => isSelected)
					.map(([id, _]) => id)
			);
		},
		[]
	);

	const handleShare = async () => {
		setIsSubmitting(true);

		const saveSuccessful = await saveWorksheet();

		if (!saveSuccessful) {
			setIsSubmitting(false);
			return;
		}

		const response = await WebToolAPI.shareWebToolReport(
			worksheet.id,
			selectedUsers,
			reportName
		);

		if (response instanceof Error) {
			createToastFromError(response);
		} else {
			createToast(
				ToastType.SUCCESS,
				{
					message: 'Report shared successfully',
					hint: `The ${pluralize(
						'user',
						selectedUsers.length
					)} you've selected can find it in their report browser as '${reportName}'.`,
				},
				{
					duration: 5000,
				}
			);
			handleClose();
		}

		setIsSubmitting(false);
	};

	return (
		<Modal
			title="Share Report"
			size="md"
			onClose={handleClose}
			isOpen={isShareModalOpen}
			{...modalProps}
		>
			<Modal.Body>
				<Flex direction="column" gap={16}>
					<TextField
						label="Report name"
						name="name"
						value={reportName}
						onChange={(e) => setReportName(e.target.value)}
						description="You can customize the name of this report for the purpose of sharing it. This will not affect the name of the report in your workspace."
					/>
					<FormField
						label="Users"
						description="Select at least one user you want to share this report with."
					>
						<Table
							data={worksheet.sharing.availableUsers}
							onRowSelectionChange={handleUserSelectionChange}
							columns={columns}
							identifierKey="id"
						/>
					</FormField>
				</Flex>
			</Modal.Body>
			<Modal.Actions alignment="between">
				<Button
					variant="secondary"
					onClick={handleClose}
					isDisabled={isSubmitting}
				>
					Close
				</Button>
				<Button
					variant="primary"
					isLoading={isSubmitting}
					onClick={() =>
						handleShare().catch(catchWithMessage('Failed to share report'))
					}
					icon={Share}
					isDisabled={selectedUsers.length === 0 || reportName.length === 0}
				>
					Share
				</Button>
			</Modal.Actions>
		</Modal>
	);
};

export default WorksheetShareModal;
