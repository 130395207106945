import classNames from 'classnames';
import React from 'react';

type SeparatorProps = {
	orientation?: 'horizontal' | 'vertical';
};

const Separator = ({ orientation = 'vertical' }: SeparatorProps) => {
	return (
		<div className={classNames('separator', `separator--${orientation}`)} />
	);
};

export default Separator;
