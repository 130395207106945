import { ChevronRight, FileArchive } from 'lucide-react';
import { useState } from 'react';
import Button from 'ui/components/Button';
import Card from 'ui/components/Card';
import YearAndMonthFragment from 'ui/components/YearAndMonthFragment';
import formatBytes from 'utils/helpers/formatBytes';
import { LastMonthReport } from '../../api/ReportHubAPI';
import BipReportDetailsModal from '../BipReportDetailsModal';

type BipReportsPanelProps = {
	reports: LastMonthReport[];
};

const BipReportsPanel = ({ reports }: BipReportsPanelProps) => {
	const [selectedReport, setSelectedReport] = useState<LastMonthReport | null>(
		null
	);

	if (reports.length === 0) {
		return null;
	}

	return (
		<Card className="bip-reports">
			<div className="bip-reports__header">
				<h2 className="bip-reports__title">BIP Report Deliveries</h2>
				<Button
					variant="secondary"
					icon={ChevronRight}
					iconPosition="right"
					to="/report-hub"
				>
					Report Hub
				</Button>
			</div>
			<ul className="bip-reports__list">
				{reports.map((report) => (
					<li
						key={report.id}
						className="bip-reports__item"
						role="button"
						tabIndex={0}
						onClick={() => setSelectedReport(report)}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								setSelectedReport(report);
							}
						}}
					>
						<div className="bip-reports__icon">
							<FileArchive />
						</div>
						<div className="bip-reports__details">
							<span className="bip-reports__date">
								<YearAndMonthFragment value={report.reportDate} />
							</span>
							<b className="bip-reports__name">
								{report.productName} ({formatBytes(report.fileSizeInBytes)})
							</b>
						</div>
					</li>
				))}
			</ul>
			<BipReportDetailsModal
				report={selectedReport}
				isOpen={!!selectedReport}
				onClose={() => setSelectedReport(null)}
			/>
		</Card>
	);
};

export default BipReportsPanel;
