export type APIErrorList = [string, string][];

class APIError extends Error {
	public status?: number;
	public data?: any;
	public fieldErrors: APIErrorList | undefined;
	public headers: Headers | undefined;

	constructor(
		message?: string,
		status?: number,
		data?: any,
		headers?: Headers
	) {
		super(message ?? 'An error occurred');
		this.name = 'APIError';
		this.status = status;
		this.data = data;
		this.headers = headers;

		this.createFieldErrors();
	}

	private createFieldErrors() {
		if (this.data?.errors) {
			const errorMap = this.data.errors as Record<string, any>;
			this.fieldErrors = Object.entries(errorMap);
		}
	}
}

export default APIError;
