import {
	LayoutRouteProps,
	LoaderFunctionArgs,
	useLoaderData,
} from 'react-router-dom';
import Flex from 'ui/components/Flex';
import ImpactReportPanel from 'ui/components/ImpactReportPanel';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { LoaderData } from 'utils/types/loaderData';
import AirImpactReportsAPI from '../../api/AirImpactReportsAPI';

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	const reportData = await AirImpactReportsAPI.getImpactReport(
		params.publicationId as string
	);

	return {
		report: reportData,
	};
};

export function PublicationsDetailsPage() {
	const { report } = useLoaderData() as LoaderData<typeof loader>;

	return (
		<Flex direction="column" gap={16}>
			<ImpactReportPanel impactReport={report} showDownloadLink />
		</Flex>
	);
}

export const PUBLICATIONS_DETAILS_ROUTE: LayoutRouteProps = {
	loader,
	element: <PublicationsDetailsPage />,
	handle: {
		crumb: () => 'Details',
	},
};
